import {
    SET_WORKSPACE_INFORMATION_LOADING,
    SET_WORKSPACE_INFORMATION_SUCCESS,
    SET_WORKSPACE_INFORMATION_ERROR,
    SET_CREATE_WORKSPACE_LOADING,
    SET_CREATE_WORKSPACE_SUCCESS,
    SET_CREATE_WORKSPACE_ERROR
} from './constants';

const INITIAL_STATE = {
    information: {
        state: 'idle',
        data: {},
        error: ''
    },
    create: {
        state: 'idle',
        data: {},
        error: ''
    }
};

export const WorkspaceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_WORKSPACE_INFORMATION_LOADING:
            return {
                ...state,
                information: {
                    state: 'loading',
                    data: {},
                    error: ''
                }
            };
        case SET_WORKSPACE_INFORMATION_SUCCESS:
            return {
                ...state,
                information: {
                    state: 'success',
                    data: action.payload,
                    error: ''
                }
            };
        case SET_WORKSPACE_INFORMATION_ERROR:
            return {
                ...state,
                information: {
                    state: 'error',
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            };
        case SET_CREATE_WORKSPACE_LOADING:
            return {
                ...state,
                create: {
                    state: 'loading',
                    data: {},
                    error: ''
                }
            };
        case SET_CREATE_WORKSPACE_SUCCESS:
            return {
                ...state,
                create: {
                    state: 'success',
                    data: action.payload,
                    error: ''
                }
            };
        case SET_CREATE_WORKSPACE_ERROR:
            return {
                ...state,
                create: {
                    state: 'error',
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            };
        default:
            return state;
    }
};
