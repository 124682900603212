export const evaluateTagColor = (type) => {
    switch (type) {
        case "prospect":
            return "orange";
        case "customer":
            return "green"
        case "new prospects":
            return "purple"
        case "churned":
            return "red"
        default:
            return "primary"
    }
}