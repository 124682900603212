import { setMagicError, setMagicLinkLoading, setMagicSuccess } from "./actions";
import { axios } from '../../../loaders/axios.loader';

export const validateMagicLink = (token) => async (dispatch, getState) => {
    dispatch(setMagicLinkLoading())
    try {
        const request = await axios.post(
            `/auth/magic/verify`,
            {
                token
            }
        )
        const { response } = request.data
        dispatch(setMagicSuccess(response))
    } catch (error) {
        console.log(error);
        dispatch(setMagicError("oops ! invalid magic link"))
    }
}