import { LOAD_PUBLIC_FAN, LOAD_PUBLIC_FAN_ERROR, SET_ERROR, SET_LIST, SET_PUBLIC_FAN, SET_WORKSPACE } from "./constants"

export const setList = (payload) => {
    return {
        type: SET_LIST,
        payload
    }
}

export const setWorkspace = (payload) => {
    return {
        type: SET_WORKSPACE,
        payload
    }
}

export const setError = (payload) => {
    return {
        type: SET_ERROR,
        payload
    }
}

export const processPublicFan = () => {
    return {
        type: LOAD_PUBLIC_FAN
    }
}

export const setPublicFan = (payload) => {
    return {
        type: SET_PUBLIC_FAN,
        payload
    }
}

export const setPublicFanError = (error) => {
    return {
        type: LOAD_PUBLIC_FAN_ERROR,
        error
    }
}