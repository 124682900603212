import { Avatar, Box, Button, Flex, Grid, Stack, Tag, TagLabel, Text } from "@chakra-ui/react";
import Page from "../../../components/core/@page";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadReport } from "../../../redux/app/home/thunk";
import moment from "moment-timezone";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

function Report() {
  const { setCurrentFan, onFanModalOpen } = useOutletContext()
  const workspace = useSelector((state) => state.dashboard.core.workspace);
  const reportData = useSelector((state) => state.dashboard.home.report);
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf("month").toDate(),
    new Date(),
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { report } = useParams()

  function diff(duration, date) {
    const start = moment(date)
      .subtract(duration, "months")
      .set("date", 1)
      .format("YYYY-MM-DD");
    const end = moment(date).set("date", 1).format("YYYY-MM-DD");
    return { start, end };
  }

  useEffect(() => {
    if (workspace?.uuid && report) {
      dispatch(
        loadReport(
            workspace?.uuid,
            report
        )
      );
    }
  }, [workspace, report]);

  return (
    <Page
      title={"Report"}
      subtitle={reportData?.data?.name}
      actions={[]
      }
    >
      <Stack gap={4} p={4}>
        <Flex>
          <Grid gap={4} flex={0.6} gridTemplateColumns={'repeat(4, 1fr)'}>
            {(reportData.data?.views || []).map(view => {
                return <Stack justifyContent={"space-between"} rounded={8} p={4} border={"1px solid #cacaca"} gap={0}>
                <Box>
                  <Text fontWeight={"semibold"} flex={1}>{view.view?.name}</Text>
                </Box>
                <Text fontWeight={"semibold"} fontSize={"4xl"}>{view.total}</Text>
                <Text marginTop={2}>{view.view?.queryName}</Text>
              </Stack>
            })}
            
          </Grid>
        </Flex>
        <Flex gap={4}>
            {(reportData.data?.views || []).map(view => {
                return (
                    <Stack flex={0.33} rounded={8} p={4} border={"1px solid #cacaca"}>
                        <Box>
                        <Text fontWeight={"semibold"}>{view?.view?.queryName || view?.view?.name}</Text>
                        </Box>
                        <Stack>
                            {view.fans.map(fan => {
                            return <Flex cursor={"pointer"} onClick={() => {
                                setCurrentFan?.(fan.uuid)
                                onFanModalOpen?.()
                            }} gap={2} py={1}>
                                <Box mt={1}>
                                <Avatar size={"xs"} src={fan.profile.image} name={fan.fullName} />
                                </Box>
                                <Box flex={1}>
                                <Text>{fan.fullName}</Text>
                                <Text fontSize={"sm"} color={"gray"}>{fan.path[0].positions[0].name}</Text>
                                </Box>
                                <Tag h={"min-content"} px={1} rounded={2} size={"sm"} fontWeight={"medium"} my={1} w={"fit-content"} colorScheme="green">
                                <i className='fi fi-sr-star fui-fan-star'></i>
                                <TagLabel noOfLines={2}>{fan.score ? `${fan.score.toFixed(1)} / 5.0` : '-'}</TagLabel>
                                </Tag>
                            </Flex>
                            })}
                        </Stack>
                        <Button fontSize={"sm"} variant={"outline"} colorScheme="primary" onClick={() => {
                            navigate(`/home/fans?page=1${view.url}`)
                        }}>View more fans</Button>
                    </Stack>
                )
            })}
        </Flex>
      </Stack>
    </Page>
  );
}

export default Report;
