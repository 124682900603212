import { VALIDATE_MAGIC_LINK, SET_MAGIC_ERROR, SET_MAGIC_SUCCESS } from "./constants";

const INITIAL_STATE = {
    isLoading: true,
    success: false,
    data: {},
    error: ''
}

const MagicReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VALIDATE_MAGIC_LINK:
            return {
                ...state,
                success: false,
                error: '',
                isLoading: true
            }
        case SET_MAGIC_ERROR:
            return {
                ...state,
                success: false,
                error: action.error,
                isLoading: false
            }
        case SET_MAGIC_SUCCESS:
            return {
                ...state,
                success: true,
                error: '',
                data: action.payload,
                isLoading: false
            }
        default:
            return state
    }
}


export default MagicReducer;