import { SET_EVENTS_DETAIL_ERROR, SET_EVENTS_DETAIL_LOADING, SET_EVENTS_DETAIL_SUCCESS, SET_EVENTS_LIST_ERROR, SET_EVENTS_LIST_LOADING, SET_EVENTS_LIST_SUCCESS } from "./constants";

export function setEventsListLoading() {
    return {
        type: SET_EVENTS_LIST_LOADING
    }
}

export function setEventsListSuccess(payload, metadata) {
    return {
        type: SET_EVENTS_LIST_SUCCESS,
        payload,
        metadata
    }
}

export function setEventsListError (error) {
    return {
        type: SET_EVENTS_LIST_ERROR,
        error
    }
}

export function setEventDetailLoading() {
    return {
        type: SET_EVENTS_DETAIL_LOADING
    }
}

export function setEventDetailSuccess(payload, metadata) {
    return {
        type: SET_EVENTS_DETAIL_SUCCESS,
        payload,
        metadata
    }
}

export function setEventDetailError (error) {
    return {
        type: SET_EVENTS_DETAIL_ERROR,
        error
    }
}