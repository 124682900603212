import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getEventDetail } from "../../../redux/app/meetings/thunk"
import { Avatar, AvatarGroup, Box, Button, Divider, Flex, Grid, Heading, Icon, IconButton, Img, Link, OrderedList, Stack, Text } from "@chakra-ui/react"
import { ArrowLeftIcon, Forward } from "lucide-react"
import { TooltipAvatar } from "../../../components/layout/frame"
import { AttendeeCard } from "../recommendations"
import { axios } from "../../../loaders/axios.loader"

const CircleIcon = (props) => (
    <Icon viewBox='0 0 200 200' {...props}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
    </Icon>
  )


function CompanyDetail ({ website }) {
    const [state, setState] = useState("loading");
    const [profile, setProfile] = useState({})

    async function getCompanyProfileByWebsite (e) {
        try {
            const request = await axios.get(`/companies/getByWebsite?website=${encodeURIComponent(e)}`);
            const { response } = request.data;
            setProfile(response || {})
            setState("success")
        } catch (err) {
            setState("error")
        }
    }

    useEffect(() => {
        getCompanyProfileByWebsite(website)
    }, [])

    return (
        <Stack borderRadius={8} pb={4} border={"1px solid #e4e4e4"} alignItems={"start"}>
            <Img borderRadius={8} borderBottomRadius={0} objectFit={"cover"} h={140} src={profile.cover} />
            <Stack gap={0} p={2}>
                <Avatar size={"lg"} objectFit={"cover"} border={"4px solid white"} mt={-10} src={profile.image} />
                <Text fontSize={18} fontWeight={"bold"}>{profile.name}</Text>
                <Text fontSize={"sm"} color={"gray"}>{profile.tagline}</Text>
                <Flex mt={1} alignItems={"center"}>
                    <i style={{
                        marginRight: 4
                    }} className="fi fi-rr-marker"></i> 
                    <Text color="gray.700" fontSize={"sm"}>{profile?.headquarter?.city || ''}, {profile?.headquarter?.geographic_area || ''}</Text>
                    <CircleIcon style={{
                        marginTop: 4,
                        color: 'gray',
                        marginLeft: 4,
                        marginRight: 4
                    }} color="gray.700" boxSize={1} />
                    <Text color="gray.700" fontSize={"sm"}>{profile?.headquarter?.country || ''}</Text>
                </Flex>
                <Text mt={1} fontSize={"sm"}>{profile.headcount ? `${profile.headcount} employees` : ''}{profile.followers ? `, ${profile.followers} followers` : ''}</Text>
                <Button w={"fit-content"} as={Link} href={`https://www.linkedin.com/company/${profile?.linkedIn || profile?.reference?.replace("urn:li:fsd_profile:", "")}/`} target='_blank' ml={0} mt={3} variant={"outline"} colorScheme="primary" size={"sm"} leftIcon={<i className="fi fi-br-arrow-up-right fui-fan-star"></i>}>View Linkedin</Button>
            </Stack>
            {profile.news?.length ? <Stack px={2}>
                <Text fontWeight={"semibold"}>Latest news about {profile.name}</Text>
                <OrderedList gap={2} fontSize={14} ml={7}>
                    {profile.news?.map(news => {
                        return <li style={{ marginBottom: 4 }}>
                            <Stack gap={1}>
                                <Text>{news.summary}</Text>
                                <Flex gap={1}>
                                    <strong>Source</strong>:
                                    <Link _hover={{ textDecor: "underline" }} color={"var(--chakra-colors-primary-500)"} href={news.source} isExternal> {(new URL(news.source)).hostname}</Link>
                                    <Text as={"span"}>, <strong>Date</strong>: {news.date}</Text>
                                </Flex>
                            </Stack>
                        </li>
                    })}
                </OrderedList>
            </Stack> : <></>}
            
        </Stack>
    )
}

export default function MeetingDetail ({ id, onClose }) {
    const detail = useSelector((state) => state.dashboard.events.detail);
    const { meeting } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getEventDetail(meeting || id))
    }, [id, meeting])

    return (
        <Stack p={[2, 4]}>
            <Flex gap={4} align={"center"}>
                <IconButton
                    onClick={() => {
                        onClose?.()
                    }}
                    variant={"none"}
                    rounded={"full"}
                    border={"1px solid #e4e4e4"}
                    icon={<ArrowLeftIcon size={20} />}
                />
                <Stack gap={0} flex={1}>
                    <Heading fontSize={24}>{detail.data?.name}</Heading>
                    <AvatarGroup gap={2} mt={2} size='sm' max={3}>
                            {detail.data?.attendees?.map(attendee => {
                                return (
                                    <TooltipAvatar cursor={"pointer"} name={`${attendee.email}`} />
                                )
                            })}
                    </AvatarGroup>
                </Stack>
                <Flex>
                    <Button colorScheme="primary" rounded={"full"} fontSize={"sm"} leftIcon={<Forward size={16} />}>Share</Button>
                </Flex>
            </Flex>
            <Divider my={4} />
            <Flex gap={6}>
                <Stack flex={0.4}>
                    <Text fontSize={18} fontWeight={"semibold"}>About company</Text>
                    <Stack>
                        {Array.from(new Set(detail.data?.attendees?.map(e => e.email.split("@").pop()))).slice(0, 1).map(a => <CompanyDetail website={a} />)}
                    </Stack>
                </Stack>
                <Stack flex={0.6}>
                    <Text fontSize={18} fontWeight={"semibold"}>External Attendees</Text>
                    <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={3} >
                        {detail.data?.attendees?.map(a => {
                        return (
                            <AttendeeCard organizer={a.organizer} email={a.email} />
                        )
                        })}
                    </Grid>
                </Stack>
            </Flex>
        </Stack>
    )
}
