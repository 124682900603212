import { axios } from "../../../loaders/axios.loader"
import { setConnectedAccount, setConnectedAccountError, setConnectedAccountsLoading } from "./actions";

export const createConnectedAccount = (source, code, workspace) => async (dispatch) => {
    dispatch(setConnectedAccountsLoading())
    try {
        const request = await axios.post(
            '/accounts/connected-accounts',
            {
                source,
                code,
                workspace
            }
        )
        const { response } = request.data;
        dispatch(setConnectedAccount(response))
    } catch (err) {
        console.log(err)
        dispatch(setConnectedAccountError(err.toString()))
    }
}

export const reconnectConnectedAccount = (source, code, account, workspace) => async (dispatch) => {
    dispatch(setConnectedAccountsLoading())
    try {
        const request = await axios.post(
            `/accounts/connected-accounts/${account}/re-connect`,
            {
                source,
                code,
                workspace
            }
        )
        const { response } = request.data;
        dispatch(setConnectedAccount(response))
    } catch (err) {
        console.log(err)
        dispatch(setConnectedAccountError(err.toString()))
    }
}