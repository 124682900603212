import { LOAD_MY_NOTIFICATIONS, LOAD_NOTIFICATIONS, LOAD_PLATFORM_NOTIFICATIONS, SET_MY_NOTIFICATIONS, SET_NOTIFICATIONS, SET_NOTIFICATIONS_ERROR, SET_PLATFORM_NOTIFICATIONS } from "./constants";

const INITIAL_STATE = {
    platform: {
        status: "loading",
        data: [],
        error: ''
    },
    list: {
        status: "loading",
        data: [],
        error: ''
    },
    my: {
        status: "loading",
        data: [],
        error: ''
    },
    archive: {
        status: "pending",
        data: {},
        error: ''
    },
    get: {
        status: "pending",
        data: {},
        error: ''
    }
};

const NotificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_NOTIFICATIONS:
            return {
                ...state,
                list: {
                    status: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_NOTIFICATIONS:
            return {
                ...state,
                list: {
                    status: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case LOAD_MY_NOTIFICATIONS:
            return {
                ...state,
                my: {
                    status: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_MY_NOTIFICATIONS:
            return {
                ...state,
                my: {
                    status: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case LOAD_PLATFORM_NOTIFICATIONS:
            return {
                ...state,
                platform: {
                    status: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_PLATFORM_NOTIFICATIONS:
            return {
                ...state,
                platform: {
                    status: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                platform: {
                    status: "error",
                    data: [],
                    error: action.error
                },
                list: {
                    status: "error",
                    data: [],
                    error: action.error
                }
            }
        default:
            return state
    }
}

export default NotificationsReducer;