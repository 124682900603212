import { axios } from "../../../loaders/axios.loader"
import { setFanCalendarRecommendationsLoading, setFanCalendarRecommendationsSuccess, setFanConvertError, setFanConvertLoading, setFanConvertSuccess } from "./actions";

export const loadFanRecommendations = (workspace, email = [], user, { page = 1, search, replace = false, event }) => async (dispatch) => {
    dispatch(setFanCalendarRecommendationsLoading())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/fans/recommendations/calendar?page=${page}&size=25&email[]=${email.join('&email[]=')}&user=${user}&event=${event || ''}&keyword=${search || ''}`
        )
        const { response } = request.data;
        dispatch(setFanCalendarRecommendationsSuccess(response, replace))
    } catch (e) {
        console.log(e)
    }
};

export const convertFan = (workspace, fan, fanSince) => async (dispatch) => {
    dispatch(setFanConvertLoading())
    try {
        const request = await axios.post(
            `/workspaces/${workspace}/fans/convert`,
            {
                fan,
                fanSince
            }
        )
        const { response } = request.data;
        dispatch(setFanConvertSuccess(response))
    } catch (e) {
        dispatch(setFanConvertError(e.toString()))
        console.log(e)
    }
}