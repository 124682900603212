import { Alert, Box, ButtonGroup, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, IconButton, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Skeleton, Stack, Tag, TagLabel, TagLeftIcon, Text, Textarea, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import Page from "../../../components/core/@page";
import CalendarSvg from '../../../assets/img/calendar.svg';
import { Field, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { AArrowUpIcon, ArrowDownAZIcon, AudioLinesIcon, BuildingIcon, ChevronDownIcon, CircleDot, CopyIcon, DotIcon, HashIcon, LinkedinIcon, SearchIcon, SlidersHorizontalIcon, SparkleIcon, StarIcon, StarsIcon, ZapIcon } from "lucide-react";
import { convertFan, loadFanRecommendations } from "../../../redux/app/recommendations/thunk";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { resetFanConvert } from "../../../redux/app/recommendations/actions";
import moment from "moment-timezone";
import { axios } from "../../../loaders/axios.loader";
import { ArrowBackIcon } from "@chakra-ui/icons";
import _ from 'lodash';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { Badge } from "../../../components/ui/badge";
import { AvatarFallback, Avatar, AvatarImage } from "../../../components/ui/avatar";
import { generateFallbackText } from "../../../utils/utils";
import { generateHSL } from "../../../lib/utils";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../../../components/ui/hover-card";
import { Separator } from "../../../components/ui/separator";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";

export function AttendeeCard ({ email, organizer }) {
  const [state, setState] = useState("loading");
  const [profile, setProfile] = useState({})

  async function getUserProfileByEmail (e) {
    try {
      const request = await axios.get(`/directory/getByReference?email=${encodeURIComponent(e)}`);
      const { response } = request.data;
      setProfile(response)
      setState("success")
    } catch (err) {
      setState("error")
    }
  }

  useEffect(() => {
    getUserProfileByEmail(email)
  }, [])

  return (state === "loading" ? <Skeleton height={100} /> : state === "error" ? <></> :
    <Stack pos={"relative"} border={"1px solid #e4e4e4"} borderRadius={10} py={3} px={2} >
      <Flex alignItems={"center"} gap={3}>
        <Avatar mt={1} src={profile.images ? profile.image : profile.image} name={profile.fullName} />
        <Stack flex={1} gap={0}>
          <Flex w={"100%"}>
          <Text gap={1} alignItems={"center"} display={"inline-flex"}>{profile.fullName} <Link target="_blank" href={`https://www.linkedin.com/in/${profile.linkedIn}`} ><LinkedinIcon size={14} /></Link></Text>
          </Flex>
          <Text fontSize={"sm"} color={"gray"}>{email}</Text>
          {organizer ? <Tag pos={"absolute"} right={1} top={1} mt={1} size={"sm"} verticalAlign={"middle"} rounded={"full"} colorScheme="orange">Organizer</Tag> : <Box></Box>}
        </Stack>
      </Flex>
      <Stack gap={1}>
        <Flex flexDirection={"column"}>
          <Text noOfLines={1} title={profile?.experience?.[0]?.positions?.[0]?.name} alignItems={"center"} gap={2} fontWeight={"semibold"} fontSize={"sm"}>
            {profile?.experience?.[0]?.positions?.[0]?.name}
          </Text>
          {/* <DotIcon style={{
            display: "inline",
            verticalAlign: "middle"
          }} color="gray" size={16} /> */}
         {profile?.experience?.[0]?.positions?.[0]?.startedOn ? <Text color={"gray"} fontSize={"sm"}>
            {moment(profile?.experience?.[0]?.positions?.[0]?.startedOn).fromNow(true)} in role
          </Text> : <></>}
          {/* <Popover isOpen={false} trigger="hover">
            <PopoverTrigger> */}
              <Text cursor={"pointer"} title={profile?.summary} noOfLines={2} alignItems={"center"} gap={2} fontSize={"sm"}>
                {profile?.summary}
              </Text>
            {/* </PopoverTrigger>
            <PopoverContent>
              <PopoverBody p={4}>
                {profile?.summary}
              </PopoverBody>
            </PopoverContent>
          </Popover> */}
        </Flex>
        {/* <Text fontSize={"sm"}>{profile?.headline}</Text> */}
      </Stack>
    </Stack>
  )
}

export default function Recommendations () {
    const ctx = useOutletContext();
    const workspace = useSelector(state => state.dashboard.core.workspace);
    const calendar = useSelector(state => state.dashboard.recommendations.calendar);
    const convert = useSelector(state => state.dashboard.recommendations.convert);
    const lists = useSelector(state => state.dashboard.core.lists);
    const isLoading = useSelector(state => state.dashboard.fans.isLoading)
    const fans = useSelector(state => state.dashboard.fans.data)
    const total = useSelector(state => state.dashboard.fans.total)
    const profile = useSelector(state => state.dashboard.core.profile)
    const [currentFan, setCurrentFan] = useState(null)
    const { onClose, onOpen, isOpen } = useDisclosure()
    const { onClose: onEventClose, onOpen: onEventOpen, isOpen: isEventOpen } = useDisclosure()
    const { onClose: onEventsClose, onOpen: onEventsOpen, isOpen: isEventsOpen } = useDisclosure()
    const params = useParams();
    const formRef = useRef();
    const dispatch = useDispatch()
    const [date, setDate] = useState(undefined);
    const toast = useToast();
    const [page, setPage] = useState(1)
    const [addedFans, setAddedFans] = useState([])
    const [event, currentEvent] = useState({ "attendees": [] });
    const [events, setEvents] = useState([])
    const [searchParams] = useSearchParams()
    const timer = useRef()

    const inputDebouncer = _.debounce((e) => {
      dispatch(loadFanRecommendations(workspace.uuid, [], profile?.uuid, { page: 1, search: e.target.value || '', replace: true, event: searchParams.get("event") }))
    }, 300)

    useEffect(() => {
      if (workspace.uuid) {
        dispatch(loadFanRecommendations(workspace.uuid, [], profile?.uuid, { page, event: searchParams.get("event") }))
      }
    }, [workspace])

    useEffect(() => {
      if (convert.state === "success") {
        dispatch(loadFanRecommendations(workspace.uuid, [], profile?.uuid, { page: 1, event: searchParams.get("event") }))
        setAddedFans([
          ...addedFans,
          currentFan
        ])
        dispatch(resetFanConvert())
        toast({
          title: "Fan added to your fanbase",
          status: "success"
        })
        setCurrentFan(null)
        onClose()
      }
    }, [convert])

    useEffect(() => {
      if (calendar.data.length < 24 && calendar.state !== "loading" && !searchParams.get("event")) {
        if (!timer.current) {
          timer.current = setInterval(() => {
            dispatch(loadFanRecommendations(workspace.uuid, [], profile?.uuid, { page: page + 1, event: searchParams.get("event") }))
            setPage((page) => page += 1)
          }, 5000)
        }
      } else if (timer.current) {
        clearInterval(timer.current)
        timer.current = null
      }
    }, [calendar, timer, page])

    function evaluateLatestEvent (events) {
      let event;
      for (const e of events) {
        if (!event) {
          event = e
        } else {
          const eventDate = new Date(e.start.date).getTime()
          const existingEventDate = new Date(event.start.date).getTime()
          if (eventDate > existingEventDate) {
            event = e
          }
        }
      }
      return event
    }

    return (
        <Page
          title={"Fan Recommendations"}
          subtitle={"Explore fan recommendations based on their interactions across multiple platforms"}
    > 
      <Modal size={"4xl"} isCentered onClose={() => {
        onEventClose()
      }} isOpen={isEventOpen}>
        <ModalOverlay></ModalOverlay>
        <ModalContent>
          <ModalBody pb={8}>
            <Flex justifyContent={"space-between"} alignItems={"center"} pb={3}>
              <Flex gap={4} alignItems={"center"}>
                <IconButton onClick={() => {
                  currentEvent({ "attendees": [] })
                  onEventClose()
                  onEventsOpen()
                }} rounded={"full"}  icon={<ArrowBackIcon />} />
                <Stack gap={0}>
                  <Text fontSize={"xl"} fontWeight={"semibold"}>{event?.name}</Text>
                  <Text fontSize={"sm"} color={"gray"}>{moment.parseZone(event?.start?.date).format("DD MMM, yyyy HH:mm a")}</Text>
                </Stack>
              </Flex>
              <Button leftIcon={<img src={CalendarSvg} width={16} height={16} alt="calendar logo" />} size={"sm"} rounded={"full"} variant={"outline"} as={Link} target="_blank" href={event?.url}>View on calendar</Button>
            </Flex>
            <Stack gap={4}>
              <Box>
                <Text mb={4} pb={2} borderBottom={"1px solid #cacaca"} fontWeight={"semibold"}>Attendees</Text>
                <Grid gridTemplateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={3} flex={0.5}>
                  {event.attendees.map(a => {
                    return (
                      <AttendeeCard organizer={a.organizer} email={a.email} />
                    )
                  })}
                </Grid>
              </Box>
              <Box>
                <Text mb={4} pb={2} borderBottom={"1px solid #cacaca"} fontWeight={"semibold"}>Description</Text>
                <Stack flex={0.5}>
                  <Text dangerouslySetInnerHTML={{
                    __html: event.summary
                  }}></Text>
                </Stack>
              </Box>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal size={"2xl"} isCentered onClose={() => {
        setCurrentFan(null)
        onEventsClose()
      }} isOpen={isEventsOpen}>
        <ModalOverlay></ModalOverlay>
        <ModalContent>
          {/* <ModalHeader>
            Event Information
          </ModalHeader> */}
          <ModalBody pt={4} pb={8}>
            <Flex justifyContent={"space-between"} alignItems={"center"} pb={3}>
              <Stack gap={0}>
                <Text fontSize={"xl"} fontWeight={"semibold"}>Your meetings with {currentFan?.fullName}</Text>
                <Text fontSize={"sm"} color={"gray"}>{currentFan?.events?.length} meeting{currentFan?.events?.length > 1 ? 's' : ''}</Text>
              </Stack>
            </Flex>
            <Stack gap={4}>
            {(_.cloneDeep(currentFan?.events || [])).toSorted((a, b) => new Date(b?.start?.date).getTime() - new Date(a?.start?.date).getTime()).map(e => {
                return (
                  <Flex pb={2} borderBottom={"1px solid #e4e4e4"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack gap={0}>
                      <Text fontSize={"lg"} fontWeight={"semibold"}>{e.name}</Text>
                      <Text color={"gray"}>{moment.parseZone(e?.start?.date).format("DD MMM, yyyy hh:mm a")}</Text>
                      <Tag fontWeight={'bold'} mt={1} w={"fit-content"} rounded={"full"}>{e.attendees?.length} attendees</Tag>
                      {/* <Text fontSize={"sm"} color={"gray"}>Organizer: {e.attendees.filter(a => a.organizer)[0]?.email}</Text> */}
                    </Stack>
                    <Button size={"sm"} rounded={"full"} colorScheme="primary" onClick={() => {
                      currentEvent(e)
                      onEventsClose();
                      onEventOpen()
                    }}>View event</Button>
                  </Flex>
                )
              })}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal size={"lg"} isCentered onClose={() => {
        onClose()
        setCurrentFan(null)
      }} isOpen={isOpen}>
        <ModalOverlay></ModalOverlay>
        <Formik
          initialValues={{
            fanSince: undefined
          }}
          onSubmit={(values) => {
            dispatch(convertFan(workspace?.uuid, currentFan, date))
          }}
        >
          {({ handleSubmit, errors, touched }) => {
            return (<form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <ModalContent>
                <ModalHeader>Add fan to fanbase</ModalHeader>
                <ModalBody>
                  <Stack gap={4}>
                    {/* {invitation.error?.length ? <Alert variant={"solid"} fontSize={"sm"} rounded={6} mb={2} status="error">
                      {invitation.error}
                    </Alert> : <></>} */}
                    <FormControl
                      size={"sm"}
                      isInvalid={!!errors.fanSince && touched.fanSince}
                    >
                      <FormLabel fontSize={"sm"} htmlFor="fanSince">
                        When did the user become your fan ?
                      </FormLabel>
                      <Field
                        size={"sm"}
                        validate={(value) => {
                          let error;
                          if (!date) {
                            error = "Please enter when did the user become your fan";
                          }
                          return error;
                        }}
                        render={() => 
                            <SingleDatepicker
                        name="fanSince"
                                configs={{
                                    dateFormat: 'MM-dd-yyyy'
                                }}
                                date={date}
                                onDateChange={setDate}
                            />}
                        id="fanSince"
                        name="fanSince"
                        variant="filled"
                      />
                      <FormErrorMessage>{errors.fanSince}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant={"outline"}
                    mr={3}
                    size={"sm"}
                    onClick={() => {
                      dispatch(resetFanConvert())
                      setCurrentFan(null)
                      onClose()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={convert.state === "loading"}
                    type="submit"
                    colorScheme="primary"
                    size={"sm"}
                    variant="solid"
                  >
                    Add fan
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )}}
        </Formik>
      </Modal>

      <Separator />
        <Flex alignItems={"center"} py={2.5} px={4} gap={2}>
          <Box pos={"relative"}>
              <SearchIcon className="absolute left-3 top-2 h-4 w-4 rounded-full text-muted-foreground" />
              <Input onChange={inputDebouncer} placeholder="Search fan recommendations" className="pl-8 w-60 h-8 rounded-full" />
          </Box>
        </Flex>
        <Stack gap={0} justifyContent={"space-between"} height={"calc(100vh - 125px)"}>
        <Box flex={1} h={"calc(100vh - 125px - 90px)"} overflow={"scroll"} onScroll={(event) => {
          const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
          if (!isLoading) {
            if (scrollHeight - scrollTop - 0.5 === clientHeight) {
              // User has scrolled to the bottom, trigger data fetching

              dispatch(loadFanRecommendations(workspace.uuid, [], profile?.uuid, { page: page + 1, event: searchParams.get("event") }))
              setPage(page + 1)
            }
          }
        }} className="border border-x-0">
          <Table className={"table-fixed"}>
            <TableHeader>
            <TableRow >
                  {[
                    {
                      name: "Name",
                    },
                    {
                      name: "LinkedIn",
                      icon: <LinkedinIcon size={16} strokeWidth={2} />
                    },
                    {
                      name: "Role",
                      icon: <AArrowUpIcon size={16} strokeWidth={2} />
                    },
                    {
                      name: "Company",
                      icon: <BuildingIcon size={16} strokeWidth={2} />
                    },
                    {
                      name: "Fan Strength",
                      icon: <ZapIcon size={16} strokeWidth={2} />
                    },
                    {
                      name: "Total Events",
                      icon: <HashIcon size={16} strokeWidth={2} />
                    }
                  ].map((header, index, arr) => {
                    return (
                      <TableHead colspan={[0, 2, 5].includes(index) ? 2 : 1} className={`h-10 font-semibold text-black ${index === 0 ? "border-l-0" : ""}`} width={180}>
                          <Flex gap={1} alignItems={"center"}>
                          {header.icon}
                          {header.name}
                          </Flex>
                      </TableHead>
                    )
                  })}
                </TableRow>
            </TableHeader>
            <TableBody>
              {calendar.data?.length ? (
                calendar.data.map((e) => {
                  const location = e.profile[0]?.location?.name;
                  const startedOn = e.path[0].positions[0].startedOn;
                  const startedOnFormatted = moment.parseZone(startedOn).format( "MMM yyyy")
                  const linkedIn = e.profile[0]?.linkedIn
                  const role = e.path[0].positions[0].name;
                  const company = e.path?.[0]?.company || {};
                  const fanSince = moment.parseZone(e.fanSince).fromNow(true)
                  const now = moment()
                  const jobDiff = now.diff(startedOn, "months")
                  const isPromotion = e.path[0].positions.length > 1
                  const image =
                    !e?.profile?.[0]?.images
                      ? e.profile?.[0].cover
                      : e.profile?.[0].image;
                  return (
                    <TableRow
                      // data-state={row.getIsSelected() && "selected"}
                    >
                    <TableCell onClick={() => {
                      // ctx?.setCurrentFan?.(e.uuid)
                      // ctx?.onFanModalOpen?.()
                    }} colspan={2} className={"px-4 cursor-pointer border-l-0 group"}>
                      {/* <Checkbox
                        iconSize={14}
                        className="h-4 w-4"
                          aria-label="Select all"
                        /> */}
                        <HoverCard openDelay={300}>
                          <HoverCardTrigger>
                            <Flex gap={2} alignItems={"center"}>
                              <Avatar>
                                <AvatarImage  className={"w-4 h-4 rounded-xl"} src={image} />
                                <AvatarFallback style={{
                                        backgroundColor: `${generateHSL(
                                          generateFallbackText(e.fullName)
                                        )}`,
                                      }} className={"w-4 h-4 text-xs"}>{generateFallbackText(e.firstName)}</AvatarFallback>
                              </Avatar>
                              <Text flex={1} fontWeight={500} noOfLines={1}>{e.fullName}</Text>
                              <span onClick={async (ev) => {
                                ev.preventDefault()
                                ev.stopPropagation()
                                await window.navigator.clipboard.writeText(e.fullName)
                                toast({
                                  title: "Copied to clipboard"
                                })
                              }} size={"xs"} className={`h-auto transition opacity-0 text-gray-500 bg-transparent translate-x-4 delay-300 group-hover:opacity-100 duration-300 group-hover:translate-x-2`}>
                                <CopyIcon size={14} />
                              </span>
                            </Flex>
                            </HoverCardTrigger>
                            <HoverCardContent align={"start"} className={"w-80"}>
                              <Flex gap={2} alignItems={"center"}>
                                <Avatar>
                                  <AvatarImage  className={"w-10 h-10 rounded-full"} src={image} />
                                  <AvatarFallback style={{
                                          backgroundColor: `${generateHSL(
                                            generateFallbackText(e.fullName)
                                          )}`,
                                        }} className={"w-10 h-10 text-sm rounded-full"}>{generateFallbackText(e.firstName)}</AvatarFallback>
                                </Avatar>
                                <Box>
                                  <Text className="font-semibold text-base">{e.fullName}</Text>
                                  <Text mt={-0.5} className="text-sm text-muted-foreground">{location}</Text>
                                  <Text mt={-0.5} className="text-primary underline" onClick={(ev) => {
                                    ev.preventDefault()
                                    window.open(`https://www.linkedin.com/in/${linkedIn}`, "_blank")
                                  }}>{e.profile.linkedIn}</Text>
                                </Box>
                              </Flex>
                              <Badge className={"mt-2"} variant="outline">Fan since {fanSince}</Badge>
                              <ButtonGroup w={"full"}  mt={3} justifyContent={"flex-end"}>
                                <Button onClick={() => {
                                  ctx?.setCurrentFan?.(e.uuid)
                                  ctx?.onFanModalOpen?.()
                                }} size={"sm"} className="text-sm" rounded={"full"} variant={"outline"}>View fan</Button>
                                <Button onClick={addedFans.includes(e.uuid) ? () => {} : () => {
                                  setCurrentFan(e.uuid)
                                  onOpen()
                                }} leftIcon={<SparkleIcon size={16} />} className={"rounded-full"} size={"sm"} colorScheme={addedFans.includes(e.uuid) ? "green" : "primary"}>{addedFans.includes(e.uuid) ? "Added" : "Add to Fanbase"}</Button>
                            </ButtonGroup>
                            </HoverCardContent>
                          </HoverCard>
                    </TableCell>
                    <TableCell colspan={1} className={"group"}>
                      <Flex alignItems={"center"}>
                      <Text flex={1} onClick={() => {
                        window.open(`https://www.linkedin.com/in/${linkedIn}`, "_blank")
                      }} className="underline cursor-pointer" noOfLines={1}>{linkedIn}</Text>
                      <span onClick={async (ev) => {
                                ev.preventDefault()
                                ev.stopPropagation()
                                await window.navigator.clipboard.writeText(`https://www.linkedin.com/in/${linkedIn}`)
                                toast({
                                  title: "Copied to clipboard"
                                })
                              }} size={"xs"} className={`h-auto transition opacity-0 text-gray-500 bg-transparent translate-x-4 delay-300 group-hover:opacity-100 duration-300 group-hover:translate-x-2`}>
                                <CopyIcon size={14} />
                              </span>
                      </Flex>
                    </TableCell>
                    <TableCell colspan={2}>
                      <Text noOfLines={1}>{role}</Text>
                    </TableCell>
                    <TableCell colspan={1}>
                      <Flex gap={2} alignItems={"center"}>
                        <Avatar>
                          <AvatarImage className={"w-4 h-4 rounded-xl"} src={company?.image} />
                          <AvatarFallback style={{
                                  backgroundColor: `${generateHSL(
                                    generateFallbackText(company?.name)
                                  )}`,
                                }} className={"w-4 h-4 text-xs"}>{generateFallbackText(company?.name)}</AvatarFallback>
                        </Avatar>
                        <Text fontWeight={500} noOfLines={1}>{company?.name}</Text>
                      </Flex>
                    </TableCell>
                    <TableCell colspan={1}>
                      <Badge variant="transparent" className={"text-sm text-medium gap-2"}>{
                        e.score > 4.4 ? <ZapIcon size={16} fill="#22c55e" stroke={"#22c55e"} /> : <div className={`w-2.5 h-2.5 rounded-sm ${e.score > 4 ? "bg-green-500" : e.score > 3 ? "bg-blue-500" : e.score  > 2 ? "bg-yellow-500" : "bg-red-500"}`} />
                        }{
                        e.score > 4.4 ? "Very Strong" : e.score > 4 ? "Strong" : e.score > 3 ? "Good" : e.score > 2 ? "Weak" : "Very Weak"
                      }</Badge>
                    </TableCell>
                    <TableCell colspan={2}>
                      {e.eventSize || 1}
                      {/* <Flex>
                      {jobDiff <= 3 ? <Badge variant={isPromotion ? "warning-subtle" : "default-subtle"}>{isPromotion ? `Got promoted recently` : `Changed jobs recently`}</Badge> : <></>}
                      </Flex> */}
                    </TableCell>
                    </TableRow>
                  )})
              ) : calendar.state !== "loading" && !calendar.data?.length ? (
                <TableRow>
                  <TableCell colSpan={9} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              ) : <></>}
              {calendar.state === "loading" ? (
                [...Array(20)].map(e => <TableRow>
                  <TableCell colSpan={2} className="text-center">
                   <Flex alignItems={"center"} gap={2}>
                   <Skeleton className="w-5 h-4 rounded-full" />
                   <Skeleton className="size-4 w-full h-2" />
                   </Flex>
                  </TableCell>
                  <TableCell colSpan={1} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                  <TableCell colSpan={2} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                  <TableCell colSpan={1} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                  <TableCell colSpan={1} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                  <TableCell colSpan={2} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                </TableRow>)
              ) : <></>}
            </TableBody>
          </Table>
          
        </Box>
        <Table className={"table-fixed border-0"}>
            <TableHeader>
            <TableRow className={"border-0"} style={{
                borderWidth: 0
              }}>
                      <TableHead colspan={2} className={`h-9 font-semibold text-black`} width={180}>
                          <Box></Box>
                      </TableHead>
                      <TableHead colspan={1} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                      <TableHead colspan={2} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                      <TableHead colspan={1} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                      <TableHead colspan={1} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                      <TableHead colspan={2} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                </TableRow>
            </TableHeader>
            </Table>
        </Stack>
    </Page>
    )
}