import { SET_CONNECTION_ERROR, SET_CONNECTION_LOADING, SET_CONNECTION_SUCCESS } from "./constants";

const INITIAL_STATE = {
    connection: {
        state: "loading",
        data: {},
        error: ''
    }
}

const ConnectReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CONNECTION_LOADING:
            return {
                ...state,
                connection: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_CONNECTION_SUCCESS:
            return {
                ...state,
                connection: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_CONNECTION_ERROR:
            return {
                ...state,
                connection: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        default:
            return state;
    }
}

export default ConnectReducer;