/* eslint-disable import/no-anonymous-default-export */
import './editor.scss'

import CharacterCount from '@tiptap/extension-character-count'
import Document from '@tiptap/extension-document'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { Button, Text as ChakraText, Flex, IconButton, ListItem } from '@chakra-ui/react'
import { Editor, EditorContent, EditorProvider, useCurrentEditor, useEditor } from '@tiptap/react'
import React, { useEffect } from 'react'

import suggestion from './suggestion.js'
import { Box } from '@chakra-ui/react'
import MenuBar from './MenuBar.js'
import Color from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import PlaceHolder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import { Bold, Code, Fan, Italic, List, ListOrdered, Strikethrough } from 'lucide-react'
import { useSelector } from 'react-redux'
import { PluginKey } from '@tiptap/pm/state'
import mixpanelEvents from '../../../utils/events'

function parseMentions(data) {
  const mentions = (data.content || []).flatMap(parseMentions)
  if (data.type === 'mention') {
    mentions.push(data.attrs)
  }
  return mentions
}

const UsersMention = (data) => Mention.extend({
  name: "mention"
}).configure({
  HTMLAttributes: {
    class: 'mention',
  },
  suggestion: suggestion([
    ...data.map(t => ({ "id": t.uuid, "label": t.name })),
    {
      "id": "support@fanbaseai.com",
      "label": "FanBase Support"
    },
    {
      "id": "fanactivity",
      "label": "FanActivity"
    }
  ])
})

const HashMention = Mention.extend({
  name: "hashtag"
}).configure({
  HTMLAttributes: {
    class: 'hashtag',
  },
  suggestion: suggestion([
    {
      "id": "fanbot",
      "label": "Fan Notification"
    }
  ], '#', new PluginKey("hashtag"))
})

export default ({ isLoading, onClick }) => {
  const limit = 280000000
  const team = useSelector((state) => state.dashboard.team.data);

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      PlaceHolder.configure({
        placeholder: "Enter your comment"
      }),
      CharacterCount.configure({
        limit,
      }),
      UsersMention(team),
      HashMention,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
    ],
    content:''
  })

  const percentage = editor
    ? Math.round((100 / limit) * editor.storage.characterCount.characters())
    : 0

  

  return (
      <Box borderRadius={6} flex={1} style={{
        border: "1px solid #757575"
        }}>
       <EditorContent style={{
            padding: "16px"
        }} editor={editor} />
        {editor && <Flex justifyContent={"space-between"}>
            <Flex flexWrap={"wrap"}>
        <IconButton
        borderRadius={0}
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        icon={<Bold size={16} />}
        isActive={editor.isActive('bold')}
        backgroundColor={"transparent"}
      />
      <IconButton
        borderRadius={0}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        icon={<Italic size={16} />}
        isActive={editor.isActive('italic')}
        backgroundColor={"transparent"}
      />
      <IconButton
        borderRadius={0}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        icon={<Strikethrough size={16} />}
        isActive={editor.isActive('strike')}
        backgroundColor={"transparent"}
      />
      <IconButton
        borderRadius={0}
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleCode()
            .run()
        }
        icon={<Code size={16} />}
        backgroundColor={"transparent"}
        isActive={editor.isActive('code')}
      />
      <IconButton
        borderRadius={0}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        }
        icon={<List size={16} />}
        backgroundColor={"transparent"}
        isActive={editor.isActive('bulletList')}
      />
      <IconButton
        borderRadius={0}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleOrderedList()
            .run()
        }
        backgroundColor={"transparent"}
        icon={<ListOrdered size={16} />}
        isActive={editor.isActive('orderedList')}
      />
        </Flex>
        <Flex>
      <Button isLoading={isLoading} onClick={() => {
       mixpanelEvents.trackFanActivityUsed(editor.getHTML(), parseMentions(editor.getJSON())); 
        onClick?.(editor.getHTML(), parseMentions(editor.getJSON()))
        editor.commands.clearContent()
      }} borderRadius={3} size={"sm"} m={1} colorScheme='primary'>Add comment</Button>
    </Flex>
            </Flex>}
      </Box>
  )
}