import { SET_CONNECTION_ERROR, SET_CONNECTION_LOADING, SET_CONNECTION_SUCCESS } from "./constants";

export function setConnectionLoading () {
    return {
        type: SET_CONNECTION_LOADING
    }
}

export function setConnectionSuccess (payload) {
    return {
        type: SET_CONNECTION_SUCCESS,
        payload
    }
}

export function setConnectionError (error) {
    return {
        type: SET_CONNECTION_ERROR,
        error
    }
}