import { Center, Icon, Spinner, Stack, Text } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { validateMagicLink } from "../../redux/auth/magic/thunk"
import { useNavigate, useSearchParams } from "react-router-dom"
import { axios } from "../../loaders/axios.loader"
import { useCookies } from "react-cookie"

function MagicLink() {
    const isLoading = useSelector((state) => state.auth.magic.isLoading)
    const error = useSelector((state) => state.auth.magic.error)
    const success = useSelector((state) => state.auth.magic.success)
    const data = useSelector((state) => state.auth.magic.data)
    const dispatch = useDispatch();
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies(["accessToken"])

    useEffect(() => {
        const code = params.get("token")
        if (!code) {
            window.location.href = "/login"
        } else {
            dispatch(validateMagicLink(code))
        }
    }, [])

    useEffect(() => {
        if (!isLoading && success) {
            setCookie("accessToken", data.accessToken)
            axios.defaults.headers["Authorization"] = `Bearer ${data.accessToken}`
            navigate(`/authorize?code=${data.accessToken}&redirectUrl=${encodeURIComponent(params.get("redirectUrl")) || "/welcome"}`, {
                replace: true
            })
        }
    }, [ success, data, error, isLoading ])


    return (
        <Center h={"90vh"}>
            <Stack align={"center"}>
                {isLoading || success ? <><Spinner />
                <Text >logging you in</Text>
                </> : <><i style={{ fontSize: 36, color: "#C53030" }} class="fi fi-rr-exclamation"></i>
                <Text mt={1} color={"red.600"} >{error}</Text></>}
            </Stack>
        </Center>
    )
}

export default MagicLink