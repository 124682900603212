import { RESET_INVITATION, SET_INVITATION_ERROR, SET_INVITATION_LOADING, SET_INVITATION_SUCCESS, SET_REMOVE_INVITATION_ERROR, SET_REMOVE_INVITATION_LOADING, SET_REMOVE_INVITATION_SUCCESS, SET_TEAM_ERROR, SET_TEAM_LOADING, SET_TEAM_SUCCESS } from "./constants";

export function setTeamLoading () {
    return {
        type: SET_TEAM_LOADING
    }
}

export function setTeam (payload) {
    return {
        type: SET_TEAM_SUCCESS,
        payload
    }
}

export function setTeamError (error) {
    return {
        type: SET_TEAM_ERROR,
        error
    }
}

export function sendInvitation () {
    return {
        type: SET_INVITATION_LOADING
    }
}

export function resetInvitation () {
    return {
        type: RESET_INVITATION
    }
}

export function setInvitationSuccess (payload) {
    return {
        type: SET_INVITATION_SUCCESS,
        payload
    }
}

export function setInvitationError (error) {
    return {
        type: SET_INVITATION_ERROR,
        error
    }
}

export function setRemoveLoading () {
    return {
        type: SET_REMOVE_INVITATION_LOADING
    }
}

export function setRemoveSuccess (payload) {
    return {
        type: SET_REMOVE_INVITATION_SUCCESS,
        payload
    }
}

export function setRemoveError (error) {
    return {
        type: SET_REMOVE_INVITATION_ERROR,
        error
    }
}