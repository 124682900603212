/**
 * your actions will go here
 */

import { LOAD_ALL_ACTIONS, LOAD_ALL_APPS, LOAD_PROPERTIES, LOAD_SPECIFIC_APP, LOAD_UPDATE_MAPPING, LOAD_UPDATE_MAPPING_ERROR, LOAD_WORKSPACE_CONNECTIONS, RESET_MAPPING, SET_ALL_ACTIONS, SET_ALL_ACTIONS_ERROR, SET_ALL_APPS, SET_ALL_APPS_ERROR, SET_PROPERTIES, SET_SPECIFIC_APP, SET_SPECIFIC_APP_ERROR, SET_UPDATE_MAPPING, SET_WORKSPACE_CONNECTIONS, SET_WORKSPACE_CONNECTIONS_ERROR } from "./constants";

export function loadApps () {
    return {
        type: LOAD_ALL_APPS
    }
}

export function setApps (payload) {
    return {
        type: SET_ALL_APPS,
        payload
    }
}

export function setAppsError (error) {
    return {
        type: SET_ALL_APPS_ERROR,
        error
    }
}

export function loadWorkspaceConnections () {
    return {
        type: LOAD_WORKSPACE_CONNECTIONS
    }
}

export function setWorkspaceConnections (payload) {
    return {
        type: SET_WORKSPACE_CONNECTIONS,
        payload
    }
}

export function setWorkspaceConnectionsError (error) {
    return {
        type: SET_WORKSPACE_CONNECTIONS_ERROR,
        error
    }
}

export function loadSpecificApp () {
    return {
        type: LOAD_SPECIFIC_APP
    }
}

export function setSpecificApp (payload) {
    return {
        type: SET_SPECIFIC_APP,
        payload
    }
}

export function setSpecificError (error) {
    return {
        type: SET_SPECIFIC_APP_ERROR,
        error
    }
}

export function loadActions () {
    return {
        type: LOAD_ALL_ACTIONS
    }
}

export function setActions (payload) {
    return {
        type: SET_ALL_ACTIONS,
        payload
    }
}

export function setActionsError (error) {
    return {
        type: SET_ALL_ACTIONS_ERROR,
        error
    }
}

export function loadProperties () {
    return {
        type: LOAD_PROPERTIES
    }
}

export function setProperties (payload) {
    return {
        type: SET_PROPERTIES,
        payload
    }
}

export function loadPropertiesError (error) {
    return {
        type: SET_PROPERTIES,
        error
    }
}

export function loadUpdateMapping () {
    return {
        type: LOAD_UPDATE_MAPPING
    }
}

export function setUpdateMapping (payload) {
    return {
        type: SET_UPDATE_MAPPING,
        payload
    }
}

export function loadUpdateMappingError (error) {
    return {
        type: LOAD_UPDATE_MAPPING_ERROR,
        error
    }
}

export function resetMapping () {
    return {
        type: RESET_MAPPING
    }
}