import { LINK_SUCCESS, PROCESS_LOGIN, SET_EMAIL, SET_ERROR, SET_INVITATION_ACTION_ERROR, SET_INVITATION_ACTION_LOADING, SET_INVITATION_ACTION_SUCCESS, SET_SUCCESS } from "./constants";

export const processLogin = () => {
    return {
        type: PROCESS_LOGIN
    }
}

export const setLoginError = (payload) => {
    return {
        type: SET_ERROR,
        payload
    }
}

export const setEmail = (email) => {
    return {
        type: SET_EMAIL,
        payload: email
    }
}

export const setLinkSuccess = () => {
    return {
        type: LINK_SUCCESS
    }
}

export const setLoginSuccess = (payload) => {
    return {
        type: SET_SUCCESS,
        payload
    }
}

export const setInvitationLoading = () => {
    return {
        type: SET_INVITATION_ACTION_LOADING
    }
}


export const setInvitationSuccess = (payload) => {
    return {
        type: SET_INVITATION_ACTION_SUCCESS,
        payload
    }
}

export const setInvitationError = (error) => {
    return {
        type: SET_INVITATION_ACTION_ERROR,
        error
    }
}