import { configureStore, combineReducers } from '@reduxjs/toolkit'
import LoginReducer from './auth/login/reducers'
import SharedListReducer from './app/shared/list/reducers'
import HomeReducer from './app/home/reducers'
import FansReducer from './app/fans/reducers'
import CoreReducer from './app/core/reducers'
import MagicReducer from './auth/magic/reducers'
import TeamReducer from './app/team/reducers'
import ChatReducer from './app/chat/reducers'
import AccountsReducer from './app/accounts/reducers'
import AppsReducer from './app/apps/reducers'
import EventsReducer from './app/meetings/reducers'
import ConnectReducer from './app/connect/reducers'
import OnboardingReducer from './app/onboarding/reducers'
import RecommendationsReducer from './app/recommendations/reducers'
import NotificationsReducer from './app/notifications/reducers'
import { ProfileReducer } from './app/user/reducers'
import { WorkspaceReducer } from './app/workspaces/reducers'

export const store = configureStore({
    reducer: {
        auth: combineReducers({
            login: LoginReducer,
            magic: MagicReducer
        }),
        common: combineReducers({
            platform: LoginReducer,
            onboarding: OnboardingReducer,
            profile: ProfileReducer,
            workspace: WorkspaceReducer
        }),
        dashboard: combineReducers({
            team: TeamReducer,
            events: EventsReducer,
            core: CoreReducer,
            chat: ChatReducer,
            recommendations: RecommendationsReducer,
            connect: ConnectReducer,
            home: HomeReducer,
            fans: FansReducer,
            accounts: AccountsReducer,
            apps: AppsReducer,
            notifications: NotificationsReducer
        }),
        shared: combineReducers({
            lists: SharedListReducer
        })
    }
})