'use client'

import * as React from 'react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'

import { ny } from '../../lib/utils'

const Separator = React.forwardRef((
   { className, orientation = 'horizontal', decorative = true, ...props },
   ref,
) => (
   <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={ny(
         'bg-border shrink-0',
         orientation === 'horizontal' ? 'h-px w-full' : 'h-full w-px',
         className
      )}
      {...props} />
))
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
