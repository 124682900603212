import { SET_ERROR, SET_SUCCESS, PROCESS_LOGIN, RESET_STATE, SET_EMAIL, LINK_SUCCESS, SET_INVITATION_ACTION_LOADING, SET_INVITATION_ACTION_SUCCESS, SET_INVITATION_ACTION_ERROR } from "./constants";

const INITIAL_STATE = {
    isLoading: false,
    isLinkSent: false,
    error: '',
    email: '',
    password: '',
    data: {},
    invitation: {
        state: "loading",
        data: {},
        error: ''
    },
    success: false
}

const LoginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROCESS_LOGIN:
            return {
                ...state,
                isLinkSent: false,
                error: '',
                isLoading: true
            }
        case SET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: '',
                data: action.payload
            }
        case SET_EMAIL:
            return {
                ...state,
                error: '',
                email: action.payload
            }
        case LINK_SUCCESS:
            return {
                ...state,
                isLinkSent: true,
                error: '',
                isLoading: false
            }
        case RESET_STATE:
            return {
                ...state,
                email: '',
                password: '',
                isLoading: false,
                error: '',
                data: {},
                success: false
            }
        case SET_INVITATION_ACTION_LOADING:
            return {
                ...state,
                invitation: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_INVITATION_ACTION_SUCCESS:
            return {
                ...state,
                invitation: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_INVITATION_ACTION_ERROR:
            return {
                ...state,
                invitation: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        default:
            return state
    }
}


export default LoginReducer;