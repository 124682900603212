import { LOAD_FAN, LOAD_WORKSPACE_FANS, RESET_ACTION, RESET_FAN, SET_ACTION_ERROR, SET_ACTION_LOADING, SET_ACTION_SUCCESS, SET_CREATE_FAN_ERROR, SET_CREATE_FAN_LOADING, SET_CREATE_FAN_STATUS, SET_CREATE_FAN_SUCCESS, SET_DISCUSSION_CREATE_STATUS, SET_FAN, SET_FAN_ATTRIBUTE, SET_FAN_DISCUSSIONS, SET_FAN_DISCUSSIONS_ERROR, SET_FAN_DISCUSSIONS_LOADING, SET_FAN_LOAD_ERROR, SET_WORKSPACE_FANS } from "./constants";

export function loadWorkspaceFans (append) {
    return {
        type: LOAD_WORKSPACE_FANS,
        append
    }
}

export function setWorkspaceFans (data, total, append) {
    return {
        type: SET_WORKSPACE_FANS,
        data,
        total,
        append
    }
}

export function setFanLoading () {
    return {
        type: LOAD_FAN
    }
}

export function setFan(payload) {
    return {
        type: SET_FAN,
        payload
    }
}

export function resetFan() {
    return {
        type: RESET_FAN
    }
}

export function setFanLoadError (error) {
    return {
        type: SET_FAN_LOAD_ERROR,
        error
    }
}

export function setFanDiscussions (payload) {
    return {
        type: SET_FAN_DISCUSSIONS,
        payload
    }
}

export function setFanDiscussionsLoading () {
    return {
        type: SET_FAN_DISCUSSIONS_LOADING
    }
}

export function setFanDiscussionsError (error) {
    return {
        type: SET_FAN_DISCUSSIONS_ERROR,
        error
    }
}

export function setDisucssionCreateStatus (status) {
    return {
        type: SET_DISCUSSION_CREATE_STATUS,
        status
    }
}

export function setCreateFanLoading () {
    return {
        type: SET_CREATE_FAN_LOADING
    }
}

export function setCreateFanSuccess (payload) {
    return {
        type: SET_CREATE_FAN_SUCCESS,
        payload
    }
}

export function setCreateFanError (error) {
    return {
        type: SET_CREATE_FAN_ERROR,
        error
    }
}

export function setCreateFanStatus (status) {
    return {
        type: SET_CREATE_FAN_STATUS,
        status
    }
}

export function setActionLoading () {
    return {
        type: SET_ACTION_LOADING
    }
}

export function setActionSuccess (payload) {
    return {
        type: SET_ACTION_SUCCESS,
        payload
    }
}


export function setActionError (error) {
    return {
        type: SET_ACTION_ERROR,
        error
    }
}

export function resetAction () {
    return {
        type: RESET_ACTION
    }
}

export function setFanAttribute (key, data) {
    return {
        type: SET_FAN_ATTRIBUTE,
        key,
        data
    }
}