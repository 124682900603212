export const colors = [
"#7856FF",
"#FF7557",
"#80E1D9",
"#F8BC3B",
"#B2596E",
"#72BEF4",
"#FFB27A",
"#0D7EA0",
"#3BA974",
"#FEBBB2",
"#CA80DC",
"#5BB7AF"
]