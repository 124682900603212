import {
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SkeletonText,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { addMessage, resetMessages, setError } from "../../../redux/app/chat/actions";
import { useSelector, useDispatch } from "react-redux";
import { AddIcon, ArrowBackIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import "./_chat.scss";
import ChatIcon from "./bubble.svg";
import { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import {
  createChatSession,
  getChatSessions,
  getSpecificChatSession,
  likeChat,
  reportChat,
  sendChatMessage,
  unLikeChat,
} from "../../../redux/app/chat/thunk";
import FallbackImage from '../../../assets/img/fallback-user.png'
import { useSearchParams } from "react-router-dom";
import mixpanelEvents from "../../../utils/events";
import Markdown from 'react-markdown'
import { ArrowUpRightIcon, Trash2Icon } from "lucide-react";

function Chat({ onModalClose, fanModalOpen }) {
  const profile = useSelector((state) => state.dashboard.core.profile);
  const [currentMessage, setCurrentMessage] = useState("");
  const messages = useSelector((state) => state.dashboard.chat.messages);
  const isLoading = useSelector((state) => state.dashboard.chat.isLoading);
  const isSessionLoading = useSelector((state) => state.dashboard.chat.isSessionLoading);
  const streamingMessage = useSelector((state) => state.dashboard.chat.currentMessage);
  const session = useSelector((state) => state.dashboard.chat.session);
  const history = useSelector((state) => state.dashboard.chat.history);
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.dashboard.core.workspace);
  const error = useSelector(state => state.dashboard.chat.error)
  const scrollContainer = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [search] = useSearchParams()
  const toast = useToast({
    "title": "Report successful",
    "description": "Thank you for reporting an incorrect response. Our team will closely look into the issue to see what went wrong",
    styleConfig: {
      'baseStyle': {
        backgroundColor: "red"
      }
    }
  })

  useEffect(() => {
    const deviceDetails = {
      $os: window.navigator.platform,
      $browser: window.navigator.userAgent,
      $current_url: window.location.href,
      $referrer: document.referrer,
    };

    mixpanelEvents.trackFanGPTUsed(deviceDetails);
  }, []);

  useEffect(() => {
    if (session?.uuid&& currentMessage.trim().length) {
      dispatch(
        addMessage({
          role: "user",
          content: currentMessage,
          data: [],
        })
      );
      dispatch(sendChatMessage(workspace.uuid, session?.uuid, currentMessage));
      setCurrentMessage("");
    }
  }, [session]);

  const dispatchMessage = (sess) => {
    if (sess?.uuid && currentMessage.trim().length) {
      dispatch(
        addMessage({
          role: "user",
          content: currentMessage,
          data: [],
        })
      );
      dispatch(sendChatMessage(workspace.uuid, sess?.uuid, currentMessage));
      setCurrentMessage("");
      

      mixpanelEvents.trackFanGPTUsed(currentMessage, []);
    } else if (currentMessage.trim().length) {
      dispatch(createChatSession(workspace.uuid, currentMessage));
    }
  };

  useEffect(() => {
    if (search.get("question")) {
      setCurrentMessage(search.get("question"))
    }
  }, [])

  useEffect(() => {
    if (error.length) {
      toast({
        title: error,
        colorScheme: "red",
        description: ""
      })
      dispatch(setError(""))
    }
  }, [error])

  useEffect(() => {
    if (workspace?.uuid) {
      dispatch(getChatSessions(workspace?.uuid))
    }
  }, []);

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
    }
  }, [messages, scrollContainer, streamingMessage]);

  return (
    <Box h={"calc(100svh)"}>
          {/* <Stack
            mr={2}
            mt={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            direction={"row"}
          >
            <Button
              w={"full"}
              leftIcon={<AddIcon />}
              size={"sm"}
              rounded={6}
              colorScheme="primary"
            >
              new chat
            </Button>
          </Stack> */}
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
         <DrawerContent>
          <DrawerBody px={0} py={4} pb={0}>
            {isSessionLoading ? <SkeletonText p={4} gap={3} skeletonHeight='4' noOfLines={4} />: <><Stack height={'calc(100vh - 64px - 72px)'} overflow={"scroll"} mx={4} gap={2} flex={1}>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text color={"#757575"} fontWeight={"semibold"} fontSize={"sm"}>Previous Chats</Text>
              <IconButton size={"sm"} onClick={onClose} rounded={"full"} icon={<CloseIcon />} />
            </Flex>
              {history.map(c => <Flex backgroundColor={c.uuid === session?.uuid ? 'var(--chakra-colors-primary-50)' : 'transparent'} color={c.uuid === session?.uuid ? 'var(--chakra-colors-primary-500)' : "black"} onClick={() => {
                dispatch(getSpecificChatSession(workspace.uuid, c.uuid))
                onClose()
              }} borderRadius={5} transition={"all 0.3s"} cursor={"pointer"} _hover={{ background: 'var(--chakra-colors-primary-50)', color: 'var(--chakra-colors-primary-500)' }} alignItems={"center"} gap={2} py={2}>
                <i className="fi fi-rr-beacon" ></i>
                <Text fontSize={"sm"} fontWeight={"medium"} textOverflow={"ellipsis"} noOfLines={1} borderRadius={0}>
                {c.name}
                </Text>
              </Flex>)}
            </Stack>
            <Stack>
              <Button mx={4} onClick={() => {
                if (session?.uuid) {
                  dispatch(getChatSessions(workspace.uuid));
                  setCurrentMessage('')
                  dispatch(resetMessages())
                  onClose()
                  // dispatch(addMessage({ content:'Hi 👋 , how can I help you ?', role:'ai', data: []}))
                } else {
                  onClose()
                }
              }} borderRadius={0} leftIcon={<AddIcon />} fontWeight={500} colorScheme="primary">New chat</Button>
              <Flex borderTop={"0.5px solid #cacaca"} alignItems={"center"} gap={2} p={4}>
                <Avatar fallbackSrc={FallbackImage} size={"sm"} src={profile?.image} />
                <Box>
                  <Text fontWeight={"medium"} fontSize={"sm"}>{profile?.fullName}</Text>
                  <Text color={"#757575"} fontSize={"xs"}>{profile?.accounts?.google?.email || profile?.accounts?.local?.email}</Text>
                </Box>
              </Flex>
            </Stack></>}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex direction={["column", "column", "row"]} h={"full"}>
        <Stack justifyContent={"space-between"} flex={[null, null, 0.3]} borderRight={"1px solid #cacaca"}>
          <Flex alignItems={"center"} borderBottom={"0.5px solid #cacaca"} p={3} >
            <Flex flex={1} gap={2} alignItems={"center"}>
              <IconButton onClick={onModalClose} icon={<ArrowBackIcon />} rounded={"full"} />
              <Image src={ChatIcon} />
              <Stack gap={0}>
                <Heading size={"sm"}>FanGPT</Heading>
                <Text fontSize={"sm"} fontWeight={"normal"} color={"gray.500"}>
                  Fan data made easy
                </Text>
              </Stack>
            </Flex>
            <Flex gap={2} alignItems={"center"} display={["flex", "flex", "none"]}>
              {/* <Button size={"sm"} leftIcon={<AddIcon />} borderRadius={0} colorScheme="primary">New Chat</Button> */}
              <IconButton onClick={onOpen} borderRadius={0} icon={<HamburgerIcon />} />
            </Flex>
          </Flex>
          {isSessionLoading ? <Center><Spinner /></Center> : <Stack height={'calc(100vh - 64px - 72px)'} overflow={"scroll"} display={["none", "none", "flex"]} mx={2} gap={2} flex={1}>
            <Text color={"#757575"} fontWeight={"semibold"} fontSize={"sm"}>Previous Sessions</Text>
            {history.map(c => <Flex role="group" backgroundColor={c.uuid === session?.uuid ? 'var(--chakra-colors-primary-50)' : 'transparent'} color={c.uuid === session?.uuid ? 'var(--chakra-colors-primary-500)' : "black"} onClick={() => {
                dispatch(getSpecificChatSession(workspace.uuid, c.uuid))
              }} borderRadius={5} px={2} transition={"all 0.3s"} cursor={"pointer"} _hover={{ background: 'var(--chakra-colors-primary-50)', color: 'var(--chakra-colors-primary-500)' }} alignItems={"center"} gap={2} py={2}>
              <i className="fi fi-rr-beacon" ></i>
              <Box flex={1} mt={1} pos={"relative"}>
                <Text fontSize={"sm"} fontWeight={"medium"} textOverflow={"ellipsis"} noOfLines={1} borderRadius={0}>
                {c.name}
                </Text>
                <Box onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                }} px={0.5} rounded={2} py={0.5} background={"white"} display={"none"} top={0} right={0} pos={"absolute"} _groupHover={{ display: "block", background: "transparent" }}><Trash2Icon color="#C53030" size={16} /></Box>
              </Box>
            </Flex>)}
          </Stack> }
          <Stack display={["none", "none", "flex"]}>
            <Button onClick={() => {
                if (session?.uuid) {
                  dispatch(getChatSessions(workspace.uuid));
                  setCurrentMessage('')
                  dispatch(resetMessages())
                  // dispatch(addMessage({ content:'Hi 👋 , how can I help you ?', role:'ai', data: []}))
                } else {
                  onClose()
                }
              }} mx={4} borderRadius={0} leftIcon={<AddIcon />} fontWeight={500} colorScheme="primary">New chat</Button>
          <Flex borderTop={"0.5px solid #cacaca"} alignItems={"center"} gap={2} p={4}>
            <Avatar name={profile?.fullName || profile?.firstName} size={"sm"} src={profile?.image} />
            <Box>
              <Text fontWeight={"medium"} fontSize={"sm"}>{profile?.fullName}</Text>
              <Text color={"#757575"} fontSize={"xs"}>{profile?.accounts?.google?.email || profile?.accounts?.local?.email}</Text>
            </Box>
          </Flex>
          </Stack>
        </Stack>
        <Stack height={"100svh"} flex={[1, 1, 0.7]}>
          <Stack
          ref={scrollContainer}
          overflow={"scroll"}
          __css={{
            scrollbarColor: "transparent",
            scrollbarWidth: 0,
          }}
          pt={4}
          borderRadius={8}
          height={"full"}
          maxH={['calc(100vh - 200px)', 'calc(100vh - 200px)', "full"]}
          gap={3}
          flex={1}
        >
          {messages.filter(m => !m.hidden).map((m, i) => {
            return (
              <Flex
                opacity={m.isReported ? 0.5 : 1}
                cursor={m.isReported ? "no-drop" : "default"}
                px={2}
                py={2}
                w={"full"}
                justifyContent={
                  m.role === "ai" || m.role === "assistant" || m.role === "system"
                    ? "flex-start"
                    : "flex-end"
                }
              >
               <Tooltip hasArrow isDisabled={!m.isReported} placement="right" label={m.reason}><Box
                  alignItems={
                    m.role === "ai" || m.role === "assistant" || m.role === "system"
                      ? "flex-start"
                      : "flex-end"
                  }
                  display={"flex"}
                  color={"white"}
                  flexDirection={
                    m.role === "ai" || m.role === "assistant" || m.role === "system" ? "row" : "row-reverse"
                  }
                >
                  {m.role === "ai" || m.role === "assistant" || m.role === "system" ? (
                    <Center
                      rounded={"full"}
                      backgroundColor={"#F2F8FF"}
                      boxSize={8}
                    >
                      <Avatar borderRadius={"full"} boxSize={5} src={ChatIcon} />
                    </Center>
                  ) : (
                    <Image
                    fallbackSrc={'https://i1.wp.com/cdn.mxpnl.com/static/tracked-user-icons/10/64.png?ssl=1'}
                      borderRadius={"full"}
                      boxSize={8}
                      src={profile.image}
                    />
                  )}
                  <Stack
                    pointerEvents={m.isReported ? "none" : "all"}
                    py={m.role === "ai" || m.role === "assistant" || m.role === "system" ? 4 : 3}
                    w={"85%"}
                    px={4}
                    position={"relative"}
                    borderRadius={8}
                    color={
                      m.role === "ai" || m.role === "assistant" || m.role === "system" ? "black" : "white"
                    }
                    backgroundColor={
                      m.role === "ai" || m.role === "assistant" || m.role === "system"
                        ? "#F2F8FF"
                        : "#5452F6"
                    }
                    mx={3}
                  >
                    {m?.content ? <Markdown className={"f-markdown"}>
                      {m?.content}
                    </Markdown> : <></>}
                    {m?.data?.length > 0 && (
                      <Stack gap={4}>
                        {m?.content ? <Text fontWeight={"bold"}>More details about fans: </Text> : ""}
                        {m.data.map((f) => (
                          <Flex gap={10} justifyContent={"space-between"}>
                            <Flex gap={2}>
                              <Image boxSize={8} rounded={"full"} fallbackSrc={FallbackImage} size={"sm"} src={f?.profile?.image} />
                              <Stack gap={0}>
                                <Flex gap={1} alignItems={"center"}>
                                  <Text fontSize={"sm"}>{f.fullName}</Text>
                                  <Link
                                    isExternal
                                    href={`https://www.linkedin.com/in/${f?.profile?.linkedIn || f?.profile?.salesReference?.replace("urn:li:fsd_profile:", "")}`}
                                  >
                                    <i class="fi fi-brands-linkedin"></i>
                                  </Link>
                                </Flex>
                                <Tag px={1} rounded={2} size={"sm"} fontWeight={"medium"} my={1} w={"fit-content"} colorScheme="green">
                                  <i className='fi fi-sr-star fui-fan-star'></i>
                                  <TagLabel>{f.score ? `${f.score.toFixed(1)} / 5.0` : '-'}</TagLabel>
                                </Tag>
                                <Text mt={0.5} fontSize={"sm"} color={"gray"}>
                                  {f.path?.[0]?.positions?.[0]?.name}
                                </Text>
                                <Text fontSize={"sm"} color={"gray"}>
                                  {moment(
                                    f.path?.[0]?.positions?.[0]?.startedOn
                                  ).format("MMM YYYY")}{" "}
                                  - Present
                                </Text>
                              </Stack>
                            </Flex>
                            <Button
                            onClick={() => {
                              fanModalOpen(f)
                            }}
                            fontWeight={500}
                              colorScheme="primary"
                              size={"sm"}
                              variant={"outline"}
                            >
                              View
                            </Button>
                          </Flex>
                        ))}
                      </Stack>
                    )}
                    {m.role === "ai" || m.role === "assistant" || m.role === "system" ? <Flex justifyContent={"space-between"} alignItems={"center"} mt={2}>
                      <Flex gap={2}>
                        <Tag onClick={() => {
                          if (m.isLiked) {
                            dispatch(unLikeChat(workspace.uuid, session?.uuid, m.uuid ? m.uuid : i, m.uuid))
                          } else {
                            dispatch(likeChat(workspace.uuid, session?.uuid,  m.uuid ? m.uuid : i, m.uuid))
                          }
                        }} variant={m.isLiked ? "solid" : "subtle"} colorScheme={m.isLiked ? "primary" : "none"} cursor={"pointer"} size={"md"} gap={2} alignItems={"center"}>
                          <TagLabel>{m.isLiked ? "Liked" : "Like"}</TagLabel>
                          <i style={{ color: m.isLiked ? "white" : "var(--chakra-colors-primary-500)" }} class={m.isLiked ? "fi fi-sr-heart" : "fi fi-br-heart"}></i>
                        </Tag>
                        <Menu>
                          <MenuButton as={Tag} sx={{
                            '& > span': {
                              "display": "flex",
                              "gap": 2
                            }
                          }} variant={m.isReported ? "solid" : "subtle"} colorScheme={m.isReported ? "red" : "none"}  cursor={"pointer"} size={"md"} gap={2} alignItems={"center"}>
                              <TagLabel>{m.isReported ? "Reported" : "Report"}</TagLabel>
                              <i style={{ color: m.isReported ? "white" : "var(--chakra-colors-primary-500)" }} class={m.isReported ?  "fi fi-sr-hexagon-exclamation" : "fi fi-br-hexagon-exclamation"}></i>
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => {
                                if (!m.isReported) {
                                  dispatch(reportChat(workspace.uuid, session.uuid, m.uuid || i, "Shows wrong data", "WRONG_DATA"))
                                  toast()
                                }
                              }}>Wrong data shown on UI</MenuItem>
                            <MenuItem onClick={() => {
                                if (!m.isReported) {
                                  dispatch(reportChat(workspace.uuid, session.uuid, m.uuid || i, "Shows out of context response", "OUT_OF_CONTEXT"))
                                  toast()
                                }
                              }}>Out of context response</MenuItem>
                            <MenuItem onClick={() => {
                                if (!m.isReported) {
                                  dispatch(reportChat(workspace.uuid, session.uuid, m.uuid || i, "Shows offensive response", "OFFENSIVE"))
                                  toast()
                                }
                              }}>Offensive</MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                      {/* {m?.data?.length > 4 ? <Button rightIcon={<ArrowUpRightIcon size={16} />} size={"sm"} variant={"outline"} colorScheme={"primary"}>
                        View more fans
                      </Button> :  */}
                      <Box></Box>
                      {/* } */}
                    </Flex> : <></>}
                  </Stack>
                </Box>
              </Tooltip>
              </Flex>
            );
          })}
          {isLoading === true && (
            <Flex px={2} py={2} w={"full"} justifyContent={"flex-start"}>
              <Box
                alignItems={"flex-start"}
                display={"flex"}
                color={"white"}
              >
                <Center rounded={"full"} backgroundColor={"#F2F8FF"} boxSize={8}>
                  <Avatar borderRadius={"full"} boxSize={5} src={ChatIcon} />
                </Center>
                <Text
                  py={3}
                  px={4}
                  borderRadius={2}
                  backgroundColor={"#F2F8FF"}
                  mx={3}
                  color={"black"}
                  fontSize={"sm"}
                >
                 <Spinner size={"xs"} />
                </Text>
              </Box>
            </Flex>
          )}
        </Stack>
        <Stack mx={4} my={4}>
          <Flex boxShadow={"1px 1px 24px 1px rgba(0, 0, 0, 0.1)"} border={'0.52px solid #e4e4e4'} gap={2} height={14} borderRadius={8} alignItems={"center"}>
              <Input
                outline={0}
                border={'none'}
                borderColor={"transparent !important"}
                boxShadow={'none !important'}
                disabled={isLoading}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && currentMessage.trim().length) {
                    dispatchMessage(session);
                  }
                }}
                onChange={(e) => {
                  // if (e.target.value.trim().length) {
                    setCurrentMessage(e.target.value);
                  // }
                }}
                value={currentMessage}
                height={"full"}
                placeholder="Enter your prompt here"
                colorScheme="primary"
                rounded={"full"}
              />
              <IconButton
                pointerEvents={isLoading ? "none" : "all"}
                onClick={() => {
                  if (currentMessage.length) {
                    dispatchMessage(session);
                  }
                }}
                rounded={"full"}
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="Done"
                className="send-button"
                alignItems={"center"}
                icon={<i class="fi fi-rr-paper-plane-top"></i>}
              />
            </Flex>
          <Text textAlign={"center"} color={"gray"} fontSize={"xs"}>FanGPT is still in beta. FanGPT is continiously trained based on the feedback provided on the chat history</Text>
        </Stack>
        </Stack>
      </Flex>
    </Box>
  );
}

export default Chat;
