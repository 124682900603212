/**
 * your constant variables will go here
 */
export const SET_SESSION = "SET_SESSION";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const SET_CHAT_SESSIONS = "SET_CHAT_SESSIONS";
export const LOAD_CHAT_SESSIONS = "LOAD_CHAT_SESSIONS";
export const LIKE_CHAT = "LIKE_CHAT";
export const UNLIKE_CHAT = "UNLIKE_CHAT";
export const REPORT_CHAT = "REPORT_CHAT";
export const SET_CHAT_ERROR = "SET_CHAT_ERROR";
export const SET_MESSAGES = "SET_MESSAGES";
export const LOAD_SESSION = "LOAD_SESSION";
export const SET_CURRENT_MESSAGE = "SET_CURRENT_MESSAGE";
export const RESET_CURRENT_MESSAGE = "RESET_CURRENT_MESSAGE";
export const SET_DELETE_CHAT_LOADING = "chats/delete/loading"
export const SET_DELETE_CHAT_SUCCESS = "chats/delete/success"
export const SET_DELETE_CHAT_ERROR = "chats/delete/error"
export const RESET_DELETE_CHAT = "chats/delete/reset"