import { SET_EVENTS_DETAIL_ERROR, SET_EVENTS_DETAIL_LOADING, SET_EVENTS_DETAIL_SUCCESS, SET_EVENTS_LIST_ERROR, SET_EVENTS_LIST_LOADING, SET_EVENTS_LIST_SUCCESS } from "./constants";

const INITIAL_STATE = {
    list: {
        status: "loading",
        data: [],
        error: ''
    },
    detail: {
        status: "loading",
        data: {},
        error: ''
    }
};

const MeetingReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_EVENTS_LIST_LOADING:
            return {
                ...state,
                list: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_EVENTS_LIST_SUCCESS:
            return {
                ...state,
                list: {
                    state: "success",
                    data: action.payload,
                    error: '',
                    metadata: action.metadata
                }
            }
        case SET_EVENTS_LIST_ERROR:
            return {
                ...state,
                list: {
                    state: "error",
                    data: [],
                    error: action.error
                }
            }
            case SET_EVENTS_DETAIL_LOADING:
                return {
                    ...state,
                    detail: {
                        state: "loading",
                        data: {},
                        error: ''
                    }
                }
            case SET_EVENTS_DETAIL_SUCCESS:
                return {
                    ...state,
                    detail: {
                        state: "success",
                        data: action.payload,
                        error: '',
                        metadata: action.metadata
                    }
                }
            case SET_EVENTS_DETAIL_ERROR:
                return {
                    ...state,
                    detail: {
                        state: "error",
                        data: {},
                        error: action.error
                    }
                }
        default:
            return state
    }
}

export default MeetingReducer