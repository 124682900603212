import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Td,
  Text,
  Textarea,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Page from "../../../components/core/@page";
import { AddIcon } from "@chakra-ui/icons";
import Table from "../../../components/core/@table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { inviteTeamMember, loadTeam, reinviteTeamMember, removeTeamMember } from "../../../redux/app/team/thunk";
import { Field, Formik } from "formik";
import { resetInvitation } from "../../../redux/app/team/actions";

function Team() {
  const workspace = useSelector((state) => state.dashboard.core.workspace);
  const isLoading = useSelector((state) => state.dashboard.team.isLoading);
  const invitation = useSelector((state) => state.dashboard.team.invitation);
  const revoke = useSelector((state) => state.dashboard.team.revoke);
  const team = useSelector((state) => state.dashboard.team.data);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const toast = useToast()

  useEffect(() => {
    dispatch(loadTeam(workspace.uuid));
  }, []);

  useEffect(() => {
    if (!invitation.isLoading && invitation?.data?.status === 200) {
      toast({
        title: "Invitation sent successfully",
        status: "success"
      })
      dispatch(resetInvitation())
      onClose()
      dispatch(loadTeam(workspace.uuid));
    }
  }, [invitation])

  useEffect(() => {
    if (!revoke.isLoading && revoke?.data?.status === 200) {
      toast({
        title: "User removed",
        status: "success"
      })
      dispatch(resetInvitation())
      dispatch(loadTeam(workspace.uuid));
    }
  }, [revoke])

  return (
    <Page
      title={"Team management"}
      subtitle={"Invite, manage & collaborate with your team"}
      actions={
        <ButtonGroup size={"sm"}>
          <Button
            onClick={() => {
              onOpen();
            }}
            colorScheme="primary"
            size={"sm"}
            leftIcon={<AddIcon boxSize={3} />}
          >
            Invite Member
          </Button>
        </ButtonGroup>
      }
    >
      <Modal size={"lg"} isCentered onClose={() => {
        dispatch(resetInvitation())
        onClose()
      }} isOpen={isOpen}>
        <ModalOverlay></ModalOverlay>
        <Formik
          initialValues={{
            email: "",
            name: "",
            message: "",
            role: "admin"
          }}
          onSubmit={(values) => {
            dispatch(
                inviteTeamMember(workspace?.uuid, {
                    name: values.name,
                    email: values.email,
                    message: values.message,
                    role: values.role,
                    redirectUrl: "/"
                })
            )
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <ModalContent>
                <ModalHeader>Invite Team</ModalHeader>
                <ModalBody>
                  <Stack gap={4}>
                    {invitation.error?.length ? <Alert variant={"solid"} fontSize={"sm"} rounded={6} mb={2} status="error">
                      {invitation.error}
                    </Alert> : <></>}
                    <FormControl
                      size={"sm"}
                      isInvalid={!!errors.name && touched.name}
                    >
                      <FormLabel fontSize={"sm"} htmlFor="name">
                        Name
                      </FormLabel>
                      <Field
                        size={"sm"}
                        as={Input}
                        id="name"
                        name="name"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          if (value.length < 2) {
                            error = "Name must contain at least 2 characters";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      size={"sm"}
                      isInvalid={!!errors.email && touched.email}
                    >
                      <FormLabel fontSize={"sm"} htmlFor="email">
                        Email
                      </FormLabel>
                      <Field
                        size={"sm"}
                        as={Input}
                        id="email"
                        name="email"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          if (!value.includes("@")) {
                            error = "Enter valid email address";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      size={"sm"}
                      isInvalid={!!errors.role}
                    >
                      <FormLabel fontSize={"sm"} htmlFor="role">
                        Role
                      </FormLabel>
                      <Field
                        size={"sm"}
                        as={Select}
                        id="role"
                        name="role"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          if (!value.length) {
                            error = "Role is required";
                          }
                          return error;
                        }}
                      >
                        <option value={"admin"}>Admin</option>
                        <option value={"member"}>Member</option>
                      </Field>
                      <FormErrorMessage>{errors.role}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      size={"sm"}
                      isInvalid={!!errors.message && touched.message}
                    >
                      <FormLabel fontSize={"sm"} htmlFor="message">
                        Message
                      </FormLabel>
                      <Field
                        size={"sm"}
                        as={Textarea}
                        id="message"
                        name="message"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          // if (value.length < 30) {
                          //   error =
                          //     "Message should contain atleast 30 characters";
                          // }
                          return error;
                        }}
                      />
                      <FormErrorMessage>{errors.message}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant={"outline"}
                    mr={3}
                    size={"sm"}
                    onClick={() => {
                      onClose()
      dispatch(resetInvitation())
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={invitation.isLoading}
                    type="submit"
                    colorScheme="primary"
                    size={"sm"}
                    variant="solid"
                  >
                    Send Invitation
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )}
        </Formik>
      </Modal>
      <Stack p={4}>
        <Table
          disableActions={true}
          isLoading={isLoading}
          page={1}
          total={1}
          headers={["Name", "Role", "Actions"]}
        >
          {team.map((t) => {
            return (
              <Tr onClick={() => {}}>
                <Td>
                  <Flex alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"} mr={2}>
                      <Avatar
                        name={t.user?.fullName || t.name}
                        fontSize={"sm"}
                        border={"1px solid #cacaca"}
                        rounded={"full"}
                        size={"sm"}
                        src={t.user?.image}
                      />
                    </Stack>
                    <Box>
                      <Text>{t.user?.fullName || t.name}</Text>
                      <Text color={"gray"}>
                        {t.user?.accounts?.google?.email ||
                          t.user?.accounts?.local?.email}
                      </Text>
                    </Box>
                  </Flex>
                </Td>
                <Td>
                  <Badge
                    fontSize={"sm"}
                    textTransform={"capitalize"}
                    fontWeight={500}
                    variant="subtle"
                    colorScheme={t.status === "pending" ? "orange" : "green"}
                  >
                    {t.status === "pending"
                      ? "invitation pending"
                      : t.role}
                  </Badge>
                </Td>
                <Td>
                  {t.status === "pending" ? (
                    <Flex gap={2}>
                      <Button
                      isLoading={invitation.isLoading}
                      onClick={() => {
                        dispatch(reinviteTeamMember(workspace.uuid, t.uuid))
                      }}
                        size={"sm"}
                        fontWeight={"normal"}
                        leftIcon={<i class="fi fi-rr-arrows-repeat"></i>}
                      >
                        Re Invite
                      </Button>
                      <IconButton
                        isLoading={revoke.isLoading}
                        onClick={() => {
                          dispatch(removeTeamMember(workspace.uuid, t.uuid))
                        }}
                        colorScheme="red"
                        variant={"outline"}
                        size={"sm"}
                        icon={<i class="fi fi-rr-trash"></i>}
                      />
                    </Flex>
                  ) : (
                    <Flex gap={2}>
                      {/* <Button
                        size={"sm"}
                        fontWeight={"normal"}
                        leftIcon={<i class="fi fi-rr-user-pen"></i>}
                      >
                        Manage
                      </Button> */}
                      <IconButton
                        isLoading={revoke.isLoading}
                        onClick={() => {
                          dispatch(removeTeamMember(workspace.uuid, t.uuid))
                        }}
                        colorScheme="red"
                        variant={"outline"}
                        size={"sm"}
                        icon={<i class="fi fi-rr-trash"></i>}
                      />
                    </Flex>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Table>
      </Stack>
    </Page>
  );
}

export default Team;
