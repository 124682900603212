export const SET_PLATFORM_STATUS = "SET_PLATFORM_STATUS";
export const SET_WORKSPACES = "SET_WORKSPACES";
export const LOAD_WORKSPACES = "LOAD_WORKSPACES";
export const SET_ERROR = "SET_ERROR";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const LOAD_PROFILE = "LOAD_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_ERROR = "SET_PROFILE_ERROR";
export const SET_OPTIONS_LOADING = "SET_OPTIONS_LOADING";
export const SET_OPTIONS = "SET_OPTIONS";
export const SET_LISTS_LOADING = "SET_LISTS_LOADING";
export const SET_LISTS = "SET_LISTS";