import { Avatar, Box, Flex, Grid, Stack, Tag, TagLabel, Text } from "@chakra-ui/react";
import Page from "../../../components/core/@page";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadKpis } from "../../../redux/app/home/thunk";
import moment from "moment-timezone";
import { useNavigate, useOutletContext } from "react-router-dom";

function Activity() {
  const { setCurrentFan, onFanModalOpen } = useOutletContext()
  const workspace = useSelector((state) => state.dashboard.core.workspace);
  const kpis = useSelector((state) => state.dashboard.home.kpis);
  const job_changes = useSelector((state) => state.dashboard.home.job_changes);
  const job_promotions = useSelector((state) => state.dashboard.home.job_promotions);
  const top_prospects = useSelector((state) => state.dashboard.home.prospects);
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf("month").toDate(),
    new Date(),
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  function diff(duration, date) {
    const start = moment(date)
      .subtract(duration, "months")
      .set("date", 1)
      .format("YYYY-MM-DD");
    const end = moment(date).set("date", 1).format("YYYY-MM-DD");
    return { start, end };
  }

  useEffect(() => {
    if (workspace?.uuid && !kpis?.data?.total_fans) {
      dispatch(
        loadKpis(
          workspace?.uuid
        )
      );
    }
  }, [workspace]);

  return (
    <Page
      title={"Insights"}
      subtitle={"View and track your fan activity"}
      actions={[]
        // <Flex alignItems={"center"} fontWeight={"medium"} gap={3}>
        //   <IconButton
        //     isRound={true}
        //     variant={"outline"}
        //     size={"xs"}
        //     aria-label="Search database"
        //     icon={<ChevronLeft />}
        //   />
        //   <RangeDatepicker
        //     configs={{
        //       dateFormat: "dd MMM",
        //     }}
        //     propsConfigs={{
        //       dayOfMonthBtnProps: {
        //         selectedBtnProps: {
        //           colorScheme: "primary",
        //           background: "primary.400",
        //           color: "white",
        //           rounded: "full",
        //         },
        //         isInRangeBtnProps: {
        //           colorScheme: "primary",
        //           background: "primary.400",
        //           rounded: "full",
        //           color: "white"
        //         },
        //       },
        //       inputProps: {
        //         rounded: "full",
        //         w: 120,
        //         p: 0,
        //         margin: 0,
        //         border: 0,
        //         outline: 0,
        //         textAlign: "end",
        //         cursor: "pointer",
        //         isReadOnly: true,
        //         _focusVisible: {
        //           border: 0,
        //         },
        //       },
        //     }}
        //     selectedDates={selectedDates}
        //     onDateChange={setSelectedDates}
        //   />
        //   <IconButton
        //     isRound={true}
        //     variant={"outline"}
        //     size={"xs"}
        //     aria-label="Search database"
        //     icon={<ChevronRight />}
        //   />
        // </Flex>
      }
    >
      <Stack gap={4} p={4}>
        <Flex>
          <Grid gap={4} flex={0.6} gridTemplateColumns={'repeat(4, 1fr)'}>
            <Stack justifyContent={"space-between"} rounded={8} p={4} border={"1px solid #cacaca"} gap={0}>
              <Box>
                <Text fontWeight={"semibold"} flex={1}>Total Fans</Text>
              </Box>
              <Text fontWeight={"semibold"} fontSize={"4xl"}>{kpis.data.total_fans}</Text>
            </Stack>
            {kpis.data.tx_fans ? <Stack justifyContent={"space-between"} rounded={8} p={4} border={"1px solid #cacaca"} gap={0}>
              <Box>
                <Text fontWeight={"semibold"} flex={1}>2x Fans</Text>
              </Box>
              <Text fontWeight={"semibold"} fontSize={"4xl"}>{kpis.data.tx_fans}</Text>
            </Stack> : <></>}
            <Stack justifyContent={"space-between"} rounded={8} p={4} border={"1px solid #cacaca"} gap={0}>
              <Box>
                <Text fontWeight={"semibold"} flex={1}>Job Changes</Text>
              </Box>
              <Text fontWeight={"semibold"} fontSize={"4xl"}>{kpis.data.job_change_fans}</Text>
            </Stack>
            <Stack justifyContent={"space-between"} rounded={8} p={4} border={"1px solid #cacaca"} gap={0}>
              <Box>
                <Text fontWeight={"semibold"} flex={1}>Job Promotions</Text>
              </Box>
              <Text fontWeight={"semibold"} fontSize={"4xl"}>{kpis.data.job_promotion_fans}</Text>
            </Stack>
          </Grid>
        </Flex>
        <Flex gap={4}>
          <Stack flex={0.33} rounded={8} p={4} border={"1px solid #cacaca"}>
            <Box>
              <Text fontWeight={"semibold"}>Top fans who changed jobs recently</Text>
            </Box>
            <Stack>
                {job_changes.data.map(fan => {
                  return <Flex cursor={"pointer"} onClick={() => {
                    setCurrentFan?.(fan.uuid)
                    onFanModalOpen?.()
                  }} gap={2} py={1}>
                    <Box mt={1}>
                      <Avatar size={"xs"} src={fan.profile.image} name={fan.fullName} />
                    </Box>
                    <Box flex={1}>
                      <Text>{fan.fullName}</Text>
                      <Text fontSize={"sm"} color={"gray"}>{fan.path[0].positions[0].name}</Text>
                    </Box>
                    <Tag h={"min-content"} px={1} rounded={2} size={"sm"} fontWeight={"medium"} my={1} w={"fit-content"} colorScheme="green">
                      <i className='fi fi-sr-star fui-fan-star'></i>
                      <TagLabel noOfLines={2}>{fan.score ? `${fan.score.toFixed(1)} / 5.0` : '-'}</TagLabel>
                    </Tag>
                  </Flex>
                })}
            </Stack>
          </Stack>
          <Stack flex={0.33} rounded={8} p={4} border={"1px solid #cacaca"}>
            <Box>
              <Text fontWeight={"semibold"}>Top fans who promoted recently</Text>
            </Box>
            <Stack>
                {job_promotions.data.map(fan => {
                  return <Flex cursor={"pointer"} onClick={() => {
                    setCurrentFan?.(fan.uuid)
                    onFanModalOpen?.()
                  }} gap={2} py={1}>
                    <Box mt={1}>
                      <Avatar size={"xs"} src={fan.profile.image} name={fan.fullName} />
                    </Box>
                    <Box flex={1}>
                      <Text>{fan.fullName}</Text>
                      <Text fontSize={"sm"} color={"gray"}>{fan.path[0].positions[0].name}</Text>
                    </Box>
                    <Tag h={"min-content"} px={1} rounded={2} size={"sm"} fontWeight={"medium"} my={1} w={"fit-content"} colorScheme="green">
                      <i className='fi fi-sr-star fui-fan-star'></i>
                      <TagLabel noOfLines={2}>{fan.score ? `${fan.score.toFixed(1)} / 5.0` : '-'}</TagLabel>
                    </Tag>
                  </Flex>
                })}
            </Stack>
          </Stack>
          {top_prospects?.data?.length ? <Stack flex={0.33} rounded={8} p={4} border={"1px solid #cacaca"}>
            <Box>
              <Text fontWeight={"semibold"}>Super hot accounts with multiple fans</Text>
            </Box>
            <Stack>
                {top_prospects.data.map(prospect => {
                  return <Flex cursor={"pointer"} onClick={() => {
                    navigate(`/home/fans?currentCompany=${prospect.account?.company.name}`)
                  }} gap={2} py={1}>
                    <Box mt={1}>
                      <Avatar size={"xs"} src={prospect?.account?.company?.image} name={prospect?.account?.company?.name} />
                    </Box>
                    <Box flex={1}>
                      <Text>{prospect?.account?.company?.name}</Text>
                      <Text fontSize={"sm"}>{prospect?.account?.company?.headcount} employees</Text>
                      <Text fontSize={"sm"} color={"gray"}>{prospect?.fanSize} fans</Text>
                    </Box>
                  </Flex>
                })}
            </Stack>
          </Stack> : <></>}
        </Flex>
      </Stack>
    </Page>
  );
}

export default Activity;
