import {
    SET_PROFILE_IMAGE_UPLOAD_LOADING,
    SET_PROFILE_IMAGE_UPLOAD_SUCCESS,
    SET_PROFILE_IMAGE_UPLOAD_ERROR,
    SET_GET_PROFILE_LOADING,
    SET_GET_PROFILE_SUCCESS,
    SET_GET_PROFILE_ERROR,
    SET_UPDATE_PROFILE_LOADING,
    SET_UPDATE_PROFILE_SUCCESS,
    SET_UPDATE_PROFILE_ERROR
} from './constants';

const INITIAL_STATE = {
    image: {
        state: 'idle',
        data: {},
        error: ''
    },
    get: {
        state: 'idle',
        data: {},
        error: ''
    },
    update: {
        state: 'idle',
        data: {},
        error: ''
    }
};

export const ProfileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PROFILE_IMAGE_UPLOAD_LOADING:
            return {
                ...state,
                image: {
                    state: 'loading',
                    data: {},
                    error: ''
                }
            };
        case SET_PROFILE_IMAGE_UPLOAD_SUCCESS:
            return {
                ...state,
                image: {
                    state: 'success',
                    data: action.payload,
                    error: ''
                }
            };
        case SET_PROFILE_IMAGE_UPLOAD_ERROR:
            return {
                ...state,
                image: {
                    state: 'error',
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            };
        case SET_GET_PROFILE_LOADING:
            return {
                ...state,
                get: {
                    state: 'loading',
                    data: {},
                    error: ''
                }
            };
        case SET_GET_PROFILE_SUCCESS:
            return {
                ...state,
                get: {
                    state: 'success',
                    data: action.payload,
                    error: ''
                }
            };
        case SET_GET_PROFILE_ERROR:
            return {
                ...state,
                get: {
                    state: 'error',
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            };
        case SET_UPDATE_PROFILE_LOADING:
            return {
                ...state,
                update: {
                    state: 'loading',
                    data: {},
                    error: ''
                }
            };
        case SET_UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                update: {
                    state: 'success',
                    data: action.payload,
                    error: ''
                }
            };
        case SET_UPDATE_PROFILE_ERROR:
            return {
                ...state,
                update: {
                    state: 'error',
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            };
        default:
            return state;
    }
};
