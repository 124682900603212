import { Avatar, Box, Button, ButtonGroup, Center, Collapse, Divider, Flex, Heading, Icon, Image, Link, Spinner, Stack, Tag, TagLabel, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { AddIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, createRef, useState } from 'react';
import Xarrow from 'react-xarrows';
import { getSpecificFan } from '../../../redux/app/fans/thunk';
import './_fan.scss';
import Chart from 'react-apexcharts';
import { colors } from '../../../utils/color.constants';
import Table from '../../../components/core/@table';
import { evaluateTagColor } from '../../../utils/tag.constants';
import { useParams } from 'react-router-dom';
import { loadPublicFan } from '../../../redux/app/shared/list/thunk';
import { useCookies } from 'react-cookie';
import { loadWorkspace } from '../../../redux/app/core/thunk';
import { cloneDeep } from 'lodash';

const CircleIcon = (props) => (
    <Icon viewBox='0 0 200 200' {...props}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
    </Icon>
  )

const formatDate = (date) => moment.parseZone(date).format("MMM YYYY")

function FanPublic() {
    const isLoading = useSelector(state => state.shared.lists.isFanLoading)
    const fan = useSelector(state => state.shared.lists.fan)
    const { workspace: wId, fan: fId } = useParams()
    const dispatch = useDispatch()
    const [path, setPath] = useState([])
    const [currentPath, setCurrentPath] = useState({})
    const [show, setShow] = useState(false)
    const handleToggle = () => setShow(!show);
    const [cookies] = useCookies(["accessToken"])
    const _workspace = useSelector((state) => state.dashboard.core.workspace);

    useEffect(() => {
        if (wId && fId) {
            dispatch(loadPublicFan(wId, fId))
        }
    }, [])

    useEffect(() => {
        if (cookies.accessToken) {
            dispatch(loadWorkspace(wId))
        }
    }, [])

    useEffect(() => {
        if (_workspace?.handle) {
            const protocol = window.location.protocol
            const host = window.location.host.split(".");
            const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
            const redirectUrl = `${protocol}//${_workspace.handle}.${domain}`
            window.location.href = `${redirectUrl}/home/fans/${fId}`;
        }
    }, [_workspace])

    useEffect(() => {
        let startIndex = -1;
        const _p = []
        if (!isLoading && fan?.uuid) {
            const _p = [];
            const interactions = []
                const experience = cloneDeep(fan.path)?.reverse() || []
                // const hasCustomerAccount = (experience.filter(e => ["customer", "fan"].includes(e.account?.type)).length > 0) && !shared
                const hasCustomerAccount = true;
                if (hasCustomerAccount) {
                    for (const i in experience) {
                        const totalStartedOn = experience[i].positions?.slice(-1)?.[0]?.startedOn
                        const totalEndedOn = experience[i].positions?.[0]?.endedOn
                        const fanSince = fan?.fanSince
                        if (startIndex === -1 && moment.parseZone(totalStartedOn).toDate().getTime() <= moment.parseZone(fanSince).toDate().getTime() && moment.parseZone(fanSince).toDate().getTime()  <=  moment.parseZone(totalEndedOn).toDate().getTime()) {
                            startIndex = i
                            if (startIndex > 0) {
                                const positions = [...experience[i - 1].positions]?.reverse() || []
                                for (const position of positions) {
                                    _p.push({
                                        account: experience[i - 1].account,
                                        timeline: experience[i - 1]._timeline,
                                        position: position,
                                        ref: createRef(null)
                                    })
                                }
                            }
                        }
                        if (startIndex !== -1) {
                            const positions = [...experience[i].positions]?.reverse() || []
                            for (const position of positions) {
                                _p.push({
                                    account: experience[i].account,
                                    position: position,
                                    timeline: experience[i]._timeline,
                                    ref: createRef(null)
                                })
                            }
                        }
                    }
                } else if (experience.length) {
                    const sl = experience.slice(-2)
                    for (const i in sl) {
                        const positions = [...sl[i].positions]?.reverse() || []
                        for (const position of positions) {
                            _p.push({
                                account: sl[i].account,
                                timeline: experience[i]._timeline,
                                position: position,
                                ref: createRef(null)
                            })
                        }
                    }
                } 
        }
        setCurrentPath(_p[_p.length - 1])
        setPath(_p)
    }, [ fan ])

    return (
        <Box border={"0.5px solid #cacaca"} maxW={"720px"} mx={["0", "auto", "auto"]} w={["100%", "80%", "60%"]} pb={12}>
            {isLoading ? <Center height={240}>
                <Spinner />
            </Center> : <div className="fui-fan">
            <div className="fui-fan-actions">
            </div>
            <div className="fui-fan-header">
                <div className="fui-fan-background">
                    <Image fallbackSrc={`https://static.licdn.com/sc/h/55k1z8997gh8dwtihm11aajyq`} alt={fan?.fullName} src={fan?.profile?.cover} />
                </div>
                <Flex mx={4} justifyContent={"space-between"} direction={["column", "column", "row"]}>
                    <div className="fui-fan-bio-details">
                        <div className="fui-fan-bio-image">
                            <Avatar outline={'6px solid #ffffff'} boxSize={24} name={fan?.fullName} src={fan?.profile?.image} />
                        </div>
                        <Box mt={2} className="fui-fan-bio-title">
                            <Heading size={"md"}>{fan?.fullName}</Heading>
                        </Box>
                        <Box  mt={0.5} color={"gray"} className="fui-fan-bio-subtitle">
                            <Text fontSize={"sm"}>{fan?.profile?.headline}</Text>
                        </Box>
                        <Box mt={1} className="fui-fan-bio-subtitle">
                            <i style={{
                                marginRight: 4
                            }} className="fi fi-rr-marker"></i> 
                            <Text color="gray.700" fontSize={"sm"}>{fan?.profile?.location?.name || ''}</Text>
                            <CircleIcon style={{
                                marginTop: 4,
                                color: 'gray',
                                marginLeft: 4,
                                marginRight: 4
                            }} color="gray.700" boxSize={1} />
                            <Text color="gray.700" fontSize={"sm"}>{fan?.profile?.country?.name || ''}</Text>
                        </Box>
                    </div>
                    <Stack mt={[4, 4, 6]} gap={[0, 0, 0]} alignItems={["flex-start", null, "flex-end"]}>
                    <Flex gap={2} mt={4}>
                        {/* <Tooltip label={
                            <Stack>
                                <Text><i className="fi fi-br-check review-tooltip" /> G2 Reviewer</Text>
                                <Text><i className="fi fi-br-check review-tooltip" /> Revenue Maverick</Text>
                                <Text><i className="fi fi-br-check review-tooltip" /> LinkedIn Top Interactor</Text>
                            </Stack>
                        }> */}
                            <Tag cursor={"pointer"} fontWeight={"semibold"} colorScheme='green'>
                                <i className='fi fi-sr-star fui-fan-star'></i>
                                <TagLabel>{fan?.score || 1}/5</TagLabel>
                            </Tag>
                        {/* </Tooltip> */}
                        {/* {path.filter(p => p.account.lists.filter(l => l.type === "customer").length).length && <Tag fontWeight={"semibold"} colorScheme='purple'>
                            <TagLabel>{path.filter(p => p.account.lists.filter(l => l.type === "customer").length).length}x fan</TagLabel>
                        </Tag>} */}
                    </Flex>
                    </Stack>
                </Flex>
                <Button as={Link} href={`https://www.linkedin.com/in/${fan?.profile?.linkedIn || fan?.profile?.salesReference?.replace("urn:li:fsd_profile:", "")}/`} target='_blank' ml={4} mt={3} variant={"outline"} colorScheme="primary" size={"sm"} leftIcon={<i className="fi fi-br-arrow-up-right fui-fan-star"></i>}>View Linkedin</Button>
            </div>
            <Divider mt={4} />
            <Stack p={4}>
                <Text mb={3} fontWeight={"semibold"} fontSize={"md"}>Experiences</Text>
                <Flex pb={8} w={["full", "full", path.length <= 2 ? 0.7 : "full"]} flexWrap={"wrap"} gap={[8, null, 0]} position={"relative"} alignItems={"center"} mt={2} justifyContent={["center", null, "space-between"]}>
                    {path.filter((p, i, a) => p?.position?.endedOn || i === a.length - 1).map((p, i, a) => {
                        if (p?.position?.endedOn || i === a.length - 1) {
                                let start;
                                let end;
                                if (i < a.length - 1) {
                                    start = a[i]?.position?._id
                                    end = a[i + 1]?.position?._id
                                }
                                return (<>
                                <Stack pos={"relative"} w={["full", null, "auto"]} mt={[0, 0, i > 2 ? 10 : 0]} alignItems={"center"} id={p.position?._id}>
                                    <Avatar name={p?.account?.company?.name} icon={<Image borderRadius={"50%"} src={`https://d1ejypfl65ma19.cloudfront.net/companies/images/${p?.account?.company?.uuid}.png`} />}  border={'1px solid #cacaca'} pos={"relative"} src={p?.account?.company?.image}>
                                    {p.account.lists?.[0] && p.account.lists?.[0]?.type !== "stale" ? <Tag w={"min-content"} whiteSpace={"nowrap"} minW={"min-content"} textTransform={"none"} rounded={"full"} textAlign={"right"} fontWeight={"medium"} pos={"absolute"} zIndex={1} top={-4} left={"100%"} borderBottomLeftRadius={2} colorScheme={evaluateTagColor(p.account.lists?.[0]?.type)}>{p.account.lists?.[0]?.name}</Tag> : <></>}
                                    </Avatar>
                                    <Box textAlign={"center"}>
                                        {p?.account?.company?.linkedIn ? <Text fontSize={"sm"}>{p?.account?.company?.name}</Text> : <Link target="_blank" color={"var(--chakra-colors-primary-500)"} textDecor={"underline"} href={`https://www.linkedin.com/company/${p?.account?.company?.reference?.replace("urn:li:fsd_company:", "")}`} isExternal={true}>{p?.account?.company?.name}</Link> }
                                        <Text w={["full", null, '200px']} fontSize={"sm"}>{p?.position?.name}</Text>
                                        <Flex textColor={"gray"} gap={2} alignItems={"center"} justifyContent={"center"}>
                                            <Text  fontSize={"sm"}>{moment.parseZone(p?.position?.startedOn).format("MMM YYYY")}</Text>
                                            <Text mt={-1}>-</Text>
                                            <Text fontSize={"sm"}>{p?.position?.endedOn ? moment.parseZone(p?.position?.endedOn).format("MMM YYYY") : 'Current'}</Text>
                                        </Flex>
                                    </Box>
                                </Stack>
                                {start && end && <Xarrow
                                    path="grid"
                                    startAnchor={{
                                        position: (i + 1) % 3 === 0 ? "bottom" : "auto",
                                        offset: {
                                            y: (i + 1) % 3 === 0 ? 10 : -10
                                        }
                                    }}
                                    endAnchor={{
                                        position: (i + 1) % 3 === 0 ? "top" : "auto",
                                        offset: {
                                            y: (i + 1) % 3 === 0 ? 0 : -10
                                        }
                                    }}
                                    color='#bfbfbf'
                                    dashness={{ strokeLen: 6, nonStrokeLen: 4 }}
                                    tailSize={2}
                                    strokeWidth={1.1}
                                    start={start}
                                    end={end}
                                />}
                                </>)
                        }
                        return <></>
                    })}
                </Flex>
            </Stack>
            <Divider mt={4} />
            {currentPath?.account?.company?.headcount ? <Stack mb={4} p={4}>
                <Text mb={3} fontWeight={"semibold"} fontSize={"md"}>About {currentPath?.account?.company?.name}</Text>
                <Flex alignItems={"center"}>
                    <Box>
                        <Avatar size={"lg"} borderRadius={4} src={currentPath?.account?.company?.image} icon={<Image borderRadius={"50%"} src={`https://d1ejypfl65ma19.cloudfront.net/companies/images/${currentPath?.account?.company?.uuid}.png`} />} name={currentPath?.account?.company?.name} />
                    </Box>
                    <Box ml={2}>
                        <Link isExternal href={`https://linkedin.com/company/${currentPath?.account?.company?.linkedIn}`}>{currentPath?.account?.company?.name} <i style={{ color: 'var(--chakra-colors-linkedin-600)' }} className='fi fi-brands-linkedin'></i></Link>
                        {currentPath?.account?.company?.headcount ? <Text fontSize={"sm"}>{currentPath?.account?.company?.headcount} employees {currentPath?.account?.company?.distribution?.filter(d => d.name === "Sales")[0]?.count ? `, ${currentPath?.account?.company?.distribution?.filter(d => d.name === "Sales")[0]?.count} Sales Reps` : ''}</Text> : <></> }
                        <Text color={"gray"} fontSize={"sm"}>{currentPath?.account?.company?.headquarter?.city}, {currentPath?.account?.company?.headquarter?.country}</Text>
                    </Box>
                </Flex>
                {/* <Text fontSize={"sm"} color={"#757575"} >{currentPath?.account?.company?.tagline}</Text> */}
                <Collapse startingHeight={46} in={show}>
                <Text fontSize={"sm"}>{!show ? `${currentPath?.account?.company?.description?.slice(0, 210)}...`: currentPath?.account?.company?.description}</Text>
                </Collapse>
                <Button colorScheme="primary" variant={"link"} w={"fit-content"} display={"inline"} size='sm' onClick={handleToggle}>
                    Show {show ? 'less' : 'more'}
                </Button>
                <Divider mt={4} />
            </Stack> : <></>}
            {currentPath?.account?.company?.distribution?.length ? <Stack pb={4} px={4}>
                <Text mb={3} fontWeight={"semibold"} fontSize={"md"}>Headcount & Distribution</Text>
                {currentPath?.account?.company?.distribution?.length && <Flex direction={['column', null, "row"]} mt={2} justifyContent={"space-between"}>
                    <Chart
                    options={{
                        chart: {
                            fontFamily: "Plus Jakarta Sans",
                        },
                        colors: colors,
                        plotOptions: {
                            pie: {
                                donut: {
                                    size: '50%'
                                }
                            },
                        },
                        labels: currentPath.account?.company?.distribution?.map(f => f.name)?.sort((a, b) => a.count - b.count)?.slice(0, 5),
                        legend: {
                            position: 'bottom'
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: '100%'
                                }
                            }
                        }]
                    }}
                    series={currentPath?.account?.company?.distribution?.map(f => f.count)?.slice(0, 5)}
                    type="donut"
                    height={300}
                />
                <Table tableStyle={{
                    marginTop: 0,
                    flex: 1
                }} headers={[
                    "name",
                    "headcount"
                ]} disableActions={true} disablePagination={true}>
                    {currentPath?.account?.company?.distribution?.slice(0, 5)?.map(f => <Tr>
                        <Td>{f.name}</Td>
                        <Td>{f.count}</Td>
                    </Tr>)}
                </Table>
                </Flex>}
            </Stack> : <></> }
        </div>}
        </Box>
    );
}

export default FanPublic