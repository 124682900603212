import { LOAD_ALL_ACTIONS, LOAD_ALL_APPS, LOAD_PROPERTIES, LOAD_PROPERTIES_ERROR, LOAD_SPECIFIC_APP, LOAD_UPDATE_MAPPING, LOAD_UPDATE_MAPPING_ERROR, LOAD_WORKSPACE_CONNECTIONS, RESET_MAPPING, SET_ALL_ACTIONS, SET_ALL_ACTIONS_ERROR, SET_ALL_APPS, SET_ALL_APPS_ERROR, SET_PROPERTIES, SET_SPECIFIC_APP, SET_SPECIFIC_APP_ERROR, SET_UPDATE_MAPPING, SET_WORKSPACE_CONNECTIONS, SET_WORKSPACE_CONNECTIONS_ERROR } from "./constants";

/**
 * your reducers will go here
 */
const INITIAL_STATE = {
    apps: {
        state: "loading",
        data: [],
        error : ''
    },
    connections: {
        state: "loading",
        data: [],
        error : ''
    },
    app: {
        state: "loading",
        data: {},
        error : ''
    },
    mapping: {
        state: "pending",
        data: {},
        error : ''
    },
    actions: {
        state: "loading",
        data: [],
        error : ''
    },
    properties: {
        state: "loading",
        data: [],
        error: ''
    }
}

const AppsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_ALL_APPS:
            return {
                ...state,
                apps: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_ALL_APPS:
            return {
                ...state,
                apps: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_ALL_APPS_ERROR:
            return {
                ...state,
                apps: {
                    state: "error",
                    data: [],
                    error: action.error
                }
            }
        case LOAD_WORKSPACE_CONNECTIONS:
            return {
                ...state,
                connections: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_WORKSPACE_CONNECTIONS:
            return {
                ...state,
                connections: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_WORKSPACE_CONNECTIONS_ERROR:
            return {
                ...state,
                connections: {
                    state: "error",
                    data: [],
                    error: action.error
                }
            }
        case LOAD_ALL_ACTIONS:
            return {
                ...state,
                actions: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_ALL_ACTIONS:
            return {
                ...state,
                actions: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_ALL_ACTIONS_ERROR:
            return {
                ...state,
                actions: {
                    state: "error",
                    data: [],
                    error: action.error
                }
            }
        case LOAD_SPECIFIC_APP:
            return {
                ...state,
                app: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_SPECIFIC_APP:
            return {
                ...state,
                app: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_SPECIFIC_APP_ERROR:
            return {
                ...state,
                app: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        case LOAD_PROPERTIES:
            return {
                ...state,
                properties: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_PROPERTIES:
            return {
                ...state,
                properties: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case LOAD_PROPERTIES_ERROR:
            return {
                ...state,
                properties: {
                    state: "error",
                    data: [],
                    error: action.error
                }
            }
        case LOAD_UPDATE_MAPPING:
            return {
                ...state,
                mapping: {
                    state: "loading",
                    data: {},
                    error : ''
                }
            }
        case SET_UPDATE_MAPPING:
            return {
                ...state,
                mapping: {
                    state: "success",
                    data: action.payload,
                    error : ''
                }
            }
        case LOAD_UPDATE_MAPPING_ERROR:
            return {
                ...state,
                mapping: {
                    state: "error",
                    data: {},
                    error : action.error
                }
            }
        case RESET_MAPPING:
            return {
                ...state,
                mapping: {
                    state: "pending",
                    data: {},
                    error : ''
                }
            }
        default:
            return state;
    }
}

export default AppsReducer