/**
 * your thunk functions go here
 */

import { axios } from "../../../loaders/axios.loader";
import { addMessage, dispachLikeChat, dispatchLoadSession, dispatchReportChat, dispatchUnlikeChat, setError, setHistory, setLoading, setMessages, setSession } from "./actions";
import { events } from 'fetch-event-stream';

export const sendChatMessage = (workspace, session, query) => async (dispatch, getState) => {
    // dispatch(resetCurrentMessage())
    dispatch(setLoading(true))
    try {
        // const stream = new EventSource(`/workspaces/${workspace}/fans/search/v2/completions`, {
        //     headers: {
        //         'Authorization': `Bearer ${cookies.get("accessToken")}`
        //     },
        //     method: "POST"
        // })
        const request = await fetch(`${axios.defaults.baseURL}/workspaces/${workspace}/fans/search/v2/completions`, {
            body: JSON.stringify({
                query,
                sessionId: session,
                streaming: true
            }),
            headers: {
                'Authorization': axios.defaults.headers["Authorization"],
                'Content-Type': "application/json"
            },
            method: "POST"
        })
        if (request.ok) {
            let stream = events(request);
            for await (let event of stream) {
              if (event.event === "message") {
                // dispatch(setCurrentMessage(event.data))
              }
              if (event.event === "response") {
                const content = JSON.parse(event.data)
                console.group("Query")
                console.log(`Question: ${query}`)
                console.log(`Query: `, content.raw)
                console.log(`Response: `, content.response)
                console.log(`Results: `, content.results)
                console.groupEnd()
                dispatch(addMessage({
                    "role": "ai",
                    "content": content.response,
                    "data": content.results
                }))
              }
            }
        }
        // const stream = request.data
        // stream.on("data", (e) => {
        //     console.log(e)
        // })
        // const request = await axios.post(
        //     `/workspaces/${workspace}/fans/search/completions`,
        //     {
        //         query,
        //         sessionId: session
        //     }
        // )
        // const { response, raw } = request.data;
        // if (raw.TYPE !== "MESSAGE") {
        //     dispatch(addMessage({
        //         "role": "ai",
        //         "content": raw?.METRICS ? `There are ${response?.[0]?.count || 0} fans` : (response.length ? `Sure, there are ${response?.length} fans` : "Sorry😔, I don't have any data matching your query"),
        //         data: raw?.METRICS ? [] : response
        //     }))
        // } else {
        //     dispatch(addMessage({
        //         "role": "ai",
        //         "content": raw.MESSAGE || raw.DEFAULT_TEXT,
        //         data: []
        //     }))
        // }
        dispatch(setLoading(false))
    } catch (err) {
        dispatch(setError("Something went wrong. Try again with newer message"))
        dispatch(setLoading(false))
        console.log(err)
    }
}

export const getChatSessions = (workspace) => async (dispatch) => {
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/chats/`)
        const { response } = request.data;
        dispatch(setHistory(response))
    } catch (err) {
        console.log(err)
    }
}

export const getSpecificChatSession = (workspace, session) => async (dispatch) => {
    dispatch(dispatchLoadSession())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/chats/${session}`)
        const { response } = request.data;
        dispatch(setSession(response))
        dispatch(setMessages(response.messages))
    } catch (err) {
        console.log(err)
    }
}

export const createChatSession = (workspace, name) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
        const request = await axios.post(
            `/workspaces/${workspace}/chats/`,
            {
                name
            }
        )
        const { response } = request.data;
        dispatch(setSession(response))
    } catch (err) {
        console.log(err)
    }
}

export const likeChat = (workspace, session, index, chat) => async (dispatch) => {
    dispatch(dispachLikeChat(index))
    try {
        await axios.patch(
            `/workspaces/${workspace}/chats/${session}/messages/${chat}/like`,
            {
                index: chat,
                isLiked: true
            }
        )
    } catch (err) {
        console.log(err)
    }
}

export const unLikeChat = (workspace, session, index, chat) => async (dispatch) => {
    dispatch(dispatchUnlikeChat(index))
    try {
        await axios.patch(
            `/workspaces/${workspace}/chats/${session}/messages/${chat}/like`,
            {
                index: chat,
                isLiked: false
            }
        )
    } catch (err) {
        console.log(err)
    }
}

export const reportChat = (workspace, session, chat, reason, reasonCode) => async (dispatch) => {
    dispatch(dispatchReportChat(chat, reason))
    try {
        console.log(workspace, chat)
        await axios.patch(
            `/workspaces/${workspace}/chats/${session}/messages/${chat}/report`,
            {
                reason: reasonCode
            }
        )
    } catch (err) {
        console.log(err)
    }
}

export const deleteChatSession = (workspace, session) => async (dispatch) => {
    try {
        await axios.delete(`/workspaces/${workspace}/chats/${session}/`)
    } catch (err) {
        console.log(err)
    }
}