/**
 * your constant variables will go here
 */
export const LOAD_ALL_ACTIONS = "LOAD_ALL_ACTIONS";
export const SET_ALL_ACTIONS = "SET_ALL_ACTIONS";
export const SET_ALL_ACTIONS_ERROR = "SET_ALL_ACTIONS_ERROR";
export const LOAD_ALL_APPS = "LOAD_ALL_APPS";
export const SET_ALL_APPS = "SET_ALL_APPS";
export const SET_ALL_APPS_ERROR = "SET_ALL_APPS_ERROR";
export const LOAD_WORKSPACE_CONNECTIONS = "LOAD_WORKSPACE_CONNECTIONS";
export const SET_WORKSPACE_CONNECTIONS = "SET_WORKSPACE_CONNECTIONS";
export const SET_WORKSPACE_CONNECTIONS_ERROR = "SET_WORKSPACE_CONNECTIONS_ERROR";
export const LOAD_SPECIFIC_APP = "LOAD_SPECIFIC_APP";
export const SET_SPECIFIC_APP = "SET_SPECIFIC_APP";
export const SET_SPECIFIC_APP_ERROR = "SET_SPECIFIC_APP_ERROR";
export const LOAD_PROPERTIES = "LOAD_PROPERTIES";
export const SET_PROPERTIES = "SET_PROPERTIES";
export const LOAD_PROPERTIES_ERROR = "LOAD_PROPERTIES_ERROR";
export const LOAD_UPDATE_MAPPING = "LOAD_UPDATE_MAPPING";
export const SET_UPDATE_MAPPING = "SET_UPDATE_MAPPING";
export const LOAD_UPDATE_MAPPING_ERROR = "LOAD_UPDATE_MAPPING_ERROR";
export const RESET_MAPPING = "RESET_MAPPING";
