import { data } from "browserslist"
import { LOAD_FAN, LOAD_WORKSPACE_FANS, RESET_ACTION, RESET_FAN, SET_ACTION_ERROR, SET_ACTION_LOADING, SET_ACTION_SUCCESS, SET_CREATE_FAN_ERROR, SET_CREATE_FAN_LOADING, SET_CREATE_FAN_STATUS, SET_CREATE_FAN_SUCCESS, SET_DISCUSSION_CREATE_STATUS, SET_FAN, SET_FAN_ATTRIBUTE, SET_FAN_DISCUSSIONS, SET_FAN_DISCUSSIONS_ERROR, SET_FAN_DISCUSSIONS_LOADING, SET_FAN_LOAD_ERROR, SET_WORKSPACE_FANS } from "./constants"

const INITIAL_STATE = {
    isLoading: false,
    data: [],
    error: '',
    fan: {
        isLoading: true,
        error: '',
        data: {},
        total: '-'
    },
    discussions: {
        isLoading: true,
        error: '',
        data: []
    },
    create: {
        status: "pending",
        error: '',
        data: {}
    },
    action: {
        state: "pending",
        error: '',
        data: {}
    },
    status: "pending",
    hasMoreData: true
}

const FansReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_WORKSPACE_FANS:
            return {
                ...state,
                isLoading: true,
                ...(action.append ? {} : {
                    total: "-"
                })
            }
        case SET_WORKSPACE_FANS:
            return {
                ...state,
                error: '',
                isLoading: false,
                data: action.append ? [...state.data, ...action.data] : action.data,
                total: action.total || '-',
                hasMoreData: action.data.length
            }
        case LOAD_FAN:
            return {
                ...state,
                fan: {
                    isLoading: true,
                    data: {},
                    error: ''
                }
            }
        case SET_FAN:
            return {
                ...state,
                fan: {
                    error: '',
                    isLoading: false,
                    data: action.payload
                }
            }
        case RESET_FAN:
            return {
                ...state,
                fan: {
                    isLoading: true,
                    error: '',
                    data: {},
                    total: '-'
                }
            }
        case SET_FAN_LOAD_ERROR:
            return {
                ...state,
                fan: {
                    isLoading: false,
                    data: {},
                    error: action.error
                }
            }
        case SET_FAN_DISCUSSIONS:
            return {
                ...state,
                discussions: {
                    isLoading: false,
                    error: '',
                    data: action.payload,
                }
            }
        case SET_FAN_DISCUSSIONS_LOADING:
            return {
                ...state,
                discussions: {
                    isLoading: true,
                    error: '',
                    data: [],
                }
            }
        case SET_FAN_DISCUSSIONS_ERROR:
            return {
                ...state,
                discussions: {
                    isLoading: false,
                    error: action.error,
                    data: [],
                }
            }
        case SET_DISCUSSION_CREATE_STATUS:
            return {
                ...state,
                status: action.status
            }
        case SET_CREATE_FAN_LOADING:
            return {
                ...state,
                create: {
                    status: "loading",
                    error: '',
                    data: {}
                },
            }
        case SET_CREATE_FAN_SUCCESS:
            return {
                ...state,
                create: {
                    status: "success",
                    error: '',
                    data: action.payload
                },
            }
        case SET_CREATE_FAN_ERROR:
            return {
                ...state,
                create: {
                    status: "error",
                    error: action.error,
                    data: {}
                },
            }
        case SET_CREATE_FAN_STATUS:
            return {
                ...state,
                create: {
                    status: action.status,
                    error: '',
                    data: {}
                }
            }
        case SET_ACTION_LOADING:
            return {
                ...state,
                action: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_ACTION_SUCCESS:
            return {
                ...state,
                action: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_ACTION_ERROR:
            return {
                ...state,
                action: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        case RESET_ACTION:
            return {
                ...state,
                action: {
                    state: "pending",
                    data: {},
                    error: ''
                }
            }
        case SET_FAN_ATTRIBUTE:
            return {
                ...state,
                fan: {
                    ...state.fan,
                    data: {
                        ...state.fan.data,
                        [action.key]: action.data
                    }
                }
            }
        default:
            return state
    }
}

export default FansReducer