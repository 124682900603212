import { IS_CONNECTED_ACCOUNTS_LOADING, RESET_CONNECTED_ACCOUNT, SET_CONNCTED_ACCOUNT, SET_CONNECTED_ACCOUNT_ERROR } from "./constants"

const INITIAL_STATE = {
    connectedAccount: {
        status: "pending",
        data: {},
        error: ''
    }
}

const OnboardingReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case IS_CONNECTED_ACCOUNTS_LOADING:
            return {
                ...state,
                connectedAccount: {
                    data: {},
                    error: '',
                    status: "loading"
                }
            }
        case SET_CONNCTED_ACCOUNT:
            return {
                ...state,
                connectedAccount: {
                    data: action.payload,
                    error: '',
                    status: "success"
                }
            }
        case SET_CONNECTED_ACCOUNT_ERROR:
            return {
                ...state,
                connectedAccount: {
                    error: action.error,
                    status: "error"
                }
            }
        case RESET_CONNECTED_ACCOUNT:
            return {
                ...state,
                connectedAccount: {
                    status: "pending",
                    data: {},
                    error: ''
                }
            }
        default:
            return state
    }
}

export default OnboardingReducer
