import { axios } from "../../../loaders/axios.loader";
import { setEventDetailError, setEventDetailLoading, setEventDetailSuccess, setEventsListError, setEventsListLoading, setEventsListSuccess } from "./actions";

export const getEvents = (start, end, page = 1, size = 12) => async (dispatch) => {
    dispatch(setEventsListLoading())
    try {
        const events = await axios.get(
            `/events?start=${start}&end=${end}&page=${page}&size=${size}`
        )
        const { response, metadata } = events.data;
        console.log(metadata)
        dispatch(setEventsListSuccess(response, metadata))
    } catch (err) {
        dispatch(setEventsListError(err))
    }
}

export const getEventDetail = (event) => async (dispatch) => {
    dispatch(setEventDetailLoading())
    try {
        const detail = await axios.get(
            `/events/${event}/`
        )
        const { response } = detail.data;
        dispatch(setEventDetailSuccess(response))
    } catch (err) {
        dispatch(setEventDetailError(err))
    }
}