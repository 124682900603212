import { AddIcon } from "@chakra-ui/icons"
import { Avatar, Box, Button, Flex, Input, Menu, MenuButton, MenuItem, MenuList, Spinner, Text, useDisclosure } from "@chakra-ui/react"
import { debounce } from "lodash"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import './_index.scss';

/**
 * 
 * @param {import("@chakra-ui/react").MenuProps} props 
 * @returns 
 */
export default function SearchableMenu (props) {
    const isLoading = useSelector(state => state.dashboard.core.options.isLoading)
    const [options, setOptions] = useState([])
    const items = useSelector(state => state.dashboard.core.options.data)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const dispatch = useDispatch()
    const ref = useRef(null)

    const handleInputChange = debounce((e) => {
        if (props.onInputChange) {
            props.onInputChange(e)
        }
    }, 300)

    useEffect(() => {
        setOptions(items)
        if (ref.current) {
           setTimeout(() => {
            ref.current.focus()
           }, 300)
        }
    }, [ items ])

    useEffect(() => {
        if (props.items) {
            setOptions(props.items)
        }
    }, [])
    
    return (
        <Menu autoSelect={false} closeOnSelect={false} size={"sm"} isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <MenuButton as={Button} leftIcon={<AddIcon />} rounded={"full"} colorScheme="blackAlpha" size={"sm"} variant={"outline"}>{props.label || "add more"}</MenuButton>
            <MenuList maxW={"300px"} gap={4} h={!props.items ? '300px' : 'auto'} overflow={!props.items ? "scroll" : "auto"} borderRadius={8} pt={0}>
                {!props.items && <Input ref={ref} autoFocus fontSize={"sm"} borderRadius={2} focusBorderColor={0} border={0} outline={0} borderBottom={"1px solid #cacaca"} placeholder={props.placeholder || "Search"} onChange={handleInputChange} />}
                {options.map(i => <MenuItem cursor={"pointer"} py={2} borderBottom={"1px solid #eaeaea"} fontSize={"sm"} onClick={() => {
                    if (props.onItemClick) {
                        props.onItemClick(i)
                    }
                }}>{props.item(i)}</MenuItem>)}
                {isLoading && <MenuItem justifyContent={"center"}>
                <Spinner size={"sm"} /></MenuItem>}
                {(!options.length && !isLoading) && <MenuItem mt={2} color={"gray"} fontSize={"sm"} justifyContent={"center"}>No options Available</MenuItem>}
            </MenuList>
        </Menu >
        // <CommandPalette id="root" isOpen={true}>
        //     <CommandPalette.Page>
        //         <CommandPalette.List>
        //             <CommandPalette.ListItem>hELLO</CommandPalette.ListItem>
        //     </CommandPalette.List>
        //     </CommandPalette.Page>
        // </CommandPalette>
    )
}