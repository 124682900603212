import moment from "moment";
import { axios } from "../../../loaders/axios.loader"
import { setJobChangeSuccess, setJobPromotionsSuccess, setKpisLoading, setKpisSuccess, setSpecificReportLoading, setSpecificReportSuccess, setTopProspectsSuccess } from "./actions"

function diff(duration, date) {
    const start = moment(date)
      .subtract(duration, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const end = moment(date).endOf("month").format("YYYY-MM-DD");
    return { start, end };
  }

export const loadKpis = (workspace) => async (dispatch) => {
    try {
        const date = diff(1)
        dispatch(setKpisLoading())
        const total_fans = await axios.get(`/workspaces/${workspace}/fans/search/filters?page=1&size=10`)
        const { total: totalFans, response: fans } = total_fans.data
        const job_change_fans = await axios.get(`/workspaces/${workspace}/fans/search/filters?page=1&size=10&type[]=job_change`)
        const { total: totalJobChangeFans, response: jobChangeFans } = job_change_fans.data
        dispatch(setJobChangeSuccess(jobChangeFans, {
            total: totalJobChangeFans
        }))
        const job_promotion_fans = await axios.get(`/workspaces/${workspace}/fans/search/filters?page=1&size=10&type[]=job_promotion`)
        const { total: totalJobPromotionFans, response: jobPromotionFans } = job_promotion_fans.data
        dispatch(setJobPromotionsSuccess(jobPromotionFans, {
            total: totalJobPromotionFans
        }))
        const tx_fans = await axios.get(`/workspaces/${workspace}/fans/search/filters?page=1&size=10&type[]=2x_fan`)
        const { total: txFansTotal, response: txFans } = tx_fans.data
        dispatch(setKpisSuccess({
            total_fans: totalFans,
            job_change_fans: totalJobChangeFans,
            job_promotion_fans: totalJobPromotionFans,
            tx_fans: txFansTotal
        }, {
            total: totalFans
        }))
        const multi_fan_accounts = await axios.get(`/workspaces/${workspace}/multi-fan-accounts`)
        const { total: totalMultiFanAccounts, response: multiFanAccounts } = multi_fan_accounts.data
        dispatch(setTopProspectsSuccess(multiFanAccounts, totalMultiFanAccounts))
    } catch (err) {

    }
}

export const loadReport = (workspace, report) => async (dispatch) => {
    try {
        dispatch(setSpecificReportLoading())
        const request = await axios.get(`/workspaces/${workspace}/reports/${report}`)
        const { response } = request.data
        for (const view of response.views) {
            const { query } = view.view
            let filters = ''
            let url =''
            if (query.type?.length) {
                filters += `&type[]=${query.type.join("&type[]=")}`
                url += `&type=${query.type.join(",")}`
            }
            if (query.attendees?.length) {
                filters += `&attendees[]=${query.attendees.join("&attendees[]=")}`
                url += `&attendees=${query.attendees.join(",")}`
            }
            if (query.duration) {
                filters += `&duration=${query.duration}`
                url += `&duration=${query.duration}`
            }
            if (query.pastCompany?.length) {
                filters += `&pastCompany=${query.pastCompany.join(",")}`
                url += `&pastCompany=${query.pastCompany.join(",")}`
            }
            if (query.currentCompany?.length) {
                filters += `&currentCompany=${query.currentCompany.join(",")}`
                url += `&currentCompany=${query.currentCompany.join(",")}`
            }
            const _request = await axios.get(
                `/workspaces/${workspace}/fans/search/filters?page=${1}&size=10${filters}`,
            );
            const { response: _response } = _request.data
            view.url = url
            view.fans = _response
            view.total = _request.data.total
        }
        console.log(response)
        dispatch(setSpecificReportSuccess(response))
    } catch (err) {

    }
}