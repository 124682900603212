import { axios } from "../../../loaders/axios.loader"
import { setCreateWorkspaceLoading, setCreateWorkspaceSuccess, setWorkspaceInformationError, setWorkspaceInformationLoading, setWorkspaceInformationSuccess } from "./actions"

export const loadWorkspaceInformation = (website) => async (dispatch) => {
    dispatch(setWorkspaceInformationLoading())
    try {
        const request = await axios.post(`/shared/extract-metadata`, {
            url: website
        })
        dispatch(setWorkspaceInformationSuccess(request.data))
    } catch (err) {
        dispatch(setWorkspaceInformationError())
    }
}

export const createWorkspace = (payload, callback, errorCallback) => async (dispatch) => {
    dispatch(setCreateWorkspaceLoading())
    try {
        const formData = new FormData()
        for (const key of Object.keys(payload)) {
            formData.set(key, payload[key])
        }
        const request = await axios.postForm(`/accounts/workspaces/create`, formData)
        const { response } = request.data
        dispatch(setCreateWorkspaceSuccess(response))
        callback?.(response)
    } catch (err) {
        errorCallback?.(err)
    }
}