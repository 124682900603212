import { axios } from "../../../loaders/axios.loader"
import { loadProfile } from "../core/thunk"
import { setProfileImageUploadError, setProfileImageUploadLoading, setProfileImageUploadSuccess, setUpdateProfileError, setUpdateProfileLoading, setUpdateProfileSuccess } from "./actions"

export const uploadUserImage = (image, callback, errorCallback) => async (dispatch) => {
    dispatch(setProfileImageUploadLoading())
    try {
        const formData = new FormData()
        formData.append("file", image)
        const request = await axios.postForm(`/accounts/profile/upload-image`, formData)
        const { response } = request.data
        dispatch(loadProfile())
        callback?.(response.url)
        dispatch(setProfileImageUploadSuccess())
    } catch (err) {
        errorCallback?.(err)
        dispatch(setProfileImageUploadError())
    }
}

export const updateUserProfile = (payload, callback, errorCallback) => async (dispatch) => {
    dispatch(setUpdateProfileLoading())
    try {
        await axios.patch(`/accounts/profile/`, payload)
        dispatch(loadProfile())
        callback?.()
        dispatch(setUpdateProfileSuccess())
    } catch (err) {
        errorCallback?.()
        dispatch(setUpdateProfileError())
    }
}