import { Avatar, AvatarGroup, Badge, Box, Button, ButtonGroup, Card, CardBody, CardHeader, Center, Flex, Heading, IconButton, Image, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Td, Text, Tr, useDisclosure } from "@chakra-ui/react"
import Page from "../../../components/core/@page"
import { AddIcon, EditIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons"
import Table from "../../../components/core/@table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadTeam } from "../../../redux/app/team/thunk";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { EmptyUserIcon } from "../../../components/core/utils";
import { getNotifications, getUserNotifications } from "../../../redux/app/notifications/thunk";
import moment from "moment-timezone";
import { AtSignIcon, BellRingIcon } from "lucide-react";

function Notification() {
    const ctx = useOutletContext();
    const workspace = useSelector(state => state.dashboard.core.workspace)
    const notifications = useSelector(state => state.dashboard.notifications.list)
    const myNotifications = useSelector(state => state.dashboard.notifications.my)
    const isLoading = useSelector(state => state.dashboard.team.isLoading)
    const team = useSelector(state => state.dashboard.team.data)
    const error = useSelector(state => state.dashboard.team.error)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getNotifications(workspace.uuid))
        dispatch(getUserNotifications(workspace.uuid))
    }, [])

    return (
        <Page
        title={"Notifications"}
        subtitle={"view, manage all your alerts"}
    >
      <Tabs w={"fit-content"} variant="line" colorScheme="primary">
          <TabList>
            <Tab>Mentions</Tab>
            <Tab>Comments</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>

            {notifications.status === "loading" ? <Center height={"60vh"}>
                <Spinner />
            </Center> : notifications.status === "success" ? (!notifications.data.length ? <Center>
            <Card boxShadow={0} mt={8} align='center'>
                <CardHeader pb={0} textAlign={"center"}>
                    <WarningTwoIcon boxSize={6} />
                    <Heading mt={2} size='md'> no new notifications</Heading>
                </CardHeader>
                <CardBody pt={2}>
                <Text>there are no new alerts yet</Text>
                </CardBody>
                </Card>
            </Center> : <Stack mt={2} maxW={600} ml={4}>
            {notifications.data.map(notification => {
                return (
                    <Flex cursor={"pointer"} onClick={() => {
                        if (notification.metadata?.fan?.uuid) {
                            ctx?.setCurrentFan?.(notification.metadata?.fan?.uuid)
                            ctx?.onFanModalOpen?.()
                        }
                    }} pb={2} borderBottom={"1px solid #cacaca"} w={"full"} alignItems={"center"}>
                        <Box cursor={"pointer"}  px={2} py={2} borderRadius={6}>
                          <AvatarGroup size={"sm"}>
                        {notification?.metadata?.fan ? <Avatar name={ notification.metadata?.fan?.fullName || notification.metadata?.fan?.firstName} src={notification.metadata?.fan?.profile?.image} /> : <></>}
                          {/* { notification.type === "mention" ? <AtSignIcon size={16} /> : <BellRingIcon size={16} />} */}
                        
                            <Avatar name={ notification.createdBy?.fullName || notification.createdBy?.firstName} src={notification.type === "mention" && notification.createdBy ? notification.createdBy?.image : '/logo512.png'} />
                            {/* { notification.type === "mention" ? <AtSignIcon /> : <BellRingIcon />} */}
                          </AvatarGroup>
                        </Box>
                        <Stack w={"full"} gap={1} ml='3'>
                          <Flex alignItems={"center"} gap={4} justifyContent={"space-between"}>
                          <Text noOfLines={1} title={notification.title} fontWeight='semibold'>
                            {notification.title}
                          </Text>
                          <Stack alignItems={"end"}>
                            {/* <Box fontSize={"xs"} color={"white"} boxSize={2} rounded={"full"} bg={"red.500"}></Box> */}
                            <Text fontSize={"sm"} color={"gray"}>
                                {moment(notification.createdAt).fromNow()}
                            </Text>
                          </Stack>
                          </Flex>
                          <Text py={1} textOverflow={"ellipsis"} dangerouslySetInnerHTML={{ __html: notification.content }}></Text>
                        </Stack>
                    </Flex>
                )
            })}
            </Stack>) : <></>}
            </TabPanel>
            <TabPanel>

            {myNotifications.status === "loading" ? <Center height={"60vh"}>
                <Spinner />
            </Center> : myNotifications.status === "success" ? (!myNotifications.data.length ? <Center>
            <Card boxShadow={0} mt={8} align='center'>
                <CardHeader pb={0} textAlign={"center"}>
                    <WarningTwoIcon boxSize={6} />
                    <Heading mt={2} size='md'> no new notifications</Heading>
                </CardHeader>
                <CardBody pt={2}>
                <Text>there are no new alerts yet</Text>
                </CardBody>
                </Card>
            </Center> : <Stack mt={2} maxW={600} ml={4}>
            {myNotifications.data.map(notification => {
                return (
                    <Flex cursor={"pointer"} onClick={() => {
                        if (notification.metadata?.fan?.uuid) {
                            ctx?.setCurrentFan?.(notification.metadata?.fan?.uuid)
                            ctx?.onFanModalOpen?.()
                        }
                    }} pb={2} borderBottom={"1px solid #cacaca"} w={"full"} alignItems={"center"}>
                        <Box cursor={"pointer"}  px={2} py={2} borderRadius={6}>
                          <AvatarGroup size={"sm"}>
                        {notification?.metadata?.fan ? <Avatar name={ notification.metadata?.fan?.fullName || notification.metadata?.fan?.firstName} src={notification.metadata?.fan?.profile?.image} /> : <></>}
                          {/* { notification.type === "mention" ? <AtSignIcon size={16} /> : <BellRingIcon size={16} />} */}
                        
                            <Avatar name={ notification.user?.fullName || notification.user?.firstName} src={notification.user?.image} />
                            {/* { notification.type === "mention" ? <AtSignIcon /> : <BellRingIcon />} */}
                          </AvatarGroup>
                        </Box>
                        <Stack w={"full"} gap={1} ml='3'>
                          <Flex alignItems={"center"} gap={4} justifyContent={"space-between"}>
                          <Text noOfLines={1} title={notification.title} fontWeight='semibold'>
                            {notification.title}
                          </Text>
                          <Stack alignItems={"end"}>
                            {/* <Box fontSize={"xs"} color={"white"} boxSize={2} rounded={"full"} bg={"red.500"}></Box> */}
                            <Text fontSize={"sm"} color={"gray"}>
                                {moment(notification.createdAt).fromNow()}
                            </Text>
                          </Stack>
                          </Flex>
                          <Text py={1} textOverflow={"ellipsis"} dangerouslySetInnerHTML={{ __html: notification.content }}></Text>
                        </Stack>
                    </Flex>
                )
            })}
            </Stack>) : <></>}
            </TabPanel>
          </TabPanels>
        </Tabs>
    </Page>
    )
}

export default Notification