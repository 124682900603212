import { LOAD_WORKSPACE_ACCOUNTS, SET_WORKSPACE_ACCOUNTS, SET_WORKSPACE_ACCOUNTS_ERROR,
SET_SPECIFIC_ACCOUNT, LOAD_SPECIFIC_ACCOUNT, SET_SPECIFIC_ACCOUNT_LOAD_ERROR } from "./constants";

export function setWorkspaceAccountsLoading () {
    return {
        type: LOAD_WORKSPACE_ACCOUNTS
    }
}

export function setWorkspaceAccounts (payload) {
    return {
        type: SET_WORKSPACE_ACCOUNTS,
        payload
    }
}

export function setWorkspaceAccountsError (error) {
    return {
        type: SET_WORKSPACE_ACCOUNTS_ERROR,
        error
    }
}

export function setSpecificAccountLoading () {
    return {
        type: LOAD_SPECIFIC_ACCOUNT
    }
}

export function setSpecificAccount(payload) {
    return {
        type: SET_SPECIFIC_ACCOUNT,
        payload
    }
}

export function setSpecificAccountLoadError (error) {
    return {
        type: SET_SPECIFIC_ACCOUNT_LOAD_ERROR,
        error
    }
}