import { SET_JOB_CHANGE_ERROR, SET_JOB_CHANGE_LOADING, SET_JOB_CHANGE_SUCCESS, SET_JOB_PROMOTIONS_ERROR, SET_JOB_PROMOTIONS_LOADING, SET_JOB_PROMOTIONS_SUCCESS, SET_KPIS_ERROR, SET_KPIS_LOADING, SET_KPIS_SUCCESS, SET_MEETINGS_ERROR, SET_MEETINGS_LOADING, SET_MEETINGS_SUCCESS, SET_SPECIFIC_REPORT_ERROR, SET_SPECIFIC_REPORT_LOADING, SET_SPECIFIC_REPORT_SUCCESS, SET_TOP_PROSPECTS_ERROR, SET_TOP_PROSPECTS_LOADING, SET_TOP_PROSPECTS_SUCCESS } from "./constants"

export const setKpisLoading = () => {
    return {
        type: SET_KPIS_LOADING
    }
}

export const setKpisSuccess = (payload, metadata) => {
    return {
        type: SET_KPIS_SUCCESS,
        payload,
        metadata
    }
}

export const setKpisError = (error) => {
    return {
        type: SET_KPIS_ERROR,
        error
    }
}

// Job Changes
export const setJobChangeLoading = () => {
    return {
        type: SET_JOB_CHANGE_LOADING
    }
}

export const setJobChangeSuccess = (payload, metadata) => {
    return {
        type: SET_JOB_CHANGE_SUCCESS,
        payload,
        metadata
    }
}

export const setJobChangeError = (error) => {
    return {
        type: SET_JOB_CHANGE_ERROR,
        error
    }
}

// Job Promotions
export const setJobPromotionsLoading = () => {
    return {
        type: SET_JOB_PROMOTIONS_LOADING
    }
}

export const setJobPromotionsSuccess = (payload, metadata) => {
    return {
        type: SET_JOB_PROMOTIONS_SUCCESS,
        payload,
        metadata
    }
}

export const setJobPromotionsError = (error) => {
    return {
        type: SET_JOB_PROMOTIONS_ERROR,
        error
    }
}

// Meetings
export const setMeetingsLoading = () => {
    return {
        type: SET_MEETINGS_LOADING
    }
}

export const setMeetingsSuccess = (payload, metadata) => {
    return {
        type: SET_MEETINGS_SUCCESS,
        payload,
        metadata
    }
}

export const setMeetingsError = (error) => {
    return {
        type: SET_MEETINGS_ERROR,
        error
    }
}

// Top Prospects
export const setTopProspectsLoading = () => {
    return {
        type: SET_TOP_PROSPECTS_LOADING
    }
}

export const setTopProspectsSuccess = (payload, metadata) => {
    return {
        type: SET_TOP_PROSPECTS_SUCCESS,
        payload,
        metadata
    }
}

export const setTopProspectsError = (error) => {
    return {
        type: SET_TOP_PROSPECTS_ERROR,
        error
    }
}

export const setSpecificReportLoading = () => {
    return {
        type: SET_SPECIFIC_REPORT_LOADING
    }
}

export const setSpecificReportSuccess = (payload, metadata) => {
    return {
        type: SET_SPECIFIC_REPORT_SUCCESS,
        payload,
        metadata
    }
}

export const setSpecificReportError = (error) => {
    return {
        type: SET_SPECIFIC_REPORT_ERROR,
        error
    }
}