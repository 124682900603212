import {
    SET_PROFILE_IMAGE_UPLOAD_LOADING,
    SET_PROFILE_IMAGE_UPLOAD_SUCCESS,
    SET_PROFILE_IMAGE_UPLOAD_ERROR,
    SET_GET_PROFILE_LOADING,
    SET_GET_PROFILE_SUCCESS,
    SET_GET_PROFILE_ERROR,
    SET_UPDATE_PROFILE_LOADING,
    SET_UPDATE_PROFILE_SUCCESS,
    SET_UPDATE_PROFILE_ERROR
} from './constants';

// Profile Image Upload Actions
export function setProfileImageUploadLoading() {
    return {
        type: SET_PROFILE_IMAGE_UPLOAD_LOADING
    };
}

export function setProfileImageUploadSuccess(payload) {
    return {
        type: SET_PROFILE_IMAGE_UPLOAD_SUCCESS,
        payload
    };
}

export function setProfileImageUploadError(error) {
    return {
        type: SET_PROFILE_IMAGE_UPLOAD_ERROR,
        error
    };
}

// Get Profile Actions
export function setGetProfileLoading() {
    return {
        type: SET_GET_PROFILE_LOADING
    };
}

export function setGetProfileSuccess(payload) {
    return {
        type: SET_GET_PROFILE_SUCCESS,
        payload
    };
}

export function setGetProfileError(error) {
    return {
        type: SET_GET_PROFILE_ERROR,
        error
    };
}

// Update Profile Actions
export function setUpdateProfileLoading() {
    return {
        type: SET_UPDATE_PROFILE_LOADING
    };
}

export function setUpdateProfileSuccess(payload) {
    return {
        type: SET_UPDATE_PROFILE_SUCCESS,
        payload
    };
}

export function setUpdateProfileError(error) {
    return {
        type: SET_UPDATE_PROFILE_ERROR,
        error
    };
}
