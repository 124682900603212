import { Image } from "@chakra-ui/react";
import HubspotIcon from '../../../assets/img/hubspot.svg'
import CalendarIcon from '../../../assets/img/calendar.svg'

const HubspotImage = ({size}) => <Image boxSize={size || 4} src={HubspotIcon} />
const CalendarImage = ({size}) => <Image boxSize={size || 4} src={CalendarIcon} />

export {
    HubspotImage,
    CalendarImage
};
