import { SET_LISTS, SET_LISTS_LOADING, SET_OPTIONS, SET_OPTIONS_LOADING, SET_PROFILE, SET_WORKSPACE, SET_WORKSPACES } from "./constants"

const INITIAL_STATE = {
    workspaces: [],
    isLoading: true,
    workspace: {
        isLoading: true
    },
    profile: {
        isLoading: true
    },
    options: {
        isLoading: false,
        data: []
    },
    lists: {
        isLoading: false,
        data: []
    }
}

export default function CoreReducer (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_WORKSPACES:
            return {
                ...state,
                workspaces: action.payload || [],
                isLoading: false
            }
        case SET_WORKSPACE:
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    isLoading: false,
                    ...(action.payload || {})
                }
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    isLoading: false,
                    ...(action.payload || {})
                }
            }
        case SET_OPTIONS_LOADING:
            return {
                ...state,
                options: {
                    isLoading: true,
                    data: []
                }
            }
        case SET_OPTIONS:
            return {
                ...state,
                options: {
                    isLoading: false,
                    data: action.payload || []
                }
            }
        case SET_LISTS_LOADING:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    isLoading: true
                }
            }
        case SET_LISTS:
            return {
                ...state,
                lists: {
                    isLoading: false,
                    data: action.payload
                }
            }
        default:
            return state
    }
}