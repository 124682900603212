import { ny } from '../../lib/utils'

function Skeleton({
   className,
   ...props
}) {
   return (<div className={ny('bg-muted animate-pulse rounded-md', className)} {...props} />);
}

export { Skeleton }
