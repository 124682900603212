import {
  Avatar,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Progress,
  SkeletonCircle,
  SkeletonText,
  SlideFade,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { Step, StepSeparator, Stepper, useSteps } from "@chakra-ui/react";
import Logo from "../../components/core/@branding/logo";
import Frame from "../../assets/img/frame.png";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { CreatableSelect, Select } from "chakra-react-select";
import slugify from "slugify";
import { useRive } from "@rive-app/react-canvas";
import { axios } from "../../loaders/axios.loader";
import { components } from "react-select";
import { debounce } from "lodash";

const steps = [
  { title: "First", description: "Contact Info" },
  { title: "First", description: "Contact Info" },
  { title: "Second", description: "Date & Time" },
  { title: "Third", description: "Select Rooms" },
];

const CircleIcon = (props) => (
  <Icon viewBox='0 0 200 200' {...props}>
    <path
      fill='currentColor'
      d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
    />
  </Icon>
)

function ProfileCard ({ l }) {
  const [score, setScore] = useState('-');

  useEffect(() => {
    // (async () => {
    //   const request = await axios.post(
    //     "http://127.0.0.1:5001/api/fan/ranking/",
    //     {
    //       "query": l.currentPositions[0].title,
    //       "icp": "CXOs, Revenue Operations & Sales Operations",
    //       "mapping": "CRO, CXO, VP of Revenue Operations, VP of Sales Operations => 5\nDirector or Sr. Manager of RevOps / Sales Ops => 4\nManager, Rev Ops / Sales Ops, VP of Sales => 3\nAccount Executives => 2.5\nSales ops or Rev ops roles other than this => 2\nOther roles out of industry => 1"
    //   }
    //   );
    //   const { score: s } = request.data;
    //   setScore(s)
    // })()
  }, [])
  const getLinkedInProfile = (s) => `https://www.linkedin.com/in/${s.split('(')[1].split(",")[0]}`

  return <Stack px={3} py={3} height={"100%"} border={"1px solid #cacaca"} borderRadius={6} flex={0.3}>
  <Avatar src={l.image} name={l.fullName} />
  <Flex alignItems={"center"}>
    <Box>
      {/* <Text fontWeight={"medium"}>{l.fullName} <Link isExternal href={getLinkedInProfile(l.entityUrn)}><i style={{ color: 'var(--chakra-colors-linkedin-600)' }} className='fi fi-brands-linkedin'></i></Link></Text> */}
      <Text fontWeight={"semibold"}>{l.fullName}</Text>
      <Text fontSize={"sm"} fontWeight={"medium"} noOfLines={1} w={360} textOverflow={"ellipsis"}>{l.currentPositions[0].title}</Text>
      <Flex alignItems={"center"}>
      <Text fontSize={"sm"} fontWeight={"medium"}>{l.currentPositions[0].companyName}</Text>
      <CircleIcon style={{
          marginTop: 4,
          color: 'gray',
          marginLeft: 4,
          marginRight: 4
      }} color="gray.700" boxSize={1} />
      <Text w={"100px"} textOverflow={"ellipsis"} noOfLines={"1"} fontSize={"sm"}>
      <i style={{
          marginRight: 4
      }} className="fi fi-rr-marker"></i>
        {l.location}
      </Text>
      </Flex>
      
    </Box>
    <Tag cursor={"pointer"} fontWeight={"semibold"} colorScheme='green'>
        <i className='fi fi-sr-star fui-fan-star'></i>
        <TagLabel>{score ? <>{score}/5</> : <Spinner size={"xs"} colorScheme="whiteAlpha" />}</TagLabel>
    </Tag>
  </Flex>
</Stack>
}

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "black",
    display: "flex",
    cursor: "pointer"
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <Checkbox pointerEvents={"none"} cursor={"pointer"} mr={3} isChecked={isSelected} colorScheme='primary'>
      {children}
      </Checkbox>
    </components.Option>
  );
};

function Onboarding() {
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({
    name: "",
    url: "",
    customers: [],
    target: [],
    personas: [],
    seniority: []
  });
  const { rive, RiveComponent } = useRive({
    src: "/5132-10323-logo-interaction.riv",
    autoplay: true,
    animations: "goz",
  });
  const [domain, setDomain] = useState('');
  const [domainUrl, setDomainUrl] = useState('');
  const [error, setError] = useState(true);
  const [isLeadsLoading, setLeadsLoading] = useState(false);
  const [isWorkspaceLoading, setWorkspaceLoading] = useState(false)
  const [leads, setLeads] = useState([]);

  const getCustomerNames = async () => {
    const request = await axios.post(
      "/shared/companies-search/",
      {
        url: domainUrl,
      }
    );
    const { response } = request.data;
    setOptions(
      response.map((c) => ({
        label: c,
        value: c.toLowerCase(),
      })) || []
    );
  };

  const getCaseStudyUrl = async (url) => {
    const request = await axios.post(
      "/shared/case-study/",
      {
        url: url.startsWith('http') ? url : `https://${url}`,
      }
    );
    const { response } = request.data;
    if (response?.length) {
      let durl = new URL(response, url.startsWith('http') ? url : `https://${url}`)
      setDomainUrl(durl.href);
    }
  };

  const handleDomainChange = debounce((e) => {
    getCaseStudyUrl(e.target.value)
  }, 1500)

  useEffect(() => {
    if (domainUrl.length) {
      getCustomerNames()
    }
  }, [ domainUrl ])

  const extractImageFromArtifacts = (source) => {
    if (!source) {
      return "";
    }
    const root = source?.rootUrl;
    const artifacts = source?.artifacts || [];
    const image = artifacts.sort((a, b) => a.width - b.width).pop();
    return `${root || ""}${image?.fileIdentifyingUrlPathSegment || ""}`;
  };

  const getLeads = async () => {
    setLeadsLoading(true)
    const request = await axios.post(
      `/shared/leads/`,
      {
        companies: data.customers.map(c => c.value),
        functions: data.personas.map(p => ({ id: p.value, displayValue: p.label })),
        seniority: data.seniority.map(p => ({ id: p.value, displayValue: p.label })),
      }
    );
    setLeads(
      request.data.response.map((p) => ({
        firstName: p.firstName,
        lastName: p.lastName,
        fullName: p.fullName,
        location: p.geoRegion,
        currentPositions: p.currentPositions,
        entityUrn: p.entityUrn,
        image: extractImageFromArtifacts(p.profilePictureDisplayImage),
      }))
    );
    setLeadsLoading(false)
  };

  const setupWorkspace = async () => {
    setWorkspaceLoading(true)
    const workspace = await axios.post(
      `/accounts/workspaces/create`,
      {
        handle: data.url,
        name: data.name,
        url: domain,
        companies: options.map(e => e.label)
      }
    );
    await axios.post(
      `/workspaces/${workspace.data.response.uuid}/leads`,
      {
        companies: data.customers.map(c => c.value),
        functions: data.personas.map(p => ({ id: p.value, displayValue: p.label })),
        seniority: data.seniority.map(p => ({ id: p.value, displayValue: p.label })),
      }
    )
    const protocol = window.location.protocol
    const host = window.location.host.split(".");
    const tdomain = host.slice(host.length > 2 ? 1 : 0).join(".")
    const redirectUrl = `${protocol}//${data.url}.${tdomain}`
    window.location.href = `${redirectUrl}/home/fans?open=true&new=true&question=${encodeURIComponent(`Is there a fan working in ${(leads[0]?.currentPositions?.[0]?.companyName?.split("(")?.[0] || '').trim()}`)}`
    setWorkspaceLoading(false)
  }

  useEffect(() => {
    if (!options.length) {
    }
  }, []);

  const checkWorkspaceAvailability = async () => {
    if (data.url) {
      try {
        await axios.get(`/shared/validate?handle=${data.url}`)
        setError(false)
      } catch (err) {
        setError(true)
      }
    }
  }

  useEffect(() => {
    checkWorkspaceAvailability()
  }, [ data.url ])

  return (
    <Flex>
      <Stack
        alignItems={"center"}
        justifyContent={"space-between"}
        height={"100vh"}
        flex={activeStep < 3 ? 0.55 : 1}
        borderRight={activeStep < 3 ? "0.5px solid #CACACA" : 0}
      >
        {activeStep === 3 && isWorkspaceLoading && (
          <Box h={"100vh"} pos={"relative"}>
            <RiveComponent
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -60%)",
                zIndex: -1,
              }}
            />
            <Text
              fontWeight={"semibold"}
              fontSize={"lg"}
              marginTop={"110%"}
              zIndex={1}
            >
              Please wait while we are building your fanbase
            </Text>
            <Progress
              borderRadius={10}
              mt={4}
              colorScheme="primary"
              size="xs"
              isIndeterminate
            />
          </Box>
        )}
        {(activeStep === 3 && !leads.length && !isWorkspaceLoading) ? <Stack h={"100vh"} justifyContent={"center"} alignItems={"center"}>
          <Center><Spinner /></Center>
        </Stack> : <></>}
        {/* Leads code here */}
        {(activeStep === 3 && leads.length && !isWorkspaceLoading) && <Stack h={"100vh"} justifyContent={"center"} alignItems={"center"}>
                <SlideFade in={true} delay={0.1}>
                  <Heading mb={6} fontSize={"2xl"}>Yay 🎉, here are some of your fans</Heading>
                </SlideFade>
                <Flex w={0.95} justifyContent={"center"} wrap={"wrap"} gap={3}>
                {leads.slice(0, 9).map((l, i) => {
                  return <SlideFade in={true} delay={0.1 * i + 0.1}>
                    <ProfileCard l={l} />
                  </SlideFade>
                })}
                  </Flex>
                <Flex gap={2}>
                <Button onClick={() => {
                  setActiveStep(2)
                  setWorkspaceLoading(false)
                  setLeadsLoading(false)
                }} mt={6} fontWeight={"medium"} w={"fit-content"} variant={"outline"}>Previous</Button>
                <Button onClick={() => {
                  setupWorkspace()
                }} mt={6} rightIcon={<ArrowForwardIcon />} fontWeight={"medium"} w={"fit-content"} colorScheme="primary">Take me in</Button>
                </Flex>
              </Stack> }
        {/* Ends here */}
        <Stack
          display={activeStep < 3 ? "flex" : "none"}
          style={{
            width: "70%",
          }}
          gap={3}
          py={10}
        >
          <Stack w={0.7} mt={10}>
            <SlideFade delay={0.1} in={activeStep < 3} offsetY="20px">
              <Logo
                style={{
                  height: 24,
                  marginBottom: 10,
                }}
                size="small"
              />
            </SlideFade>

            <SlideFade delay={0.1} in={activeStep < 3} offsetY="20px">
              <Stack
                style={{
                  marginBottom: 16,
                }}
                alignItems={"start"}
              >
                <Text letterSpacing={-1} fontSize={"4xl"} fontWeight={"bold"}>
                  {activeStep === 1
                    ? "What would you like to name your workspace ?"
                    : "Let's build your fanbase"}
                </Text>
                <Text>
                  {activeStep === 1 ? (
                    "Let's start by setting up your workspace"
                  ) : (
                    <span>
                      Tell us more about your <strong>customers</strong>
                    </span>
                  )}
                </Text>
              </Stack>
            </SlideFade>
            <SlideFade
              style={{
                width: "100%",
              }}
              delay={0.2}
              in={activeStep < 3}
              offsetY="20px"
            >
              <Stepper
                width={"40%"}
                colorScheme={"primary"}
                index={activeStep}
                gap={1}
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepSeparator
                      style={{
                        height: 8,
                        borderRadius: 4,
                        marginInlineStart: index > 0 ? 4 : 0,
                      }}
                    />
                  </Step>
                ))}
              </Stepper>
            </SlideFade>
            {activeStep === 1 && (
              <Box w={"full"} mt={4}>
                <Stack gap={4}>
                <SlideFade
                    unmountOnExit={true}
                    delay={0.3}
                    in={activeStep === 1}
                    offsetY="20px"
                  >
                    <FormControl>
                      <FormLabel>Domain Name</FormLabel>
                      <Input
                        type="url"
                        value={domain}
                        onChange={(e) => {
                          setDomain(e.target.value)
                          handleDomainChange(e)
                        }
                        }
                        colorScheme="primary"
                        placeholder="https://abc.xyz"
                      />
                    </FormControl>
                  </SlideFade>
                  <SlideFade
                    unmountOnExit={true}
                    delay={0.3}
                    in={activeStep === 1}
                    offsetY="20px"
                  >
                    <FormControl>
                      <FormLabel>Workspace name</FormLabel>
                      <Input
                        value={data.name}
                        onChange={(e) => {
                          setData({ ...data, name: e.target.value });
                        }}
                        colorScheme="primary"
                        placeholder="Acme Inc."
                      />
                    </FormControl>
                  </SlideFade>
                  <SlideFade
                    delay={0.4}
                    unmountOnExit={true}
                    in={activeStep === 1}
                    offsetY="20px"
                  >
                    <FormControl isInvalid={data.url.length && error}>
                      <FormLabel>Workspace url</FormLabel>
                      <InputGroup>
                        <Input
                          value={data.url}
                          onChange={(e) => {
                            setData({ ...data, url: e.target.value });
                          }}
                          colorScheme="primary"
                          placeholder="acme"
                        />
                        <InputRightAddon children=".fanbaseai.com" />
                      </InputGroup>
                      <FormErrorMessage>Workspace URL already taken</FormErrorMessage>
                    </FormControl>
                  </SlideFade>
                </Stack>
              </Box>
            )}
            {activeStep === 2 && (
              <Box w={"full"} mt={4}>
                <Stack gap={8}>
                  <SlideFade
                    unmountOnExit={true}
                    delay={0.1}
                    in={activeStep === 2}
                    offsetY="20px"
                  >
                    <FormControl>
                      <FormLabel>Choose your customers</FormLabel>
                      <CreatableSelect
                        useBasicStyles
                        allowCreate={true}
                        focusBorderColor="primary.600"
                        closeMenuOnSelect={false}
                        components={{
                          Option: InputOption
                        }}
                        onChange={(e) => {
                          setData({
                            ...data,
                            customers: e,
                          });
                        }}
                        chakraStyles={{
                          control: (provided, state) => {
                            return {
                              ...provided,
                              borderRadius: 24,
                            };
                          },
                          option: (b) => {
                            return {
                              ...b,
                              backgroundColor: "transparent !important"
                            }
                          },
                          menuList: (provided, state) => {
                            return {
                              ...provided,
                              borderRadius: 10,
                              marginTop: 0,
                              padding: 0,
                            };
                          },
                        }}
                        isMulti={true}
                        hideSelectedOptions={false}
                        value={data.customers}
                        options={options}
                        isLoading={!options.length}
                      />
                    </FormControl>
                  </SlideFade>
                  <SlideFade
                    unmountOnExit={true}
                    delay={0.2}
                    in={activeStep === 2}
                    offsetY="20px"
                  >
                    <FormControl>
                      <FormLabel>Choose the seniority level</FormLabel>
                      <Select
                        colorScheme="primary"
                        useBasicStyles
                        value={data.seniority}
                        closeMenuOnSelect={false}
                        focusBorderColor="primary.600"
                        onChange={(e) => {
                          setData({
                            ...data,
                            seniority: e,
                          });
                        }}
                        tagVariant="solid"
                        chakraStyles={{
                          control: (provided, state) => {
                            return {
                              ...provided,
                              borderRadius: 24,
                            };
                          },
                          menuList: (provided, state) => {
                            return {
                              ...provided,
                              borderRadius: 10,
                              marginTop: 0,
                              padding: 0,
                            };
                          },
                          multiValue: (provided) => {
                            return {
                              ...provided,
                              borderRadius: 24,
                            };
                          },
                        }}
                        isMulti={true}
                        options={[
                          {
                              "displayValue": "Owner / Partner",
                              "id": "320"
                          },
                          {
                              "displayValue": "CXO",
                              "id": "310"
                          },
                          {
                              "displayValue": "Vice President",
                              "id": "300"
                          },
                          {
                              "displayValue": "Director",
                              "id": "220"
                          },
                          {
                              "displayValue": "Experienced Manager",
                              "id": "210"
                          },
                          {
                              "displayValue": "Entry Level Manager",
                              "id": "200"
                          },
                          {
                              "displayValue": "Strategic",
                              "id": "130"
                          },
                          {
                              "displayValue": "Senior",
                              "id": "120"
                          },
                          {
                              "displayValue": "Entry Level",
                              "id": "110"
                          },
                          {
                              "displayValue": "In Training",
                              "id": "100"
                          }
                      ].map(s => ({
                        "label": s.displayValue,
                        "value": s.id
                      }))}
                      />
                    </FormControl>
                  </SlideFade>
                  <SlideFade
                    unmountOnExit={true}
                    delay={0.3}
                    in={activeStep === 2}
                    offsetY="20px"
                  >
                    <FormControl>
                      <FormLabel>Who are your buyer personas ?</FormLabel>
                      <Select
                        tagVariant="solid"
                        useBasicStyles
                        value={data.personas}
                        closeMenuOnSelect={false}
                        focusBorderColor="primary.600"
                        chakraStyles={{
                          control: (provided, state) => {
                            return {
                              ...provided,
                              borderRadius: 24,
                            };
                          },
                          menuList: (provided, state) => {
                            return {
                              ...provided,
                              borderRadius: 10,
                              marginTop: 0,
                              padding: 0,
                            };
                          },
                          multiValue: (provided) => {
                            return {
                              ...provided,
                              borderRadius: 24,
                            };
                          },
                          option: (provided, state) => {
                            return {
                              ...provided,
                              // "&:hover": {
                              //   backgroundColor:
                              //     "var(--chakra-colors-primary-500)",
                              //   color: "white",
                              // },
                              // "&:focus": {
                              //   backgroundColor:
                              //     "var(--chakra-colors-primary-500)",
                              //   color: "white",
                              // },
                              // backgroundColor: "white",
                            };
                          },
                        }}
                        isMulti={true}
                        colorScheme="primary"
                        onChange={(e) => {
                          setData({
                            ...data,
                            personas: e,
                          });
                        }}
                        options={[{
                          "displayValue": "Accounting",
                          "id": "1"
                      },
                      {
                          "displayValue": "Administrative",
                          "id": "2"
                      },
                      {
                          "displayValue": "Arts and Design",
                          "id": "3"
                      },
                      {
                          "displayValue": "Business Development",
                          "id": "4"
                      },
                      {
                          "displayValue": "Community and Social Services",
                          "id": "5"
                      },
                      {
                          "displayValue": "Consulting",
                          "id": "6"
                      },
                      {
                          "displayValue": "Education",
                          "id": "7"
                      },
                      {
                          "displayValue": "Engineering",
                          "id": "8"
                      },
                      {
                          "displayValue": "Entrepreneurship",
                          "id": "9"
                      },
                      {
                          "displayValue": "Finance",
                          "id": "10"
                      },
                      {
                          "displayValue": "Healthcare Services",
                          "id": "11"
                      },
                      {
                          "displayValue": "Human Resources",
                          "id": "12"
                      },
                      {
                          "displayValue": "Information Technology",
                          "id": "13"
                      },
                      {
                          "displayValue": "Legal",
                          "id": "14"
                      },
                      {
                          "displayValue": "Marketing",
                          "id": "15"
                      },
                      {
                          "displayValue": "Media and Communication",
                          "id": "16"
                      },
                      {
                          "displayValue": "Military and Protective Services",
                          "id": "17"
                      },
                      {
                          "displayValue": "Operations",
                          "id": "18"
                      },
                      {
                          "displayValue": "Product Management",
                          "id": "19"
                      },
                      {
                          "displayValue": "Program and Project Management",
                          "id": "20"
                      },
                      {
                          "displayValue": "Purchasing",
                          "id": "21"
                      },
                      {
                          "displayValue": "Quality Assurance",
                          "id": "22"
                      },
                      {
                          "displayValue": "Real Estate",
                          "id": "23"
                      },
                      {
                          "displayValue": "Research",
                          "id": "24"
                      },
                      {
                          "displayValue": "Sales",
                          "id": "25"
                      },
                      {
                          "displayValue": "Customer Success and Support",
                          "id": "26"
                      }].map(v => ({ label: v.displayValue, value: v.id }))}
                      />
                    </FormControl>
                  </SlideFade>
                </Stack>
              </Box>
            )}
            <SlideFade
              style={{
                alignSelf: "end",
                marginTop: 16,
                width: "100%",
              }}
              delay={0.5}
              in={activeStep < 3}
              offsetY="20px"
            >
              <Button
                borderRadius={4}
                isLoading={isLeadsLoading}
                isDisabled={(() => {
                  if (activeStep === 1) {
                    return error || !data.url || !data.name
                  }
                  if (activeStep === 2) {
                    return !(data.customers.length && data.seniority.length && data.personas.length)
                  }
                })()}
                rightIcon={<ArrowForwardIcon />}
                onClick={() => {
                  if (activeStep === 2) {
                    getLeads();
                  }
                  setActiveStep(activeStep + 1);
                }}
                colorScheme="primary"
                alignSelf={"end"}
              >
                Continue
              </Button>
            </SlideFade>
          </Stack>
        </Stack>
      </Stack>
      <Box
        display={activeStep < 3 ? "block" : "none"}
        flex={activeStep < 3 ? 0.45 : 0}
      >
        <SlideFade delay={0.5} in={activeStep < 3} offsetY="20px">
          <Stack
            mt={10}
            backgroundImage={Frame}
            backgroundSize={"150%"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"left 60px"}
            h={"100vh"}
          >
            <Flex ml={4} mt={"128px"} gap={2}>
              <Avatar
                size={"lg"}
                p={2}
                rounded={22}
                border={"1px solid #cacaca"}
                src={`https://s2.googleusercontent.com/s2/favicons?domain=${domain}&sz=128`}
              />
              <Stack gap={0}>
                <Text
                  color={data.name.length ? "black" : "gray.400"}
                  textTransform={"capitalize"}
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                >
                  {data.name || "Acme, Inc"}
                </Text>
                <Text color={data.url.length ? "black" : "gray.400"}>
                  @
                  {slugify(data.url || "acme", {
                    remove: /[*+~.()'"!:@]/g,
                  })}
                </Text>
              </Stack>
            </Flex>
            {(activeStep === 1 ||
              !(
                data.customers.length ||
                data.personas.length ||
                data.seniority.length
              )) && (
              <Stack gap={8} mt={8} ml={6}>
                <Flex alignItems={"center"} gap={4}>
                  <Box boxSize={10} bg={"gray.200"} rounded={"full"} />
                  <Box w={0.6} borderRadius={4} bg={"gray.200"} height="8" />
                </Flex>
                <Flex alignItems={"center"} gap={4}>
                  <Box boxSize={10} bg={"gray.200"} rounded={"full"} />
                  <Box w={0.6} borderRadius={4} bg={"gray.200"} height="8" />
                </Flex>
                <Flex alignItems={"center"} gap={4}>
                  <Box boxSize={10} bg={"gray.200"} rounded={"full"} />
                  <Box w={0.6} borderRadius={4} bg={"gray.200"} height="8" />
                </Flex>
                <Flex alignItems={"center"} gap={4}>
                  <Box boxSize={10} bg={"gray.200"} rounded={"full"} />
                  <Box w={0.6} borderRadius={4} bg={"gray.200"} height="8" />
                </Flex>
              </Stack>
            )}
            {activeStep === 2 && (
              <Stack gap={8} mt={8} ml={6}>
                <SlideFade in={data.customers.length} offsetY="20px">
                  <Box>
                    <Text fontWeight={"semibold"}>
                      <i
                        style={{ marginRight: 10, marginTop: 1 }}
                        className="fi fi-rr-following"
                      ></i>
                      Customers
                    </Text>
                    <Flex flexWrap={"wrap"} mt={4} gap={3}>
                      {data.customers.map((d) => (
                        <Tag
                          size={"lg"}
                          rounded={"full"}
                          color={"black"}
                          variant={"outline"}
                        >
                          {d.label}
                        </Tag>
                      ))}
                    </Flex>
                  </Box>
                </SlideFade>
                <SlideFade in={data.seniority.length} offsetY="20px">
                  <Box>
                    <Text fontWeight={"semibold"}>
                      <i
                        style={{ marginRight: 10, marginTop: 1 }}
                        className="fi fi-rr-building"
                      ></i>
                      Seniority Level
                    </Text>
                    <Flex flexWrap={"wrap"} mt={4} gap={3}>
                      {data.seniority.map((d) => (
                        <Tag
                          size={"lg"}
                          rounded={"full"}
                          colorScheme="orange"
                          variant={"outline"}
                        >
                          {d.label}
                        </Tag>
                      ))}
                    </Flex>
                  </Box>
                </SlideFade>
                <SlideFade in={data.personas.length} offsetY="20px">
                  <Box>
                    <Text fontWeight={"semibold"}>
                      <i
                        style={{ marginRight: 10, marginTop: 1 }}
                        className="fi fi-rr-users"
                      ></i>
                      Persona
                    </Text>
                    <Flex flexWrap={"wrap"} mt={4} gap={3}>
                      {data.personas.map((d) => (
                        <Tag
                          size={"lg"}
                          rounded={"full"}
                          colorScheme="green"
                          variant={"outline"}
                        >
                          {d.label}
                        </Tag>
                      ))}
                    </Flex>
                  </Box>
                </SlideFade>
              </Stack>
            )}
          </Stack>
        </SlideFade>
      </Box>
    </Flex>
  );
}

export default Onboarding;
