import { SET_ERROR, SET_PLATFORM_STATUS, SET_WORKSPACES, SET_WORKSPACE, LOAD_PROFILE, SET_PROFILE, SET_PROFILE_ERROR, SET_OPTIONS_LOADING, SET_OPTIONS, SET_LISTS_LOADING, SET_LISTS } from "./constants"

export const setError = (error) => {
    return {
        type: SET_ERROR,
        payload: error
    }
}

export const setWorkspaces = (workspaces) => {
    return {
        type: SET_WORKSPACES,
        payload: workspaces
    }
}

export const setPlatformState = (state) => {
    return {
        type: SET_PLATFORM_STATUS,
        payload: state
    }
}

export const setWorkspace = (workspace) => {
    return {
        type: SET_WORKSPACE,
        payload: workspace
    }
}

export const setProfileLoading = () => {
    return {
        type: LOAD_PROFILE
    }
}

export const setProfile = (profile) => {
    return {
        type: SET_PROFILE,
        payload: profile
    }
}

export const setProfileError = (error) => {
    return {
        type: SET_PROFILE_ERROR,
        error
    }
}

export const setOptionsLoading = () => {
    return {
        type: SET_OPTIONS_LOADING
    }
}

export const setOptions = (payload) => {
    return {
        type: SET_OPTIONS,
        payload
    }
}

export const setListsLoading = () => {
    return {
        type: SET_LISTS_LOADING
    }
}

export const setLists = (payload) => {
    return {
        type: SET_LISTS,
        payload
    }
}