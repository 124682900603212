import { axios } from "../../../../loaders/axios.loader";
import { processPublicFan, setError, setList, setPublicFan, setPublicFanError, setWorkspace } from "./actions";

export const loadSharedWorkspaceList = (workspace, list) => async (dispatch, getState) => {
    try {
        const request = await axios.get(
            `/shared/${workspace}/lists/${list}/`
        )
        const response = request.data;
        dispatch(setList(response.response))
    } catch (error) {
        dispatch(setError(error?.response?.data?.message || error.message || 'Failed to process your request'))
    }
}

export const loadSharedWorkspace = (workspace) => async (dispatch, getState) => {
    try {
        const request = await axios.get(
            `/shared/${workspace}/`
        )
        const response = request.data;
        dispatch(setWorkspace(response.response))
    } catch (error) {
        dispatch(setError(error?.response?.data?.message || error.message || 'Failed to process your request'))
    }
}

export const loadPublicFan = (workspace, fan) => async (dispatch, getState) => {
    dispatch(processPublicFan())
    try {
        const request = await axios.get(
            `/shared/${workspace}/fans/${fan}/`
        )
        const { response } = request.data;
        dispatch(setPublicFan(response))
    } catch (error) {
        dispatch(setPublicFanError(error?.response?.data?.message || error.message || 'Failed to process your request'))
    }
}