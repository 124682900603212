export const LOAD_WORKSPACE_FANS = "LOAD_WORKSPACE_FANS";
export const SET_WORKSPACE_FANS = "SET_WORKSPACE_FANS";
export const SET_WORKSPACE_FANS_ERROR = "SET_WORKSPACE_FANS_ERROR";
export const LOAD_FAN = "LOAD_FAN";
export const SET_FAN = "SET_FAN";
export const RESET_FAN = "RESET_FAN";
export const SET_FAN_LOAD_ERROR = "SET_FAN_LOAD_ERROR";
export const SET_FAN_DISCUSSIONS = "SET_FAN_DISCUSSIONS";
export const SET_FAN_DISCUSSIONS_LOADING = "SET_FAN_DISCUSSIONS_LOADING";
export const SET_FAN_DISCUSSIONS_ERROR = "SET_FAN_DISCUSSIONS_ERROR";
export const SET_DISCUSSION_CREATE_STATUS = "SET_DISCUSSION_CREATE_STATUS";
export const SET_CREATE_FAN_LOADING = "SET_CREATE_FAN_LOADING";
export const SET_CREATE_FAN_ERROR = "SET_CREATE_FAN_ERROR";
export const SET_CREATE_FAN_SUCCESS = "SET_CREATE_FAN_SUCCESS";
export const SET_CREATE_FAN_STATUS = "SET_CREATE_FAN_STATUS";
export const SET_ACTION_LOADING = "SET_ACTION_LOADING";
export const SET_ACTION_SUCCESS = "SET_ACTION_SUCCESS";
export const SET_ACTION_ERROR = "SET_ACTION_ERROR";
export const SET_SEARCH_ACTION_LOADING = "SET_SEARCH_ACTION_LOADING";
export const SET_SEARCH_ACTION_SUCCESS = "SET_SEARCH_ACTION_SUCCESS";
export const SET_SEARCH_ACTION_ERROR = "SET_SEARCH_ACTION_ERROR";
export const RESET_ACTION = "RESET_ACTION";
export const SET_FAN_ATTRIBUTE = "SET_FAN_ATTRIBUTE";