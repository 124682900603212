import { axios } from "../../../loaders/axios.loader";
import { setWorkspaceAccounts, setWorkspaceAccountsLoading, setSpecificAccount,  setSpecificAccountLoading} from "./actions";

export const getWorkspaceAccounts = (workspace, page=1) => async (dispatch, getState) => {
    dispatch(setWorkspaceAccountsLoading())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/accounts?page=${page}&size=10`
        )
        const { response } = request.data;
        dispatch(setWorkspaceAccounts(response))
    } catch (err) {
        console.log(err)
    }
}

export const getSpecificWorkspaceAccount = (workspace, id) => async (dispatch, getState) => {

    dispatch(setSpecificAccountLoading())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/accounts/${id}/`
        )
        const { response } = request.data;
        dispatch(setSpecificAccount(response))

    } catch (err) {
        console.log(err)
    }
}