import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  SlideFade,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { loadProfile } from "../../redux/app/core/thunk";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { Switch } from "../../components/ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { FaGoogle, FaWindows } from "react-icons/fa";
import { generateHSL } from "../../lib/utils";
import { updateUserProfile, uploadUserImage } from "../../redux/app/user/thunk";
import { constructImageURL } from "../../utils/utils";
import { createWorkspace, loadWorkspaceInformation } from "../../redux/app/workspaces/thunk";
import { Textarea } from "../../components/ui/textarea";
import _ from "lodash";

const formSchema = z.object({
  name: z.string().trim().min(1, {
    message: "Name must be at least 2 characters.",
  }),
  email: z.string().trim().email({
    message: "Invalid email",
  }),
  role: z
    .string({
      required_error: "Role is required",
    })
    .trim()
    .min(1, {
      message: "Please select a role",
    }),
  marketing_emails: z.boolean().default(false),
});

const workspaceSchema = z.object({
  name: z.string().trim().min(1, {
    message: "Name must be at least 2 characters.",
  }),
  handle: z.string().trim({
    message: "Invalid handle",
  }),
  size: z.string({
    required_error: "Please select your company size"
  }).trim().min(1, {
    message: "Please select your company size",
  }),
  website: z.string(),
  description: z.string().trim().min(1, {
    message: "Please enter your product description",
  }),
});

export default function Welcome() {
  const profile = useSelector((state) => state.dashboard.core.profile);
  const update = useSelector((state) => state.common.profile.update);
  const create = useSelector((state) => state.common.workspace.create);
  const information = useSelector(
    (state) => state.common.workspace.information
  );
  const image = useSelector((state) => state.common.profile.image);
  const dispatch = useDispatch();
  const profileImageRef = useRef();
  const workspaceImageRef = useRef();
  const [currentTab, setCurrentTab] = useState(0);
  const [file, setFile] = useState();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: useMemo(() => {
      return {
        name: "",
        email: "",
        role: "",
        marketing_emails: false,
      };
    }, [profile]),
  });

  const workspace = useForm({
    resolver: zodResolver(workspaceSchema),
    defaultValues: useMemo(() => {
      return {
        name: "",
        handle: "",
        size: "",
        website: "",
        description: "",
      };
    }, [profile]),
  });

  useEffect(() => {
    if (information.data?.success) {
      const _handle = new URL(
        information.data.requestUrl || information.data?.request?.requestUrl
      ).host;
      workspace.reset({
        name: _.capitalize(_handle),
        image: information.data.favicon,
        website:
          information.data.requestUrl || information.data?.request?.requestUrl,
        description: information.data?.ogDescription,
        handle: _handle.replace(".", '-'),
      });
    }
  }, [information]);

  useEffect(() => {
    if (profileImageRef.current) {
      profileImageRef.current.addEventListener("change", (v) => {
        dispatch(uploadUserImage(v.target.files[0], () => {}));
      });
    }
  }, [profileImageRef]);

  useEffect(() => {
    if (currentTab === 1) {
      setTimeout(() => {
        if (workspaceImageRef.current) {
          workspaceImageRef.current.addEventListener("change", (v) => {
            setFile(v.target.files[0]);
          });
        }
      }, 1500)
    }
  }, [workspaceImageRef, currentTab]);

  const [selectedMode, setSelectedMode] = useState("1");

  useEffect(() => {
    if (!profile.uuid) {
      dispatch(loadProfile());
    } else {
      const website = (
        profile?.email ||
        profile?.accounts?.local?.email ||
        profile?.accounts?.google?.email
      )
        .split("@")
        .pop();
      dispatch(loadWorkspaceInformation(website));
      form.reset({
        name: `${profile.firstName} ${profile.lastName || ""}`.trim(),
        image: profile.image,
        email:
          profile.email ||
          profile.accounts?.local?.role ||
          profile.accounts?.google.email,
        role: profile.position,
        marketing_emails: profile.preferences?.marketing_emails || false,
      });
    }
  }, [profile]);

  return (
    <Flex>
      <Stack
        flex={0.5}
        borderRight={"1px solid #e4e4e4"}
        gap={0}
        py={8}
        justifyContent={"space-between"}
        h={"100vh"}
      >
        <Flex mx={8}>{/* <Img src={LogoSmall} h={5} /> */}</Flex>
        <Tabs isLazy flex={0.95} w={"full"} index={currentTab}>
          <TabPanels w={"full"}>
            <TabPanel px={8} w={"full"} rounded={"lg"}>
              <Stack mx={"auto"} w={"60%"} mt={2} flex={0.5}>
                <SlideFade delay={0.2} in={currentTab === 0}>
                  <Text className="text-gray-500 text-sm font-semibold">
                    {currentTab + 1}/5
                  </Text>
                </SlideFade>
                <SlideFade delay={0.3} in={currentTab === 0}>
                  <Box>
                    <Text className="text-3xl font-semibold">
                      Create your account
                    </Text>
                    <Text className="text-sm">
                      Let's create your profile in a minute
                    </Text>
                  </Box>
                </SlideFade>
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit((values) => {
                      const [firstName, lastName = ""] = values.name.split(" ");
                      dispatch(
                        updateUserProfile(
                          {
                            ...values,
                            firstName,
                            lastName,
                            preferences: {
                              ...(profile?.preferences || {}),
                              marketing_emails: values.marketing_emails,
                            },
                          },
                          () => {
                            setCurrentTab(1);
                          }
                        )
                      );
                    })}
                    className="space-y-2"
                  >
                    <SlideFade delay={0.4} in={currentTab === 0}>
                      <Box className="mt-4 mb-6">
                        <FormLabel className={"text-xs"}>
                          Profile Picture
                        </FormLabel>
                        <Flex alignItems={"center"} gap={3}>
                          <Avatar className={"w-14 mt-1 rounded-full"}>
                            <AvatarImage
                              className={"object-cover"}
                              src={
                                profile?.image
                                  ? constructImageURL(profile?.image)
                                  : undefined
                              }
                            />
                            <AvatarFallback
                              style={{
                                backgroundColor: `${generateHSL(
                                  `${profile?.firstName} ${
                                    profile?.lastName || ""
                                  }`.trim() || ""
                                )}`,
                              }}
                              className={"w-14 h-14"}
                            >
                              {`${profile?.firstName} ${
                                profile?.lastName || ""
                              }`
                                .trim()[0]
                                .toUpperCase()}
                            </AvatarFallback>
                          </Avatar>
                          <Stack>
                            <input type="file" hidden ref={profileImageRef} />
                            <Button
                              type={"button"}
                              size={"sm"}
                              isLoading={image.state === "loading"}
                              onClick={() => {
                                if (profileImageRef.current) {
                                  profileImageRef.current.click();
                                }
                              }}
                              variant={"outline"}
                              className={"text-sm h-8 w-fit"}
                            >
                              Upload Image
                            </Button>
                            <Text className="text-xs text-gray-500">
                              .png, .jpeg files upto 5 mb at least 256x256px.
                            </Text>
                          </Stack>
                        </Flex>
                      </Box>
                    </SlideFade>
                    <SlideFade delay={0.5} in={currentTab === 0}>
                      <FormField
                        name="name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel
                              className={"text-xs text-muted-foreground"}
                            >
                              Enter your Name
                            </FormLabel>
                            <FormControl>
                              <Input
                                className={"rounded-lg"}
                                placeholder="Enter your name"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </SlideFade>
                    <SlideFade delay={0.6} in={currentTab === 0}>
                      <FormField
                        control={form.control}
                        name="role"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel
                              className={"text-xs text-muted-foreground"}
                            >
                              What is your role ?
                            </FormLabel>
                            <Select
                              value={field.value}
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <SelectTrigger className={"rounded-lg"}>
                                <SelectValue placeholder="Choose your role" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="CXO">CXO</SelectItem>
                                <SelectItem value="VP">VP</SelectItem>
                                <SelectItem value="Director">
                                  Director
                                </SelectItem>
                                <SelectItem value="Manager">Manager</SelectItem>
                                <SelectItem value="AE">
                                  Account Executive
                                </SelectItem>
                                <SelectItem value="SDR-BDR">
                                  SDR / BDR
                                </SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </SlideFade>
                    <SlideFade delay={0.7} in={currentTab === 0}>
                      <FormField
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel
                              className={"text-xs text-muted-foreground"}
                            >
                              Email Address
                            </FormLabel>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <FormControl>
                                    <Input
                                      className={"rounded-lg bg-gray-100"}
                                      placeholder="Enter your email"
                                      disabled
                                      {...field}
                                    />
                                  </FormControl>
                                </TooltipTrigger>
                                <TooltipContent
                                  className="text-xs"
                                  side="top"
                                  variant="dark"
                                >
                                  Email address cannot be changed
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>

                            {/* <FormDescription className={'text-xs'}>
                                                This is your public display name.
                                            </FormDescription> */}
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </SlideFade>
                    {/* <Separator style={{ marginTop: 16, marginBottom: 16 }} /> */}
                    <SlideFade delay={0.8} in={currentTab === 0}>
                      <FormField
                        control={form.control}
                        name="marketing_emails"
                        render={({ field }) => (
                          <FormItem
                            style={{ marginTop: 14 }}
                            className="flex flex-row space-x-2 items-center justify-between rounded-lg border p-3 shadow-sm"
                          >
                            <div className="space-y-0.5">
                              <FormLabel className={"text-sm font-semibold"}>
                                Subscribe to our newsletter
                              </FormLabel>
                              <FormDescription className={"text-xs"}>
                                Receive emails about new releases, playbooks,
                                and more.
                              </FormDescription>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </SlideFade>
                    <SlideFade delay={0.9} in={currentTab === 0}>
                      <Button
                        isLoading={update.state === "loading"}
                        size="sm"
                        style={{ marginTop: 16 }}
                        className={"w-full my-4 rounded-lg font-semibold"}
                        type="submit"
                      >
                        Continue
                      </Button>
                    </SlideFade>
                  </form>
                </Form>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack mx={"auto"} w={"60%"} mt={2} flex={0.5}>
                <SlideFade delay={0.2} in={currentTab === 1}>
                  <Text className="text-gray-500 text-sm font-semibold">
                    {currentTab + 1}/5
                  </Text>
                </SlideFade>
                <SlideFade delay={0.3} in={currentTab === 1}>
                  <Box>
                    <Text className="text-3xl font-semibold">
                      Setup your workspace
                    </Text>
                    <Text className="text-sm">
                      Tell us more about your workspace so we can provide you a
                      personalized experience
                    </Text>
                  </Box>
                </SlideFade>
                <Form {...workspace}>
                  <form className="space-y-2" onSubmit={workspace.handleSubmit((values) => {
                    dispatch(createWorkspace({
                      ...values,
                      file
                    }, () => {
                      setCurrentTab(1)
                    }))
                  })}>
                    <SlideFade delay={0.4} in={currentTab === 1}>
                      <Box className="mt-4 mb-6">
                        <FormLabel className={"text-xs"}>
                          Workspace Logo 
                        </FormLabel>
                        <Flex alignItems={"center"} gap={3}>
                          <Avatar className={"w-14 h-14 mt-1 rounded-lg border p-2"}>
                            <AvatarImage
                              className={"object-contain"}
                              src={file ? URL.createObjectURL(file) : workspace.getValues().image}
                            />
                            <AvatarFallback
                              style={{
                                backgroundColor: `${generateHSL(
                                  (workspace.getValues().name?.trim()?.[0] || '')
                                )}`,
                              }}
                              className={"w-14 h-14"}
                            >
                              {(workspace.getValues().name?.trim()?.[0] || '').toUpperCase()}
                            </AvatarFallback>
                          </Avatar>
                          <Stack>
                            <input ref={workspaceImageRef} hidden type="file" />
                            <Button
                              onClick={() => {
                                if (workspaceImageRef.current) {
                                  workspaceImageRef.current.click();
                                }
                              }}
                              type={"button"}
                              size={"sm"}
                              variant={"outline"}
                              className={"text-sm h-8 w-fit"}
                            >
                              Upload Image
                            </Button>
                            <Text className="text-xs text-gray-500">
                              .png, .jpeg files upto 5 mb at least 256x256px.
                            </Text>
                          </Stack>
                        </Flex>
                      </Box>
                    </SlideFade>
                    <SlideFade delay={0.5} in={currentTab === 1}>
                      <FormField
                        name="name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel
                              className={"text-xs text-muted-foreground"}
                            >
                              Workspace Name
                            </FormLabel>
                            <FormControl>
                              <Input
                                className={"rounded-lg"}
                                placeholder="Enter your workspace name"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </SlideFade>
                    <SlideFade delay={0.6} in={currentTab === 1}>
                      <FormField
                        name="handle"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel
                              className={"text-xs text-muted-foreground"}
                            >
                              Workspace handle
                            </FormLabel>
                            <FormControl
                              className={
                                "rounded-lg border focus-visible:ring-2 focus-visible:ring-offset-2"
                              }
                            >
                              <Flex
                                alignItems={"center"}
                                className="pl-3 focus-visible:ring-2 focus-visible:ring-offset-2"
                              >
                                <Text className="text-sm">
                                  app.fanbaseai.com/
                                </Text>
                                <Input
                                  className={
                                    "border-0 rounded-lg focus-visible:ring-0 focus-visible:ring-offset-0 pl-0"
                                  }
                                  placeholder="acme-inc"
                                  {...field}
                                />
                                {/* <Box h={10} px={4} background={"gray.200"} className="text-sm">
                                <Text mt={2.5}>.fanbaseai.com</Text>
                            </Box> */}
                              </Flex>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </SlideFade>
                    <SlideFade delay={0.7} in={currentTab === 1}>
                      <FormField
                        control={workspace.control}
                        name="size"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel
                              className={"text-xs text-muted-foreground"}
                            >
                              Company Size
                            </FormLabel>
                            <Select
                              value={field.value}
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <SelectTrigger className={"rounded-lg text-sm"}>
                                <SelectValue placeholder="Choose your company size" />
                              </SelectTrigger>
                              <SelectContent className={"text-sm"}>
                                <SelectItem value="0-1">Just Me</SelectItem>
                                <SelectItem value="1-10">1-10</SelectItem>
                                <SelectItem value="10-100">10-100</SelectItem>
                                <SelectItem value="100-500">100-500</SelectItem>
                                <SelectItem value="500+">500+</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </SlideFade>
                    <SlideFade delay={0.8} in={currentTab === 1}>
                      <FormField
                        name="description"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel
                              className={"text-xs text-muted-foreground"}
                            >
                              About
                            </FormLabel>
                            <FormControl>
                              <Textarea
                                placeholder="Tell us a little bit about your company"
                                className="resize-none"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </SlideFade>
                    <SlideFade delay={0.9} in={currentTab === 1}>
                      <Button
                        size="sm"
                        isLoading={create.state === "loading"}
                        style={{ marginTop: 16 }}
                        className={"w-full my-4 rounded-lg font-semibold"}
                        type="submit"
                      >
                        Continue
                      </Button>
                    </SlideFade>
                  </form>
                </Form>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack mx={"auto"} w={"60%"} mt={2} flex={0.5}>
                <SlideFade delay={0.2} in={currentTab === 2}>
                  <Text className="text-gray-500 text-sm font-semibold">
                    {currentTab + 1}/5
                  </Text>
                </SlideFade>
                <SlideFade delay={0.3} in={currentTab === 2}>
                  <Box>
                    <Text className="text-3xl font-semibold">
                      Sync your calendar
                    </Text>
                    <Text className="text-sm">
                      By connecting your calendar, your fanbase will be
                      automatically calculated based on your interactions
                    </Text>
                  </Box>
                </SlideFade>
                <SlideFade delay={0.4} in={currentTab === 2}>
                  <Stack gap={1} className="text-sm">
                    <li>Fans will be constantly synced</li>
                    <li>All fans will be enriched with numerous data points</li>
                    <li>New fans will automatically be added</li>
                  </Stack>
                </SlideFade>
                <RadioGroup
                  onChange={(v) => {
                    setSelectedMode(v);
                  }}
                  my={4}
                  value={selectedMode}
                  defaultValue={selectedMode}
                >
                  <Stack gap={3} spacing={5}>
                    <SlideFade delay={0.5} in={currentTab === 2}>
                      <Flex
                        onClick={() => {
                          setSelectedMode("1");
                        }}
                        p={3}
                        justifyContent={"space-between"}
                        className="border-2 rounded-lg border-blue-500"
                        w={"full"}
                      >
                        <Box>
                          <Text className="font-semibold text-sm">
                            Subject and Metadata
                          </Text>
                          <Text className="text-xs text-gray-500">
                            Subjects and timestamps will be visible to your
                            workspace members. Participants won't be visible
                            unless shared
                          </Text>
                        </Box>
                        <Radio colorScheme="blue" value={"1"} />
                      </Flex>
                    </SlideFade>
                    <SlideFade delay={0.6} in={currentTab === 2}>
                      <Flex
                        onClick={() => {
                          setSelectedMode("2");
                        }}
                        p={3}
                        justifyContent={"space-between"}
                        className="border rounded-lg"
                        w={"full"}
                      >
                        <Box>
                          <Text className="font-semibold text-sm">
                            Full access
                          </Text>
                          <Text className="text-xs text-gray-500">
                            Everything is shared with your workspace members
                            including the participants
                          </Text>
                        </Box>
                        <Radio colorScheme="blue" value={"2"} />
                      </Flex>
                    </SlideFade>
                  </Stack>
                </RadioGroup>
                <Stack>
                  <SlideFade delay={0.7} in={currentTab === 2}>
                    <Button className={"gap-x-2 rounded-lg w-full"} size="sm">
                      <FaGoogle /> Connect Google account
                    </Button>
                  </SlideFade>
                  <SlideFade delay={0.8} in={currentTab === 2}>
                    <Button className={"gap-x-2 rounded-lg w-full"} size="sm">
                      <FaWindows /> Connect Microsoft account
                    </Button>
                  </SlideFade>
                  <SlideFade delay={0.9} in={currentTab === 2}>
                    <Button
                      className={"mt-6 rounded-lg bg-gray-100 w-full"}
                      variant={"secondary"}
                    >
                      I'll do this later
                    </Button>
                  </SlideFade>
                </Stack>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
      <Stack flex={0.5} h={"100vh"} backgroundColor={"#f4f4f4"}></Stack>
    </Flex>
  );
}
