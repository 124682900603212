import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority';

import { ny } from '../../lib/utils'
import { Loader2Icon } from 'lucide-react';

const buttonVariants = cva(
   'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
   {
      variants: {
         variant: {
            default: 'bg-primary text-primary-foreground hover:bg-primary/90',
            destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
            outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
            secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
            ghost: 'hover:bg-accent hover:text-accent-foreground',
            link: 'text-primary underline-offset-4 hover:underline',
         },
         size: {
            default: 'h-10 px-4 py-2',
            sm: 'h-9 rounded-md px-3',
            lg: 'h-11 rounded-md px-8',
            icon: 'h-10 w-10',
         },
      },
      defaultVariants: {
         variant: 'default',
         size: 'default',
      },
   }
)

const Button = React.forwardRef(({ className, variant, isLoading = false, size, asChild = false, ...props }, ref) => {
   const Comp = asChild ? Slot : 'button'
   return (
      (<Comp
         disabled={isLoading}
         className={ny(buttonVariants({ variant, size, className }), "h-8")}
         ref={ref}
         {...props}
         children={(
            <>
            {isLoading ? <Loader2Icon size={16} className="mr-2 size-4 animate-spin" /> : <></>}
            {props.children}
            </>
         )} />)
   );
})
Button.displayName = 'Button'

export { Button, buttonVariants }
