import LogoHorizontal from '../../../assets/img/logo-small.svg';
import LogoThin from '../../../assets/img/logo-solid-thin.svg';
import Icon from '../../../assets/img/icon.svg';
import { Image } from '@chakra-ui/react';


/**
 * 
 * @param {{type: "icon" | "image", size: "small" | "medium" | "large", style: import('react').CSSProperties}} param0 
 * @returns 
 */
function Logo({ type, size, style }) {
    return (
        <Image boxSize={typeof(size) !== "object" ? [10, 10, 6] : size} alt='fanbaseai' src={type === "icon" ? Icon : LogoHorizontal} />
    )
}

export default Logo

export const LogoSmall = LogoThin