import { IS_CONNECTED_ACCOUNTS_LOADING, RESET_CONNECTED_ACCOUNT, SET_CONNCTED_ACCOUNT, SET_CONNECTED_ACCOUNT_ERROR } from "./constants"

export const setConnectedAccountsLoading = () => {
    return {
        type: IS_CONNECTED_ACCOUNTS_LOADING
    }
}

export const setConnectedAccount = (payload) => {
    return {
        type: SET_CONNCTED_ACCOUNT,
        payload
    }
}

export const setConnectedAccountError = (error) => {
    return {
        type: SET_CONNECTED_ACCOUNT_ERROR,
        error
    }
}

export const resetConnectedAccount = () => {
    return {
        type: RESET_CONNECTED_ACCOUNT
    }
}