import { Badge, Button, Checkbox, Flex, Input, InputGroup, InputLeftElement, Spinner, Table as CTable, TableContainer, Tbody, Td, Text, Tfoot, Th as ThBase, Thead, Tr, Skeleton, Box, Heading, Stack, ButtonGroup, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, FormControl, FormLabel, FormErrorMessage, useToast } from "@chakra-ui/react";
import './_table.scss';
import { debounce } from "lodash";
import { AddIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Field, Formik } from "formik";
import { createFan } from "../../../redux/app/fans/thunk";
import { setCreateFanStatus } from "../../../redux/app/fans/actions";
import { useNavigate } from "react-router-dom";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import mixpanelEvents from "../../../utils/events";

export function Th(props) {
    return (
        <ThBase {...props}>{props.children}</ThBase>
    );

}

export default function Table ({ headers = [], isDownloading = false, disablePagination, sortTarget, disableActions, sortFunction, children, searchFunc, isLoading, filterFunc, filterCount, nextFunc, previousFunc, page, total, tableStyle, chatOpen, onDownload }) {
    const workspace = useSelector((state) => state.dashboard.core.workspace);
    const create = useSelector((state) => state.dashboard.fans.create)
    const formRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()
    const toast = useToast({ duration: 3000 })
    const navigate = useNavigate()
    const [date, setDate] = useState(undefined);

    const handleSearch = debounce((e) => {
        if (searchFunc) {
            searchFunc(e)
        }
    }, 300)

    useEffect(() => {
        if (create.status === "success") {
            toast({
                status: "success",
                title: "Fan created successfully"
            })
            onClose()
            navigate(`/home/fans/${create.data.uuid}`)
            dispatch(setCreateFanStatus("pending"))
        } else if (create.status === "error") {
            toast({
                status: "error",
                title: "Fan creation failed"
            })
        }
    }, [create])

    
    return (
        <Box style={tableStyle} className="fui-table">
            <Modal size={"xl"} isCentered onClose={onClose} isOpen={isOpen}>
                <ModalOverlay></ModalOverlay>
                <Formik
                    initialValues={{
                        url: "",
                        fanSince: undefined
                    }}
                    onSubmit={(values) => {
                        dispatch(
                            createFan(workspace?.uuid, values.url, date)
                        )
                    }}
                >
                    {({ handleSubmit, errors, touched }) => (
                    <form
                    ref={formRef}
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                    }}
                    >
                <ModalContent>
                    <ModalHeader>Add fan</ModalHeader>
                    <ModalBody>
                    <Stack gap={3}>
                    <FormControl
                      size={"sm"}
                      isInvalid={!!errors.url && touched.url}
                    >
                      <FormLabel fontSize={"sm"} htmlFor="url">
                        Linkedin URL
                      </FormLabel>
                      <Field
                        size={"sm"}
                        as={Input}
                        id="url"
                        name="url"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          if (!value.length || !value?.startsWith("https://") || !value.includes("linkedin.com")) {
                            error = "Enter valid URL";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage>{errors.url}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      size={"sm"}
                    >
                      <FormLabel fontSize={"sm"} htmlFor="fanSince">
                        When did the user become your fan ?
                      </FormLabel>
                      <Field
                        size={"sm"}
                        render={() => 
                            <SingleDatepicker
                                configs={{
                                    dateFormat: 'MM-dd-yyyy'
                                }}
                                name="fanSince"
                                date={date}
                                onDateChange={setDate}
                            />}
                        id="fanSince"
                        name="fanSince"
                        variant="filled"
                      />
                      <FormErrorMessage>{errors.fanSince}</FormErrorMessage>
                    </FormControl>
                    </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant={"outline"} mr={3} size={"sm"} onClick={onClose}>
                            Close
                        </Button>
                        <Button isLoading={create.status === "loading"} type="submit" colorScheme='primary' size={"sm"} variant='solid'>Create</Button>
                    </ModalFooter>
                </ModalContent>
                </form> )}
                </Formik>
            </Modal>
             <Box py={disableActions ? 0 : 2} >
            {!disableActions && <Flex justifyContent={"space-between"}>
                            <Flex direction={["column", "row", "row"]} alignItems={["flex-start", "flex-start", "center"]} gap={3}>
                            <InputGroup size={"sm"} borderRadius={4}>
                                <InputLeftElement pointerEvents='none'>
                                    <i className="fi fi-rr-search"></i>
                                </InputLeftElement>
                                <Input onChange={handleSearch} borderRadius={4} type='text' placeholder='Search fans' />
                            </InputGroup>
                            {filterFunc !== undefined && <Button size={"sm"} borderRadius={4} colorScheme="primary" onClick={filterFunc} variant={"ghost"} leftIcon={<i className="fi fi-rr-filter"></i>}>
                                Filters {filterCount > 0 && <Badge pos={"absolute"} textAlign={"center"} color={"white"} verticalAlign={"middle"} top={-2} right={-3} boxSize={5} pt={0.5} rounded={"full"} bg='red.600'>{filterCount}</Badge>}
                            </Button> }
                            {onDownload ? <Button isLoading={isDownloading} ml={4} px={8} isDisabled={filterCount === 0} size={"sm"} borderRadius={4} colorScheme="green" onClick={onDownload} variant={"ghost"} leftIcon={<i className="fi fi-rr-cloud-download"></i>}>
                                Download
                            </Button> : <></>}
                            </Flex>
                        <Flex direction={["column", "row", "row"]} alignItems={["flex-start", "flex-start", "center"]} gap={3}>
                        <Button rounded={"full"} onClick={() => {
                            onOpen?.()
                            mixpanelEvents.trackAddFanButtonClick();
                        }} size={"sm"} leftIcon={<i class="fi fi-rr-plus"></i>} variant={"outline"} colorScheme="primary">Add Fan</Button>
                        </Flex>
                    </Flex> }
            </Box>
                    <Box mt={3} className="fui-table-container">
                    <TableContainer style={{
                            fontSize: 14
                        }}>
                            <CTable variant='simple'>
                                <Thead>
                                    <Tr>
                                    {headers.map(h => <Th onClick={sortFunction ? () => {
                                        sortFunction(h)
                                    } : null}>{h?.name || h} {sortTarget && (sortTarget?.name === h?.sortName) ? <>{sortTarget?.order === 1 ? <i class="fi fi-sr-caret-up"></i> : sortTarget.order === -1 ? <i class="fi fi-sr-caret-down"></i> : <></>}</> : <></>}</Th>)}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {isLoading ? 
                                    <Tr>
                                        {headers.map((h, i) => {
                                            return <Td><Skeleton startColor='gray.100' endColor='gray.200' height='24px' /></Td>
                                        })}
                                    </Tr> : children}
                                </Tbody>
                            {!disablePagination && <Tfoot>
                                    <Td colSpan={"6"}>
                                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                                            <Button onClick={previousFunc} variant={"outline"} size={"sm"}>Previous</Button>
                                            <Text size={"sm"}>showing {total === "NaN" ? "-" : (page * 10 < total ? page * 10 : total)} of {total === "NaN" ? '-' : total}</Text>
                                            <Button onClick={nextFunc} variant={"outline"} size={"sm"}>Next</Button>
                                        </Flex>
                                    </Td>
                                </Tfoot> }
                            </CTable>
                        </TableContainer>
                    </Box>
                </Box>
   
    )
}

