import { LIKE_CHAT, LOAD_SESSION, REPORT_CHAT, RESET_CURRENT_MESSAGE, RESET_MESSAGES, SET_CHAT_ERROR, SET_CHAT_SESSIONS, SET_CURRENT_MESSAGE, SET_MESSAGES, SET_SESSION, UNLIKE_CHAT } from "./constants";
import _ from 'lodash';

/**
 * your reducers will go here
 */
const INITIAL_STATE = {
    isLoading: false,
    messages: [
        { content:'Hi 👋 , how can I help you ?', role:'ai', data: []}
    ],
    currentMessage: '',
    timer: null,
    session: {},
    currentIndex: 0,
    isSessionLoading: true,
    history: [],
    error: ''
}

const ChatReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_MESSAGE":
            return {
                ...state,
                currentMessage: '',
                messages:[...state.messages, action.message]
            };
        case "LOAD_MESSAGE":
            return {
                ...state,
                isLoading: action.state,
            }
        case SET_CURRENT_MESSAGE:
            return {
                ...state,
                currentMessage: state.currentMessage + action.payload?.slice(1, -1)
            }
        case RESET_CURRENT_MESSAGE:
            if (state.timer) {
                clearTimeout(state.timer)
            }
            return {
                ...state,
                timer: null,
                currentIndex: 0,
                currentMessage: ''
            }
        case SET_SESSION:
            return {
                ...state,
                session: action.payload
            }
        case LOAD_SESSION:
            return {
                ...state,
                isSessionLoading: true
            }
        case SET_CHAT_SESSIONS:
            return {
                ...state,
                isSessionLoading: false,
                history: action.payload
            }
        case LIKE_CHAT:
            const me = _.cloneDeep(state.messages).map((m, i) => {
                const index = action.payload;
                if (m.uuid === index || i === index) {
                    m.isLiked = true
                }
                return m
            })
            return {
                ...state,
                messages: me
            }
        case UNLIKE_CHAT:
            const m = _.cloneDeep(state.messages).map((m, i) => {
                const index = action.payload;
                if (m.uuid === index || i === index) {
                    m.isLiked = false
                }
                return m
            })
            return {
                ...state,
                messages: m
            }
        case REPORT_CHAT:
            const rm = _.cloneDeep(state.messages).map((m, i) => {
                const { index, reason } = action.payload;
                if (m.uuid === index || i === index) {
                    m.isReported = true
                    m.reason = reason
                }
                return m
            })
            return {
                ...state,
                messages: rm
            }
        case SET_CHAT_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case RESET_MESSAGES:
            return {
                ...state,
                error: '',
                messages: [
                    { content:'Hi 👋 , how can I help you ?', role:'ai', data: []}
                ],
                session: {},
                isLoading: false
            }
        case SET_MESSAGES:
            return {
                ...state,
                messages: action.payload,
                isSessionLoading: false
            }
        default:
            return state;
    }
}

export default ChatReducer