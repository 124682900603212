/**
 * your thunk functions go here
 */

import { axios } from "../../../loaders/axios.loader";
import { loadActions, loadApps, loadProperties, loadPropertiesError, loadSpecificApp, loadUpdateMapping, loadUpdateMappingError, loadWorkspaceConnections, setActions, setApps, setProperties, setSpecificApp, setUpdateMapping, setWorkspaceConnections } from "./actions";

export const getApps = (workspace) => async (dispatch) => {
    try {
        dispatch(loadApps())
        const request = await axios.get(`/workspaces/${workspace}/apps?page=1&size=10`);
        const { response } = request.data
        dispatch(setApps(response))
    } catch (err) {
        console.log(err)
    }
}

export const getWorkspaceConnections = (workspace) => async (dispatch) => {
    try {
        dispatch(loadWorkspaceConnections())
        const request = await axios.get(`/workspaces/${workspace}/apps/connections?page=1&size=10`);
        const { response } = request.data
        dispatch(setWorkspaceConnections(response))
    } catch (err) {
        console.log(err)
    }
}

export const getWorkspaceApp = (workspace, connection) => async (dispatch) => {
    try {
        dispatch(loadSpecificApp())
        const request = await axios.get(`/workspaces/${workspace}/apps/connections/${connection}`);
        const { response } = request.data
        dispatch(setSpecificApp(response))
    } catch (err) {
        console.log(err)
    }
}

export const getWorkspaceAppProperties = (workspace, connection) => async (dispatch) => {
    try {
        dispatch(loadProperties())
        const request = await axios.get(`/workspaces/${workspace}/apps/connections/${connection}/hubspot/properties`);
        const { response } = request.data
        dispatch(setProperties(response?.results || []))
    } catch (err) {
        dispatch(loadPropertiesError("Something went wrong"))
        console.log(err)
    }
}

export const updateWorkspaceAppMapping = (workspace, connection, action, mapping) => async (dispatch) => {
    try {
        dispatch(loadUpdateMapping())
        const request = await axios.patch(`/workspaces/${workspace}/apps/connections/${connection}/configure`, {
            action,
            mapping
        });
        const { response } = request.data
        dispatch(setUpdateMapping(response))
    } catch (err) {
        dispatch(loadUpdateMappingError("Something went wrong"))
        console.log(err)
    }
}

export const getWorkspaceActions = () => async (dispatch) => {
    try {
        dispatch(loadActions())
        const request = await axios.get(`/actions`)
        const { response } = request.data
        dispatch(setActions(response))
    } catch (err) {
        console.log(err)
    }
}