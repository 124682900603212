import Axios from "axios";
import Cookies from 'universal-cookie';
import { API_URL } from "./environment.loader";
const cookies = new Cookies();

const token = cookies.get("accessToken")
const axios = Axios.create({
    baseURL: `${API_URL}`,
    headers: {
        "Content-Type": "application/json",
        ...(token ? {
            "Authorization": `Bearer ${token}`
        } : {})
    },
    responseType: 'json'
})

export {
    axios
}