import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { Controller, FormProvider, useFormContext, useFormState } from 'react-hook-form'

import { ny } from '../../lib/utils'
import { Label } from './label'

const Form = FormProvider

const FormFieldContext = React.createContext({})

function FormField(
   {
      ...props
   }
) {
   return (
      (<FormFieldContext.Provider value={{ name: props.name }}>
         <Controller {...props} />
      </FormFieldContext.Provider>)
   );
}

function useFormField() {
   const fieldContext = React.useContext(FormFieldContext)
   const itemContext = React.useContext(FormItemContext)
   const { getFieldState, formState } = useFormContext()

   const fieldState = getFieldState(fieldContext.name, formState)

   if (!fieldContext)
      throw new Error('useFormField should be used within <FormField>')

   const { id } = itemContext

   return {
      id,
      name: fieldContext.name,
      formItemId: `${id}-form-item`,
      formDescriptionId: `${id}-form-item-description`,
      formMessageId: `${id}-form-item-message`,
      ...fieldState,
   }
}

const FormItemContext = React.createContext({})

const FormItem = React.forwardRef(({ className, ...props }, ref) => {
   const id = React.useId()

   return (
      (<FormItemContext.Provider value={{ id }}>
         <div ref={ref} className={ny('space-y-1', className)} {...props} />
      </FormItemContext.Provider>)
   );
})
FormItem.displayName = 'FormItem'

const FormLabel = React.forwardRef(({ className, ...props }, ref) => {
   const { error, formItemId } = useFormField()

   return (
      (<Label
         ref={ref}
         className={ny(error && 'text-destructive', className)}
         htmlFor={formItemId}
         {...props} />)
   );
})
FormLabel.displayName = 'FormLabel'

const FormControl = React.forwardRef(({ ...props }, ref) => {
   const { error, formItemId, formDescriptionId, formMessageId }
    = useFormField()

   return (
      (<Slot
         ref={ref}
         id={formItemId}
         aria-describedby={
            !error
               ? `${formDescriptionId}`
               : `${formDescriptionId} ${formMessageId}`
         }
         aria-invalid={!!error}
         {...props} />)
   );
})
FormControl.displayName = 'FormControl'

const FormDescription = React.forwardRef(({ className, ...props }, ref) => {
   const { formDescriptionId } = useFormField()

   return (
      (<p
         ref={ref}
         id={formDescriptionId}
         className={ny('text-muted-foreground text-sm', className)}
         {...props} />)
   );
})
FormDescription.displayName = 'FormDescription'

const FormMessage = React.forwardRef(({ className, children, ...props }, ref) => {
   const { error, formMessageId } = useFormField()
   const body = error ? String(error?.message) : children

   if (!body)
      return null

   return (
      (<p
         ref={ref}
         id={formMessageId}
         className={ny('text-destructive text-sm font-medium', className)}
         {...props}>
         {body}
      </p>)
   );
})
FormMessage.displayName = 'FormMessage'

const FormGlobalError = React.forwardRef(({ className, ...props }, ref) => {
   const { errors } = useFormState()
   const rootError = errors.root
   if (!rootError)
      return null

   return (
      (<p
         ref={ref}
         className={ny('text-destructive text-sm font-medium', className)}
         {...props}>
         {rootError.message}
      </p>)
   );
})
FormGlobalError.displayName = 'FormGlobalError'

export {
   useFormField,
   Form,
   FormItem,
   FormLabel,
   FormControl,
   FormDescription,
   FormMessage,
   FormGlobalError,
   FormField,
}
