import { Avatar, Box, Button, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputRightAddon, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadProfile, loadWorkspaces } from "../../redux/app/core/thunk";
import { useGoogleLogin } from "@react-oauth/google";
import { createConnectedAccount, reconnectConnectedAccount } from "../../redux/app/onboarding/thunk";
import { useSearchParams } from "react-router-dom";

export function LittleGuy () {
    const { rive, RiveComponent } = useRive({
        src: "/lil_guy.riv",
        autoplay: true,
        animations: ["idle", "blink"],
        layout: new Layout({
            fit: Fit.Fill,
            alignment: Alignment.Center
        }),
    });

    return (
        <RiveComponent />
    )
}

export function OnboardingV2 () {
    const profile = useSelector(state => state.dashboard.core.profile);
    const workspaces = useSelector(state => state.dashboard.core.workspaces);
    const connectedAccount = useSelector(state => state.common.onboarding.connectedAccount);
    const dispatch = useDispatch();
    const [selectedWorkspace, setSelectedWorkspace] = useState(null);
    const [shouldCreateNewWorkspace, setShouldCreateNewWorkspace] = useState(false);
    const [params] = useSearchParams()
    const { rive, RiveComponent } = useRive({
        src: "/lil_guy.riv",
        autoplay: true,
        animations: ["idle", "blink"],
        layout: new Layout({
            fit: Fit.FitWidth,
            alignment: Alignment.Center,
        }),
    });
    const [step, setActiveStep] = useState(0)

    useEffect(() => {
        dispatch(loadProfile())
        dispatch(loadWorkspaces())
    }, [ ])


    useEffect(() => {
        if (params.get('workspace')) {
            setSelectedWorkspace(params.get('workspace'))
        }
    }, [ workspaces ])

    useEffect(() => {
        console.log(connectedAccount)
        if (connectedAccount.status === "success") {
            dispatch(loadProfile())
            // if (rive) {
                const protocol = window.location.protocol
                const host = window.location.host.split(".");
                const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
                const redirectUrl = `${protocol}//${workspaces.filter(w => w.workspace && w.workspace.uuid === selectedWorkspace)[0].workspace.handle}.${domain}/home/fans?success=true`
                window.location.href = redirectUrl
            // }
        }
    }, [ connectedAccount ])

    useEffect(() => {
        if (selectedWorkspace && step !== 0) {
            if (rive) {
                try {
                    setTimeout(() => {
                        rive.play(["run", "blink"])
                    }, 1000)
                } catch (err) {
                    
                }
            }
        }
    }, [ selectedWorkspace, step ])

    const login = useGoogleLogin({
        onSuccess: (e) => {
            if (params.get("action") === "reconnect" && params.get("connection")) {
                dispatch(reconnectConnectedAccount("google", e.code, params.get("connection"), selectedWorkspace))
            } else {
                dispatch(createConnectedAccount("google", e.code, selectedWorkspace))
            }
        },
        onError: (e) => {
          console.log(e)
        },
        onNonOAuthError: (n) => {
          console.log(n)
        },
        scope: "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly",
        flow: "auth-code"
    })

    return (
        <Flex gap={8} maxH={"100vh"}>
            <Stack flex={0.3} borderRight={"1px solid #e4e4e4"}>
                <RiveComponent height={360} width={360} />
            </Stack>
            <Box overflow={"scroll"} pb={12} pt={6} mt={6} flex={0.7}>
                <Heading size={"lg"} variant={"h2"}>Let's get you <Text as={"span"} color={"var(--chakra-colors-primary-500)"}>fan</Text>tasized</Heading>
                <Stack w={"54%"} gap={6} mt={10}>
                    {/* boxShadow={step === 0 ? "1px 0px 24px 4px var(--chakra-colors-primary-50)" : undefined} */}
                    <Flex pointerEvents={step !== 0 ? "none" : "all"} borderRadius={10} gap={4} alignItems={"center"} border={"1px solid #e4e4e4"} py={4} px={4} pb={6}>
                        {workspaces.length && !shouldCreateNewWorkspace ? <Stack w={"100%"} gap={0}>
                            <Text fontSize={20} fontWeight={"semibold"} >Choose your workspace</Text>
                            <Text mb={2} color="gray" >Select your workspace to connect calendar.</Text>
                            {step === 0 ? <><RadioGroup w={"100%"} onChange={(v) => {
                                setSelectedWorkspace(v)
                            }} value={selectedWorkspace}>
                            {workspaces.filter(w => w.workspace).map(workspace => {
                                return (
                                    <Flex pb={2} borderBottom={"0.5px solid #e4e4e4"} cursor={"pointer"} onClick={() => {
                                        setSelectedWorkspace(workspace.workspace.uuid)
                                    }} w={"100%"} mb={2}>
                                        <Flex flex={1} gap={2} alignItems={"center"}>
                                            <Avatar size={"sm"} borderRadius={2} src={`https://s2.googleusercontent.com/s2/favicons?domain=${workspace.workspace.website}&sz=128`} />
                                            <Box>
                                                <Text>
                                                    {workspace.workspace?.name}
                                                </Text>
                                                <Text fontSize={"sm"} color={"gray"}>
                                                    @{workspace.workspace?.handle}
                                                </Text>
                                            </Box>
                                        </Flex>
                                        <Radio colorScheme="primary" size={"lg"} value={workspace.workspace.uuid} />
                                    </Flex>
                                )
                            })}
                            </RadioGroup>
                            <Flex mt={2} justifyContent={"flex-end"} gap={2} alignItems={"center"}>
                                {/* <Button onClick={() => {
                                    setShouldCreateNewWorkspace(true)
                                }} variant={"outline"} colorScheme="primary" rounded={"full"} size={"sm"}>Create a new workspace</Button> */}
                                <Button isDisabled={selectedWorkspace === null} onClick={() => {
                                    setActiveStep(1)
                                }} colorScheme="primary" rounded={"full"} size={"sm"}>Continue</Button>
                            </Flex></> : <></>}
                            {step !== 0 && workspaces.length && selectedWorkspace ? <Flex flex={1} gap={2} alignItems={"center"}>
                                <Avatar size={"sm"} borderRadius={2} src={`https://s2.googleusercontent.com/s2/favicons?domain=${workspaces.filter(w => w.workspace && w.workspace.uuid === selectedWorkspace)[0].workspace.website}&sz=128`} />
                                    <Box>
                                    <Text>
                                        {workspaces.filter(w => w.workspace && w.workspace.uuid === selectedWorkspace)[0]?.workspace.name}
                                    </Text>
                                    <Text fontSize={"sm"} color={"gray"}>
                                        @{workspaces.filter(w => w.workspace && w.workspace.uuid === selectedWorkspace)[0]?.workspace.handle}
                                    </Text>
                                    </Box>
                                </Flex> : <></>}
                        </Stack> : (shouldCreateNewWorkspace || !workspaces.length) ? <Stack w="full" gap={0}>
                            <Text fontSize={20} fontWeight={"semibold"} >Setup your workspace</Text>
                            <Text color="gray" >Get your workspace ready.</Text>
                            <Stack w={0.7} gap={4} mt={4}>
                                <FormControl>
                                    <FormLabel color={"gray"}>Enter your workspace name</FormLabel>
                                    <Input value={"Amce, Inc."} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel  color={"gray"}>Choose your fanbase handle</FormLabel>
                                    <InputGroup>
                                        <Input value={"acme"} />
                                        <InputRightAddon children=".fanbaseai.com" />
                                    </InputGroup>
                                </FormControl>
                            </Stack>
                            <Flex mt={4} alignItems={"center"} gap={3} w={"full"} justifyContent={"end"}>
                                <Button onClick={() => {
                                    setShouldCreateNewWorkspace(false)
                                }} colorScheme="primary" size={"sm"} variant={"outline"} rounded={"full"}>Choose existing workspace</Button>
                                <Button size={"sm"} loadingText='Continue' colorScheme="primary" rounded={"full"}>Got it <ArrowRight style={{ marginLeft: 4 }} size={16} /></Button>
                            </Flex>
                        </Stack> : <></> }
                    </Flex>
                    <Flex boxShadow={step === 1 ? "1px 0px 24px 4px var(--chakra-colors-primary-50)" : undefined} opacity={step !== 1 ? 0.4 : 1} pointerEvents={step !== 1 ? "none" : "all"}  justifyContent={"space-between"} borderRadius={10} gap={4} alignItems={"center"} border={"1px solid #e4e4e4"} py={4} px={4}>
                        <Stack gap={0}>
                            <Text fontSize={18} fontWeight={"semibold"}>Connect your work calendar</Text>
                            <Text color="gray">Manage and automate calendar events, invites, and notifications.</Text>
                        </Stack>
                         {/* {profile.connectedAccounts ? <Box>
                            {profile.connectedAccounts.length ? <Button backgroundColor={"green.100"} colorScheme={"green"} variant={"outline"} leftIcon={<CheckIcon size={16} />} borderRadius={"full"} size={"sm"}>Connected</Button> :  */}
                            <Button isLoading={connectedAccount.status === "loading"} onClick={login} colorScheme={"primary"} variant={"outline"} borderRadius={"full"} size={"sm"}>Connect now <ArrowRight style={{ marginLeft: 4 }} size={16} /></Button>
                            {/* } */}
                        {/* </Box> : <></>} */}
                    </Flex>
                    <Flex w={"full"} justifyContent={"end"}>
                        {/* <Button colorScheme="primary" rounded={"full"} size={"sm"}>Take me in <ArrowRight style={{ marginLeft: 4 }} size={16} /></Button> */}
                    </Flex>
                </Stack>
            </Box>
        </Flex>
    )
}