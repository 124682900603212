import mixpanel from 'mixpanel-browser';

const mixpanelEvents = {
  trackFanActivityUsed: (comment, mentions) => {
    mixpanel.track("FanActivity Used", {
      comment,
      mentions
    });
  },

  trackFanGPTUsed: (deviceDetails) => {
    mixpanel.register({
      'Used FanGPT': true,
      ...deviceDetails,
    });

    mixpanel.track('Used FanGPT', {
      timestamp: new Date().toISOString(),
      ...deviceDetails,
    });
  },

  trackAddFanButtonClick: () => {
    mixpanel.track("Add Fan Button Clicked");
  },

  trackUserLogin: (email, data) => {
    mixpanel.track('User Login', {
      'Email': email,
      'Device': navigator.userAgent,
      'Browser': navigator.userAgent,
      'distinct_id': mixpanel.get_distinct_id(),
      '$os': mixpanel.get_property('$os'), 
      '$created': data.createdAt,
      'Login Method': email.includes('@') ? 'Email' : 'Google',
      '$city': mixpanel.get_property('$city'),
      '$country': mixpanel.get_property('$country'),
      'Timestamp': new Date().toISOString()
    });
  },
};

export default mixpanelEvents;
