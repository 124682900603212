/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Logo, { LogoSmall } from "../../core/@branding/logo";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Radio,
  RadioGroup,
  SkeletonCircle,
  SkeletonText,
  Spinner,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import "./_shell.scss";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  getLists,
  loadProfile,
  loadWorkspace,
  loadWorkspaces,
} from "../../../redux/app/core/thunk";
import Chat from "../../../pages/app/chat";
import { mixpanel } from "../../../loaders/mixpanel.loader";
import FanDetail from "../../../pages/app/fan";
import { getChatSessions } from "../../../redux/app/chat/thunk";
import { loadTeam } from "../../../redux/app/team/thunk";
import {
  ArrowUpCircleIcon,
  AudioLinesIcon,
  Bell,
  Bookmark,
  Building2Icon,
  BuildingIcon,
  ChevronDown,
  CircleHelpIcon,
  CogIcon,
  Combine,
  InfoIcon,
  LogOutIcon,
  Orbit,
  PlusIcon,
  Presentation,
  RadioIcon,
  RefreshCcwIcon,
  RssIcon,
  Settings,
  SettingsIcon,
  Star,
  UserPlus,
  UserRoundPlusIcon,
  Users,
} from "lucide-react";
import {
  getApps,
  getWorkspaceActions,
  getWorkspaceConnections,
} from "../../../redux/app/apps/thunk";
import { getNotifications } from "../../../redux/app/notifications/thunk";
import moment from "moment-timezone";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import { Separator } from "../../ui/separator";
import {
  AvatarFallback,
  AvatarImage,
  Avatar as NewAvatar,
} from "../../ui/avatar";
import {
  Menubar,
  MenubarCheckboxItem,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from "../../ui/menubar";
import { generateHSL } from "../../../lib/utils";
import { Checkbox } from "../../ui/checkbox";

function Shell() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSwitchOpen,
    onOpen: onSwitchOpen,
    onClose: onSwitchClose,
  } = useDisclosure();
  const workspaces = useSelector((state) => state.dashboard.core.workspaces);
  const notifications = useSelector(
    (state) => state.dashboard.notifications.platform
  );
  const isWorkspacesLoading = useSelector(
    (state) => state.dashboard.core.isLoading
  );
  const workspace = useSelector((state) => state.dashboard.core.workspace);
  const profile = useSelector((state) => state.dashboard.core.profile);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [subdomain, setSubdomain] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies([
    "accessToken",
    "refreshToken",
  ]);
  const navigate = useNavigate();
  const [currentFan, setCurrentFan] = useState(null);
  const [search] = useSearchParams();
  const {
    onClose: onFanModalClose,
    onOpen: onFanModalOpen,
    isOpen: isFanModalOpen,
  } = useDisclosure();
  const {
    onClose: onGlobalFanModalClose,
    onOpen: onGlobalFanModalOpen,
    isOpen: isGlobalFanModalOpen,
  } = useDisclosure();
  const location = useLocation();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const menuItems = [
    {
      title: "Insights",
      link: "/home/insights",
      subtitle: "View your insights",
      icon: <Orbit size={16} />,
    },
    {
      title: "Meetings",
      link: "/home/meetings",
      subtitle: "View your meetings",
      icon: <Presentation size={16} />,
    },
    {
      title: "Fans",
      link: "/home/fans",
      subtitle: "View all your fans",
      icon: <Users size={16} />,
    },
    {
      title: "Notifications",
      subtitle: "View your insights",
      link: "/home/notifications",
      icon: <Bell size={16} />,
    },
    {
      title: "Apps",
      link: "/home/apps",
      subtitle: "Setup external apps",
      icon: <Combine size={16} />,
    },
    {
      title: "Recommendations",
      subtitle: "View fan recommendations",
      link: "/home/recommendations",
      icon: <Star size={16} />,
    },
    {
      title: "Subscriptions",
      subtitle: "View your subscriptions",
      link: "/home/subscriptions",
      icon: <RadioIcon size={16} />,
    },
  ];

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (["k", "K"].includes(e.key) && (e.ctrlKey || e.metaKey)) {
        onOpen();
      }
    });
    dispatch(loadProfile());
  }, []);

  useEffect(() => {
    if (search.get("open")) {
      onOpen();
    }
  }, []);

  useEffect(() => {
    if (profile?.uuid) {
      mixpanel.identify(profile?.uuid);
      mixpanel.people.set_once({
        $email:
          profile?.accounts?.google?.email || profile?.accounts?.local?.email,
        $firstName: profile?.firstName,
        $lastName: profile?.lastName,
        $name: profile?.fullName,
        $avatar: profile.image,
      });
    }
  }, [profile]);

  useEffect(() => {
    // todo: extract subdomain from the URL to load the information
    const host = window.location.host.split(".");
    const s = host.slice(0, -2)[0];
    setSubdomain(s);
    dispatch(loadWorkspaces());
  }, []);

  useEffect(() => {
    if (workspaces.length && !isWorkspacesLoading) {
      const id = workspaces
        .map((w) => w.workspace)
        .filter((w) => w?.handle === subdomain);
      if (id.length) {
        dispatch(loadWorkspace(id[0].uuid));
        dispatch(getChatSessions(id[0].uuid));
        dispatch(getLists(id[0].uuid));
        dispatch(getApps(id[0].uuid));
        dispatch(getWorkspaceActions());
        dispatch(getWorkspaceConnections(id[0].uuid));
        dispatch(loadTeam(id[0].uuid));
        dispatch(getNotifications(id[0].uuid, true));
      } else {
        const w = workspaces.map((w) => w.workspace);
        const protocol = window.location.protocol;
        const host = window.location.host.split(".");
        const domain = host.slice(host.length > 2 ? 1 : 0).join(".");
        const redirectUrl = `${protocol}//${w[0].handle}.${domain}`;
        setTimeout(() => {
          window.location.href = `${redirectUrl}${location.pathname}`;
        }, 2000);
      }
    } else if (!isWorkspacesLoading && !workspaces.length) {
      const protocol = window.location.protocol;
      const host = window.location.host.split(".");
      const domain = host.slice(host.length > 2 ? 1 : 0).join(".");
      const redirectUrl = `${protocol}//app.${domain}`;
      setTimeout(() => {
        window.location.href = `${redirectUrl}/welcome`;
      }, 2000);
    }
  }, [workspaces, isWorkspacesLoading]);

  useEffect(() => {
    if (!workspace.isLoading) {
      setLoading(false);
      setSelectedWorkspace(workspace.handle);
    }
  }, [workspace]);

  return (
    <Flex gap={0} pos={"relative"} height={"100vh"} w={"full"}>
      {/* <Button
        boxShadow={`0 0 12px -2px rgba(254, 91, 37, 0), 0 0 12px -2px rgba(163, 85, 247, 0), 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px rgba(255, 255, 255, .24)`}
        _hover={{
          boxShadow: `0 0 12px -2px var(--chakra-colors-primary-200), 0 0 12px -2px #a355f7, 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px 0 rgba(255, 255, 255, .24)`,
        }}
        cursor={"pointer"}
        zIndex={10}
        pos={"fixed"}
        right={4}
        bottom={4}
        size={"lg"}
        fontSize={"medium"}
        rounded={"full"}
        onClick={() => {
          onOpen?.();
        }}
        leftIcon={<AudioLinesIcon size={16} />}
        variant={"solid"}
        colorScheme="primary"
      >
        Ask FanGPT
      </Button> */}
      <Drawer
        blockScrollOnMount={false}

        size={"xl"}
        isCentered
        onClose={() => {
          setCurrentFan(null);
          onGlobalFanModalClose();
        }}
        isOpen={isGlobalFanModalOpen}
      >
        <DrawerOverlay />
        <DrawerContent  maxW={840} overflow={"scroll"}>
          <IconButton
            pos={"absolute"}
            size={"sm"}
            rounded={"full"}
            right={2}
            top={2}
            icon={<CloseIcon />}
            onClick={() => {
              setCurrentFan(null);
              onGlobalFanModalClose();
            }}
          />
          {currentFan && <FanDetail id={currentFan} />}
        </DrawerContent>
      </Drawer>
      <Drawer
        scrollBehavior="outside"
        size={["full", "full", "xl"]}
        isOpen={isOpen}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerBody p={0}>
            <Chat
              fanModalOpen={(e) => {
                setCurrentFan(e.uuid);
                onFanModalOpen();
              }}
              onModalClose={onClose}
            />
            <Modal
              scrollBehavior="outside"
              size={"3xl"}
              isCentered
              onClose={() => {
                setCurrentFan(null);
                onFanModalClose();
              }}
              isOpen={isFanModalOpen}
            >
              <ModalOverlay />
              <ModalContent>
                <IconButton
                  pos={"absolute"}
                  size={"sm"}
                  rounded={"full"}
                  right={2}
                  top={2}
                  icon={<CloseIcon />}
                  onClick={() => {
                    setCurrentFan(null);
                    onFanModalClose();
                  }}
                />
                {currentFan && <FanDetail id={currentFan} />}
              </ModalContent>
            </Modal>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal size={"lg"} onClose={onSwitchClose} isOpen={isSwitchOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"md"}>Switch workspace</ModalHeader>
          <ModalBody>
            <RadioGroup
              gap={3}
              w={"100%"}
              onChange={(v) => {
                setSelectedWorkspace(v);
              }}
              value={selectedWorkspace}
            >
              {workspaces
                .filter((w) => w.workspace)
                .map((w) => {
                  return (
                    <Flex
                      pb={2}
                      borderBottom={"0.5px solid #e4e4e4"}
                      cursor={"pointer"}
                      onClick={() => {
                        setSelectedWorkspace(w.workspace.handle);
                      }}
                      w={"100%"}
                      mb={2}
                    >
                      <Flex flex={1} gap={2} alignItems={"center"}>
                        <Avatar
                          size={"sm"}
                          borderRadius={2}
                          src={`https://s2.googleusercontent.com/s2/favicons?domain=${w.workspace.website}&sz=128`}
                        />
                        <Box>
                          <Text>{w.workspace.name}</Text>
                          <Text fontSize={"sm"} color={"gray"}>
                            @{w.workspace.handle}
                          </Text>
                        </Box>
                      </Flex>
                      <Radio
                        colorScheme="primary"
                        size={"lg"}
                        value={w.workspace.handle}
                      />
                    </Flex>
                  );
                })}
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={workspaces?.length < 2}
              isLoading={isWorkspacesLoading}
              onClick={() => {
                const protocol = window.location.protocol;
                const host = window.location.host.split(".");
                const domain = host.slice(host.length > 2 ? 1 : 0).join(".");
                const redirectUrl = `${protocol}//${selectedWorkspace}.${domain}`;
                window.location.href = `${redirectUrl}/home/fans/`;
              }}
              size={"sm"}
              colorScheme="primary"
            >
              Switch workspace
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Stack
        w={275}
        h={"100vh"}
        pb={2}
        justifyContent={"space-between"}
        background={"#FBFBFB"}
        borderRight={`1px solid #eeeff1`}
      >
        <Stack flex={1} gap={0.5}>
          <Menubar
            className={"w-full"}
          >
            <MenubarMenu className={"w-full"}>
              <MenubarTrigger className={"w-full h-12 cursor-pointer"}>
                <Flex w={"full"} alignItems={"center"} gap={2}>
                  <NewAvatar
                    className={"py-1 w-6 h-6 justify-center rounded-md border"}
                  >
                    <AvatarImage
                      src={`https://s2.googleusercontent.com/s2/favicons?domain=${workspace?.website}&sz=128`}
                    />
                    <AvatarFallback
                      style={{
                        backgroundColor: `${generateHSL(
                          workspace?.name || ""
                        )}`,
                      }}
                      className={
                        "uppercase bg-transparent text-center rounded-md"
                      }
                    >
                      {(workspace?.name || "")[0]}
                    </AvatarFallback>
                  </NewAvatar>
                  <Text fontSize={16} className="font-semibold">
                    {workspace?.name}
                  </Text>
                  <ChevronDownIcon />
                </Flex>
              </MenubarTrigger>
              <MenubarContent style={{ width: 260 }} className={"ml-2"}>
                <Box h={'60vh'} overflow={"scroll"}>
                {workspaces
                  .filter((w) => w.workspace)
                  .map((w) => (
                    <MenubarItem onClick={() => {
                      setSelectedWorkspace(w.workspace.handle);
                      const protocol = window.location.protocol;
                      const host = window.location.host.split(".");
                      const domain = host.slice(host.length > 2 ? 1 : 0).join(".");
                      const redirectUrl = `${protocol}//${w.workspace.handle}.${domain}`;
                      window.location.href = `${redirectUrl}/home/fans/`;
                    }} className={"gap-2 cursor-pointer"}>
                      <NewAvatar
                        className={
                          "py-1 w-6 h-6 justify-center rounded-md border"
                        }
                      >
                        <AvatarImage
                          src={`https://s2.googleusercontent.com/s2/favicons?domain=${w.workspace.website}&sz=256`}
                        />
                        <AvatarFallback
                          style={{
                            backgroundColor: `${generateHSL(
                              w?.workspace?.name || ""
                            )}`,
                          }}
                          className={
                            "uppercase bg-transparent text-center rounded-md"
                          }
                        >
                          {(w?.workspace?.name || "")[0]}
                        </AvatarFallback>
                      </NewAvatar>
                      <p className="capitalize">{w?.workspace?.name}</p>
                      
                      <MenubarShortcut>
                          {w?.workspace?.handle === selectedWorkspace ? <Checkbox readonly checked={true} iconSize={10} className={'rounded-lg'} /> : <></>}
                      </MenubarShortcut>
                    </MenubarItem>
                  ))}
                </Box>
                <MenubarSeparator />
                <MenubarItem className={"gap-2"}>
                  <PlusIcon size={16} /> Create new workspace
                </MenubarItem>
                <MenubarItem className={"gap-2"}>
                  <LogOutIcon size={16} /> Sign out
                </MenubarItem>
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
          {/* <Separator className={'mb-2'} /> */}
          {menuItems.map((menu) => {
            return (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      backgroundColor={
                        location.pathname.includes(menu.link)
                          ? "#eeeff1"
                          : "inherit"
                      }
                      textDecor={"none"}
                      _hover={{ textDecor: "none", background: "#eeeff1" }}
                      rounded={10}
                      px={3}
                      mx={2}
                      onClick={() => {
                        navigate(menu.link);
                      }}
                      maxH={"32px"}
                      variant={"ghost"}
                      justifyContent={"start"}
                      fontSize={"sm"}
                      fontWeight={500}
                      leftIcon={menu.icon}
                      as={Link}
                    >
                      {menu.title}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent
                    className="text-xs"
                    side="right"
                    variant="dark"
                  >
                    {menu.subtitle}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            );
          })}
        </Stack>
        <Stack gap={0} mx={2}>
          <Button
            textDecor={"none"}
            _hover={{ textDecor: "none", background: "#eeeff1" }}
            rounded={10}
            px={3}
            mx={0}
            onClick={() => {}}
            maxH={"32px"}
            variant={"ghost"}
            justifyContent={"start"}
            fontSize={"sm"}
            fontWeight={500}
            leftIcon={<Building2Icon size={16} />}
            as={Link}
          >
            Workspace Settings
          </Button>
          <Button
            textDecor={"none"}
            _hover={{ textDecor: "none", background: "#eeeff1" }}
            rounded={10}
            px={3}
            mx={0}
            onClick={() => {
              navigate('/home/team')
            }}
            backgroundColor={
              location.pathname.includes('/home/team')
                ? "#eeeff1"
                : "inherit"
            }
            maxH={"32px"}
            variant={"ghost"}
            justifyContent={"start"}
            fontSize={"sm"}
            fontWeight={500}
            leftIcon={<UserRoundPlusIcon size={16} />}
            as={Link}
          >
            Invite teammates
          </Button>
          <Button
            textDecor={"none"}
            _hover={{ textDecor: "none", background: "#eeeff1" }}
            rounded={10}
            px={3}
            mx={0}
            onClick={() => {}}
            maxH={"32px"}
            variant={"ghost"}
            justifyContent={"start"}
            fontSize={"sm"}
            fontWeight={500}
            leftIcon={<CircleHelpIcon size={16} />}
            as={Link}
          >
            Help
          </Button>
          <Separator className={"mt-2"} />
          <Flex alignItems={"center"}>
            {profile.isLoading ? (
              <SkeletonCircle rounded={"full"} size="10" />
            ) : (
              <Avatar
                padding={2}
                boxSize={10}
                name={profile?.fullName || profile.firstName}
                src={profile?.image}
              />
            )}
            {profile.isLoading ? (
              <SkeletonText
                w={"120px"}
                noOfLines={2}
                spacing="3"
                skeletonHeight="3"
              />
            ) : (
              <Box flex={1} gap={0}>
                <Text
                  textTransform={"capitalize"}
                  fontWeight={600}
                  fontSize={"sm"}
                >
                  {profile?.fullName || profile?.firstName}
                </Text>
                <Text className={"text-xs text-gray-500"}>
                  {profile?.accounts?.local?.email ||
                    profile?.accounts?.google?.email}
                </Text>
              </Box>
            )}
          </Flex>
        </Stack>
      </Stack>
      <Box h={"100vh"} overflow={"scroll"} flex={1}>
        {workspace.isLoading ? (
          <Center height={"calc(100vh - 64px)"}>
            <Stack alignItems={"center"}>
              <Spinner />
              <Text>setting up workspace</Text>
            </Stack>
          </Center>
        ) : (
          <Outlet
            context={{
              chatOpen: onOpen,
              setCurrentFan: setCurrentFan,
              onFanModalOpen: onGlobalFanModalOpen,
            }}
          />
        )}
      </Box>
    </Flex>
  );
}

export default Shell;
