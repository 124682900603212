import { axios } from "../../../loaders/axios.loader"
import { sendInvitation, setInvitationError, setInvitationSuccess, setRemoveError, setRemoveLoading, setRemoveSuccess, setTeam } from "./actions";

export const loadTeam = (workspace) => async (dispatch, getState) => {
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/team/`
        )
        const { response } = request.data;
        dispatch(setTeam(response))
    } catch (err) {
        console.log(err)
    }
}

export const inviteTeamMember = (workspace, {
    name,
    email,
    role,
    redirectUrl,
    message
}) => async (dispatch, getState) => {
    dispatch(sendInvitation())
    try {
        const request = await axios.post(
            `/workspaces/${workspace}/team/add/`,
            {
                name,
                email,
                role,
                redirectUrl,
                message
            }
        )
        const response = request.data;
        dispatch(setInvitationSuccess(response))
    } catch (err) {
        dispatch(setInvitationError(err.response))
        console.log(err)
    }
}

export const reinviteTeamMember = (workspace, member) => async (dispatch, getState) => {
    dispatch(sendInvitation())
    try {
        const request = await axios.post(
            `/workspaces/${workspace}/team/${member}/re-invite`
        )
        const response = request.data;
        dispatch(setInvitationSuccess(response))
    } catch (err) {
        dispatch(setInvitationError(err.response))
        console.log(err)
    }
}

export const removeTeamMember = (workspace, member) => async (dispatch, getState) => {
    dispatch(setRemoveLoading())
    try {
        const request = await axios.delete(
            `/workspaces/${workspace}/team/${member}`
        )
        const response = request.data;
        dispatch(setRemoveSuccess(response))
    } catch (err) {
        dispatch(setRemoveError(err.response))
        console.log(err)
    }
}