import { SET_MAGIC_ERROR, SET_MAGIC_SUCCESS, VALIDATE_MAGIC_LINK } from './constants';

export function setMagicLinkLoading () {
    return {
        type: VALIDATE_MAGIC_LINK
    }
}

export function setMagicSuccess (payload) {
    return {
        type: SET_MAGIC_SUCCESS,
        payload
    }
}

export function setMagicError (error) {
    return {
        type: SET_MAGIC_ERROR,
        error
    }
}