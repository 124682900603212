import { 
    SET_KPIS_ERROR, SET_KPIS_LOADING, SET_KPIS_SUCCESS,
    SET_JOB_CHANGE_LOADING, SET_JOB_CHANGE_SUCCESS, SET_JOB_CHANGE_ERROR,
    SET_JOB_PROMOTIONS_LOADING, SET_JOB_PROMOTIONS_SUCCESS, SET_JOB_PROMOTIONS_ERROR,
    SET_MEETINGS_LOADING, SET_MEETINGS_SUCCESS, SET_MEETINGS_ERROR,
    SET_TOP_PROSPECTS_LOADING, SET_TOP_PROSPECTS_SUCCESS, SET_TOP_PROSPECTS_ERROR, 
    SET_SPECIFIC_REPORT_LOADING,
    SET_SPECIFIC_REPORT_SUCCESS,
    SET_SPECIFIC_REPORT_ERROR
} from "./constants";

const INITIAL_STATE = {
    kpis: {
        state: "loading",
        data: {},
        metadata: {},
        error: ''
    },
    job_changes: {
        state: "loading",
        data: [],
        metadata: {},
        error: ''
    },
    job_promotions: {
        state: "loading",
        data: [],
        metadata: {},
        error: ''
    },
    meetings: {
        state: "loading",
        data: [],
        metadata: {},
        error: ''
    },
    prospects: {
        state: "loading",
        data: [],
        metadata: {},
        error: ''
    },
    report: {
        state: "loading",
        data: {},
        metadata: {},
        error: ''
    }
};

const HomeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_KPIS_LOADING:
            return {
                ...state,
                kpis: {
                    state: "loading",
                    data: {},
                    metadata: {},
                    error: ''
                }
            };
        case SET_KPIS_SUCCESS:
            return {
                ...state,
                kpis: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata,
                    error: ''
                }
            };
        case SET_KPIS_ERROR:
            return {
                ...state,
                kpis: {
                    state: "error",
                    data: {},
                    metadata: {},
                    error: action.error
                }
            };
        case SET_JOB_CHANGE_LOADING:
            return {
                ...state,
                job_changes: {
                    state: "loading",
                    data: [],
                    metadata: {},
                    error: ''
                }
            };
        case SET_JOB_CHANGE_SUCCESS:
            return {
                ...state,
                job_changes: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata,
                    error: ''
                }
            };
        case SET_JOB_CHANGE_ERROR:
            return {
                ...state,
                job_changes: {
                    state: "error",
                    data: [],
                    metadata: {},
                    error: action.error
                }
            };
        case SET_JOB_PROMOTIONS_LOADING:
            return {
                ...state,
                job_promotions: {
                    state: "loading",
                    data: [],
                    metadata: {},
                    error: ''
                }
            };
        case SET_JOB_PROMOTIONS_SUCCESS:
            return {
                ...state,
                job_promotions: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata,
                    error: ''
                }
            };
        case SET_JOB_PROMOTIONS_ERROR:
            return {
                ...state,
                job_promotions: {
                    state: "error",
                    data: [],
                    metadata: {},
                    error: action.error
                }
            };
        case SET_MEETINGS_LOADING:
            return {
                ...state,
                meetings: {
                    state: "loading",
                    data: [],
                    metadata: {},
                    error: ''
                }
            };
        case SET_MEETINGS_SUCCESS:
            return {
                ...state,
                meetings: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata,
                    error: ''
                }
            };
        case SET_MEETINGS_ERROR:
            return {
                ...state,
                meetings: {
                    state: "error",
                    data: [],
                    metadata: {},
                    error: action.error
                }
            };
        case SET_TOP_PROSPECTS_LOADING:
            return {
                ...state,
                prospects: {
                    state: "loading",
                    data: [],
                    metadata: {},
                    error: ''
                }
            };
        case SET_TOP_PROSPECTS_SUCCESS:
            return {
                ...state,
                prospects: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata,
                    error: ''
                }
            };
        case SET_TOP_PROSPECTS_ERROR:
            return {
                ...state,
                prospects: {
                    state: "error",
                    data: [],
                    metadata: {},
                    error: action.error
                }
            };
        case SET_SPECIFIC_REPORT_LOADING:
            return {
                ...state,
                report: {
                    state: "loading",
                    data: {},
                    metadata: {},
                    error: ''
                }
            }
        case SET_SPECIFIC_REPORT_SUCCESS:
            return {
                ...state,
                report: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata || {},
                    error: ''
                }
            }
        case SET_SPECIFIC_REPORT_ERROR:
            return {
                ...state,
                report: {
                    state: "error",
                    data: {},
                    metadata: {},
                    error: action.error
                }
            }
        default:
            return state;
    }
};

export default HomeReducer;
