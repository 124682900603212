import Cookies from "universal-cookie";
import { processLogin, setInvitationError, setInvitationLoading, setInvitationSuccess, setLinkSuccess, setLoginError, setLoginSuccess } from "./actions";
import { axios } from '../../../loaders/axios.loader';
import { COOKIE_DOMAIN } from "../../../loaders/environment.loader";

const cookie = new Cookies()

const setExpiryTime = (days = 30) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return new Date(date);
};

export const processEmailLogin = (email, password) => async (dispatch, getState) => {
    dispatch(processLogin())
    try {
        const request = await axios.post(
            `/auth/common/login`,
            {
                email,
                password
            }
        )
        const response = request.data;
    } catch (error) {
        console.log(error);
        dispatch(setLoginError(error?.response?.data?.message || error.message || 'Failed to process your request'))
    }
}

export const processMagicLogin = (email, redirectUrl) => async (dispatch, getState) => {
    dispatch(processLogin())
    try {
        if (!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            const error = new Error("Invalid email address")
            throw error
        }
        await axios.post(
            `/auth/magic/login`,
            {
                email,
                redirectUrl: redirectUrl || "/"
            }
        )
        dispatch(setLinkSuccess())
    } catch (error) {
        console.log(error);
        dispatch(setLoginError(error?.response?.data?.message || error.message || 'Failed to process your request'))
    }
}

export const processGoogleLogin = (email, token) => async (dispatch, getState) => {
    dispatch(processLogin())
    try {
        const request = await axios.post(
            `/auth/google/login/`,
            {},
            {
                headers: {
                    "x-google-auth": `${token}`
                }
            }
        )
        const response = await request.data;
        if (request.status !== 200) {
            const error = new Error(response.error_description || response.message)
            throw error
        }
        const r = response.response;
        // cookie.set("accessToken", accessToken, {
        //     domain: COOKIE_DOMAIN,
        //     expires: setExpiryTime()
        // })
        // cookie.set("refreshToken", refreshToken, {
        //     domain: COOKIE_DOMAIN,
        //     expires: setExpiryTime(45)
        // })
        dispatch(setLoginSuccess(r))
    } catch (error) {
        console.log(error);
        dispatch(setLoginError(error?.response?.data?.message || error.message || 'Failed to process your request'))
    }
}

export const acceptInvitation = (token, invitation, workspace) => async (dispatch) => {
    dispatch(setInvitationLoading())
    try {
        const request = await axios.post(`/accounts/invitations/${invitation}/accept`, {
            invitation,
            workspace,
            token
        })
        const { response } = request.data
        dispatch(setInvitationSuccess(response))
    } catch (error) {
        console.error(error)
        dispatch(setInvitationError('Failed to process invitation'))
    }
}