import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import App from './app';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { defineStyleConfig } from '@chakra-ui/react'

const theme = extendTheme({
    fonts: {
      heading: `'Circular', sans-serif;`,
      body: `'Circular', sans-serif;`,
    },
    colors: {
      primary: {
        main: "#4F44E0",
        50: "#eae9fb",
        100: "#c1bdf4",
        200: "#9892ed",
        300: "#6f66e6",
        400: "#463ade",
        500: "#4F44E0",
        600: "#4F44E0",
        700: "#19126d",
        800: "#0f0b42",
        900: "#050416"
      }
    },
    components: {
      Input: {
          baseStyle: {
            backgroundColor: "red",
              _focus: {
                  boxShadow: 'none'
              },
              _focusVisible: {
                boxShadow: 'none'
            }
          }
      }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ChakraProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
