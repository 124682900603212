import {
    SET_WORKSPACE_INFORMATION_LOADING,
    SET_WORKSPACE_INFORMATION_SUCCESS,
    SET_WORKSPACE_INFORMATION_ERROR,
    SET_CREATE_WORKSPACE_LOADING,
    SET_CREATE_WORKSPACE_SUCCESS,
    SET_CREATE_WORKSPACE_ERROR
} from './constants';

// Workspace Information Actions
export function setWorkspaceInformationLoading() {
    return {
        type: SET_WORKSPACE_INFORMATION_LOADING
    };
}

export function setWorkspaceInformationSuccess(payload) {
    return {
        type: SET_WORKSPACE_INFORMATION_SUCCESS,
        payload
    };
}

export function setWorkspaceInformationError(error) {
    return {
        type: SET_WORKSPACE_INFORMATION_ERROR,
        error
    };
}

// Create Workspace Actions
export function setCreateWorkspaceLoading() {
    return {
        type: SET_CREATE_WORKSPACE_LOADING
    };
}

export function setCreateWorkspaceSuccess(payload) {
    return {
        type: SET_CREATE_WORKSPACE_SUCCESS,
        payload
    };
}

export function setCreateWorkspaceError(error) {
    return {
        type: SET_CREATE_WORKSPACE_ERROR,
        error
    };
}
