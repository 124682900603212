import { Box, Flex, Heading, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Stack, Text, useDisclosure } from '@chakra-ui/react';
import FanDirectory from '../directory';
import './_list.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { loadSharedWorkspaceList } from '../../../redux/app/shared/list/thunk';
import CoreLogo from '../../../assets/img/logo.svg';
import FanDetail from '../fan';
import Logo from "../../../components/core/@branding/logo";

function List() {
    const info = useSelector(state => state.shared.lists.list)
    const isLoading = useSelector(state => state.shared.lists.isListLoading)
    const params = useParams()
    const dispatch = useDispatch();
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [selectedIndex, setSelectedIndex] = useState(0)

    useEffect(() => {
        const list = params["list"]
        const workspace = "0aec2ab3-47d2-4021-aeb9-0e24ef223715";
        dispatch(loadSharedWorkspaceList(workspace, list))
    }, [])

    return (
        <Stack p={[4, 6, 10]} gap={4}>
            <Modal p={0} size={"3xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalBody p={0} pb={12} style={{
                paddingBottom: 36
            }} >
                <FanDetail  />
            </ModalBody>
            </ModalContent>
        </Modal>
            <Box ml={4}>
            <Logo size={[10]} />
            </Box>
            <Stack ml={4}>
                <Heading mt={2} size={"md"}>{info?.name}</Heading>
                <Text color={"gray.600"} fontSize={"md"}>{info?.description}</Text>
            </Stack>
            <FanDirectory readOnly={true} shared={true} workspace={"0aec2ab3-47d2-4021-aeb9-0e24ef223715"} list={info?._id} onCellClick={(fan, index) => {
                setSelectedIndex(index)
                onOpen()
            }} isLoading={isLoading} />
            <Flex ml={4} mr={4} justifyContent={"space-between"} mt={4}>
                <Box>
                    <Text fontSize={"sm"} color={"gray.500"}>All rights reserved</Text>
                    <Text fontSize={"sm"} color={"gray.500"}>FanbaseAI, Inc.</Text>
                </Box>
            </Flex>
        </Stack>
    )
}

export default List