/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Logo from "../../components/core/@branding/logo";
import Google from "../../assets/img/google.svg";
import Microsoft from "../../assets/img/microsoft.png";
import LogoHorizontal from '../../assets/img/logo-solid-thin.svg';
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Img,
  Link,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import {
  processGoogleLogin,
  processMagicLogin,
} from "../../redux/auth/login/thunk";
import { setEmail } from "../../redux/auth/login/actions";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { axios } from "../../loaders/axios.loader";
import { useCookies } from "react-cookie";
import mixpanelEvents from "../../utils/events";
import { Button } from "../../components/ui/button";
import { MailIcon } from "lucide-react";
import { Separator } from "../../components/ui/separator";
import { Input } from "../../components/ui/input";

function Login() {
  const email = useSelector((state) => state.auth.login.email);
  const success = useSelector((state) => state.auth.login.success);
  const data = useSelector((state) => state.auth.login.data);
  const linkSuccess = useSelector((state) => state.auth.login.isLinkSent);
  const isLoading = useSelector((state) => state.auth.login.isLoading);
  const error = useSelector((state) => state.auth.login.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["accessToken"]);
  const [searchParams] = useSearchParams();

  const login = useGoogleLogin({
    onSuccess: (e) => {
      dispatch(processGoogleLogin(email, e.code));
    },
    onError: (e) => {
      console.log(e);
    },
    onNonOAuthError: (n) => {
      console.log(n);
    },
    flow: "auth-code",
  });

  useEffect(() => {
    if (success) {
      setCookie("accessToken", data.accessToken);
      axios.defaults.headers["Authorization"] = `Bearer ${data.accessToken}`;
      navigate(
        `/authorize?code=${data.accessToken}&redirectUrl=${encodeURIComponent(
          searchParams.get("next")
        )}`,
        {
          replace: true,
        }
      );
      mixpanelEvents.trackUserLogin(email, data);
    }
  }, [success, email, data]);

  return (
    <Flex height={"100vh"}>
      <Box w={"full"} borderRight={"1px solid"} borderColor={"blackAlpha.200"}>
        <Stack py={6} height={"100vh"} justifyContent={"space-between"} alignItems={"center"} spacing={0}>
          <Stack w={400}>
            {/* <LogoHorizontal
                size="large"
                style={{
                  marginRight: 10,
                  height: 84,
                  alignItems: "start",
                  border: "1px solid #CACACA",
                  borderRadius: 6,
                  padding: 6,
                }}
              /> */}
          </Stack>
          <Stack w={400}>
            <Stack alignItems={"start"}>
              {/* <Img mb={6} h={6} src={LogoHorizontal} /> */}
              <Box w={"full"}>
                <Text className="font-bold text-3xl">
                  Sign In
                </Text>
                <Text mt={1} fontSize={"sm"} color={"gray"}>
                  Enter your details below to proceed to dashboard
                </Text>
                {linkSuccess && (
                  <Alert
                    variant={"left-accent"}
                    py={2}
                    px={3}
                    borderRadius={4}
                    mt={4}
                    w={"full"}
                    fontSize={"sm"}
                    status="success"
                  >
                    <AlertIcon />
                    Login link sent to your email address
                  </Alert>
                )}
              </Box>
            </Stack>
            <Stack marginTop={"8"} spacing={4}>
              <FormControl isInvalid={error.length}>
                <Input
                  rounded={"full"}
                  type="email"
                  className="text-md"
                  onChange={(e) => {
                    dispatch(setEmail(e.target.value));
                  }}
                  value={email}
                  placeholder="Enter your email address"
                  size="md"
                />
                <FormErrorMessage>{error}</FormErrorMessage>
              </FormControl>
              <Link
                href="mailto:support@fanbaseai.com?subject=Trouble logging in&body=Hi there, I am facing issues with the login. My email is: <replace with your email>"
                isExternal
                color={"primary"}
                align={"end"}
                textDecoration={"underline"}
                fontSize={"sm"}
              >
                Trouble logging in ?
              </Link>
              <Button
                onClick={() => {
                  dispatch(
                    processMagicLogin(
                      email,
                      encodeURIComponent(searchParams.get("next"))
                    )
                  );
                }}
                size="sm"
                className={"font-semibold rounded-lg"}
                disabled={!email.length || isLoading}
              >
               {isLoading ? <Spinner size={"xs"} /> : <><MailIcon className="mr-2" size={18} />
                Continue with email</>}
              </Button>
              <Box className="relative">
                  <Box className="absolute inset-0 flex items-center">
                    <Box as={"span"} className="w-full border-t" />
                  </Box>
                  <Box className="relative flex justify-center text-xs uppercase">
                    <Box as="span" className="bg-background text-muted-foreground px-2">
                        Or
                    </Box>
                  </Box>
              </Box>
              <Button
                fontWeight={"medium"}
                isLoading={isLoading}
                onClick={() => {
                  login();
                }}
                variant="outline"
                size={"sm"}
                className={"font-semibold rounded-lg"}
                rounded={"full"}
                fontSize={"md"}
              >
                 <img style={{ height: 18 }} className="mr-2" src={Google} alt="google" />
                Sign in with Google
              </Button>
              <Button
                disabled
                isLoading={isLoading}
                onClick={() => {
                  login();
                }}
                className={"font-semibold rounded-lg"}
                variant="outline"
                size={"sm"}
              >
                 <img style={{ height: 18 }} className="mr-2" src={Microsoft} alt="google" />
                Sign in with Microsoft
              </Button>
            </Stack>
          </Stack>
          <Box>
            <Box w={360} className="text-xs">
            By proceeding you acknowledge that you have read, 
            understood and agree to our
                <Link textDecor={"underline"} className="underline ml-1" href="https://www.fanbaseai.com/compliance/terms-conditions" isExternal>Terms and Conditions</Link>
            </Box>
            <Flex gap={6} className="text-xs" mt={4}>
                <Text>© 2024 FanbaseAI</Text>
                <Link textDecor={"underline"} className="underline ml-1" href="https://www.fanbaseai.com/compliance/privacy-policy" isExternal>Privacy Policy</Link>
                <Link textDecor={"underline"} className="underline ml-1" href="https://www.fanbaseai.com/compliance/cookie-policy" isExternal>Cookie Settings</Link>
                <Link textDecor={"underline"} className="underline ml-1" href="mailto:support@fanbaseai.com" isExternal>Support</Link>
            </Flex>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
}

export default Login;
