import { Avatar, Box, Button, ButtonGroup, Center, Collapse, Divider, Flex, Heading, Icon, Image, Link, Spinner, Stack, Tag, TagLabel, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import './_account.scss';
import Chart from 'react-apexcharts';
import { colors } from '../../../utils/color.constants';
import Table from '../../../components/core/@table';
import { getSpecificWorkspaceAccount } from '../../../redux/app/accounts/thunk';

const CircleIcon = (props) => (
    <Icon viewBox='0 0 200 200' {...props}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
    </Icon>
  )

const formatDate = (date) => moment(date).format("MMM YYYY")

function AccountDetail({ id }) {

    const workspace = useSelector(state => state.dashboard.core.workspace)
    const isLoading = useSelector(state => state.dashboard.accounts.account.isLoading)
    const account = useSelector(state => state.dashboard.accounts.account.data)
    const error = useSelector(state => state.dashboard.accounts.account.error)
    const dispatch = useDispatch()
    const [path, setPath] = useState([])
    const [currentPath, setCurrentPath] = useState({})
    const [show, setShow] = useState(false)
    const handleToggle = () => setShow(!show)


    useEffect(() => {
         if (id) {
             dispatch(getSpecificWorkspaceAccount(workspace.uuid, id))
         }
    }, [ id ])

    const getSalesCount=() => {

       var salesCount=null;
        const distributions=account?.company?.distribution;
         if(distributions) {
            distributions.map(distribution=> {
                if(distribution.name=="Sales") {
                   salesCount=distribution.count;
                }
            });
         }
        return salesCount;
    }
     return (
        <Box pb={12}>
            {isLoading ? <Center height={240}>
                <Spinner />
            </Center> : <div className="fui-account">
            <div className="fui-account-actions">
            </div>
            <div className="fui-account-header">
                <div className="fui-account-background">
                    <Image fallbackSrc='https://static.licdn.com/sc/h/55k1z8997gh8dwtihm11aajyq' alt={account?.name}  />
                </div>
                <div className="fui-account-bio">
                    <div className="fui-account-bio-details">
                        <div className="fui-account-bio-image">
                            <Avatar outline={'6px solid #ffffff'} boxSize={24} name={account.name} src={account.company.image} />
                        </div>
                        <Box mt={2} className="fui-account-bio-title">
                            <Heading size={"md"}>{account.name}</Heading>
                        </Box>
                        <Box  mt={0.5} color={"gray"} className="fui-account-bio-subtitle">
                            <Text fontSize={"sm"}>{account.profile?.headline}</Text>
                        </Box>
                        <Box mt={1} className="fui-account-bio-subtitle">
                            <i style={{
                                marginRight: 4
                            }} className="fi fi-rr-users"></i>
                            <Text color="gray.700" fontSize={"sm"}>{account.company.headcount || ''} Employees</Text>

                            <CircleIcon style={{
                                marginTop: 4,
                                color: 'gray',
                                marginLeft: 4,
                                marginRight: 4
                            }} color="gray.700" boxSize={1} />
                            <Text color="gray.700" fontSize={"sm"}>{getSalesCount() || ''} Sales Employees</Text>
                        </Box>
                        {account.company?.location?
                        <Box mt={1} className="fui-account-bio-subtitle">
                            <i style={{
                                marginRight: 4
                            }} className="fi fi-rr-marker"></i>

                            <Text color="gray.700" fontSize={"sm"}>{account.company?.location?.name || ''}</Text>
                            <CircleIcon style={{
                                marginTop: 4,
                                color: 'gray',
                                marginLeft: 4,
                                marginRight: 4
                            }} color="gray.700" boxSize={1} />
                            <Text color="gray.700" fontSize={"sm"}>{account.company?.country?.name || ''}</Text>

                        </Box> :null}
                    </div>
                    <div className="fui-account-bio-actions">
                    <ButtonGroup size={"sm"} gap='1'>
                        <Button colorScheme='primary' variant={"solid"} leftIcon={<AddIcon boxSize={3.5} />}>add to list</Button>
                        <Button leftIcon={<i className="fi fi-rr-eye"></i>} variant={"outline"}>add to salesforce</Button>
                    </ButtonGroup>
                    </div>
                </div>
                <Button _hover={{
                    textDecoration: "none"
                }} textDecoration={"none"} as={Link} href={`https://www.linkedin.com/company/${account?.company?.linkedIn}/`} target='_blank' ml={4} mt={3} variant={"outline"} colorScheme="primary" size={"sm"} leftIcon={<i className="fi fi-br-arrow-up-right fui-account-star"></i>}>view on linkedin</Button>
                <Button _hover={{
                     textDecoration: "none"
                }} textDecoration={"none"} as={Link} href={account?.company.website} target='_blank' ml={4} mt={3} variant={"outline"} colorScheme="primary" size={"sm"} leftIcon={<i className="fi fi-br-arrow-up-right fui-account-star"></i>}>website</Button>
            </div>
            <Divider mt={4} />
            <Stack mb={4} p={4}>
                <Text mb={3} fontWeight={"bold"} fontSize={"md"}>About {account?.company?.name}</Text>
                     <Flex alignItems={"center"}>
                        <Box>
                            <Avatar size={"lg"} borderRadius={4} src={account?.company?.image} name={currentPath?.account?.company?.name} />
                        </Box>
                        <Box ml={2}>
                            <Text>{account?.company?.name}</Text>
                            <Text fontSize={"sm"}>{account?.company?.headcount} employees</Text>
                            <Text color={"gray"} fontSize={"sm"}>{account?.company?.headquarter?.city}, {account?.company?.headquarter?.country}</Text>
                        </Box>
                     </Flex>
                            {/* <Text fontSize={"sm"} color={"#757575"} >{currentPath?.account?.company?.tagline}</Text> */}
                     <Collapse startingHeight={46} in={show}>
                        <Text fontSize={"sm"}>{!show ? `${account?.company?.description?.slice(0, 210)}...`: account?.company?.description}</Text>
                     </Collapse>
                     <Button colorScheme="primary" variant={"link"} w={"fit-content"} display={"inline"} size='sm' onClick={handleToggle}>
                        show {show ? 'less' : 'more'}
                     </Button>
                     <Divider mt={4} />
            </Stack>
            <Stack pb={4} px={4}>
            <Text mb={3} fontWeight={"bold"} fontSize={"md"}>headcount & distribution</Text>
                            {account?.company?.distribution?.length && <Flex mt={2} justifyContent={"space-between"}>
                            <Table tableStyle={{
                                marginTop: 0,
                                flex: 1
                            }} headers={[
                                "name",
                                "headcount"
                            ]} disableActions={true} disablePagination={true}>
                                {account?.company?.distribution?.map(f => <Tr>
                                    <Td>{f.name}</Td>
                                    <Td>{f.count}</Td>
                                </Tr>)}
                            </Table>
                            </Flex>}
                        </Stack>
        </div>}
        </Box>
    );
}

export default AccountDetail