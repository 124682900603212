import { Center, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { acceptInvitation } from "../../redux/auth/login/thunk";
import { CheckCheckIcon } from "lucide-react";

export default function Invite () {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const dispatch = useDispatch()
    const state = useSelector(state => state.auth.login.invitation.state)
    const data = useSelector(state => state.auth.login.invitation.data)
    const error = useSelector(state => state.auth.login.invitation.error)
    const toast = useToast()

    useEffect(() => {
        if (!params.get("token") || !params.get("invitation") || !params.get("workspace")) {
            navigate("/", {
                replace: true
            })
        } else {
            dispatch(acceptInvitation(params.get("token"), params.get("invitation"), params.get("workspace")))
        }
    }, [ params ])

    useEffect(() => {
        if (state === "success" && data.handle) {
            toast({
                title: "Invitation accepted",
                status: "success"
            })
            const protocol = window.location.protocol
            const host = window.location.host.split(".");
            const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
            const redirectUrl = `${protocol}//${data.handle}.${domain}`
            setTimeout(() => {
                window.location.href = `${redirectUrl}/home/fans/`
            }, 2000)
        }
    }, [ state ])

    return (
        <Center height={"100vh"}>
            <Stack align={"center"}>
                {state === "loading" ?<> <Spinner />
                <Text>Procesing invitation</Text></> : state === "success" ? <><Spinner />
                <Text color={"green.500"}>Invitation accepted. Please wait ...</Text></> : state === "error" ? <><i style={{ fontSize: 36, color: "#C53030" }} class="fi fi-rr-exclamation"></i>
                <Text mt={1} color={"red.600"} >{error}</Text></> : <></>}
            </Stack>
        </Center>
    )
}