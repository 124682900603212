import {
  Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Center,
    CloseButton,
    Flex,
    Grid,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
import Page from "../../../components/core/@page";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { CalendarImage } from "../../../components/core/@icon";
import { AlertTriangleIcon, CheckIcon, SettingsIcon } from "lucide-react";
import { getWorkspaceApp, updateWorkspaceAppMapping } from "../../../redux/app/apps/thunk";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Select } from "chakra-react-select";
import { resetMapping } from "../../../redux/app/apps/actions";
import _ from "lodash";

const CircleIcon = (props) => (
  <Icon viewBox='0 0 200 200' {...props}>
    <path
      fill='currentColor'
      d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
    />
  </Icon>
)
  
function Apps() {
    const workspace = useSelector((state) => state.dashboard.core.workspace);
    const actions = useSelector((state) => state.dashboard.apps.actions);
    const profile = useSelector(state => state.dashboard.core.profile)
    const app = useSelector((state) => state.dashboard.apps.app);
    const updatedMapping = useSelector((state) => state.dashboard.apps.mapping);
    const properties = useSelector((state) => state.dashboard.apps.properties);
    const { onClose, onOpen, isOpen } = useDisclosure();
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies();
    const [mapping, setMapping] = useState({});
    const toast = useToast()

    useEffect(() => {
      if (app.data.actions?.length) {
        const m = {}
        for (const a of app.data.actions) {
          m[a.action.canonicalName] = [...(a.mapping || [])]
        }
        setMapping(m)
      }
    }, [app])

    useEffect(() => {
      if (updatedMapping.state === "success") {
        toast({
          "title": "Mapping updated successfully",
          status: "success"
        })
        dispatch(resetMapping())
      } else if (updatedMapping.state === "error") {
        toast({
          "title": "Failed to update mapping",
          status: "success"
        })
        dispatch(resetMapping())
      }
    }, [updatedMapping])
  
    return (
      <Page
        title={"Integrations"}
        subtitle={"Connect & manage all your external app connections"}
      >
        <Modal size={"2xl"} isCentered onClose={onClose} isOpen={isOpen}>
          <ModalOverlay></ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <Flex justifyContent={"space-between"}>
                <Box>
                  <Text>Actions</Text>
                  <Text color={"gray"} fontWeight={"medium"} fontSize={"sm"}>Configure actions that this app can perform</Text>
                </Box>
                <CloseButton onClick={() => {
                  onClose()
                }} />
              </Flex>
            </ModalHeader>
            <ModalBody textAlign={app.state !== "success" ? "center" : "start"}>
              {app.state === "success" ? <Accordion allowMultiple>
                {actions.data.map(action => {
                  const isActionConfigured = app.data.actions.filter(a => a.action.uuid === action.uuid).length
                  return (
                    <AccordionItem rounded={6} border={"1px solid #e4e4e4"}>
                      <AccordionButton p={0}>
                        <Card w={"100%"} boxShadow={0}>
                        <CardBody>
                          <Flex justifyContent={"space-between"} alignItems={"center"}>
                            <Box>
                              <Text textAlign={"start"} fontWeight={"semibold"} fontSize={"md"}>{action.name}</Text>
                              <Text textAlign={"start"} fontSize={"sm"} mt={1} color={"#757575"}>{action.description}</Text>
                            </Box>
                            <Flex>
                              <Button rounded={"full"} variant={isActionConfigured ? "outline" : "solid"} colorScheme={isActionConfigured ? undefined : "primary"} size={"sm"} leftIcon={isActionConfigured ? <CircleIcon boxSize={3} color="green.500" /> : <SettingsIcon size={16} />}>{isActionConfigured ? "Active" : "Configure"}</Button>
                            </Flex>
                          </Flex>
                        </CardBody>
                      </Card>
                      </AccordionButton>
                      <AccordionPanel>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>Fanbase Property</Th>
                              <Th></Th>
                              <Th>Hubspot Property</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {action.fields.map(a => <Tr>
                              <Td fontSize={"sm"}>{a.label}</Td>
                              <Td fontSize={"xs"} color={"gray"}>maps to</Td>
                              <Td fontSize={"sm"}>
                                <Select value={(mapping[action.canonicalName] || []).filter(f => f.field === a.field)} onChange={(e) => {
                                  let checkIfFieldAlreadyExists = -1;
                                  for (const _m in (mapping[action.canonicalName] || [])) {
                                    if (mapping[action.canonicalName][_m].field === a.field) {
                                      checkIfFieldAlreadyExists = _m
                                    }
                                  }
                                  console.log(checkIfFieldAlreadyExists)
                                  if (checkIfFieldAlreadyExists < 0) {
                                    setMapping({
                                      ...mapping,
                                      [action.canonicalName]: [
                                        ...(mapping[action.canonicalName] || []),
                                        {
                                          "field": a.field,
                                          "fieldLabel": a.label,
                                          "label": e.label,
                                          "property": e.value
                                        }
                                      ]
                                    })
                                  } else {
                                    const newMapping = mapping[action.canonicalName]
                                    newMapping[checkIfFieldAlreadyExists] = {
                                      "field": a.field,
                                      "fieldLabel": a.label,
                                      "label": e.label,
                                      "property": e.value
                                    }
                                    setMapping({
                                      ...mapping,
                                      [action.canonicalName]: newMapping
                                    })
                                  }
                                }} options={properties.data.map(p => ({
                                  "label": p.label,
                                  "value": p.name
                                }))} chakraStyles={{
                                  control: (base, state) => ({
                                    ...base,
                                     borderRadius: 24
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    fontSize: "xs"
                                  })
                                  }} size={"sm"} variant="outline" />
                              </Td>
                            </Tr>)}
                          </Tbody>
                        </Table>
                        <Flex mt={2} w={"100%"} justifyContent={"flex-end"}>
                          <Button isLoading={updatedMapping.state === "loading"} onClick={() => {
                            dispatch(updateWorkspaceAppMapping(workspace.uuid, app.data.uuid, action.uuid, mapping))
                            dispatch(getWorkspaceApp(workspace?.uuid, app.data.uuid))
                          }} colorScheme="primary" rounded={"full"} size={"sm"}>Save</Button>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  )
                })}
              </Accordion> : <Spinner />}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
        <Stack p={4}>
          <Flex justify={"space-between"}>
            <Text fontSize={"large"} fontWeight={"bold"}>Connected accounts</Text>
            <ButtonGroup>
              <Button hidden={!profile.connectedAccounts?.length} rounded={"full"} onClick={() => {
                            const protocol = window.location.protocol
                            const host = window.location.host.split(".");
                            const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
                            const redirectUrl = `${protocol}//app.${domain}`
                            window.location.href = `${redirectUrl}/get-started?workspace=${workspace?.uuid}`;
                          }} size={"sm"} colorScheme="primary">Add calendar</Button>
            </ButtonGroup>
          </Flex>
          {!profile.connectedAccounts?.length ? <Center textAlign={"center"}>
              <Stack>
                <Text fontSize={"lg"} fontWeight={"bold"}>No Calendar Connected</Text>
                <Text>Click below to connect your calendar</Text>
                <Button hidden={!profile.connectedAccounts?.length} rounded={"full"} onClick={() => {
                            const protocol = window.location.protocol
                            const host = window.location.host.split(".");
                            const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
                            const redirectUrl = `${protocol}//app.${domain}`
                            window.location.href = `${redirectUrl}/get-started?workspace=${workspace?.uuid}`;
                          }} size={"sm"} colorScheme="primary">Connect calendar</Button>
              </Stack>
            </Center> : <></>}
          <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)','repeat(3, 1fr)']} gap={6}>
          {profile.connectedAccounts.map(connection => {
              return (
                <Card boxShadow={0} border={"1px solid #e5e5e5"}>
                  <CardBody p={3}>
                      <Stack alignItems={"end"} gap={1}>
                        <Flex w={"100%"} justifyContent={"space-between"}>
                          <CalendarImage size={10} />
                          <Flex gap={2} mt={1}>
                            {/* <Tag rounded={"full"} py={2} height={"fit-content"} size={"sm"} colorScheme="primary">{connection.syncStatus === "syncing" ? <Spinner mr={2} size={"xs"} /> : <></>}{_.capitalize(connection.syncStatus)}</Tag> */}
                            {/* <Tag rounded={"full"} py={2} height={"fit-content"} size={"sm"} colorScheme="orange"><TagLeftIcon as={Globe2Icon} /><TagLabel>{connection.metadata?.domain}</TagLabel></Tag> */}
                          </Flex>
                        </Flex>
                        <Box alignSelf={"start"} mt={1}>
                          <Text mt={1} fontWeight={"semibold"} fontSize={"lg"}>{connection.email}</Text>
                          <Text fontSize={"sm"} color={"gray"}>Connection ID: {connection.id}</Text>
                        </Box>
                        <Flex gap={2} mt={2}>
                          {connection.syncStatus === "synced" ? 
                          <Button rounded={"full"} variant={"solid"} size={"sm"} w={"fit-content"} colorScheme="green" leftIcon={<CheckIcon size={16} />}>Connected</Button> :
                          connection.syncStatus === "sync_error" ?  <Button onClick={() => {
                            const protocol = window.location.protocol
                            const host = window.location.host.split(".");
                            const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
                            const redirectUrl = `${protocol}//app.${domain}`
                            window.location.href = `${redirectUrl}/get-started?workspace=${workspace?.uuid}&connection=${connection.uuid}&action=reconnect`;
                          }} rounded={"full"} variant={"outline"} size={"sm"} w={"fit-content"} colorScheme="yellow" leftIcon={<AlertTriangleIcon size={16} />}>Reconnect calendar</Button> :
                          <Button rounded={"full"} isLoading isDisabled variant={"solid"} size={"sm"} w={"fit-content"} colorScheme="primary" leftIcon={<CheckIcon size={16} />}>Syncing</Button>
                        }
                        </Flex>
                      </Stack>
                  </CardBody>
                </Card>
              )
            })}
          </Grid>
          {/* <Text mt={4} fontSize={"large"} fontWeight={"bold"}>Workspace apps</Text>
          <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)','repeat(3, 1fr)']} gap={6}>
            {connections.data.map(connection => {
              return (
                <Card boxShadow={0} border={"1px solid #e5e5e5"}>
                  <CardBody p={3}>
                      <Stack alignItems={"end"} gap={1}>
                        <Flex w={"100%"} justifyContent={"space-between"}>
                          <HubspotImage size={10} />
                          <Flex gap={2} mt={1}>
                            <Tag rounded={"full"} py={2} height={"fit-content"} size={"sm"} colorScheme="primary">{connection.identifier}</Tag>
                            <Tag rounded={"full"} py={2} height={"fit-content"} size={"sm"} colorScheme="orange"><TagLeftIcon as={Globe2Icon} /><TagLabel>{connection.metadata?.domain}</TagLabel></Tag>
                          </Flex>
                        </Flex>
                        <Box alignSelf={"start"} mt={1}>
                          <Text mt={1} fontWeight={"semibold"} fontSize={"lg"}>{connection.provider.name}</Text>
                          <Text fontSize={"sm"} color={"gray"}>{connection.provider.description}</Text>
                        </Box>
                        <Flex gap={2} mt={2}>
                          <Button onClick={() => {
                            dispatch(getWorkspaceAppProperties(workspace?.uuid, connection?.uuid))
                            dispatch(getWorkspaceApp(workspace?.uuid, connection.uuid))
                            onOpen()
                          }} rounded={"full"} variant={"outline"} leftIcon={<ZapIcon size={16} />} size={"sm"} w={"fit-content"}>Configure actions</Button>
                          <Button rounded={"full"} variant={"solid"} size={"sm"} w={"fit-content"} colorScheme="green" leftIcon={<CheckIcon size={16} />}>Connected</Button>
                        </Flex>
                      </Stack>
                  </CardBody>
                </Card>
              )
            })}
            {connections.state === "success" && connections.data.length === 0 ? apps.data.map(app => {
              return (
                <Card boxShadow={0} border={"1px solid #e5e5e5"}>
                  <CardBody p={3}>
                      <Stack alignItems={"end"} gap={1}>
                        <Flex w={"100%"} justifyContent={"space-between"}>
                          <HubspotImage size={10} />
                          <Flex gap={2} mt={1}>
                            
                          </Flex>
                        </Flex>
                        <Box mt={1}>
                          <Text mt={1} fontWeight={"semibold"} fontSize={"lg"}>{app.name}</Text>
                          <Text fontSize={"sm"} color={"gray"}>{app.description}</Text>
                        </Box>
                        <Flex gap={2} mt={2}>
                          <Button onClick={() => {
                            const redirectUrl = process.env.REACT_APP_HUBSPOT_REDIRECT_URL;
                            const url = app.authUrl.replace("{REDIRECT_URL}", redirectUrl)
                            setCookie("connectionParams", JSON.stringify({
                              "app": app.uuid,
                              "name": app.name,
                              "workspace": workspace.uuid,
                              "redirectUrl": redirectUrl,
                              "startUrl": window.location.href
                            }), {
                              domain: window.location.hostname.split(".").slice(1).join("."),
                              path: "/"
                            })
                            window.location.href = url
                          }} rounded={"full"} variant={"solid"} size={"sm"} w={"fit-content"} colorScheme="primary" rightIcon={<ArrowRightIcon size={16} />}>Connect</Button>
                        </Flex>
                      </Stack>
                  </CardBody>
                </Card>
              )
            }) : <></>}
          </Grid> */}
        </Stack>
      </Page>
    );
  }
  
  export default Apps;
  