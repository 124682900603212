import { Avatar, AvatarGroup, Box, Divider, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Grid, Heading, IconButton, Image, Skeleton, Stack, Tag, TagLabel, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import Page from "../../../components/core/@page";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { Calendar, ChevronLeft, ChevronRight, Heart, MoreHorizontal } from "lucide-react";
import { getEvents } from "../../../redux/app/meetings/thunk";
import _ from "lodash";
import { TooltipAvatar } from "../../../components/layout/frame";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import MeetingDetail from "./detail";

function Meetings() {
  const workspace = useSelector((state) => state.dashboard.core.workspace);
  const events = useSelector((state) => state.dashboard.events.list);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("year").toDate(),
  ]);
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentMeeting, setCurrentMeeting] = useState(null)

  useEffect(() => {
    if (page > 1) {
        dispatch(getEvents(moment(selectedDates[0]).format("YYYY-MM-DD"), moment(selectedDates[1]).format("YYYY-MM-DD"), page))
    }
  }, [ page ])

  useEffect(() => {
    if (selectedDates.length === 2) {
        dispatch(getEvents(moment(selectedDates[0]).format("YYYY-MM-DD"), moment(selectedDates[1]).format("YYYY-MM-DD"), 1))
        setPage(1)
    }
  }, [ selectedDates ])

  return (
    <Page
      title={"Meetings"}
      subtitle={"View all your meetings with fans"}
      actions={
        <Flex mr={3} alignItems={"center"} fontWeight={"medium"} gap={0}>
          <IconButton
            isRound={true}
            variant={"outline"}
            size={"xs"}
            aria-label="Search database"
            icon={<Calendar size={16} />}
          />
          <RangeDatepicker
            configs={{
              dateFormat: "dd MMM",
            }}
            propsConfigs={{
              dayOfMonthBtnProps: {
                selectedBtnProps: {
                  colorScheme: "primary",
                  background: "primary.400",
                  color: "white",
                  rounded: "full",
                },
                isInRangeBtnProps: {
                  colorScheme: "primary",
                  background: "primary.400",
                  rounded: "full",
                  color: "white"
                },
              },
              inputProps: {
                rounded: "full",
                w: 120,
                p: 0,
                margin: 0,
                border: 0,
                outline: 0,
                textAlign: "end",
                cursor: "pointer",
                isReadOnly: true,
                _focusVisible: {
                  border: 0,
                },
              },
            }}
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
          />
        </Flex>
      }
    >
        <Drawer size={"full"} onClose={() => {
            setCurrentMeeting(null)
            onClose()
        }} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerBody>
                    {currentMeeting && <MeetingDetail onClose={onClose} id={currentMeeting} /> }
                </DrawerBody>
            </DrawerContent>
        </Drawer>
      {events.state === "loading" ? <Grid gap={8} p={[2, 4]} gridTemplateColumns={'repeat(4, 1fr)'}>
        <Skeleton w={330} h={390} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"} />
        <Skeleton w={330} h={390} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"} />
        <Skeleton w={330} h={390} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"} />
        <Skeleton w={330} h={390} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"} />
        <Skeleton w={330} h={390} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"} />
        <Skeleton w={330} h={390} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"} />
        <Skeleton w={330} h={390} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"} />
        <Skeleton w={330} h={390} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"} />
      </Grid> : <Grid gap={8} p={[2, 4]} gridTemplateColumns={'repeat(4, 1fr)'}>
        {events.data.map(event => {
            const organiser = event.attendees.filter(a => a.organizer)[0]
           return (
            <Stack key={event.uuid} overflow={"hidden"} w={330} flex={0.25} borderRadius={16} border={"1px solid #e5e5e5"}>
                <Box height={'240px'} pos={"relative"}>
                    <Flex gap={3} pos={"absolute"} top={4} right={3}>
                        <IconButton
                            isRound={true}
                            colorScheme='gray'
                            aria-label='Done'
                            fontSize='20px'
                            icon={<Heart />}
                            />
                        <IconButton
                            isRound={true}
                            colorScheme='gray'
                            aria-label='Done'
                            fontSize='20px'
                            icon={<MoreHorizontal />}
                            />
                    </Flex>
                    <Tag size={"sm"} bottom={0} right={2} pl={1} py={1} pos={"absolute"} variant='subtle' color={"white"} backgroundColor={"rgba(0, 0, 0, 0.8)"} borderRadius='full'>
                        <Tooltip cursor={"pointer"} label={organiser?.email}>
                            <Avatar
                                size='xs'
                                name={organiser?.displayName || _.capitalize(organiser?.email?.split("@")?.[0]?.split(".")?.join(""))}
                                mr={2}
                            />
                        </Tooltip>
                        <TagLabel>{organiser?.displayName || _.capitalize(organiser?.email?.split("@")?.[0]?.split(".")?.join(""))}</TagLabel>
                    </Tag>
                    <Image src="https://designstripe-secure.imgix.net/scene-snapshots/fe492186-b23c-4778-8720-a1208a5ffd98/1680130140820/default?auto=format&fit=clip&h=850&mark=%2Fwatermark.png&markfit=max&markalign=middle%2Ccenter&markw=1&markh=1&s=9d96bd343875438d0feba61ea9c01ce7" />
                </Box>
                <Flex gap={4} px={6} py={3}>
                    <Stack gap={1}>
                        <Heading>{moment.parseZone(event?.start?.date).format("DD")}</Heading>
                        <Text>{moment(event?.start?.date).format("MMM")}</Text>
                    </Stack>
                    <Divider orientation='vertical' />
                    <Stack w={"80%"}>
                        <Heading cursor={"pointer"} onClick={() => {
                            // navigate(`/home/meetings/${event.uuid}`)
                            setCurrentMeeting(event.uuid)
                            onOpen()
                        }} title={event.name} noOfLines={1} as='h4' size='md'>{event.name}</Heading>
                        <Text noOfLines={2} fontSize='sm'><Markdown>{event.summary || "No meeting summary available"}</Markdown></Text>
                        <AvatarGroup gap={2} mt={2} size='sm' max={3}>
                            {event.attendees.map(attendee => {
                                return (
                                    <TooltipAvatar cursor={"pointer"} name={`${attendee.email}`} />
                                )
                            })}
                        </AvatarGroup>
                    </Stack>
                </Flex>
                {/* <Box>
                    <ChevronRight />
                </Box> */}
            </Stack>
           )
        })}
      </Grid>}
      <Flex p={[2, 4]} w={"full"} justifyContent={"end"} alignItems={"center"} fontWeight={"medium"} gap={3}>
          <IconButton
            onClick={() => {
                if (page - 1 === 1) {
                    dispatch(getEvents(moment(selectedDates[0]).format("YYYY-MM-DD"), moment(selectedDates[1]).format("YYYY-MM-DD"), 1))
                }
                setPage(page - 1)
            }}
            isDisabled={page === 1}
            isRound={true}
            variant={"outline"}
            size={"xs"}
            aria-label="Search database"
            icon={<ChevronLeft />}
          />
          <Text>Showing {(page * 12) > events?.metadata?.total ? events?.metadata?.total : page * 12} of {events.metadata?.total || "-"}</Text>
          <IconButton
            onClick={() => {
                setPage(page + 1)
            }}
            isDisabled={(page * 12) > events?.metadata?.total}
            isRound={true}
            variant={"outline"}
            size={"xs"}
            aria-label="Search database"
            icon={<ChevronRight />}
          />
        </Flex>
    </Page>
  );
}

export default Meetings;
