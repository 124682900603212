/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  Route, Routes, useNavigate, useSearchParams
} from "react-router-dom";
import Login from './pages/auth/login';
import './app.scss';
import Shell from './components/layout/shell/shell';
import Onboarding from './pages/onboarding';
import FanDirectory from './pages/app/directory';
import List from './pages/app/list';
import MagicLink from './pages/auth/magic';
import Activity from './pages/app/activity';
import Accounts from './pages/app/accounts';
import Team from './pages/app/team';
import SSO from './pages/auth/sso';
import Notification from './pages/app/notifications';
import FanPublic from './pages/app/fan/public';
import Apps from './pages/app/apps';
import Connect from './pages/app/connect';
import { OnboardingV2 } from './pages/onboarding/v2';
import Recommendations from './pages/app/recommendations';
import mixpanel from 'mixpanel-browser';
import { CommunicationPreferences } from './pages/app/utils/preferences';
import Welcome from './pages/onboarding/welcome';
import Invite from './pages/auth/invite';
import Meetings from './pages/app/meetings';
import MeetingDetail from './pages/app/meetings/detail';
import Report from './pages/app/reports';
import { Toaster } from './components/ui/toaster';

mixpanel.init('139a7fe1ba607621389f8add1f261627');

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken'])
  const [params] = useSearchParams()
  const navigate = useNavigate();

  useEffect(() => {
    if (params.get("logout")) {
      removeCookie("accessToken", {
        path: '/'
      })
      removeCookie("accessToken", {
        path: '/'
      })
    }
  }, [params])

  useEffect(() => {
    const next = params.get('next')
    const redirectUrl = params.get("redirectUrl")
    const protocol = window.location.protocol
    const host = window.location.host.split(".");
    const subdomain = (host.slice(0, -2))[0];
    const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
    if (!cookies.accessToken && window.location.pathname !== "/auth/magic" && window.location.pathname !== "/authorize" && !window.location.pathname.startsWith("/communications/preferences") && !window.location.pathname.startsWith("/shared")) {
      if (!["auth", "connect"].includes(subdomain) || window.location.pathname !== "/login") {
        window.location.href = `${protocol}//auth.${domain}/login?next=${next || encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`
      }
    } else if (cookies.accessToken && window.location.pathname === "/") {
      navigate("/")
    }
  }, [ params ])

  return (
    <div className="fui">
      <Routes>
        <Route path='/shared/lists/:list/:mode/' element={<List />} />
        <Route path='/shared/:workspace/fans/:fan/' element={<FanPublic />} />
        <Route path='/login' element={<Login />} />
        <Route path='/auth/magic' element={<MagicLink />} />
        <Route path='/communications/preferences' element={<CommunicationPreferences />} />
        <Route path='/authorize' element={<SSO />} />
        <Route path='/onboarding' element={<Onboarding />} />
        <Route path='/get-started' element={<OnboardingV2 />} />
        <Route path='/communications/preferences' element={<CommunicationPreferences />} />
        <Route path='/shared/:workspace/fans/:fan/' element={<FanPublic />} />
        <Route path='/authorize' element={<SSO />} />
        <Route path='/auth/invite' element={<Invite />} />
        <Route path='/shared/lists/:list/:mode/' element={<List />} />
        <Route path='/configure-oauth' element={<Connect />} />
        <Route path='/onboarding/' element={<Onboarding />}>
        </Route>
        <Route path='/welcome/' element={<Welcome />} />
        <Route path='/home/' element={<Shell />}>
          <Route path='insights/' element={<Activity />} />
          <Route path='reports/:report' element={<Report />} />
          <Route path='apps/' element={<Apps />} />
          <Route path='meetings/' element={<Meetings />} />
          <Route path='meetings/:meeting/' element={<MeetingDetail />} />
          <Route path='fans/' element={<FanDirectory />} />
          <Route path='fans/:fan/' element={<FanDirectory />} />
          <Route path='notifications/' element={<Notification />} />
          <Route path='team/' element={<Team />} />
          <Route path='recommendations/' element={<Recommendations />} />
          <Route path='accounts/' element={<Accounts />} />
        </Route>
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
