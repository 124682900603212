import { LOAD_PUBLIC_FAN, LOAD_PUBLIC_FAN_ERROR, SET_ERROR, SET_LIST, SET_PUBLIC_FAN, SET_WORKSPACE } from "./constants";

const INITIAL_STATE = {
    isWorkspaceLoading: true,
    isListLoading: true,
    workspace: {},
    list: [],
    isFanLoading: false,
    fan: {},
    error: ''
}

const SharedListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                list: action.payload,
                isListLoading: false
            }
        case SET_WORKSPACE:
            return {
                ...state,
                workspace: action.payload,
                isWorkspaceLoading: false
            }
        case SET_ERROR:
            return {
                isListLoading: false,
                isWorkspaceLoading: false,
                error: action.payload
            }
        case LOAD_PUBLIC_FAN:
            return {
                ...state,
                isFanLoading: true
            }
        case SET_PUBLIC_FAN:
            return {
                ...state,
                isFanLoading: false,
                fan: action.payload
            }
        case LOAD_PUBLIC_FAN_ERROR:
            return {
                isFanLoading: false,
                error: action.error
            }
        default:
            return state;
    }
}

export default SharedListReducer