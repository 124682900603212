import { Avatar, Box, Button, ButtonGroup, Center, Collapse, Divider, Flex, Heading, Icon, IconButton, Image, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Spinner, Stack, Tag, TagLabel, TagLeftIcon, Td, Text, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, createRef, useState } from 'react';
import Xarrow from 'react-xarrows';
import { createFanDiscussion, deleteFanDiscussion, getFanDiscussions, getSharedFan, getSpecificFan, interactFanDiscussion, processAction } from '../../../redux/app/fans/thunk';
import './_fan.scss';
import Chart from 'react-apexcharts';
import { colors } from '../../../utils/color.constants';
import Table from '../../../components/core/@table';
import { evaluateTagColor } from '../../../utils/tag.constants';
import Editor from '../../../components/core/@editor';

import { Heart, RefreshCwIcon, Trash } from 'lucide-react';
import 'reactflow/dist/style.css';
import { cloneDeep, uniqueId } from 'lodash';
import { HubspotImage } from '../../../components/core/@icon';
import { useNavigate } from 'react-router-dom';
import { resetAction, setActionLoading } from '../../../redux/app/fans/actions';
import { axios } from '../../../loaders/axios.loader';

const CircleIcon = (props) => (
    <Icon viewBox='0 0 200 200' {...props}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
    </Icon>
  )


function FanDetail({ id, shared, wId }) {
    const workspace = useSelector(state => state.dashboard.core.workspace)
    const isLoading = useSelector(state => state.dashboard.fans.fan.isLoading)
    const fan = useSelector(state => state.dashboard.fans.fan.data)
    const error = useSelector(state => state.dashboard.fans.fan.error)
    const discussions = useSelector(state => state.dashboard.fans.discussions.data)
    const action = useSelector(state => state.dashboard.fans.action)
    const status = useSelector(state => state.dashboard.fans.status)
    const profile = useSelector(state => state.dashboard.core.profile)
    const connections = useSelector((state) => state.dashboard.apps.connections);
    const [mounted, setMounted] = useState(false)
    const dispatch = useDispatch()
    const [path, setPath] = useState([])
    const [currentPath, setCurrentPath] = useState({})
    const [show, setShow] = useState(false)
    const handleToggle = () => setShow(!show)
    const toast = useToast({ duration: 3000 })
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();
    const [currentDiscussion, setCurrentDiscussion] = useState();
    const [mutualInteractions, setMutualInteractions] = useState([]);
    const [searchResult, setSearchResult] = useState(null)
    const [hubConnection, setHubConnection] = useState(null)
    const [identifier, setIdentifier] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        if (id && !shared) {
            dispatch(getSpecificFan(workspace.uuid, id))
            dispatch(getFanDiscussions(workspace.uuid, id))
        } else if (id && shared && wId) {
            dispatch(getSharedFan(wId, id))
        }
    }, [ id, wId ]);

    useEffect(() => {
        if (action.state === "success") {
            onSearchClose()
            toast({
                title: "Contact synced successfully",
                description: "Your contact has been synced with hubspot successfully",
                status: "success"
            })
            dispatch(resetAction())
            dispatch(getSpecificFan(workspace.uuid, id))
        }
        if (action.state === "error") {
            toast({
                title: "Failed to sync contact",
                description: `Failed to sync contact. ${action.error}`,
                status: "error"
            })
            dispatch(resetAction())
        }
    }, [ action ])

    useEffect(() => {
        setIdentifier(null)
        setHubConnection(null)
        setSearchResult(null)
    }, [])
    

    useEffect(() => {
        if (!isLoading && !error.length) {
        setMounted(true)
        if (fan?.uuid) {
            let startIndex = -1;
            const _p = [];
            const interactions = []
            const experience = cloneDeep(fan.path).reverse()
            // const hasCustomerAccount = (experience.filter(e => ["customer", "fan"].includes(e.account?.type)).length > 0) && !shared
            const hasCustomerAccount = true;
            if (hasCustomerAccount) {
                for (const i in experience) {
                    const totalStartedOn = experience[i].positions?.slice(-1)?.[0]?.startedOn
                    const totalEndedOn = experience[i].positions?.[0]?.endedOn
                    const fanSince = fan?.fanSince
                    if (startIndex === -1 && moment.parseZone(totalStartedOn).toDate().getTime() <= moment.parseZone(fanSince).toDate().getTime() && moment.parseZone(fanSince).toDate().getTime()  <=  moment.parseZone(totalEndedOn).toDate().getTime()) {
                        startIndex = i
                        if (startIndex > 0) {
                            const positions = [...experience[i - 1].positions].reverse()
                            for (const position of positions) {
                                _p.push({
                                    account: experience[i - 1].account,
                                    timeline: experience[i - 1]._timeline,
                                    company: experience[i - 1].company,
                                    position: position,
                                    ref: createRef(null)
                                })
                            }
                        }
                    }
                    if (startIndex !== -1) {
                        const positions = [...experience[i].positions].reverse()
                        for (const position of positions) {
                            _p.push({
                                account: experience[i].account,
                                position: position,
                                company: experience[i].company,
                                timeline: experience[i]._timeline,
                                ref: createRef(null)
                            })
                        }
                    }
                }
            }
            if (!_p.length) {
                if (experience.length) {
                    const sl = experience.slice(-2)
                    for (const i in sl) {
                        const positions = [...sl[i].positions].reverse()
                        for (const position of positions) {
                            _p.push({
                                account: sl[i].account,
                                company: sl[i].company,
                                timeline: experience[i]._timeline,
                                position: position,
                                ref: createRef(null)
                            })
                        }
                    }
                }
            }
            setMutualInteractions(interactions)
            setCurrentPath(_p[_p.length - 1])
            setPath(_p)
            console.log(_p)
            }
        }
    }, [ isLoading ])

    const calculateFanSince = (start, end) => {
        if (fan && fan?.fanSince) {
            const startedOn = moment.parseZone(start)
            const endedOn = moment.parseZone(end || new Date())
            const fanSince = moment.parseZone(fan.fanSince)
            return fanSince >= startedOn && fanSince < endedOn
        }
        return false
    }

    return (
        <Box pb={12}>
            {isLoading ? <Center height={240}>
                <Spinner />
            </Center> : <div className="fui-fan">
            <Modal size={"xl"} isCentered onClose={() => {
                onClose()
                setCurrentDiscussion(null)
            }} isOpen={isOpen}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalHeader>Delete Discussion</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the thread ?
                    </ModalBody>

                    <ModalFooter>
                        <Button variant={"outline"} mr={3} size={"sm"} onClick={() => {
                            onClose()
                            setCurrentDiscussion(null)
                        }}>
                            Close
                        </Button>
                        <Button isLoading={status === "loading"} onClick={() => {
                            dispatch(deleteFanDiscussion(workspace.uuid, id, currentDiscussion, () => {
                                onClose()
                                setCurrentDiscussion(null)
                                toast({
                                    status: "success",
                                    title: "Thread deleted",
                                    description: "Your thread has been successfully deleted"
                                })
                            }))
                        }} colorScheme='red' size={"sm"} variant='solid'>Delete</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={"xl"} isCentered onClose={() => {
                setSearchResult(null)
                onSearchClose()
            }} isOpen={isSearchOpen}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalHeader>
                        <Flex>
                            <Text>Choose action</Text>
                            <><ModalCloseButton onClick={() =>  {
                                setSearchResult(null)
                                onSearchClose()}} /></>
                        </Flex>
                    </ModalHeader>
                    <ModalBody>
                        We found a <Link textDecor={"underline"} color={"#4F44E0"} target='_blank' href={`https://app.hubspot.com/contacts/${hubConnection?.identifier}/contact/${searchResult?.results?.[0]?.id}`}>contact</Link> that matches with current contact. <br/>Choose action to proceed
                    </ModalBody>
                    <ModalFooter>
                        <Button isLoading={action.state === "loading"} variant={"outline"} mr={3} size={"sm"} onClick={() => {
                             dispatch(processAction(workspace.uuid, hubConnection.uuid, fan.uuid, 'syncFanToContact', searchResult.results[0].id, undefined, true))
                        }}>
                            Link contact
                        </Button>
                        <Button isLoading={action.state === "loading"} onClick={() => {
                             dispatch(processAction(workspace.uuid, hubConnection.uuid, fan.uuid, 'syncFanToContact', undefined, searchResult.results[0].id))
                        }} colorScheme='primary' size={"sm"} variant='solid'>Create and Link</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <div className="fui-fan-actions">
            </div>
            <div className="fui-fan-header">
                <div className="fui-fan-background">
                    <Image fallbackSrc={`https://static.licdn.com/sc/h/55k1z8997gh8dwtihm11aajyq`} alt={fan?.fullName} src={!fan?.profile?.images ? fan?.profile?.image : fan.profile.cover} />
                </div>
                <Flex mx={4} justifyContent={"space-between"} direction={["column", "column", "row"]}>
                    <Box w={["full", "full", 0.7]} className="fui-fan-bio-details">
                        <div className="fui-fan-bio-image">
                            <Avatar outline={'6px solid #ffffff'} boxSize={24} name={fan.fullName} src={!fan?.profile?.images ? fan.profile.cover : fan.profile.image} />
                        </div>
                        <Box mt={2} className="fui-fan-bio-title">
                            <Heading size={"md"}>{fan.fullName}</Heading>
                        </Box>
                        <Box  mt={0.5} color={"gray"} className="fui-fan-bio-subtitle">
                            <Text fontSize={"sm"}>{fan.profile?.headline}</Text>
                        </Box>
                        <Box mt={1} className="fui-fan-bio-subtitle">
                            <i style={{
                                marginRight: 4
                            }} className="fi fi-rr-marker"></i> 
                            <Text color="gray.700" fontSize={"sm"}>{fan.profile?.location?.name || ''}</Text>
                            <CircleIcon style={{
                                marginTop: 4,
                                color: 'gray',
                                marginLeft: 4,
                                marginRight: 4
                            }} color="gray.700" boxSize={1} />
                            <Text color="gray.700" fontSize={"sm"}>{fan.profile?.country?.name || ''}</Text>
                        </Box>
                    </Box>
                    <Stack mt={[4, 4, 6]} gap={[0, 0, 0]} alignItems={["flex-start", null, "flex-end"]}>
                    <ButtonGroup size={"sm"} gap='1'>
                        <ButtonGroup size='sm' isAttached={identifier ? true : false} variant='outline'>
                            <Button isLoading={action.state === "loading"} onClick={async () => {
                                const hasHubSpotConnection = connections.data.filter(connection => connection.provider.provider === "hubspot")
                                if (hasHubSpotConnection.length) {
                                    const connectionIdentifier = fan.identifiers.filter(identifier => identifier.connection === hasHubSpotConnection[0]._id)
                                    if (connectionIdentifier.length) {
                                        window.open(`https://app.hubspot.com/contacts/${hubConnection?.identifier}/contact/${identifier}`, "_blank")
                                    } else {
                                        try {
                                            dispatch(setActionLoading())
                                            const request = await axios.get(`/workspaces/${workspace.uuid}/apps/connections/${hasHubSpotConnection[0].uuid}/hubspot/contacts/search?fan=${fan.uuid}`)
                                            const { response } = request.data;
                                            if (response?.total) {
                                                // dispatch(processAction(workspace.uuid, hasHubSpotConnection[0].uuid, fan.uuid, 'syncFanToContact'))
                                                setSearchResult(response)
                                                onSearchOpen()
                                                dispatch(resetAction())
                                            } else {
                                                dispatch(processAction(workspace.uuid, hasHubSpotConnection[0].uuid, fan.uuid, 'syncFanToContact'))
                                            }
                                        } catch (e) {
                                            dispatch(processAction(workspace.uuid, hasHubSpotConnection[0].uuid, fan.uuid, 'syncFanToContact'))
                                        }
                                    }
                                } else {
                                    navigate("/home/apps")
                                }
                            }} leftIcon={<HubspotImage />} variant={"outline"}>{!connections.data.filter(connection => connection.provider.provider === "hubspot").length ? "Connect Hubspot" : (() => {
                                const hasHubSpotConnection = connections.data.filter(connection => connection.provider.provider === "hubspot")
                                if (hasHubSpotConnection.length) {
                                    if (!hubConnection) {
                                        setHubConnection(hasHubSpotConnection[0])
                                    }
                                    const connectionIdentifier = fan.identifiers.filter(identifier => identifier.connection === hasHubSpotConnection[0]._id)
                                    if (connectionIdentifier.length) {
                                        if (!identifier) {
                                            setIdentifier(connectionIdentifier[0].identifier)
                                        }
                                        return connectionIdentifier[0].identifier
                                    }
                                }
                                return "Push to Hubspot"
                            })()}</Button>
                            <IconButton isLoading={action.state === "loading"} display={identifier ? "flex" : "none"} aria-label='Sync fan' onClick={async () => {
                                const hasHubSpotConnection = connections.data.filter(connection => connection.provider.provider === "hubspot")
                                if (hasHubSpotConnection.length) {
                                    const connectionIdentifier = fan.identifiers.filter(identifier => identifier.connection === hasHubSpotConnection[0]._id)
                                    if (connectionIdentifier.length) {
                                        dispatch(processAction(workspace.uuid, hasHubSpotConnection[0].uuid, fan.uuid, 'syncFanToContact'))
                                    } else {
                                        try {
                                            dispatch(setActionLoading())
                                            const request = await axios.get(`/workspaces/${workspace.uuid}/apps/connections/${hasHubSpotConnection[0].uuid}/hubspot/contacts/search?fan=${fan.uuid}`)
                                            const { response } = request.data;
                                            if (response?.total) {
                                                // dispatch(processAction(workspace.uuid, hasHubSpotConnection[0].uuid, fan.uuid, 'syncFanToContact'))
                                                setSearchResult(response)
                                                onSearchOpen()
                                                dispatch(resetAction())
                                            } else {
                                                dispatch(processAction(workspace.uuid, hasHubSpotConnection[0].uuid, fan.uuid, 'syncFanToContact'))
                                            }
                                        } catch (e) {
                                            dispatch(processAction(workspace.uuid, hasHubSpotConnection[0].uuid, fan.uuid, 'syncFanToContact'))
                                        }
                                    }
                                } else {
                                    navigate("/home/apps")
                                }
                            }} icon={<RefreshCwIcon size={16} />} />
                        </ButtonGroup>
                        <Button onClick={() => {
                            console.log(`https://app.fanbaseai.com/shared/${workspace?.uuid}/fans/${fan?.uuid}`)
                            window?.navigator?.clipboard?.writeText(`https://app.fanbaseai.com/shared/${workspace?.uuid}/fans/${fan?.uuid}`)
                            toast({ title: "Link copied" })
                        }} colorScheme='primary' variant={"solid"} leftIcon={<ExternalLinkIcon boxSize={3.5} />}>Share fan</Button>
                        {/* <Button leftIcon={<i className="fi fi-rr-refresh"></i>} variant={"outline"}>Sync to salesforce</Button> */}
                    </ButtonGroup>
                    <Flex gap={2} mt={4}>
                        {/* <Tooltip label={
                            <Stack>
                                <Text><i className="fi fi-br-check review-tooltip" /> G2 Reviewer</Text>
                                <Text><i className="fi fi-br-check review-tooltip" /> Revenue Maverick</Text>
                                <Text><i className="fi fi-br-check review-tooltip" /> LinkedIn Top Interactor</Text>
                            </Stack>
                        }> */}

                    {fan.type === "potential" ? <Tooltip label={`${fan.firstName} is your potential fan based on existing fan network of ${workspace.name}`}><Tag cursor={"pointer"} fontWeight={"semibold"} colorScheme="twitter">
                            <i className='fi fi-sr-heart-half-stroke fui-fan-star'></i>
                            <TagLabel>Potential Fan</TagLabel>
                        </Tag></Tooltip>: <></>}
                            <Popover trigger="hover">
                                <PopoverTrigger>
                                    <Tag cursor={"pointer"} fontWeight={"semibold"} colorScheme='green'>
                                        <i className='fi fi-sr-star fui-fan-star'></i>
                                        <TagLabel>{fan.score || 1}/5</TagLabel>
                                    </Tag>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                        <ul>
                                            <li>{fan.firstName} is a {fan?.path?.[0]?.positions?.[0].name}</li>
                                            {fan.fanSince ? <li>Has been our customer since {moment.parseZone(fan?.fanSince).fromNow()}</li> : <></>}
                                            {fan.events.length ? <li> Has had multiple meetings ({fan.events.length > 50 ? `50+` : `${fan.events.length}`}) with our team after becoming customer </li>: <></>}
                                            {fan.summary ? <li>{fan.summary}</li> : <></>}
                                        </ul>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        {/* </Tooltip> */}
                        {/* {path.filter(p => p.account.lists.filter(l => l.type === "customer").length).length && <Tag fontWeight={"semibold"} colorScheme='purple'>
                            <TagLabel>{path.filter(p => p.account.lists.filter(l => l.type === "customer").length).length}x fan</TagLabel>
                        </Tag>} */}
                    </Flex>
                    {fan?.fanSince ? <Tag mt={3} cursor={"pointer"} fontWeight={"semibold"} colorScheme='purple'>
                        <i className='fi fi-sr-heart fui-fan-star'></i>
                        <Tooltip label={`${fan?.firstName} is fan since ${moment.parseZone(fan.fanSince).format("DD MMM, yy")}`}>
                        <TagLabel>Fan since {moment.parseZone(fan.fanSince).fromNow(true)}</TagLabel>
                        </Tooltip>
                    </Tag> : <></>}
                    </Stack>
                </Flex>
                <Button as={Link} href={`https://www.linkedin.com/in/${fan?.profile?.linkedIn || fan?.profile?.salesReference?.replace("urn:li:fsd_profile:", "")}/`} target='_blank' ml={4} mt={3} variant={"outline"} colorScheme="primary" size={"sm"} leftIcon={<i className="fi fi-br-arrow-up-right fui-fan-star"></i>}>View Linkedin</Button>
            </div>

            {mutualInteractions.length ? <>
            <Divider mt={4} /><Stack mt={3} p={4}>
                <Text mb={3} fontWeight={"semibold"} fontSize={"md"}>Mutual Connections</Text>
                {mutualInteractions.map((e, i, a) => {
                    const rId = uniqueId()
                    return <><Flex direction={["column", null, "row"]} gap={[24, 16, 2]} justifyContent={"space-between"}>
                    <Stack  alignItems={"center"} flex={0.35} gap={0}>
                            <Avatar name={e.target?.profile?.fullName} src={e.target?.profile?.profile?.image} />
                            <Text>{e.target?.profile?.fullName}</Text>
                            <Text fontSize={"sm"} color={"gray"} noOfLines={1}>{e.target?.profile?.profile?.headline}</Text>
                            <Divider my={2} />
                             <Stack>  {/*py={2} border={"1px solid #cacaca"} borderRadius={4} */}
                                <Flex pr={2} id={`target-${rId}`} alignItems={"center"} gap={2}>
                                    <Avatar size={"sm"} src={e.target?.account?.company?.image} />
                                    <Box>
                                        <Text maxW={"240px"}>{e.target?.account?.name}</Text>
                                        <Text color={"gray"} noOfLines={1} fontSize={"sm"}>{e.source?.positions?.[0]?.name}</Text>
                                        <Text color={"gray"} fontSize={"sm"}>{moment.parseZone(e.source?.positions?.slice(-1)?.[0]?.startedOn).format("MMM yyyy")} - {moment.parseZone(e.source?.positions?.[0]?.endedOn).format("MMM yyyy")}</Text>
                                    </Box>
                                </Flex>
                            </Stack>
                        </Stack>
                        <Stack backgroundColor={"white"} alignItems={"center"} flex={0.35} gap={0}>
                            <Avatar name={fan?.fullName} src={fan?.profile?.cover} />
                            <Text>{fan?.fullName}</Text>
                            <Text fontSize={"sm"} color={"gray"} noOfLines={1}>{fan?.profile?.headline}</Text>
                            <Divider my={2} />
                            <Stack>
                                <Flex pl={2} id={`source-${rId}`} alignItems={"center"} gap={2}>
                                    <Avatar size={"sm"} src={e.target?.account?.company?.image} />
                                    <Box>
                                        <Text maxW={"240px"}>{e.target?.account?.name}</Text>
                                        <Text color={"gray"} noOfLines={1} fontSize={"sm"}>{e.target?.positions?.[0]?.name}</Text>
                                        <Text color={"gray"} fontSize={"sm"}>{moment.parseZone(e.target?.positions?.slice(-1)?.[0]?.startedOn).format("MMM yyyy")} - {moment.parseZone(e.target?.positions?.[0]?.endedOn).format("MMM yyyy")}</Text>
                                    </Box>
                                </Flex>
                            </Stack>
                        </Stack>
                        <Xarrow
                            zIndex={-1}
                            path="grid"
                            color='#363636'
                            dashness={{ strokeLen: 6, nonStrokeLen: 4 }}
                            labels={{
                                middle: <Tag marginTop={['-64px', '-64px', 0]} py={2} colorScheme="red">Past Working<br/> Relationship</Tag>
                            }}
                            tailSize={2}
                            strokeWidth={1.1}
                            start={`target-${rId}`}
                            end={`source-${rId}`}
                        />
                    </Flex>
                   {i < (a.length - 1) ? <Divider my={4} /> : <></>}</>
                    }
                )}
            </Stack></> : <></>}
            <Divider mt={4} />
            <Stack p={4}>
                <Text mb={3} fontWeight={"semibold"} fontSize={"md"}>Experiences</Text>
                <Flex pr={8} w={["full", "full", path.length <= 2 ? 0.7 : "full"]} flexWrap={"wrap"} gap={[8, null, 0]} position={"relative"} alignItems={"center"} mt={2} justifyContent={["center", null, "space-between"]}>
                    {path.filter((p, i, a) => {
                        const disallowedRoles = []
                        let isRoleAllowed = disallowedRoles.every(r => !p?.position?.name?.toLowerCase().includes(r))
                        return isRoleAllowed && ((p?.position?.endedOn || ["customer", "fan"].includes(p?.account?.type)) || i === a.length - 1)
                    }).map((p, i, a) => {
                        if (p?.position?.endedOn || ["customer", "fan"].includes(p?.account?.type) || i === a.length - 1) {
                                let start;
                                let end;
                                if (i < a.length - 1) {
                                    start = a[i]?.position?._id
                                    end = a[i + 1]?.position?._id
                                }
                                return (<>
                                <Stack maxW={["auto", null, 240]} pos={"relative"} w={["full", null, "auto"]} mt={[0, 0, i > 2 ? 10 : 0]} alignItems={"center"} id={p.position?._id}>
                                    <Avatar name={p?.account?.company?.name || p?.company?.name} icon={<Avatar src={`https://d1ejypfl65ma19.cloudfront.net/companies/images/${p?.account?.company?.uuid}.png`} title={p?.account?.company?.name} name={p?.account?.company?.name} />}  border={'1px solid #cacaca'} pos={"relative"} src={p?.account?.company?.image}>
                                    {p.position?._timeline?.length ? <Tag w={"min-content"} whiteSpace={"nowrap"} minW={"min-content"} textTransform={"none"} rounded={"full"} textAlign={"right"} fontWeight={"medium"} pos={"absolute"} zIndex={1} top={-4} left={"100%"} borderBottomLeftRadius={2} colorScheme={evaluateTagColor(p.position._timeline?.[0]?.status?.toLowerCase())}>{p.position?._timeline?.[0].status}</Tag> : <></>}
                                    </Avatar>
                                    <Box textAlign={"center"}>
                                        {!p?.account?.company?.linkedIn ? <Text maxW={240} fontSize={"sm"}>{p?.account?.company?.name || p?.company?.name}{p.position?._timeline?.length > 1 ? <Box ml={1} verticalAlign={"text-bottom"} display={"inline-block"}><Tooltip hasArrow placement='right' label={(p.position?._timeline || [])?.slice(1).filter(t => t.status === "Customer").length ? `${p.account?.name} was also a customer during this time` : `Other status of the account during this time: ${(p.position?._timeline || []).slice(1).map(t => t.status).join(", ")}`}><InfoIcon boxSize={3} /></Tooltip></Box> : <></>}</Text> : <Link maxW={240} fontSize={"sm"} target="_blank" color={"var(--chakra-colors-primary-500)"} textDecor={"underline"} href={`https://www.linkedin.com/company/${p?.account?.company?.reference?.replace("urn:li:fsd_company:", "")}`} isExternal={true}>{p?.account?.company?.name || p?.company?.name}</Link> }
                                        <Text noOfLines={2} w={["full", null, '200px']} fontSize={"sm"}>{p?.position?.name}</Text>
                                        <Flex textColor={"gray"} gap={2} alignItems={"center"} justifyContent={"center"}>
                                            <Text fontSize={"sm"}>{moment.parseZone(p?.position?.startedOn).format("MMM YYYY")}</Text>
                                            <Text mt={-1}>-</Text>
                                            <Text fontSize={"sm"}>{p?.position?.endedOn ? moment.parseZone(p?.position?.endedOn).format("MMM YYYY") : 'Current'}</Text>
                                        </Flex>
                                        {/* TODO: Check below fan since */}
                                        {calculateFanSince(p?.position?.startedOn, p?.position?.endedOn) ? <Tooltip label={`${fan.firstName} was working at ${p?.account?.company?.name || p?.company?.name} when he/she became your fan`}>
                                            <Tag variant={"subtle"} colorScheme="red" mt={2} size={"sm"}>Fan from here</Tag>
                                        </Tooltip> : <></>}
                                    </Box>
                                </Stack>
                                {start && end && <Xarrow
                                    path="grid"
                                    startAnchor={{
                                        position: (i + 1) % 3 === 0 ? "bottom" : "auto",
                                        offset: {
                                            y: (i + 1) % 3 === 0 ? 10 : -10
                                        }
                                    }}
                                    endAnchor={{
                                        position: (i + 1) % 3 === 0 ? "top" : "auto",
                                        offset: {
                                            y: (i + 1) % 3 === 0 ? 0 : -10
                                        }
                                    }}
                                    color='#bfbfbf'
                                    dashness={{ strokeLen: 6, nonStrokeLen: 4 }}
                                    tailSize={2}
                                    strokeWidth={1.1}
                                    start={start}
                                    end={end}
                                />}
                                </>)
                        }
                        return <></>
                    })}
                </Flex>
            </Stack>
            {currentPath?.account?.company?.headcount ? <Stack mb={4} p={4}>
                <Text mb={3} fontWeight={"semibold"} fontSize={"md"}>About {currentPath?.account?.company?.name}</Text>
                <Flex alignItems={"center"}>
                    <Box>
                        <Avatar size={"lg"} borderRadius={4} src={currentPath?.account?.company?.image} icon={<Image borderRadius={"50%"} src={`https://d1ejypfl65ma19.cloudfront.net/companies/images/${currentPath?.account?.company?.uuid}.png`} />} name={currentPath?.account?.company?.name || currentPath?.company?.name} />
                    </Box>
                    <Box ml={2}>
                        <Link isExternal href={`https://linkedin.com/company/${currentPath?.account?.company?.linkedIn}`}>{currentPath?.account?.company?.name} <i style={{ color: 'var(--chakra-colors-linkedin-600)' }} className='fi fi-brands-linkedin'></i></Link>
                        {currentPath?.account?.company?.headcount ? <Text fontSize={"sm"}>{currentPath?.account?.company?.headcount} employees{currentPath?.account?.company?.distribution?.filter(d => d.name === "Sales")[0]?.count ? `, ${currentPath?.account?.company?.distribution?.filter(d => d.name === "Sales")[0]?.count} Sales Reps` : ''}</Text> : <></> }
                        <Text color={"gray"} fontSize={"sm"}>{currentPath?.account?.company?.headquarter?.city}, {currentPath?.account?.company?.headquarter?.country}</Text>
                    </Box>
                </Flex>
                {/* <Text fontSize={"sm"} color={"#757575"} >{currentPath?.account?.company?.tagline}</Text> */}
               {currentPath?.account?.company?.description ? <><Collapse startingHeight={46} in={show}>
                <Text fontSize={"sm"}>{!show ? `${currentPath?.account?.company?.description?.slice(0, 210)}...`: currentPath?.account?.company?.description}</Text>
                </Collapse>
                <Button colorScheme="primary" variant={"link"} w={"fit-content"} display={"inline"} size='sm' onClick={handleToggle}>
                    Show {show ? 'less' : 'more'}
                </Button>
                <Divider mt={4} /></> : <></>}
            </Stack> : <></>}
            {currentPath?.account?.company?.distribution?.length ? <Stack pb={4} px={4}>
                <Text mb={3} fontWeight={"semibold"} fontSize={"md"}>Headcount & Distribution</Text>
                {currentPath?.account?.company?.distribution?.length && <Flex direction={['column', null, "row"]} mt={2} justifyContent={"space-between"}>
                    <Chart
                    options={{
                        chart: {
                            fontFamily: "Plus Jakarta Sans",
                        },
                        colors: colors,
                        plotOptions: {
                            pie: {
                                donut: {
                                    size: '50%'
                                }
                            },
                        },
                        labels: currentPath.account?.company?.distribution?.map(f => f.name)?.sort((a, b) => a.count - b.count)?.slice(0, 5),
                        legend: {
                            position: 'bottom'
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: '100%'
                                }
                            }
                        }]
                    }}
                    series={currentPath?.account?.company?.distribution?.map(f => f.count)?.slice(0, 5)}
                    type="donut"
                    height={300}
                />
                <Table tableStyle={{
                    marginTop: 0,
                    flex: 1
                }} headers={[
                    "name",
                    "headcount"
                ]} disableActions={true} disablePagination={true}>
                    {currentPath?.account?.company?.distribution?.slice(0, 5)?.map(f => <Tr>
                        <Td>{f.name}</Td>
                        <Td>{f.count}</Td>
                    </Tr>)}
                </Table>
                </Flex>}
            </Stack> : <></> }
        </div>}
       {!isLoading && !shared ? <Stack p={4}>
                <Text mb={3} fontWeight={"semibold"} fontSize={"md"}>Comments</Text>
                <Flex gap={2}>
                <Avatar size={"sm"} src={profile?.image} name={profile?.fullName} />
                <Editor isLoading={status === "loading"} onClick={(text, mentions) => {
                    dispatch(createFanDiscussion(workspace.uuid, id, text, mentions))
                }} />
                </Flex>
            </Stack>: <></> }
            <Divider mb={3} />
            <Stack gap={0} p={4}>
            {discussions.map((d, i) => {
                return (
                    <Flex pb={6} position={"relative"} gap={2}>
                        {i < (discussions.length - 1) ? <Divider left={3.5} height={"100%"} position={"absolute"} borderColor={"#cacaca"} orientation='vertical' /> : <></>}
                        <Avatar name={(d?.type|| []).includes("notification") ? "Fanbase Bot" : d?.createdBy?.fullName} size={"sm"} src={(d?.type|| []).includes("notification") ? '/logo512.png' : d?.createdBy?.image} />
                        <Stack flex={1} gap={2}>
                            <Flex justifyContent={"space-between"}>
                                <Text fontWeight={"semibold"}>{(d?.type|| []).includes("notification") ? "FanBot" : d?.createdBy?.fullName}</Text>
                                <Text fontSize={"sm"}>{moment.parseZone(d.createdAt).fromNow()}</Text>
                            </Flex>
                            <Text dangerouslySetInnerHTML={{__html: d?.text}}></Text>
                            <Flex mt={2}>
                                <Tag onClick={() => {
                                    dispatch(interactFanDiscussion(workspace.uuid, id, d.uuid, !d.likes.filter(u => profile._id).length))
                                }} cursor={"pointer"} variant={d.likes.filter(u => u === profile._id).length ? "solid" : "outline"} colorScheme={"red"}>
                                    <TagLeftIcon as={Heart} />
                                    <TagLabel>{d.likes.filter(u => u === profile._id).length ? "Liked" : "Like"}</TagLabel>
                                </Tag>
                                {profile.uuid === d?.createdBy.uuid ? <Tag onClick={() => {
                                    onOpen()
                                    setCurrentDiscussion(d.uuid)
                                }} cursor={"pointer"} variant={"outline"} colorScheme="black">
                                    <TagLeftIcon as={Trash} />
                                    <TagLabel>{"Delete"}</TagLabel>
                                </Tag> : <></>}
                            </Flex>
                            <Divider mt={3} />
                        </Stack>
                    </Flex>
                )
            })}
            </Stack>
            
        </Box> 
    );
}

export default FanDetail