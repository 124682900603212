export const SET_KPIS_LOADING = "home/kpis/loading"
export const SET_KPIS_SUCCESS = "home/kpis/success"
export const SET_KPIS_ERROR = "home/kpis/error"
export const SET_JOB_CHANGE_LOADING = "home/job_changes/loading"
export const SET_JOB_CHANGE_SUCCESS = "home/job_changes/success"
export const SET_JOB_CHANGE_ERROR = "home/job_changes/error"
export const SET_JOB_PROMOTIONS_LOADING = "home/job_promotions/loading"
export const SET_JOB_PROMOTIONS_SUCCESS = "home/job_promotions/success"
export const SET_JOB_PROMOTIONS_ERROR = "home/job_promotions/error"
export const SET_MEETINGS_LOADING = "home/meetings/loading"
export const SET_MEETINGS_SUCCESS = "home/meetings/success"
export const SET_MEETINGS_ERROR = "home/meetings/error"
export const SET_TOP_PROSPECTS_LOADING = "home/top_prospects/loading"
export const SET_TOP_PROSPECTS_SUCCESS = "home/top_prospects/success"
export const SET_TOP_PROSPECTS_ERROR = "home/top_prospects/error"
export const SET_SPECIFIC_REPORT_LOADING = "home/reports/get/loading"
export const SET_SPECIFIC_REPORT_SUCCESS = "home/reports/get/success"
export const SET_SPECIFIC_REPORT_ERROR = "home/reports/get/error"