import { axios } from "../../../loaders/axios.loader";
import { setLists, setListsLoading, setOptions, setOptionsLoading, setProfile, setProfileLoading, setWorkspace, setWorkspaces } from "./actions";

export const loadWorkspaces = () => async (dispatch, getState) => {
    try {
        const workspaces = await axios.get(
            '/workspaces/'
        )
        const { response } = workspaces.data;
        dispatch(setWorkspaces(response))
    } catch (err) {
        console.log(err)
    }
};

export const loadWorkspace = (workspace) => async (dispatch, getState) => {
    try {
        const workspaces = await axios.get(
            `/workspaces/${workspace}`
        )
        const { response } = workspaces.data;
        dispatch(setWorkspace(response))
    } catch (err) {
        console.log(err)
    }
};

export const loadProfile = () => async (dispatch, getState) => {
    dispatch(setProfileLoading())
    try {
        const profile = await axios.get(
            `/accounts/profile`
        )
        const { response } = profile.data;
        dispatch(setProfile(response))
    } catch (err) {
        console.log(err)
    }
};

export const searchCompanies = (workspace, keyword) => async (dispatch) => {
    dispatch(setOptionsLoading())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/accounts/search?keyword=${keyword}`
        )
        const { response } = request.data
        dispatch(setOptions(response))
    } catch (err) {
        console.log(err)
    }
}

export const searchRoles = (workspace, keyword) => async (dispatch) => {
    dispatch(setOptionsLoading())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/roles/search?keyword=${keyword}`
        )
        const { response } = request.data
        dispatch(setOptions(response))
    } catch (err) {
        console.log(err)
    }
}

export const getLists = (workspace) => async (dispatch) => {
    dispatch(setListsLoading())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/lists/`
        )
        const { response } = request.data
        dispatch(setLists(response))
    } catch (err) {
        console.log(err)
    }
}