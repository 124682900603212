import { LOAD_MY_NOTIFICATIONS, LOAD_NOTIFICATIONS, LOAD_PLATFORM_NOTIFICATIONS, SET_MY_NOTIFICATIONS, SET_MY_NOTIFICATIONS_ERROR, SET_NOTIFICATIONS, SET_NOTIFICATIONS_ERROR, SET_PLATFORM_NOTIFICATIONS } from "./constants"

export const setPlatformNotifications = (payload) => {
    return {
        type: SET_PLATFORM_NOTIFICATIONS,
        payload
    }
}

export const setNotifications = (payload) => {
    return {
        type: SET_NOTIFICATIONS,
        payload
    }
}

export const setNotificationsError = (error) => {
    return {
        type: SET_NOTIFICATIONS_ERROR,
        error
    }
}

export const loadNotifications = () => {
    return {
        type: LOAD_NOTIFICATIONS
    }
}

export const setMyNotifications = (payload) => {
    return {
        type: SET_MY_NOTIFICATIONS,
        payload
    }
}

export const setMyNotificationsError = (error) => {
    return {
        type: SET_MY_NOTIFICATIONS_ERROR,
        error
    }
}

export const loadMyNotifications = () => {
    return {
        type: LOAD_MY_NOTIFICATIONS
    }
}

export const loadPlatformNotifications = () => {
    return {
        type: LOAD_PLATFORM_NOTIFICATIONS
    }
}