'use client'

import * as React from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

import { ny } from '../../lib/utils'

const Avatar = React.forwardRef(({ className, ...props }, ref) => (
   <AvatarPrimitive.Root
      ref={ref}
      className={ny('relative flex size-10 shrink-0 overflow-hidden rounded-3', className)}
      {...props} />
))
Avatar.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef(({ className, ...props }, ref) => (
   <AvatarPrimitive.Image ref={ref} className={ny('aspect-square size-full', className)} {...props} />
))
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef(({ className, ...props }, ref) => (
   <AvatarPrimitive.Fallback
      ref={ref}
      className={ny(
         'bg-muted flex size-full items-center justify-center rounded-full',
         className
      )}
      {...props} />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export { Avatar, AvatarImage, AvatarFallback }
