import { Box, Flex, Text } from "@chakra-ui/react";

function Page({ title, subtitle, actions, tabs, children, noHeader }) {
    return (
        <>
        <Box>
         {!noHeader ? <Flex justifyContent={"space-between"} alignItems={"center"} p={2} px={5}>
            <Box>
              <Text fontWeight={"bold"} fontSize={"lg"}>{title}</Text>
              <Text fontSize={"sm"} color='gray' mt={-1}>{subtitle}</Text>
            </Box>
            <Flex gap={3} alignItems={"center"}>
              {actions}
            </Flex>
          </Flex> : <></>}
        </Box>
        <Box className="fui-shell-container">
          {children}
        </Box>
        </>
    )
}

export default Page;