import { Box, Center, Spinner, Stack, Text } from "@chakra-ui/react"
import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { initiateConnection } from "../../../redux/app/connect/thunk"
import { useSearchParams } from "react-router-dom"
import { loadWorkspace } from "../../../redux/app/core/thunk"
import { CheckCheckIcon, CheckCircle2Icon, CheckCircleIcon, CrossIcon, CrosshairIcon, XCircleIcon } from "lucide-react"

function Connect () {
    const space = useSelector(state => state.dashboard.core.workspace)
    const profile = useSelector(state => state.dashboard.core.profile)
    const connection = useSelector(state => state.dashboard.connect.connection)
    const [cookies, setCookie, removeCookie] = useCookies(["connectionParams"]);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        loadWorkspace()
    }, [])

    useEffect(() => {
        if (cookies.connectionParams && !space.uuid) {
            const { workspace } = cookies.connectionParams;
            if (workspace) {
                dispatch(loadWorkspace(workspace))
            }
        }
        if (cookies.connectionParams && space.uuid && connection.state === "loading") {
            const { redirectUrl, app, name, startUrl } = cookies.connectionParams;
            dispatch(initiateConnection(space.uuid, app, searchParams.get("code"), redirectUrl, name))
        }
    }, [cookies, space, searchParams])

    useEffect(() => {
        if (connection.state !== "loading" && cookies.connectionParams) {
            removeCookie("connectionParams")
            setTimeout(() => {
                window.location.href = `${cookies.connectionParams.startUrl}?status=${connection.state}`
            }, 1000)
        }
    }, [connection])

    return (
       <Center height={"100vh"}>
            {connection.state === "loading" ? <Stack gap={2} alignItems={"center"}>
                <Spinner size={"lg"} />
                <Text>Please wait while we are connecting your account</Text>
            </Stack> : connection.state === "success" ? <Stack gap={2} alignItems={"center"}>
                <CheckCircle2Icon size={48} color="#33BA78" />
                <Text>App connected succsessfully. Please wait while we redirect</Text>
            </Stack> : <Stack gap={2} alignItems={"center"}>
                <XCircleIcon size={48} color="#ce3535" />
                <Text color="#ce3535">{connection.error || "Something went wrong. Please contact the administrator"}</Text>
            </Stack>}
       </Center>
    )
}

export default Connect