import { axios } from "../../../loaders/axios.loader";
import { loadWorkspaceFans, setActionError, setActionLoading, setActionSuccess, setCreateFanError, setCreateFanLoading, setCreateFanSuccess, setDisucssionCreateStatus, setFan, setFanAttribute, setFanDiscussions, setFanDiscussionsLoading, setFanLoading, setWorkspaceFans } from "./actions";

export const getWorkspaceFans = (workspace, page=1) => async (dispatch, getState) => {
    dispatch(loadWorkspaceFans())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/fans/?page=${page}&size=10`
        )
        const { response } = request.data;
        dispatch(setWorkspaceFans(response))
    } catch (err) {
        console.log(err)
    }
}

export const getSpecificFan = (workspace, fan) => async (dispatch, getState) => {
    dispatch(setFanLoading())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/fans/${fan}/`
        )
        const { response } = request.data;
        dispatch(setFan(response))
    } catch (err) {
        console.log(err)
    }
}

export const getSharedFan = (workspace, fan) => async (dispatch, getState) => {
    dispatch(setFanLoading())
    try {
        const request = await axios.get(
            `/shared/${workspace}/fans/${fan}/`
        )
        const { response } = request.data;
        dispatch(setFan(response))
    } catch (err) {
        console.log(err)
    }
}

export const searchFans = (
  workspace, 
  filters, 
  page = 1, 
  sort = [], 
  signal, 
  append = false,
  searchParams = new URLSearchParams()
) => async (dispatch, getState) => {
  dispatch(loadWorkspaceFans(append));
  try {
    let f = '';
    if (filters.keyword) {
      f += `&keyword=${encodeURIComponent(filters.keyword)}`;
    }
    if (filters.currentCompany?.length || searchParams.get('currentCompany')) {
      const currentCompanies = filters.currentCompany?.length ? filters.currentCompany : searchParams.get('currentCompany').split(',');
      f += `&${currentCompanies.map(company => `currentCompany[]=${encodeURIComponent(company)}`).join('&')}`;
    }
    if (filters.pastCompany?.length || searchParams.get('pastCompany')) {
      const pastCompanies = filters.pastCompany?.length ? filters.pastCompany : searchParams.get('pastCompany').split(',');
      f += `&${pastCompanies.map(company => `pastCompany[]=${encodeURIComponent(company)}`).join('&')}`;
    }
    if (filters.location?.length || searchParams.get('location')) {
      f += `&location[]=${searchParams.get('location').split(",").join("&location[]=")}`;
    }
    if (filters.role?.length || searchParams.get('role')) {
      const roles = filters.role?.length ? filters.role : searchParams.get('role').split(',');
      f += `&${roles.map(role => `role[]=${encodeURIComponent(role)}`).join('&')}`;
    }
    if (filters.score || searchParams.get('score')) {
      f += `&score=${encodeURIComponent(filters.score || searchParams.get('score'))}`;
    }
    if (filters.list || searchParams.get('list')) {
      f += `&list=${encodeURIComponent(filters.list || searchParams.get('list'))}`;
    }
    if (filters.type || searchParams.get('type')) {
      f += `&type=${decodeURIComponent(filters.type || searchParams.get('type'))}`
    }
    const headcount = searchParams.get('headcount') || filters.headcount;
    if (headcount) {
      f += `&headcount[]=${headcount.split('-').map(h => encodeURIComponent(h)).join('&headcount[]=')}`;
    }
    if (filters.salesHeadcount || searchParams.get('salesHeadcount')) {
      f += `&salesHeadcount=${(filters.salesHeadcount || searchParams.get('salesHeadcount')).split('-').map(h => encodeURIComponent(h)).join('&salesHeadcount[]=')}`;
    }
    if (sort?.length) {
      f += `&sort=${encodeURIComponent(sort[0])},${encodeURIComponent(sort[1])}`;
    }
    if (filters.duration || searchParams.get('duration')) {
      f += `&duration=${encodeURIComponent(filters.duration || searchParams.get('duration'))}`;
    }
    if (filters.attendees || searchParams.get('attendees')) {
        f += `&attendees[]=${searchParams.get('attendees').split(",").join("&attendees[]=")}`
    }
    const pageParam = searchParams.get('page');
    const currentPage = pageParam ? parseInt(pageParam) : page;

    const request = await axios.get(
      `/workspaces/${workspace}/fans/search/filters?page=${currentPage}&size=25${f}`,
      { signal }
    );
    const { response, total } = request.data;
    dispatch(setWorkspaceFans(response, total, append));
  } catch (err) {
    console.log(err);
  }
};


export const downloadFans = (workspace, filters, page = 1, sort = [], signal, searchParams = new Map(), onCallback) => async (dispatch, getState) => {
    try {
        let f = '';
        if (filters.keyword) {
            f += `&keyword=${filters.keyword}`
        }
        if (filters.currentCompany?.length || searchParams.get('currentCompany')) {
            const currentCompanies = filters.currentCompany?.length ? filters.currentCompany : searchParams.get('currentCompany').split(',');
            f += `&${currentCompanies.map(company => `currentCompany[]=${encodeURIComponent(company)}`).join('&')}`;
        }
        if (filters.pastCompany?.length || searchParams.get('pastCompany')) {
            const pastCompanies = filters.pastCompany?.length ? filters.pastCompany : searchParams.get('pastCompany').split(',');
            f += `&${pastCompanies.map(company => `pastCompany[]=${encodeURIComponent(company)}`).join('&')}`;
        }
        if (filters.location?.length || searchParams.get('location')) {
            f += `&location[]=${searchParams.get('location').split(",").join("&location[]=")}`
        }
        if (filters.role?.length) {
            f += `&role[]=${(filters.role.join("&role[]="))}`
        }
        if (filters.score || searchParams.get('score')) {
            f += `&score=${decodeURIComponent(filters.score || searchParams.get('score'))}`
        }
        if (filters.list || searchParams.get('list')) {
            f += `&list=${filters.list || searchParams.get('list')}`
        }
        if (filters.type || searchParams.get('type')) {
            f += `&type=${decodeURIComponent(filters.type || searchParams.get('type'))}`
        }
        if (filters.headcount?.length) {
            f += `&headCount[]=${(filters.headcount.split("-").join("&headCount[]="))}`
        }
        if (filters.salesHeadcount?.length || searchParams.get('salesHeadcount')) {
            f += `&salesHeadcount[]=${(decodeURIComponent(filters.salesHeadcount || searchParams.get('salesHeadcount')).split("-").join("&salesHeadcount[]="))}`
        }
        if ((sort || []).length) {
            f += `&sort=(${sort[0]}:${sort[1]})`
        }
        if (filters.duration || searchParams.get('duration')) {
            f += `&duration=${decodeURIComponent(filters.duration || searchParams.get('duration'))}`
        }
        if (filters.attendees || searchParams.get('attendees')) {
            f += `&attendees[]=${searchParams.get('attendees').split(",").join("&attendees[]=")}`
        }
        const response = await axios.get(
            `/workspaces/${workspace}/fans/search/filters/download?page=${page}&size=10${f}`,
            {
                signal
            }
        )
        const blob = new Blob([response.data], {
            type: "text/csv"
        })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a') 
        a.setAttribute('href', url)
        console.log(a, url)
        a.setAttribute('download', "fans.csv");
        a.click()
        onCallback?.()
    } catch (err) {
        console.log(err)
    }
}

export const sharedFanSearch = (workspace, filters, page = 1, sort = []) => async (dispatch, getState) => {
    dispatch(loadWorkspaceFans())
    try {
        let f = '';
        if (filters.keyword) {
            f += `&keyword=${filters.keyword}`
        }
        if (filters.currentCompany?.length) {
            f += `&currentCompany[]=${(filters.currentCompany.join("&currentCompany[]="))}`
        }
        if (filters.pastCompany?.length) {
            f += `&pastCompany[]=${(filters.pastCompany.join("&currentCompany[]="))}`
        }
        if (filters.role?.length) {
            f += `&role[]=${(filters.role.join("&role[]="))}`
        }
        if (filters.score) {
            f += `&score=${filters.score}`
        }
        if (filters.list) {
            f += `&list=${filters.list}`
        }
        if (filters.type) {
            f += `&type=${filters.type}`
        }
        if (filters.headcount?.length) {
            f += `&headCount[]=${(filters.headcount.split("-").join("&headCount[]="))}`
        }
        if (filters.salesHeadcount?.length) {
            f += `&salesHeadcount[]=${(filters.salesHeadcount.split("-").join("&salesHeadcount[]="))}`
        }
        if ((sort || []).length) {
            f += `&sort=(${sort[0]}:${sort[1]})`
        }
        const request = await axios.get(
            `/shared/${workspace}/fans/search?page=${page}&size=10${f}`
        )
        const { response, total } = request.data
        dispatch(setWorkspaceFans(response, total))
    } catch (err) {
        console.log(err)
    }
}

export const getFanDiscussions = (workspace, fan) => async (dispatch) => {
    dispatch(setFanDiscussionsLoading())
    try {
        const request = await axios.get(
            `/workspaces/${workspace}/fans/${fan}/discussions/`
        )
        const { response } = request.data;
        dispatch(setFanDiscussions(response))
    } catch (err) {
        console.log(err)
    }
}

export const createFanDiscussion = (workspace, fan, text, mentions = []) => async (dispatch, getState) => {
    dispatch(setDisucssionCreateStatus("loading"))
    try {
        const request = await axios.post(
            `/workspaces/${workspace}/fans/${fan}/discussions/`,
            {
                text,
                mentions
            }
        )
        const { response } = request.data;
        dispatch(setDisucssionCreateStatus("success"))
        dispatch(getFanDiscussions(workspace, fan))
        const summary = await axios.post(
            `/workspaces/${workspace}/fans/${fan}/summarize`
        )
        dispatch(setFanAttribute("summary", summary.data.response))
    } catch (err) {
        console.log(err)
    }
}

export const deleteFanDiscussion = (workspace, fan, discussion, onDone) => async (dispatch, getState) => {
    dispatch(setDisucssionCreateStatus("loading"))
    try {
        const request = await axios.delete(
            `/workspaces/${workspace}/fans/${fan}/discussions/${discussion}`)
        dispatch(setDisucssionCreateStatus("success"))
        onDone?.()
        dispatch(getFanDiscussions(workspace, fan))
    } catch (err) {
        console.log(err)
    }
}

export const interactFanDiscussion = (workspace, fan, discussion, isLiked) => async (dispatch, getState) => {
    try {
        const request = await axios.patch(
            `/workspaces/${workspace}/fans/${fan}/discussions/${discussion}`, {
                liked: isLiked
            })
        dispatch(getFanDiscussions(workspace, fan))
    } catch (err) {
        console.log(err)
    }
}

export const createFan = (workspace, url, fanSince) => async (dispatch, getState) => {
    dispatch(setCreateFanLoading())
    try {
        const request = await axios.post(
            `/workspaces/${workspace}/fans/sync`, {
                url,
                ...(fanSince ? { fanSince: fanSince.toISOString() } : {})
            })
        dispatch(setCreateFanSuccess(request.data.response))
    } catch (err) {
        console.log(err)
        dispatch(setCreateFanError(err?.response?.data?.message || ''))
    }
}

export const processAction = (workspace, connection, fan, action, contact, associationContact, link = false) => async (dispatch) => {
    dispatch(setActionLoading())
    try {
        const request = await axios.post(
            `/workspaces/${workspace}/apps/connections/${connection}/hubspot/contacts/sync?fan=${fan}&action=${action}${associationContact ? `&associationContact=${associationContact}` : ''}${link ? `&link=true` : ''}${contact ? `&contact=${contact}` : ''}`
        )
        dispatch(setActionSuccess(request.data))
    } catch (err) {
        console.log(err)
        dispatch(setActionError(err?.response?.data?.message || err.message))
    }
}