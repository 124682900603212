import { RESET_FAN_CONVERT, SET_CALENDAR_RECOMMENDATIONS_ERROR, SET_CALENDAR_RECOMMENDATIONS_LOADING, SET_CALENDAR_RECOMMENDATIONS_SUCCESS, SET_FAN_CONVERT_ERROR, SET_FAN_CONVERT_LOADING, SET_FAN_CONVERT_SUCCESS } from "./constants"

const INITIAL_STATE = {
    calendar: {
        error: '',
        data: [],
        state: "loading"
    },
    convert: {
        state: "pending",
        error: '',
        data: {}
    }
}

const FanRecommendationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CALENDAR_RECOMMENDATIONS_LOADING:
            return {
                ...state,
                calendar: {
                    state: "loading",
                    error: '',
                    data: (state.calendar.data)
                }
            }
        case SET_CALENDAR_RECOMMENDATIONS_SUCCESS:
            return {
                ...state,
                calendar: {
                    state: "success",
                    data: action.replace ? action.payload : [...(state.calendar.data || []), ...(action.payload.filter(a => state.calendar.data.every(d => d.uuid !== a.uuid)))],
                    error: ''
                }
            }
        case SET_CALENDAR_RECOMMENDATIONS_ERROR:
            return {
                ...state,
                calendar: {
                    state: "error",
                    data: [],
                    error: action.error
                }
            }
        case SET_FAN_CONVERT_LOADING:
            return {
                ...state,
                convert: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_FAN_CONVERT_SUCCESS:
            return {
                ...state,
                convert: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_FAN_CONVERT_ERROR:
            return {
                ...state,
                convert: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        case RESET_FAN_CONVERT:
            return {
                ...state,
                convert: {
                    state: "pending",
                    data: {},
                    error: ''
                }
            }
        default:
            return state
    }
}

export default FanRecommendationsReducer