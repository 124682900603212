/**
 * your actions will go here
 */

import { LIKE_CHAT, LOAD_SESSION, REPORT_CHAT, RESET_CURRENT_MESSAGE, RESET_MESSAGES, SET_CHAT_ERROR, SET_CHAT_SESSIONS, SET_CURRENT_MESSAGE, SET_MESSAGES, SET_SESSION, UNLIKE_CHAT } from "./constants"

 export function addMessage (message) {
     return {
         "type": "ADD_MESSAGE",
         "message": message
     }
 }

export function setLoading (state) {
    return {
        "type": "LOAD_MESSAGE",
        state
    }
}

export function setError(message) {
    return {
        type: SET_CHAT_ERROR,
        payload: message
    }
}

export function resetMessages(message) {
    return {
        type: RESET_MESSAGES,
        payload: message
    }
}

export function setMessages(message) {
    return {
        type: SET_MESSAGES,
        payload: message
    }
}

export function setSession (payload) {
    return {
        type: SET_SESSION,
        payload
    }
}

export function setHistory (payload) {
    return {
        type: SET_CHAT_SESSIONS,
        payload
    }
}

export function dispatchLoadSession () {
    return {
        type: LOAD_SESSION
    }
}

export function dispachLikeChat (index) {
    return {
        type: LIKE_CHAT,
        payload: index
    }
}

export function dispatchUnlikeChat(index) {
    return {
        type: UNLIKE_CHAT,
        payload: index
    }
}

export function dispatchReportChat(index, reason) {
    return {
        type: REPORT_CHAT,
        payload: {
            index,
            reason
        }
    }
}

export function setCurrentMessage (payload) {
    return {
        type: SET_CURRENT_MESSAGE,
        payload
    }
}

export function resetCurrentMessage () {
    return {
        type: RESET_CURRENT_MESSAGE
    }
}