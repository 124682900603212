import * as React from 'react'
import { cva } from 'class-variance-authority';

import { ny } from '../../lib/utils'

const badgeVariants = cva(
   'inline-flex items-center rounded-full border px-2.5 cursor-pointer py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
   {
      variants: {
         variant: {
            default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
            secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
            destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
          "warning-subtle":
        'border-transparent bg-yellow-500/30 hover:bg-yellow-500/40',
        "default-subtle":
      'border-transparent bg-primary text-white hover:bg-blue-500/40',
            outline: 'text-foreground',
            transparent: 'border-transparent px-0',
         },
      },
      defaultVariants: {
         variant: 'default',
      },
   }
)

function Badge({
   className,
   variant,
   ...props
}) {
   return (<div className={ny(badgeVariants({ variant }), className)} {...props} />);
}

export { Badge, badgeVariants }
