import { Center, Spinner, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { loadWorkspace, loadWorkspaces } from "../../redux/app/core/thunk";
import { axios } from "../../loaders/axios.loader";

const setExpiryTime = (days = 30) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return new Date(date);
};

export default function SSO() {
    const workspaces = useSelector(state => state.dashboard.core.workspaces)
    const isWorkspacesLoading = useSelector(state => state.dashboard.core.isLoading)
    const workspace = useSelector(state => state.dashboard.core.workspace)
    const profile = useSelector(state => state.dashboard.core.profile)
    const [cookies, setCookie] = useCookies(['accessToken']);
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const [code, setCode] = useState()

    useEffect(() => {
        if (!params.get("code")) {
            window.location.href = "/login"
        } else {
            setCode(params.get("code"))
            axios.defaults.headers["Authorization"] = `Bearer ${params.get("code")}`
            dispatch(loadWorkspaces())
        }
    }, [])

    useEffect(() => {
        if (workspaces.length && !params.get("redirectUrl")) {
            dispatch(loadWorkspace(workspaces[0].workspace.uuid))
        } else if ((!isWorkspacesLoading && !workspaces.length) || params.get("redirectUrl")) {
            const protocol = window.location.protocol
            const host = window.location.host.split(".");
            const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
            const redirectUrl = `${protocol}//app.${domain}`
            setCookie("accessToken", code, {
                path: "/",
                domain: `.${domain.split(":")[0]}`,
                expires: setExpiryTime(30)
            })
            setTimeout(() => {
                window.location.href =  `${redirectUrl}${params.get("redirectUrl") || "/welcome"}`
            }, 2000)
        }
    }, [ workspaces, isWorkspacesLoading ])

    useEffect(() => {
        if (!workspace.isLoading && !workspace.error) {
            const protocol = window.location.protocol
            const host = window.location.host.split(".");
            const domain = host.slice(host.length > 2 ? 1 : 0).join(".")
            const redirectUrl = `${protocol}//${workspace.handle}.${domain}`
            setCookie("accessToken", code, {
                path: "/",
                domain: `.${domain.split(":")[0]}`,
                expires: setExpiryTime(30)
            })
            setTimeout(() => {
                window.location.href = `${redirectUrl}/home/fans/`
            }, 2000)
        }
    }, [ workspace ])

    return (
        <Center h={"100vh"}>
            <Stack align={"center"}>
                <Spinner />
                <Text >logging you in</Text>
            </Stack>
        </Center>
    )
}