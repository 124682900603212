import { RESET_INVITATION, SET_INVITATION_ERROR, SET_INVITATION_LOADING, SET_INVITATION_SUCCESS, SET_REMOVE_INVITATION_ERROR, SET_REMOVE_INVITATION_LOADING, SET_REMOVE_INVITATION_SUCCESS, SET_TEAM_ERROR, SET_TEAM_LOADING, SET_TEAM_SUCCESS } from "./constants"

const INITIAL_STATE = {
    invitation: {
        isLoading: false
    },
    isLoading: true,
    revoke: {
        isLoading: false
    },
    data: [],
    error: ''
}

const TeamReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TEAM_LOADING:
            return {
                ...state,
                isLoading: true,
                data: []
            }
        case SET_TEAM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                error: ''
            }
        case SET_TEAM_ERROR:
            return {
                ...state,
                isLoading: false,
                error: ''
            }
        case SET_INVITATION_LOADING:
            return {
                ...state,
                invitation: {
                    isLoading: true,
                    error: '',
                    data: {}
                }
            }
        case RESET_INVITATION:
            return {
                ...state,
                invitation: {
                    isLoading: false,
                    error: '',
                    data: {}
                },
                revoke: {
                    isLoading: false,
                    error: '',
                    data: {}
                }
            }
        case SET_INVITATION_SUCCESS:
            return {
                ...state,
                invitation: {
                    ...state.invitation,
                    isLoading: false,
                    data: action.payload
                }
            }
        case SET_INVITATION_ERROR:
            return {
                ...state,
                invitation: {
                    isLoading: false,
                    data: {},
                    error: action.error?.data?.message
                }
            }
        case SET_REMOVE_INVITATION_LOADING:
            return {
                ...state,
                revoke: {
                    ...state.revoke,
                    isLoading: true,
                    data: action.payload
                }
            }
        case SET_REMOVE_INVITATION_SUCCESS:
            return {
                ...state,
                revoke: {
                    isLoading: false,
                    data: action.payload
                }
            }
        case SET_REMOVE_INVITATION_ERROR:
            return {
                ...state,
                revoke: {
                    isLoading: false,
                    data: {},
                    error: action.error?.data?.message
                }
            }
        default:
            return state
    }
}

export default TeamReducer