import { axios } from "../../../loaders/axios.loader"
import { loadMyNotifications, loadNotifications, loadPlatformNotifications, setMyNotifications, setNotifications, setPlatformNotifications } from "./actions"

export const getNotifications = (workspace, platform = false, page = 1, size = 10) => async (dispatch) => {
    if (platform) {
        dispatch(loadPlatformNotifications())
    } else {
        dispatch(loadNotifications())
    }
    try {
        const notifications = await axios.get(
            `/workspaces/${workspace}/notifications?page=${page}&size=${size}${platform ? `&read=false` : ''}`
        )
        const { response } = notifications.data;
        if (platform) {
            dispatch(setPlatformNotifications(response))
        } else {
            dispatch(setNotifications(response))
        }
    } catch (err) {

    }
}

export const getUserNotifications = (workspace, platform = false, page = 1, size = 10) => async (dispatch) => {
    dispatch(loadMyNotifications())
    try {
        const notifications = await axios.get(
            `/workspaces/${workspace}/notifications/me?page=${page}&size=${size}${platform ? `&read=false` : ''}`
        )
        const { response } = notifications.data;
        dispatch(setMyNotifications(response))
    } catch (err) {

    }
}