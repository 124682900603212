/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  useDisclosure,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  Box,
  Link,
  Tag,
  TagLabel,
  Divider,
  MenuItem,
  Center,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  TagCloseButton,
  Card,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
} from "@chakra-ui/react";
import Page from "../../../components/core/@page";
import { CloseIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  createFan,
  searchFans,
  sharedFanSearch,
} from "../../../redux/app/fans/thunk";
import FanDetail from "../fan";
import SearchableMenu from "../../../components/core/@searchable-menu";
import { searchCompanies, searchRoles } from "../../../redux/app/core/thunk";
import Dropzone from "react-dropzone";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import _ from "lodash";
import moment from "moment-timezone";
import { resetFan, setCreateFanStatus } from "../../../redux/app/fans/actions";
import { AArrowUpIcon, ArrowDownAZIcon, AudioLinesIcon, BuildingIcon, CopyIcon, ExternalLink, LinkedinIcon, LinkIcon, PlusIcon, RefreshCcwIcon, SearchIcon, SlidersHorizontalIcon, SparkleIcon, SparklesIcon, SquareActivityIcon, ZapIcon } from "lucide-react";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { Avatar, AvatarFallback, AvatarImage } from "../../../components/ui/avatar";
import { generateFallbackText } from "../../../utils/utils";
import { generateHSL } from "../../../lib/utils";
import { Button } from "../../../components/ui/button";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../../../components/ui/hover-card";
import { Badge } from "../../../components/ui/badge";
import { Separator } from "../../../components/ui/separator";
import { Input } from "../../../components/ui/input";
import { Field, Formik } from "formik";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import ShinyButton from "../../../components/ui/shiny-button";
import { useToast } from "../../../components/ui/use-toast";
import { Skeleton } from "../../../components/ui/skeleton";
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "../../../components/ui/pagination";

function FanDirectory(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const ctx = useOutletContext();
  const chatOpen = ctx?.chatOpen;
  const workspace = useSelector((state) => state.dashboard.core.workspace);
  const lists = useSelector((state) => state.dashboard.core.lists);
  const isLoading = useSelector((state) => state.dashboard.fans.isLoading);
  const hasMoreData = useSelector((state) => state.dashboard.fans.hasMoreData);
  const fans = useSelector((state) => state.dashboard.fans.data);
  const total = useSelector((state) => state.dashboard.fans.total);
  const profile = useSelector((state) => state.dashboard.core.profile);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { onClose: onFanAddClose, onOpen: onFanAddOpen, isOpen: isFanAddOpen } = useDisclosure();
  const params = useParams();
  const {
    onClose: onFanModalClose,
    onOpen: onFanModalOpen,
    isOpen: isFanModalOpen,
  } = useDisclosure();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFan, setCurrentFan] = useState(null);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { toast } = useToast();
  const [isDownloading, setDownloading] = useState(false);
  const [filterState, setFilterState] = useState({
    currentCompany: [],
    pastCompany: [],
    role: [],
    list: props?.list,
    headcount: undefined,
    salesHeadcount: undefined,
    duration: undefined,
    type: undefined,
    score: undefined,
  });
  const create = useSelector((state) => state.dashboard.fans.create)
  const formRef = useRef(null);
  const [date, setDate] = useState(undefined);
  useEffect(() => {
    if (create.status === "success") {
        toast({
            status: "success",
            title: "Fan created successfully"
        })
        onFanAddClose()
        navigate(`/home/fans/${create.data.uuid}`)
        dispatch(setCreateFanStatus("pending"))
    } else if (create.status === "error") {
        toast({
            status: "error",
            title: "Fan creation failed"
        })
    }
}, [create])

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    let updatedFilters = { ...filterState };

    if (params.currentCompany) {
      updatedFilters.currentCompany = params.currentCompany.split(',').map(name => ({ name }));
    }
    if (params.pastCompany) {
      updatedFilters.pastCompany = params.pastCompany.split(',').map(name => ({ name }));
    }
    if (params.role) {
      updatedFilters.role = params.role.split(',').map(name => ({ name }));
    }
    if (params.list) {
      updatedFilters.list = params.list;
    }
    if (params.headcount) {
      updatedFilters.headcount = { id: params.headcount, name: params.headcount };
    }
    if (params.salesHeadcount) {
      updatedFilters.salesHeadcount = { id: params.salesHeadcount, name: params.salesHeadcount };
    }
    if (params.duration) {
      updatedFilters.duration = { id: params.duration, name: params.duration };
    }
    if (params.type) {
      updatedFilters.type = { id: params.type, name: params.type };
    }
    if (params.score) {
      updatedFilters.score = { id: params.score, name: params.score };
    }

    setFilterState(updatedFilters);

    let filterCount = 0;
    if (updatedFilters.currentCompany.length) filterCount++;
    if (updatedFilters.pastCompany.length) filterCount++;
    if (updatedFilters.role.length) filterCount++;
    if (updatedFilters.list) filterCount++;
    if (updatedFilters.headcount) filterCount++;
    if (updatedFilters.salesHeadcount) filterCount++;
    if (updatedFilters.duration) filterCount++;
    if (updatedFilters.type) filterCount++;
    if (updatedFilters.score) filterCount++;
    setFilterCount(filterCount);

  }, [searchParams]);

  const [tabs, setTabs] = useState([
    {
      name: "all fans",
    },
  ]);
  const [target, setTarget] = useState({
    name: null,
    order: null,
  });
  const [sort, setSort] = useState(null);
  const [filterCount, setFilterCount] = useState(0);
  const [filters, setFilters] = useState({
    ...(props?.list ? { list: props?.list } : {}),
  });
  const {
    isOpen: isImportOpen,
    onOpen: onImportOpen,
    onClose: onImportClose,
  } = useDisclosure();

  useEffect(() => {
    // dispatch(searchFans(workspace.uuid, {}, currentPage))
    if (params["fan"] && params["fan"]?.length) {
      setCurrentFan(params["fan"]);
      onFanModalOpen();
    }
  }, [params]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    setCurrentPage(filters.append ? currentPage + 1 : 1);
    if (workspace?.uuid && hasMoreData) {
      dispatch(
        searchFans(workspace.uuid, filters, filters.append ? currentPage + 1 : 1, undefined, signal, filters.append || false, search)
      );
    }
    if (props.shared && props.list) {
      dispatch(
        sharedFanSearch(
          props.workspace,
          {
            ...filters,
            list: props?.list,
          },
          1
        )
      );
    }
    return () => {
      abortController.abort();
    };
  }, [filters, props.list, search]);

  useEffect(() => {
    setTabs(
      lists.data
        .filter((d) => !["accounts", "stale"].includes(d.type))
        .map((d) => ({
          id: d._id,
          name: d.name,
        }))
    );
  }, [lists]);

  const parseTarget = (t) => {
    switch (t) {
      case null:
        return 1;
      case 1:
        return -1;
      case -1:
        return null;
      default:
        return null;
    }
  };

  const applyFilters = () => {
    let c = 0;
    if (filterState.currentCompany.length) {
      c += 1;
    }
    if (filterState.pastCompany.length) {
      c += 1;
    }
    if (filterState.score) {
      c += 1;
    }
    if (filterState.role.length) {
      c += 1;
    }
    if (filterState.type) {
      c += 1;
    }
    if (filterState.list) {
      c += 1;
    }
    if (filterState.headcount) {
      c += 1;
    }
    if (filterState.salesHeadcount) {
      c += 1;
    }
    if (filterState.duration) {
      c += 1;
    }
    setFilterCount(c);
    setFilters({
      ...filters,
      keyword: "",
      list: filterState?.list,
      currentCompany: filterState.currentCompany.map((c) => c.name),
      pastCompany: filterState.pastCompany.map((c) => c.name),
      type: filterState.type?.id,
      score: filterState.score?.id,
      role: filterState.role.map((r) => r.name),
      headcount: filterState.headcount?.id,
      salesHeadcount: filterState.salesHeadcount?.id,
      duration: filterState.duration?.id,
      append: false
    });

    const params = new URLSearchParams();
    if (filterState.currentCompany.length) {
      params.append(
        "currentCompany",
        filterState.currentCompany.map((c) => c.name).join(",")
      );
    }
    if (filterState.pastCompany.length) {
      params.append(
        "pastCompany",
        filterState.pastCompany.map((c) => c.name).join(",")
      );
    }
    if (filterState.role.length) {
      params.append("role", filterState.role.map((r) => r.name).join(","));
    }
    if (filterState.score) {
      params.append("score", filterState.score.id);
    }
    if (filterState.list) {
      params.append("list", filterState.list);
    }
    if (filterState.headcount) {
      params.append("headcount", filterState.headcount.id);
    }
    if (filterState.salesHeadcount) {
      params.append("salesHeadcount", filterState.salesHeadcount.id);
    }
    if (filterState.duration) {
      params.append("duration", filterState.duration.id);
    }
    if (filterState.type) {
      params.append("type", filterState.type.id);
    }

    navigate(`/home/fans?${params.toString()}`);
    onClose();
  };

  const clearFilters = () => {
    setFilterCount(0);
    setFilters({});
    setCurrentPage(1);
    setSort(null);
    setTarget({
      name: null,
      order: null,
    });
    setFilterState({
      currentCompany: [],
      pastCompany: [],
      role: [],
      list: undefined,
      headcount: undefined,
      salesHeadcount: undefined,
      duration: undefined,
      type: undefined,
    });

    navigate(`/home/fans`);
    onClose();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(search);
    params.set("page", page);
    navigate(`/home/fans?${params.toString()}`);
    if (workspace?.uuid) {
      dispatch(searchFans(workspace.uuid, filters, page, sort, null, filters.append || false, search));
    } else if (props.shared && props.list) {
      dispatch(
        sharedFanSearch(
          props.workspace,
          {
            list: props?.list,
          },
          page,
          sort
        )
      );
    }
  };

  // useEffect(() => {
  //   const currentParams = new URLSearchParams(search);
  //   const page = parseInt(currentParams.get("page")) || 1;
  //   setCurrentPage(page);
  //   if (workspace?.uuid) {
  //     dispatch(searchFans(workspace.uuid, filters, page, sort, null, filters.append || false, search));
  //   } else if (props.shared && props.list) {
  //     dispatch(
  //       sharedFanSearch(
  //         props.workspace,
  //         {
  //           list: props?.list,
  //         },
  //         page,
  //         sort
  //       )
  //     );
  //   }
  // }, [search]);

  return (
    <Page
      noHeader={props.readOnly}
      title={"Fans"}
      actions={[
        <ShinyButton onClick={() => {
          chatOpen?.()
        }} text="Ask FanGPT" />,
        <Button rounded={"full"} onClick={() => {
          onFanAddOpen?.();
        }} size={"xs"} className={"rounded-lg px-3"} colorScheme="primary"><PlusIcon size={16} /> <Text ml={1} fontWeight={600}>Add Fan</Text></Button>
        ]
      }
      subtitle={"Manage all your fans"}
    >
      <Modal size={"xl"} isCentered onClose={onFanAddClose} isOpen={isFanAddOpen}>
          <ModalOverlay></ModalOverlay>
          <Formik
              initialValues={{
                  url: "",
                  fanSince: undefined
              }}
              onSubmit={(values) => {
                  dispatch(
                      createFan(workspace?.uuid, values.url, date)
                  )
              }}
          >
              {({ handleSubmit, errors, touched }) => (
              <form
              ref={formRef}
              onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
              }}
              >
          <ModalContent>
              <ModalHeader>Add fan</ModalHeader>
              <ModalBody>
              <Stack gap={3}>
              <FormControl
                size={"sm"}
                isInvalid={!!errors.url && touched.url}
              >
                <FormLabel fontSize={"sm"} htmlFor="url">
                  Linkedin URL
                </FormLabel>
                <Field
                  size={"sm"}
                  as={Input}
                  id="url"
                  name="url"
                  variant="filled"
                  validate={(value) => {
                    let error;
                    if (!value.length || !value?.startsWith("https://") || !value.includes("linkedin.com")) {
                      error = "Enter valid URL";
                    }
                    return error;
                  }}
                />
                <FormErrorMessage>{errors.url}</FormErrorMessage>
              </FormControl>
              <FormControl
                size={"sm"}
              >
                <FormLabel fontSize={"sm"} htmlFor="fanSince">
                  When did the user become your fan ?
                </FormLabel>
                <Field
                  size={"sm"}
                  render={() => 
                      <SingleDatepicker
                          configs={{
                              dateFormat: 'MM-dd-yyyy'
                          }}
                          name="fanSince"
                          date={date}
                          onDateChange={setDate}
                      />}
                  id="fanSince"
                  name="fanSince"
                  variant="filled"
                />
                <FormErrorMessage>{errors.fanSince}</FormErrorMessage>
              </FormControl>
              </Stack>
              </ModalBody>
              <ModalFooter gap={2}>
                  <Button variant={"outline"} mr={3} size={"sm"} onClick={onFanAddClose}>
                      Close
                  </Button>
                  <Button variant={"default"} isLoading={create.status === "loading"} type="submit" colorScheme='primary' size={"sm"}>Create</Button>
              </ModalFooter>
          </ModalContent>
          </form> )}
          </Formik>
      </Modal>
      <Modal isOpen={isImportOpen} onClose={onImportClose}>
        <ModalOverlay></ModalOverlay>
        <ModalContent>
          <ModalHeader
            fontSize={"md"}
            borderBottom={"1px solid #cacaca"}
            pb={2}
          >
            import fans
          </ModalHeader>
          <ModalBody pt={0} mt={4}>
            <Text color={"gray"}>
              download the sample sheet{" "}
              <Link color={"blue"} textDecoration={"underline"} isExternal>
                here
              </Link>
            </Text>
            <Dropzone
              acceptedFiles={".csv"}
              onDrop={(acceptedFiles) => console.log(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <Box
                    cursor={"pointer"}
                    h={120}
                    mt={4}
                    px={4}
                    py={2}
                    border={"1px dashed #cacaca"}
                    borderRadius={4}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <Center>
                      <Text color={"gray"} fontSize={"sm"}>
                        Drag 'n' drop some files here, or click to select files
                      </Text>
                    </Center>
                  </Box>
                </section>
              )}
            </Dropzone>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={"outline"}
              mr={3}
              onClick={() => {
                onImportClose();
              }}
            >
              close
            </Button>
            <Button
              onClick={() => {
                onImportClose();
              }}
              colorScheme="primary"
              variant="solid"
            >
              import
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer
      blockScrollOnMount={false}

        size={"xl"}
        isCentered
        onClose={() => {
          setCurrentFan(null);
          onFanModalClose();
          if (!props.shared) {
            navigate(`/home/fans?${search.toString()}`, {
              replace: true,
            });
          }
        }}
        isOpen={isFanModalOpen}
      >
        <DrawerOverlay />
        <DrawerContent maxW={840} overflow={"scroll"}>
          <IconButton
            pos={"absolute"}
            size={"sm"}
            rounded={"full"}
            right={2}
            top={2}
            icon={<CloseIcon />}
            onClick={() => {
              setCurrentFan(null);
              onFanModalClose();
              dispatch(resetFan());
              if (!props.shared) {
                navigate(`/home/fans?${search.toString()}`, {
                  replace: true,
                });
              }
            }}
          />
          {currentFan && (
            <FanDetail
              shared={props.shared}
              wId={props.workspace}
              id={currentFan}
            />
          )}
        </DrawerContent>
      </Drawer>
      <Drawer size={"lg"} isCentered onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay></DrawerOverlay>
        <DrawerContent>
          <DrawerHeader
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px solid #cacaca"}
            pb={2}
          >
            <Text>Apply Filters</Text>
            <IconButton
              size={"sm"}
              onClick={onClose}
              rounded={"full"}
              icon={<CloseIcon />}
            />
          </DrawerHeader>
          <DrawerBody mt={4}>
            <Stack>
              <Flex direction={["column", "column", "row"]} gap={2}>
                <Box flex={0.5}>
                  <Text>Highlights</Text>
                  <Flex gap={2} mt={2}>
                    {filterState.type && (
                      <Tag
                        variant={"solid"}
                        colorScheme="primary"
                        borderRadius="full"
                      >
                        <TagLabel>{filterState.type.name}</TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            setFilterState({
                              ...filterState,
                              type: undefined,
                            });
                          }}
                        />
                      </Tag>
                    )}
                    <SearchableMenu
                      items={[
                        {
                          id: "JOB_CHANGE",
                          name: "Changed jobs",
                        },
                        {
                          id: "JOB_PROMOTION",
                          name: "Got promoted",
                        },
                      ]}
                      label={"Add highlights"}
                      onItemClick={(i) => {
                        setFilterState({
                          ...filterState,
                          type: i,
                        });
                      }}
                      item={(i) => {
                        return <MenuItem>{i.name}</MenuItem>;
                      }}
                    />
                  </Flex>
                </Box>
                <Box flex={0.5}>
                  <Text>Account Type</Text>
                  <Flex gap={2} mt={2}>
                    {filterState.list && (
                      <Tag
                        variant={"solid"}
                        colorScheme="primary"
                        borderRadius="full"
                      >
                        <TagLabel>
                          {
                            lists?.data?.filter(
                              (l) => l._id === filterState.list
                            )?.[0]?.name
                          }
                        </TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            setFilterState({
                              ...filterState,
                              list: undefined,
                            });
                          }}
                        />
                      </Tag>
                    )}
                    <SearchableMenu
                      items={lists?.data || []}
                      label={"Select account"}
                      onItemClick={(i) => {
                        setFilterState({
                          ...filterState,
                          list: i?._id,
                        });
                      }}
                      item={(i) => {
                        return <MenuItem>{i.name}</MenuItem>;
                      }}
                    />
                  </Flex>
                </Box>
              </Flex>

              <Divider my={2} />
              <Flex
                flexWrap={"wrap"}
                direction={["column", "column", "row"]}
                gap={2}
              >
                <Box flex={0.5}>
                  <Text>Fan Score</Text>
                  <Flex gap={2} mt={2}>
                    {filterState.score && (
                      <Tag
                        variant={"solid"}
                        colorScheme="primary"
                        borderRadius="full"
                      >
                        <TagLabel>{filterState.score.name}</TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            setFilterState({
                              ...filterState,
                              score: undefined,
                            });
                          }}
                        />
                      </Tag>
                    )}
                    <SearchableMenu
                      items={[
                        {
                          id: "1",
                          name: "1",
                        },
                        {
                          id: "2",
                          name: "2",
                        },
                        {
                          id: "3",
                          name: "3",
                        },
                        {
                          id: "4",
                          name: "4",
                        },
                        {
                          id: "5",
                          name: "5",
                        },
                      ]}
                      label={"Select score"}
                      onItemClick={(i) => {
                        setFilterState({
                          ...filterState,
                          score: i,
                        });
                      }}
                      item={(i) => {
                        return <MenuItem>{i.name}</MenuItem>;
                      }}
                    />
                  </Flex>
                </Box>
                <Box flex={0.5}>
                  <Text>Company Headcount</Text>
                  <Flex gap={2} mt={2}>
                    {filterState.headcount && (
                      <Tag
                        variant={"solid"}
                        colorScheme="primary"
                        borderRadius="full"
                      >
                        <TagLabel>{filterState.headcount.name}</TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            setFilterState({
                              ...filterState,
                              headcount: undefined,
                            });
                          }}
                        />
                      </Tag>
                    )}
                    <SearchableMenu
                      items={[
                        {
                          id: "10-50",
                          name: "10-50",
                        },
                        {
                          id: "50-100",
                          name: "50-100",
                        },
                        {
                          id: "100-500",
                          name: "100-500",
                        },
                        {
                          id: "500-1000",
                          name: "500-1000",
                        },
                        {
                          id: "1000-2500",
                          name: "1000-2500",
                        },
                        {
                          id: "2500-5000",
                          name: "2500-5000",
                        },
                        {
                          id: "5000-10000",
                          name: "5000-10000",
                        },
                        {
                          id: "10000",
                          name: "10000+",
                        },
                      ]}
                      label={"Select headcount"}
                      onItemClick={(i) => {
                        setFilterState({
                          ...filterState,
                          headcount: i,
                        });
                      }}
                      item={(i) => {
                        return <MenuItem>{i.name}</MenuItem>;
                      }}
                    />
                  </Flex>
                </Box>
              </Flex>
              <Flex
                flexWrap={"wrap"}
                direction={["column", "column", "row"]}
                gap={2}
              >
                <Box flex={0.5}>
                  <Text>Sales Headcount</Text>
                  <Flex gap={2} mt={2}>
                    {filterState.salesHeadcount && (
                      <Tag
                        variant={"solid"}
                        colorScheme="primary"
                        borderRadius="full"
                      >
                        <TagLabel>{filterState.salesHeadcount.name}</TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            setFilterState({
                              ...filterState,
                              salesHeadcount: undefined,
                            });
                          }}
                        />
                      </Tag>
                    )}
                    <SearchableMenu
                      items={[
                        {
                          id: "10-50",
                          name: "10-50",
                        },
                        {
                          id: "50-100",
                          name: "50-100",
                        },
                        {
                          id: "100-500",
                          name: "100-500",
                        },
                        {
                          id: "500-1000",
                          name: "500-1000",
                        },
                        {
                          id: "1000-2500",
                          name: "1000-2500",
                        },
                        {
                          id: "2500",
                          name: "2500+",
                        },
                      ]}
                      label={"Select headcount"}
                      onItemClick={(i) => {
                        setFilterState({
                          ...filterState,
                          salesHeadcount: i,
                        });
                      }}
                      item={(i) => {
                        return <MenuItem>{i.name}</MenuItem>;
                      }}
                    />
                  </Flex>
                </Box>
                <Box flex={0.5}>
                  <Text>Duration</Text>
                  <Flex gap={2} mt={2}>
                    {filterState.duration && (
                      <Tag
                        variant={"solid"}
                        colorScheme="primary"
                        borderRadius="full"
                      >
                        <TagLabel>{filterState.duration.name}</TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            setFilterState({
                              ...filterState,
                              duration: undefined,
                            });
                          }}
                        />
                      </Tag>
                    )}
                    <SearchableMenu
                      items={[
                        {
                          id: "7 days",
                          name: "Last 7 days",
                        },
                        {
                          id: "15 days",
                          name: "Last 15 days",
                        },
                        {
                          id: "1 months",
                          name: "1 month",
                        },
                        {
                          id: "3 months",
                          name: "3 months",
                        },
                        {
                          id: "6 months",
                          name: "6 months",
                        },
                        {
                          id: "1 year",
                          name: "1 year",
                        },
                      ]}
                      label={"Duration"}
                      onItemClick={(i) => {
                        setFilterState({
                          ...filterState,
                          duration: i,
                        });
                      }}
                      item={(i) => {
                        return <MenuItem>{i.name}</MenuItem>;
                      }}
                    />
                  </Flex>
                </Box>
              </Flex>
              <Divider my={2} />
              <Box flex={0.5}>
                <Text>Current company</Text>
                <Flex gap={2} mt={2}>
                  {filterState.currentCompany.map((c, i) => (
                    <Tag
                      colorScheme={"primary"}
                      variant={"solid"}
                      borderRadius="full"
                    >
                      {/* <Avatar size='xs' src={c.company?.image} name={c.name} /> */}
                      <TagLabel fontWeight={"semibold"} ml={2}>
                        {c.name}
                      </TagLabel>
                      <TagCloseButton
                        onClick={() => {
                          const newState = _.cloneDeep(
                            filterState.currentCompany
                          );
                          delete newState[i];
                          setFilterState({
                            ...filterState,
                            currentCompany: newState.filter((f) => f?._id),
                          });
                        }}
                      />
                    </Tag>
                  ))}
                  <SearchableMenu
                    label={
                      filterState.currentCompany.length
                        ? "Select more"
                        : "Add company"
                    }
                    onItemClick={(i) => {
                      setFilterState({
                        ...filterState,
                        currentCompany: [...filterState.currentCompany, i],
                      });
                    }}
                    item={(i) => {
                      return (
                        <Flex alignItems={"center"}>
                          <Avatar
                            size={"sm"}
                            src={i.company?.image}
                            name={i.name}
                          />
                          <Box ml={2}>
                            <Text fontWeight={"medium"}>
                              {i.name.slice(0, 36)}
                              {i?.name?.length > 36 ? "..." : ""}
                            </Text>
                            {i.company?.headcount && (
                              <Text color={"gray"} fontSize={"sm"}>
                                {i.company?.headcount} employees
                              </Text>
                            )}
                          </Box>
                        </Flex>
                      );
                    }}
                    onInputChange={(e) => {
                      dispatch(searchCompanies(workspace.uuid, e.target.value));
                    }}
                  />
                </Flex>
              </Box>
              <Divider my={2} />
              <Box flex={0.5}>
                <Text>Past company</Text>
                <Flex gap={2} mt={2}>
                  {filterState.pastCompany.map((c, i) => (
                    <Tag
                      colorScheme={"primary"}
                      variant={"solid"}
                      borderRadius="full"
                    >
                      <Avatar size="xs" src={c.company?.image} name={c.name} />
                      <TagLabel fontWeight={"semibold"} ml={2}>
                        {c.name}
                      </TagLabel>
                      <TagCloseButton
                        onClick={() => {
                          const newState = _.cloneDeep(filterState.pastCompany);
                          delete newState[i];
                          setFilterState({
                            ...filterState,
                            pastCompany: newState.filter((f) => f?._id),
                          });
                        }}
                      />
                    </Tag>
                  ))}
                  <SearchableMenu
                    label={
                      filterState.pastCompany.length
                        ? "Select more"
                        : "Add company"
                    }
                    onItemClick={(i) => {
                      setFilterState({
                        ...filterState,
                        pastCompany: [...filterState.pastCompany, i],
                      });
                    }}
                    item={(i) => {
                      return (
                        <Flex alignItems={"center"}>
                          <Avatar src={i.company?.image} name={i.name} />
                          <Box ml={2}>
                            <Text>
                              {i.name.slice(0, 36)}
                              {i?.name?.length > 36 ? "..." : ""}
                            </Text>
                            {i.company?.headcount && (
                              <Text color={"gray"} fontSize={"sm"}>
                                {i.company?.headcount} employees
                              </Text>
                            )}
                          </Box>
                        </Flex>
                      );
                    }}
                    onInputChange={(e) => {
                      dispatch(searchCompanies(workspace.uuid, e.target.value));
                    }}
                  />
                </Flex>
              </Box>
              <Divider my={2} />
              <Box flex={0.5}>
                <Text>Role</Text>
                <Flex gap={2} mt={2}>
                  {filterState.role.map((c, i) => (
                    <Tag
                      colorScheme={"primary"}
                      variant={"solid"}
                      borderRadius="full"
                    >
                      <TagLabel ml={2}>{c.name}</TagLabel>
                      <TagCloseButton
                        onClick={() => {
                          const newState = _.cloneDeep(filterState.role);
                          delete newState[i];
                          setFilterState({
                            ...filterState,
                            role: newState.filter((f) => f?._id),
                          });
                        }}
                      />
                    </Tag>
                  ))}
                  <SearchableMenu
                    label={filterState.role.length ? "Add more" : "Select role"}
                    onItemClick={(i) => {
                      setFilterState({
                        ...filterState,
                        role: [...filterState.role, i],
                      });
                    }}
                    item={(i) => {
                      return <Text alignItems={"center"}>{i.name}</Text>;
                    }}
                    onInputChange={(e) => {
                      dispatch(searchRoles(workspace.uuid, e.target.value));
                    }}
                  />
                </Flex>
              </Box>
              <Divider my={2} />
            </Stack>
          </DrawerBody>
          <DrawerFooter justifyContent={"space-between"}>
            <Button
              size={"sm"}
              colorScheme="red"
              fontWeight={"medium"}
              variant={"outline"}
              mr={3}
              onClick={clearFilters}
            >
              Clear
            </Button>
            <Flex>
              <Button
                size={"sm"}
                onClick={applyFilters}
                fontWeight={"medium"}
                colorScheme="primary"
                variant="solid"
              >
                Apply Filters
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Stack p={4} px={0} pt={0} pb={0}>
        {!profile?.connectedAccounts?.length ? (
          <Card
            py={2}
            px={4}
            border={"1px solid #e4e4e4"}
            my={4}
            alignItems={"center"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            size={"sm"}
            rounded={8}
            boxShadow={0}
          >
            <Stack gap={1}>
              <Text fontWeight={"semibold"}>Connect you work calendar</Text>
              <Text color={"gray"} fontSize={"sm"}>
                Start syncing your fans whom you have met before
              </Text>
            </Stack>
            <Button
              onClick={() => {
                const protocol = window.location.protocol;
                const host = window.location.host.split(".");
                const domain = host.slice(host.length > 2 ? 1 : 0).join(".");
                const redirectUrl = `${protocol}//app.${domain}`;
                window.location.href = `${redirectUrl}/get-started?workspace=${workspace?.uuid}`;
              }}
              size={"sm"}
              rightIcon={<ExternalLink size={16} />}
              rounded={"full"}
              colorScheme="primary"
            >
              Connect now
            </Button>
          </Card>
        ) : profile?.connectedAccounts?.filter(
            (c) => c.syncStatus === "syncing"
          )?.length ? (
          <Card
            backgroundColor={"green.50"}
            py={2}
            px={4}
            border={"1px solid #e4e4e4"}
            borderColor={"green.100"}
            my={4}
            alignItems={"center"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            size={"sm"}
            rounded={8}
            boxShadow={0}
          >
            <Stack gap={1}>
              <Text color={"green.900"} fontWeight={"semibold"}>
                Your calendar is being synced
              </Text>
              <Text color={"gray"} fontSize={"sm"}>
                Please wait while we are syncing your calendar for fans. This
                could take a moment
              </Text>
            </Stack>
            <Button
              isLoading={true}
              loadingText={"Syncing"}
              isDisabled={false}
              variant={"outline"}
              size={"sm"}
              leftIcon={<RefreshCcwIcon size={16} />}
              rounded={"full"}
              colorScheme="green"
            >
              Syncing
            </Button>
          </Card>
        ) : (
          <></>
        )}
        <Separator />
        <Flex alignItems={"center"} py={1.5} px={4} gap={2}>
          <Box pos={"relative"}>
              <SearchIcon className="absolute left-3 top-2 h-4 w-4 rounded-full text-muted-foreground" />
              <Input onChange={(e) => {
                setFilters({
                  ...filters,
                  append: false,
                  keyword: e.target.value,
                });
              }} placeholder="Search for fans" className="pl-8 w-60 h-8 rounded-full" />
          </Box>
          <Button variant={"outline"} className={"gap-1 border-dashed border-gray-300 border text-muted-foreground rounded-xl"}>
            <ArrowDownAZIcon size={16} />
            <Text>Sort</Text>
          </Button>
          <Separator orientation="vertical" className={"h-6"} />
          <Button onClick={() => {
            onOpen()
          }} variant={"outline"} className={"gap-1 border-dashed border-gray-300 border text-muted-foreground rounded-xl"}>
            <SlidersHorizontalIcon size={16} />
            <Text>Filter</Text>
          </Button>
        </Flex>
        <Stack gap={0} justifyContent={"space-between"} height={"calc(100vh - 125px)"}>
        <Box flex={1} h={"calc(100vh - 125px - 90px)"} overflow={"scroll"} onScroll={(event) => {
          const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
          if (!isLoading) {
            if (scrollHeight - scrollTop - 0.5 === clientHeight) {
              // User has scrolled to the bottom, trigger data fetching
              setFilters({
                ...filters,
                append: true
              })
            }
          }
        }} className="border border-x-0">
          <Table className={"table-fixed"}>
            <TableHeader>
            <TableRow >
                  {[
                    {
                      name: "Name",
                    },
                    {
                      name: "LinkedIn",
                      icon: <LinkedinIcon size={16} strokeWidth={2} />
                    },
                    {
                      name: "Role",
                      icon: <AArrowUpIcon size={16} strokeWidth={2} />
                    },
                    {
                      name: "Company",
                      icon: <BuildingIcon size={16} strokeWidth={2} />
                    },
                    {
                      name: "Fan Strength",
                      icon: <ZapIcon size={16} strokeWidth={2} />
                    },
                    {
                      name: "Highlights",
                      icon: <AudioLinesIcon size={16} strokeWidth={2} />
                    }
                  ].map((header, index, arr) => {
                    return (
                      <TableHead colspan={[0, 2, 5].includes(index) ? 2 : 1} className={`h-10 font-semibold text-black ${index === 0 ? "border-l-0" : ""}`} width={180}>
                          <Flex gap={1} alignItems={"center"}>
                          {header.icon}
                          {header.name}
                          </Flex>
                      </TableHead>
                    )
                  })}
                </TableRow>
            </TableHeader>
            <TableBody>
              {fans?.length ? (
                fans.map((e) => {
                  const location = e.profile.location?.name;
                  const startedOn = e.path[0].positions[0].startedOn;
                  const startedOnFormatted = moment.parseZone(startedOn).format( "MMM yyyy")
                  const role = e.path[0].positions[0].name;
                  const company = e.path?.[0]?.company || {};
                  const fanSince = moment.parseZone(e.fanSince).fromNow(true)
                  const now = moment()
                  const jobDiff = now.diff(startedOn, "months")
                  const isPromotion = e.path[0].positions.length > 1
                  const image =
                    !e?.profile?.images
                      ? e.profile.cover
                      : e.profile.image;
                  return (
                    <TableRow
                      // data-state={row.getIsSelected() && "selected"}
                    >
                    <TableCell onClick={() => {
                      setCurrentFan?.(e.uuid)
                      onFanModalOpen();
                    }} colspan={2} className={"px-4 cursor-pointer border-l-0 group"}>
                      {/* <Checkbox
                        iconSize={14}
                        className="h-4 w-4"
                          aria-label="Select all"
                        /> */}
                        <HoverCard openDelay={300}>
                          <HoverCardTrigger>
                            <Flex gap={2} alignItems={"center"}>
                              <Avatar>
                                <AvatarImage  className={"w-4 h-4 rounded-xl"} src={image} />
                                <AvatarFallback style={{
                                        backgroundColor: `${generateHSL(
                                          generateFallbackText(e.fullName)
                                        )}`,
                                      }} className={"w-4 h-4 text-xs"}>{generateFallbackText(e.firstName)}</AvatarFallback>
                              </Avatar>
                              <Text flex={1} fontWeight={500} noOfLines={1}>{e.fullName}</Text>
                              <span onClick={async (ev) => {
                                ev.preventDefault()
                                ev.stopPropagation()
                                await window.navigator.clipboard.writeText(e.fullName)
                                toast({
                                  title: "Copied to clipboard"
                                })
                              }} size={"xs"} className={`h-auto transition opacity-0 text-gray-500 bg-transparent translate-x-4 delay-300 group-hover:opacity-100 duration-300 group-hover:translate-x-2`}>
                                <CopyIcon size={14} />
                              </span>
                            </Flex>
                            </HoverCardTrigger>
                            <HoverCardContent align={"start"} className={"w-80"}>
                              <Flex gap={2} alignItems={"center"}>
                                <Avatar>
                                  <AvatarImage  className={"w-10 h-10 rounded-full"} src={image} />
                                  <AvatarFallback style={{
                                          backgroundColor: `${generateHSL(
                                            generateFallbackText(e.fullName)
                                          )}`,
                                        }} className={"w-10 h-10 text-sm rounded-full"}>{generateFallbackText(e.firstName)}</AvatarFallback>
                                </Avatar>
                                <Box>
                                  <Text className="font-semibold text-base">{e.fullName}</Text>
                                  <Text mt={-0.5} className="text-sm text-muted-foreground">{location}</Text>
                                  <Text mt={-0.5} className="text-primary underline" onClick={(ev) => {
                                    ev.preventDefault()
                                    window.open(`https://www.linkedin.com/in/${e.profile?.linkedIn}`, "_blank")
                                  }}>{e.profile.linkedIn}</Text>
                                </Box>
                              </Flex>
                              <Badge className={"mt-2"} variant="outline">Fan since {fanSince}</Badge>
                            </HoverCardContent>
                          </HoverCard>
                    </TableCell>
                    <TableCell colspan={1} className={"group"}>
                      <Flex alignItems={"center"}>
                      <Text flex={1} onClick={() => {
                        window.open(`https://www.linkedin.com/in/${e.profile?.linkedIn}`, "_blank")
                      }} className="underline cursor-pointer" noOfLines={1}>{e.profile?.linkedIn}</Text>
                      <span onClick={async (ev) => {
                                ev.preventDefault()
                                ev.stopPropagation()
                                await window.navigator.clipboard.writeText(`https://www.linkedin.com/in/${e.profile?.linkedIn}`)
                                toast({
                                  title: "Copied to clipboard"
                                })
                              }} size={"xs"} className={`h-auto transition opacity-0 text-gray-500 bg-transparent translate-x-4 delay-300 group-hover:opacity-100 duration-300 group-hover:translate-x-2`}>
                                <CopyIcon size={14} />
                              </span>
                      </Flex>
                    </TableCell>
                    <TableCell colspan={2}>
                      <Text noOfLines={1}>{role}</Text>
                    </TableCell>
                    <TableCell colspan={1}>
                      <Flex gap={2} alignItems={"center"}>
                        <Avatar>
                          <AvatarImage  className={"w-4 h-4 rounded-xl"} src={company?.image} />
                          <AvatarFallback style={{
                                  backgroundColor: `${generateHSL(
                                    generateFallbackText(company?.name)
                                  )}`,
                                }} className={"w-4 h-4 text-xs"}>{generateFallbackText(company?.name)}</AvatarFallback>
                        </Avatar>
                        <Text fontWeight={500} noOfLines={1}>{company?.name}</Text>
                      </Flex>
                    </TableCell>
                    <TableCell colspan={1}>
                      <Badge variant="transparent" className={"text-sm text-medium gap-2"}>{
                        e.score > 4.4 ? <ZapIcon size={16} fill="#22c55e" stroke={"#22c55e"} /> : <div className={`w-2.5 h-2.5 rounded-sm ${e.score > 4 ? "bg-green-500" : e.score > 3 ? "bg-blue-500" : e.score  > 2 ? "bg-yellow-500" : "bg-red-500"}`} />
                        }{
                        e.score > 4.4 ? "Very Strong" : e.score > 4 ? "Strong" : e.score > 3 ? "Good" : e.score > 2 ? "Weak" : "Very Weak"
                      }</Badge>
                    </TableCell>
                    <TableCell colspan={2}>
                      <Flex>
                      {jobDiff <= 3 ? <Badge variant={isPromotion ? "warning-subtle" : "default-subtle"}>{isPromotion ? `Got promoted recently` : `Changed jobs recently`}</Badge> : <></>}
                      </Flex>
                    </TableCell>
                    </TableRow>
                  )})
              ) : !isLoading && !fans?.length ? (
                <TableRow>
                  <TableCell colSpan={9} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              ) : <></>}
              {isLoading ? (
                [...Array(20)].map(e => <TableRow>
                  <TableCell colSpan={2} className="text-center">
                   <Flex alignItems={"center"} gap={2}>
                   <Skeleton className="w-5 h-4 rounded-full" />
                   <Skeleton className="size-4 w-full h-2" />
                   </Flex>
                  </TableCell>
                  <TableCell colSpan={1} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                  <TableCell colSpan={2} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                  <TableCell colSpan={1} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                  <TableCell colSpan={1} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                  <TableCell colSpan={2} className="text-center">
                    <Skeleton className="size-4 w-full h-2" />
                  </TableCell>
                </TableRow>)
              ) : <></>}
            </TableBody>
          </Table>
          
        </Box>
        <Table className={"table-fixed border-0"}>
            <TableHeader>
            <TableRow className={"border-0"} style={{
                borderWidth: 0
              }}>
                      <TableHead colspan={2} className={`h-9 font-semibold text-black`} width={180}>
                          <Box>{total} fans</Box>
                      </TableHead>
                      <TableHead colspan={1} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                      <TableHead colspan={2} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                      <TableHead colspan={1} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                      <TableHead colspan={1} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                      <TableHead colspan={2} className={`h-9 font-semibold text-black`} width={180}>
                          
                      </TableHead>
                </TableRow>
            </TableHeader>
            </Table>
        </Stack>
      </Stack>
    </Page>
  );
}

export default FanDirectory;
