import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";
import { Center, Spinner, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { axios } from "../../../loaders/axios.loader";

export function CommunicationPreferences () {
    const [params] = useSearchParams();
    const [status, setStatus] = useState("loading")

    useEffect(() => {
        try {
            axios.post('/shared/update-communications', {
                type: params.get("type"),
                token: params.get("token")
            }).then(() => {
                setStatus("success")
            }).catch(setStatus("error"))
        } catch (err) {
            setStatus("error")
        }
    }, [ params ])


    return (
        <Center gap={8} h={"100vh"}>
            <Stack alignItems={"center"}>
                {status === "error" ? <>
                    <CloseIcon boxSize={4} color={"red.500"} />
                    <Text>Failed to update preferences</Text></>
                    : status === "success" ? <>
                    <CheckCircleIcon boxSize={8} color={"green.500"} />
                    <Text>Thanks for the acknowledgement</Text></> : <><Spinner /><Text>Please wait</Text></>
                }
            </Stack>
        </Center>
    )
}