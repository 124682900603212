import { LOAD_WORKSPACE_ACCOUNTS, SET_WORKSPACE_ACCOUNTS, SET_WORKSPACE_ACCOUNTS_ERROR, SET_SPECIFIC_ACCOUNT,
LOAD_SPECIFIC_ACCOUNT, SET_SPECIFIC_ACCOUNT_LOAD_ERROR } from "./constants"

const INITIAL_STATE = {
    isLoading: false,
    data: [],
    error: '',
    account: {
        isLoading: true,
        error: '',
        data: {}
    }
}

const AccountsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_WORKSPACE_ACCOUNTS:
            return {
                ...state,
                error: '',
                isLoading: true
            }
        case SET_WORKSPACE_ACCOUNTS:
            return {
                ...state,
                error: '',
                isLoading: false,
                data: action.payload
            }
        case SET_WORKSPACE_ACCOUNTS_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        case LOAD_SPECIFIC_ACCOUNT:
            return {
                ...state,
                account: {
                ...state.account,
                isLoading: true,
                data: {},
                error: ' '
                    }
            }
        case SET_SPECIFIC_ACCOUNT:
            return {
                ...state,
                account: {
                    ...state.account,
                    isLoading: false,
                    data:action.payload,
                    error: ''
                }
            }
        case SET_SPECIFIC_ACCOUNT_LOAD_ERROR:
            return {
                ...state,
                account: {
                    ...state.account,
                    isLoading: false,
                    data: {},
                    error: action.error
                }
            }
        default:
            return state
    }
}


export default AccountsReducer