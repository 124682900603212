'use client'

import * as React from 'react'
import * as SwitchPrimitives from '@radix-ui/react-switch'

import { ny } from '../../lib/utils'

const Switch = React.forwardRef(({ className, ...props }, ref) => (
   <SwitchPrimitives.Root
      className={ny(
         'focus-visible:ring-ring focus-visible:ring-offset-background data-[state=checked]:bg-primary data-[state=unchecked]:bg-input peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
         className
      )}
      {...props}
      ref={ref}>
      <SwitchPrimitives.Thumb
         style={{
            width: 16,
            height: 16
         }}
         className={ny(
            'bg-background pointer-events-none block size-5 rounded-full shadow-lg size-12 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0.5'
         )} />
   </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
