import {
    Button,
    Tag,
    Flex,
    Box,
    Text,
    Stack,
    Link,
    TabList,
    Tab,
    Tabs,
    ButtonGroup,
    Modal,
    useDisclosure,
    ModalBody,
    ModalOverlay,
    ModalFooter,
    ModalContent,
    ModalHeader,
    Tr,
    Td,
    Checkbox,
    Avatar,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons';
import Page from '../../../components/core/@page';
import Table from '../../../components/core/@table';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getWorkspaceAccounts } from '../../../redux/app/accounts/thunk';
import AccountDetail from '../account';

function Accounts(props) {
    const { onOpen, isOpen, onClose } = useDisclosure()
    const { onOpen: onAccountModalOpen, isOpen: isAccountModalOpen, onClose: onAccountModalClose } = useDisclosure()
    const workspace = useSelector(state => state.dashboard.core.workspace);
    const lists = useSelector(state => state.dashboard.core.lists);
    const isLoading = useSelector(state => state.dashboard.accounts.isLoading)
    const accounts = useSelector(state => state.dashboard.accounts.data)
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1)
    const [currentAccount, setCurrentAccount] = useState(null)
    const [tabs, setTabs] = useState([
        {
            "name": "all accounts"
        }
    ])

    useEffect(() => {
        dispatch(getWorkspaceAccounts(workspace.uuid, currentPage))
    }, [])

    useEffect(() => {
        setTabs(lists.data.filter(d => ![ "fans", "stale" ].includes(d.type)).map(d => ({
            "id": d._id,
            "name": d.name
        })))
    }, [ lists ])

    return (
        <Page
            title={"accounts"}
            subtitle={"list of all accounts"}
            tabs={
                <Tabs size={"sm"} variant="solid-rounded" colorScheme="primary">
                    <TabList>
                        <Tab>all accounts</Tab>
                        <Tab>pipeline</Tab>
                        <Tab>prospect</Tab>
                        <Tab>new prospects</Tab>
                    </TabList>
                </Tabs>
            }
            actions={
                <ButtonGroup size={"sm"} >
                    <Button variant={'outline'} leftIcon={<i className="fi fi-rr-cloud-download-alt"></i>}>import</Button>
                    <Button colorScheme="primary" size={"sm"} leftIcon={<AddIcon boxSize={3} />}>create</Button>
                </ButtonGroup>
            }
        >
            <Modal size={"xl"} isCentered onClose={onClose} isOpen={isOpen}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalHeader>apply filters</ModalHeader>
                    <ModalBody>

                    </ModalBody>

                    <ModalFooter>
                        <Button variant={"outline"} mr={3} size={"sm"} onClick={onClose}>
                            clear
                        </Button>
                        <Button colorScheme='primary' size={"sm"} variant='solid'>apply filters</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal scrollBehavior="outside" size={"3xl"} isCentered onClose={() => {
                setCurrentAccount(null)
                onAccountModalClose()
            }} isOpen={isAccountModalOpen}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    {currentAccount && <AccountDetail id={currentAccount} />}
                </ModalContent>
            </Modal>
            <Stack p={4}>
                <Table
                isLoading={isLoading}
                filterFunc={onOpen}
                page={currentPage}
                total={'-'}
                filterCount={2}
                nextFunc={() => {
                    dispatch(getWorkspaceAccounts(workspace.uuid, currentPage + 1))
                    setCurrentPage(currentPage + 1)
                }}
                previousFunc={() => {
                    dispatch(getWorkspaceAccounts(workspace.uuid, currentPage - 1))
                    setCurrentPage(currentPage - 1)
                }}
                headers={[
                    "account name",
                    "location",
                    "headcount",
                    "industry",
                    "tags"
                ]}
                >
                    {accounts.map(account => {
                        return (
                            <Tr onClick={() => {
                        
                            }}>
                                <Td onClick={() => {
                                    setCurrentAccount(account.uuid)
                                    onAccountModalOpen()
                            }}>
                                    <Flex alignItems={"center"}>
                                        <Stack direction={"row"} alignItems={"center"} mr={2}>
                                            <Checkbox colorScheme='primary' />
                                            <Avatar fontSize={"sm"} name={account.company.name} boxSize={10} src={account.company.image} />
                                        </Stack>
                                        <Box>
                                            <Text>{account.company.name}</Text>
                                            <Link color={"gray"} target="_blank" href={`https://www.linkedin.com/company/${account.company.linkedIn}`}>@{account.company.linkedIn}</Link>
                                        </Box>
                                    </Flex>
                                </Td>
                                <Td>{account?.company?.location?.name}</Td>
                                <Td>{account?.company?.headcount} people</Td>
                                <Td>
                                    {account?.company?.industries?.[0]?.name}
                                </Td>
                                <Td>
                                    
                                </Td>
                            </Tr>
                        )
                    })}
                    
                </Table>
            </Stack>
        </Page>
    )
}

export default Accounts