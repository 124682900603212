export const SET_PROFILE_IMAGE_UPLOAD_LOADING = "profile/image/upload/loading";
export const SET_PROFILE_IMAGE_UPLOAD_SUCCESS = "profile/image/upload/success";
export const SET_PROFILE_IMAGE_UPLOAD_ERROR = "profile/image/upload/error";

export const SET_GET_PROFILE_LOADING = "profile/get/loading";
export const SET_GET_PROFILE_SUCCESS = "profile/get/success";
export const SET_GET_PROFILE_ERROR = "profile/get/error";

export const SET_UPDATE_PROFILE_LOADING = "profile/update/loading";
export const SET_UPDATE_PROFILE_SUCCESS = "profile/update/success";
export const SET_UPDATE_PROFILE_ERROR = "profile/update/error";
