'use client'
import { motion } from 'framer-motion';
import { SparklesIcon } from 'lucide-react';

const animationProps = {
   initial: { '--x': '100%', 'scale': 0.8 },
   animate: { '--x': '-100%', 'scale': 1 },
   whileTap: { scale: 0.95 },

   transition: {
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 1,
      type: 'spring',
      stiffness: 20,
      damping: 15,
      mass: 2,
      scale: {
         type: 'spring',
         stiffness: 200,
         damping: 5,
         mass: 0.5,
      },
   }
}

function ShinyButton({ text = 'shiny-button', onClick }) {
   return (
      (<motion.button
         {...animationProps}
         onClick={onClick}
         className="relative text-black border border-gray-200 rounded-full size-sm px-4 py-1.5 font-medium backdrop-blur-xl transition-shadow duration-300 ease-in-out hover:shadow dark:bg-[radial-gradient(circle_at_50%_0%,hsl(var(--primary)/10%)_0%,transparent_60%)] dark:hover:shadow-[0_0_20px_hsl(var(--primary)/10%)]">
         <span
            className="relative font-semibold rounded-full flex gap-2 text-sm tracking-wide text-[rgb(0,0,0,85%)] dark:text-[rgb(255,255,255,90%)]"
            style={{
               maskImage:
            'linear-gradient(-75deg,hsl(var(--primary)) calc(var(--x) + 20%),transparent calc(var(--x) + 30%),hsl(var(--primary)) calc(var(--x) + 100%))',
            }}>
            <SparklesIcon size={16} />
            {text}
         </span>
         <span
            style={{
               mask: 'linear-gradient(rgb(0,0,0), rgb(0,0,0)) content-box,linear-gradient(rgb(0,0,0), rgb(0,0,0))',
               maskComposite: 'exclude',
            }}
            className="absolute inset-0 z-10 block rounded-[inherit] bg-[linear-gradient(-75deg,hsl(var(--primary)/10%)_calc(var(--x)+20%),hsl(var(--primary)/50%)_calc(var(--x)+25%),hsl(var(--primary)/10%)_calc(var(--x)+100%))] p-px">
         </span>
      </motion.button>)
   );
}

export default ShinyButton
