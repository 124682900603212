import { RESET_FAN_CONVERT, SET_CALENDAR_RECOMMENDATIONS_ERROR, SET_CALENDAR_RECOMMENDATIONS_LOADING, SET_CALENDAR_RECOMMENDATIONS_SUCCESS, SET_FAN_CONVERT_ERROR, SET_FAN_CONVERT_LOADING, SET_FAN_CONVERT_SUCCESS } from "./constants";

export function setFanCalendarRecommendationsLoading () {
    return {
        type: SET_CALENDAR_RECOMMENDATIONS_LOADING
    }
}

export function setFanCalendarRecommendationsSuccess(payload, replace) {
    return {
        type: SET_CALENDAR_RECOMMENDATIONS_SUCCESS,
        payload,
        replace
    }
}

export function setFanCalendarRecommendationsError(error) {
    return {
        type: SET_CALENDAR_RECOMMENDATIONS_ERROR,
        error
    }
}

export function setFanConvertLoading () {
    return {
        type: SET_FAN_CONVERT_LOADING
    }
}

export function setFanConvertSuccess (payload) {
    return {
        type: SET_FAN_CONVERT_SUCCESS,
        payload
    }
}

export function setFanConvertError (error) {
    return {
        type: SET_FAN_CONVERT_ERROR,
        error
    }
}

export function resetFanConvert () {
    return {
        type: RESET_FAN_CONVERT
    }
}