import { axios } from "../../../loaders/axios.loader"
import { setConnectionError, setConnectionSuccess } from "./actions"

export const initiateConnection = (workspace, provider, code, redirectUrl, name) => async (dispatch) => {
    try {
        const request = await axios.post(`/workspaces/${workspace}/apps/connections/create`, {
            code,
            redirectUrl,
            provider,
            name
        })
        const { response } = request.data;
        dispatch(setConnectionSuccess(response))
    } catch (err) {
        dispatch(setConnectionError(err?.response?.data?.message || err?.message))
    }
}